export const BidType = {
  Energy: "ENERGY",
  Lower5min: "LOWER5MIN",
  Lower60sec: "LOWER60SEC",
  Lower6sec: "LOWER6SEC",
  Lowerreg: "LOWERREG",
  Raise5min: "RAISE5MIN",
  Raise60sec: "RAISE60SEC",
  Raise6sec: "RAISE6SEC",
  RaiseReg: "RAISEREG"
};
