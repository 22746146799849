import React, { useCallback, useState } from "react";
import { Redirect } from "react-router-dom";
import { AppContainer } from "../components";
import { DBFields, InternalLinks } from "../utils/constants";
import { RoleForm } from "../components/forms";
import { Col, Row } from "antd";
import { ApiMethodTypes } from "../hooks";
import { AuthApi } from "../utils/api";

const POST_URL = AuthApi.PostRoleUrl();

export const RoleNew = ({ history, match }) => {
  const [roleId, setRoleId] = useState("");

  const onSaveSuccess = useCallback(
    ({ data }) => {
      setRoleId(data[DBFields.RoleId]);
    },
    [setRoleId]
  );

  return (
    <AppContainer match={match} history={history}>
      {roleId && <Redirect to={InternalLinks.Role({ roleId })} />}
      <Row gutter={16}>
        <Col xl={12} sm={24}>
          <RoleForm
            submitUrl={POST_URL}
            submitUrlMethodType={ApiMethodTypes.Post}
            onSaveSuccess={onSaveSuccess}
          />
        </Col>
      </Row>
    </AppContainer>
  );
};
