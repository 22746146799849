import { ValidationMessageCompareLessThan } from "../validation_messages";
import { CheckValueLessThan } from "../checks";
import { ValidationMessageType } from "../enums";

export const ValidatePriceBandAll = (
  currentValue,
  prevValue,
  nextValue,
  currentFieldName,
  prevFieldName,
  nextFieldName
) => {
  let validations = [];

  if (nextValue !== null) {
    //Validate: Compare price band value with next one
    const check2 = CheckValueLessThan(currentValue, nextValue);
    if (!check2)
      validations.push(
        ValidationMessageCompareLessThan(
          ValidationMessageType.Error,
          "Value",
          nextFieldName
        )
      );
  }

  return validations;
};
