import { useReducer, useCallback } from "react";
import axios from "axios";
import { ApiStatus } from "../utils/constants";
import { Auth } from "../utils/auth";

const auth = new Auth();
const API = axios.create({
  // baseURL: "https://api.auth.pdzinc.com.au"
  // headers: {
  //   Authorization: `Bearer ${localStorage.getItem("pd_access_token")}` local storage might not be set
  // }
});

API.interceptors.request.use(async config => {
  if (!config.headers.common.Authorization) {
    //adding auth token
    config.headers.common["Authorization"] = `Bearer ${localStorage.getItem(
      "pd_access_token"
    )}`;
  }
  return config;
});

API.interceptors.response.use(
  async res => {
    //do nothing
    return res;
  },
  async error => {
    console.error("API request failing", { error: error });
    const errorStatus = error.response.status;
    switch (errorStatus) {
      case 401:
        //unauth, most likely token expired, so refresh it
        auth.login();
        break;
      // case 403: handled in sendRequest promise below
      //   auth.forbidden();
      //   break;

      default:
        //do nothing
        break;
    }
    throw error;
    // return error;
  }
);

const initialState = {
  apiStatus: null,
  data: null,
  error: null
};

const ApiActionTypes = {
  Send: "send",
  Response: "response",
  Error: "error",
  Clear: "clear",
  Forbidden: "forbidden",
  ConfigError: "configError" //404
};

export const ApiMethodTypes = {
  Get: "get",
  Post: "post",
  Put: "put",
  Delete: "delete"
};

const apiReducer = (oldState, action) => {
  switch (action.type) {
    case ApiActionTypes.Send:
      return {
        ...oldState,
        apiStatus: ApiStatus.Loading,
        data: null
      };

    case ApiActionTypes.Response:
      return {
        ...oldState,
        apiStatus: ApiStatus.Success,
        data: action.data
      };

    case ApiActionTypes.Error:
      return {
        ...oldState,
        apiStatus: ApiStatus.Failed,
        error: action.error
      };

    case ApiActionTypes.Forbidden:
      return {
        ...oldState,
        apiStatus: ApiStatus.Forbidden,
        error: action.error
      };

    case ApiActionTypes.ConfigError:
      return {
        ...oldState,
        apiStatus: ApiStatus.ConfigError,
        error: action.error
      };

    case ApiActionTypes.Clear:
      return initialState;

    default:
      throw new Error(
        `Not implemented action type=${action.type} in apiReducer`
      );
  }
};

export const useApi = ({ method }) => {
  const [apiState, dispatchApi] = useReducer(apiReducer, initialState);

  //reset
  const clear = useCallback(
    () => dispatchApi({ type: ApiActionTypes.Clear }),
    []
  );

  const handleError = ({ error }) => {
    if (error && error.response && error.response.status) {
      const status = error.response.status;
      switch (status) {
        case 403:
          dispatchApi({ type: ApiActionTypes.Forbidden, error: error });
          break;

        case 404:
          dispatchApi({ type: ApiActionTypes.ConfigError, error: error });
          break;
        default:
          dispatchApi({
            type: ApiActionTypes.Error,
            error: error
          });
          break;
      }
    }

    console.error("Error in API request", error);
  };

  const sendRequest = useCallback(
    ({ url, data }) => {
      dispatchApi({ type: ApiActionTypes.Send }); //set loading state
      switch (method) {
        case ApiMethodTypes.Get:
          API.get(url)
            .then(response =>
              dispatchApi({
                type: ApiActionTypes.Response,
                data: response.data
              })
            )
            .catch(error => {
              handleError({ error });
            });
          break;

        case ApiMethodTypes.Post:
          API.post(url, data)
            .then(response =>
              dispatchApi({
                type: ApiActionTypes.Response,
                data: response.data
              })
            )
            .catch(error => {
              handleError({ error });
            });
          break;

        case ApiMethodTypes.Put:
          API.put(url, data)
            .then(response =>
              dispatchApi({
                type: ApiActionTypes.Response,
                data: response.data
              })
            )
            .catch(error => {
              handleError({ error });
            });
          break;

        case ApiMethodTypes.Delete:
          API.delete(url, data)
            .then(response =>
              dispatchApi({
                type: ApiActionTypes.Response,
                data: response.data
              })
            )
            .catch(error => {
              handleError({ error });
            });
          break;
        default:
          throw new Error(
            `API method type=${method} not implemented in useApi`
          );
      }
    },
    [method]
  );

  return {
    apiStatus: apiState.apiStatus,
    error: apiState.error,
    data: apiState.data,
    sendRequest: sendRequest,
    clear: clear
  };
};
