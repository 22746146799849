import React from "react";
import PropTypes from "prop-types";
import HighCharts from "highcharts";
import HighChartsReact from "highcharts-react-official";
// import HighchartsDrilldown from "highcharts/modules/drilldown";
// import HighchartsPattern from "highcharts/modules/pattern-fill";
import HighChartsExporting from "highcharts/modules/exporting";
//import HighchartsExportData from "highcharts/modules/export-data";
HighChartsExporting(HighCharts);
//HighchartsExportData(Highcharts);

// HighchartsDrilldown(Highcharts);
// HighchartsPattern(Highcharts);
HighCharts.setOptions({
  lang: { loading: "Loading data..." },
  credits: { enabled: false }
});

export const Chart = ({
  title,
  subTitle,
  series,
  chartHeight,
  chartWidth,
  xAxis,
  yAxis,
  plotOptions,
  legend,
  valueDecimals,
  tooltip
}) => {
  const options = {
    exporting: {
      enabled: true
    },
    chart: {
      height: chartHeight,
      width: chartWidth,
      zoomType: "xy"
    },
    title: {
      text: title
    },
    subtitle: {
      text: subTitle
    },
    series: series,
    xAxis: xAxis,
    yAxis: yAxis,
    legend: {
      ...legend
    },
    plotOptions: { ...plotOptions },
    tooltip: {
      shared: true,
      valueDecimals: valueDecimals,
      ...tooltip
    }
  };
  return (
    <HighChartsReact
      highcharts={HighCharts}
      options={options}
      // immutable={true} //this fixes issue with offer chart but causes chart to refresh every few seconds on dashboard
    />
  );
};

Chart.propTypes = {
  series: PropTypes.array,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  chartHeight: PropTypes.number,
  chartWidth: PropTypes.number,
  yAxis: PropTypes.array,
  xAxis: PropTypes.array,
  plotOptions: PropTypes.object,
  legend: PropTypes.object,
  valueDecimals: PropTypes.number
  // events: PropTypes.object
  // drilldown: PropTypes.object
};
