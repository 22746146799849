import React from "react";
import { string } from "prop-types";

import { DisplayTimeAgo } from "../../generic";
import { NavLink } from "react-router-dom";

export const GridCellTimeAgoLink = React.memo(({ time, link, text }) => {
  return (
    <span>
      {link && (
        <NavLink to={link}>
          {time ? <DisplayTimeAgo datetime={time} /> : <span>{text}</span>}
        </NavLink>
      )}
    </span>
  );
});

GridCellTimeAgoLink.propTypes = {
  time: string,
  link: string,
  text: string
};

GridCellTimeAgoLink.defaultProps = {
  text: "click"
};
