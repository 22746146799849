import React from "react";
import { string, func, object, bool } from "prop-types";

import { ButtonPrimary } from "../generic";

export const ButtonActionPrimary = ({
  text,
  tooltip,
  style,
  onClick,
  disabled
}) => (
  <ButtonPrimary
    style={{ margin: "4px", ...style }}
    onClick={onClick}
    title={tooltip}
    disabled={disabled}
  >
    {text}
  </ButtonPrimary>
);

ButtonActionPrimary.propTypes = {
  text: string.isRequired,
  tooltip: string,
  style: object,
  onClick: func.isRequired,
  disabled: bool
};
