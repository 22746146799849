import React from "react";
import { any, number } from "prop-types";
import { Alert } from "antd";

export const ActionTargetCheck = React.memo(({ targetsCount, children }) => {
  return (
    <>
      {targetsCount > 0 ? (
        <>{children}</>
      ) : (
        <Alert
          showIcon={true}
          message={"No targets selected"}
          description={"Please select targets from the grid"}
          type={"warning"}
        />
      )}
    </>
  );
});

ActionTargetCheck.propTypes = {
  children: any.isRequired,
  targetsCount: number.isRequired
};
