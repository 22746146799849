import React from "react";
import { bool } from "prop-types";
import {
  CheckCircleTwoTone,
  ExclamationCircleTwoTone
} from "@ant-design/icons";

import { BidCheckStatuses } from "../../../utils";

const renderIcon = status => {
  switch (status) {
    case BidCheckStatuses.PASS:
      return (
        <span>
          <CheckCircleTwoTone twoToneColor={"#52c41a"} /> PASS
        </span>
      );
    case BidCheckStatuses.FAIL:
      return (
        <span>
          <ExclamationCircleTwoTone twoToneColor={"#f5222d"} /> FAIL
        </span>
      );

    default:
      console.error(`Not implemented status=${status} in renderIcon`);
      return null;
  }
};
export const GridCellBidCheck = ({ value }) => (
  <span>
    {value !== undefined && value !== null && <span>{renderIcon(value)}</span>}
  </span>
);

GridCellBidCheck.propTypes = {
  status: bool
};
