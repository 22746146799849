import React from "react";
import { string, number } from "prop-types";

export const IconWarning = ({ size, color }) => (
  <svg
    height={size}
    width={size}
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    x="0px"
    y="0px"
    viewBox="0 0 100 100"
  >
    <g transform="translate(0,-952.36218)">
      <path
        d="m 50.000003,970.36218 c -2.09247,0 -4.02278,1.04252 -5.09375,2.84375 l -31.093749,52.43747 c -1.07121,1.8064 -1.07403,4.0391 0,5.8438 0.89618,1.5059 2.59149,2.8802 4.6875,2.875 l 62.999999,0 c 2.09536,0 3.79123,-1.369 4.6875,-2.875 1.07401,-1.8047 1.07121,-4.0374 0,-5.8438 l -31.09375,-52.43747 c -1.07097,-1.80123 -3.00129,-2.84375 -5.09375,-2.84375 z m 0,4 c 0.62414,0 1.36662,0.41914 1.65625,0.90625 l 31.09375,52.40627 c 0.28951,0.4882 0.29033,1.2621 0,1.75 -0.46807,0.7865 -0.62312,0.9389 -1.25,0.9375 l -62.999999,0 c -0.62621,0 -0.78186,-0.1509 -1.25,-0.9375 -0.29033,-0.4879 -0.28951,-1.2618 0,-1.75 l 31.093749,-52.40627 c 0.28963,-0.48711 1.0321,-0.90625 1.65625,-0.90625 z m 0,13 c -1.1046,0 -2,0.8954 -2,2 l 0,24.00002 c 0,1.1046 0.8954,2 2,2 1.1046,0 2,-0.8954 2,-2 l 0,-24.00002 c 0,-1.1046 -0.8954,-2 -2,-2 z m 0,32.00002 c -1.65686,0 -3,1.3432 -3,3 0,1.6569 1.34314,3 3,3 1.65685,0 3,-1.3431 3,-3 0,-1.6568 -1.34315,-3 -3,-3 z"
        //fill="#000000"
        fill-opacity="1"
        stroke="none"
        marker="none"
        visibility="visible"
        display="inline"
        overflow="visible"
      />
    </g>
  </svg>
);

IconWarning.propTypes = {
  size: number.isRequired,
  color: string.isRequired
};
