import React from "react";
import { object, string, func, any } from "prop-types";
import { Result } from "antd";

import { ButtonPrimary, ButtonSecondary } from "../../buttons";

///Component to show Form result once form status has changed
export const FormResult = ({
  style,
  status,
  title,
  subTitle,
  buttonPrimaryText,
  buttonSecondaryText,
  buttonPrimaryOnClick,
  buttonSecondaryOnClick,
  icon
}) => {
  let actions = [];
  //add secondary button if it is passed in
  if (buttonSecondaryOnClick && buttonSecondaryText) {
    actions.push(
      <ButtonSecondary
        key={"secondary"}
        shape={"round"}
        onClick={buttonSecondaryOnClick}
      >
        {buttonSecondaryText}
      </ButtonSecondary>
    );
  }

  //add primary button if it is passed in
  if (buttonPrimaryText && buttonPrimaryOnClick)
    actions.push(
      <ButtonPrimary
        key={"primary"}
        shape={"round"}
        onClick={buttonPrimaryOnClick}
      >
        {buttonPrimaryText}
      </ButtonPrimary>
    );

  return (
    <Result
      style={style}
      status={status}
      title={title}
      subTitle={subTitle}
      extra={actions}
      icon={icon}
    />
  );
};

FormResult.propTypes = {
  style: object,
  title: string.isRequired,
  status: string.isRequired,
  subTitle: string,
  buttonPrimaryText: string,
  buttonSecondaryText: string,
  buttonPrimaryOnClick: func,
  buttonSecondaryOnClick: func,
  icon: any
};
