import React from "react";
import { object } from "prop-types";
import { PageSpinner } from "../components/generic/spinners";

export const Login = ({ auth, match }) => {
  const pdClientType = match && match.params && match.params.pdClientType;
  if (pdClientType) {
    auth.setPdClientType(pdClientType);
  }
  auth.login();
  return <PageSpinner text={"Redirecting to Login screen..."} />;
};

Login.propTypes = {
  auth: object
};
