import React, { useState, useCallback, useEffect, useContext } from "react";
import { func, bool, array, string } from "prop-types";
import { AppContext } from "../../../contexts";

import {
  VofferApi,
  ApiStatus,
  DBFields,
  TargetTypes,
  GetDuidBidTypeKey,
  TransformToDictByDuidBidType
} from "../../../utils";

import {
  DatePickerSettlementDate,
  Form,
  FormItem,
  InputRadio,
  InputSelect,
  ApiStatusWrapper,
  GridCellTypes
} from "../../../components";
import { ActionDrawer } from "./index";
import { ActionTypeIds } from "../ActionTypeIds";
import { getActionTextFromId } from "../GetActionTextFromId";
import { ActionTargetCheck } from "../ActionTargetCheck";
import { ActionChanges } from "../action_changes";
import { ChangeStatusTypes } from "../action_changes";
import { ApiMethodTypes, useApi } from "../../../hooks";
import { GridDuidFields } from "../../grid/grid_duid/GridDuidFields";
import { ValidationMessageType } from "../../offer_table/utils/enums";
import { ValidationMessage } from "../../validations/ValidationMessage";
const {
  GetAlgoOffersUrl,
  GetAlgoSettlementDatesUrl,
  GetDraftsSettlementDatesUrl,
  GetAckSettlementDatesUrl,
  GetTemplateSetsUrl,
  PostDraftLoadFromFilesUrl,
  PostTemplateLoadFromFilesUrl,
  GetTemplatesInaSetUrl,
  GetDraftsUrl,
  GetAckOffersMetadataLatestUrl
} = VofferApi;
const OFFERS_SOURCES = {
  Templates: "Templates",
  AckOffers: "AckOffers",
  AlgoOffers: "AlgoOffers",
  Drafts: "Drafts"
};

const FORM_FIELD = {
  OffersSource: "offers_source",
  TemplateSetId: "template_set_id",
  AlgoDate: "algo_date",
  DraftDate: "draft_date",
  AckDate: "ack_date"
};

const OFFERS_SOURCE_OPTIONS = [
  {
    value: OFFERS_SOURCES.AckOffers,
    text: "Acknowledged bids"
  },
  {
    value: OFFERS_SOURCES.AlgoOffers,
    text: "Algo bids"
  },
  {
    value: OFFERS_SOURCES.Drafts,
    text: "Draft bids"
  },
  {
    value: OFFERS_SOURCES.Templates,
    text: "Templates"
  }
];

export const ActionDrawerLoadInto = React.memo(
  ({
    drawerVisible,
    onDrawerClose,
    targets,
    targetType,
    targetLabel,
    defaultLoadFromDate
  }) => {
    const { loggedInUserId } = useContext(AppContext);

    const [changes, setChanges] = useState([]);
    const [offersSource, setOffersSource] = useState(OFFERS_SOURCES.AckOffers);
    const [algoBidSettlementDate, setAlgoBidSettlementDate] = useState(
      defaultLoadFromDate
    );
    const [ackBidSettlementDate, setAckBidSettlementDate] = useState(
      defaultLoadFromDate
    );
    const [draftBidSettlementDate, setDraftBidSettlementDate] = useState(
      defaultLoadFromDate
    );
    const [allowedAlgoDates, setAllowedAlgoDates] = useState([]);
    const [allowedAckDates, setAllowedAckDates] = useState([]);
    const [allowedDraftDates, setAllowedDraftDates] = useState([]);
    const [templateSetsOptions, setTemplateSetsOptions] = useState([]);
    const [templateSetId, setTemplateSetId] = useState("");
    const changesCols = [
      {
        field: GridDuidFields.Duid,
        headerName: "Duid",
        width: 80,
        rowGroupIndex: 0,
        hide: true
      },
      {
        field: GridDuidFields.BidType,
        headerName: "Bid Type",
        width: 100,
        hide: true
      },
      {
        field:
          targetType === TargetTypes.Draft
            ? GridDuidFields.DraftLastModifiedAt
            : GridDuidFields.TemplateLastModifiedAt,
        headerName: "Current",
        cellRenderer: GridCellTypes.GridCellTimeAgo,
        width: 100
      },
      {
        field: "change",
        headerName: "Load from",
        cellRenderer: GridCellTypes.GridCellTimeAgo,
        width: 120
      },
      {
        field: "validation",
        headerName: "Validation",
        cellRenderer: GridCellTypes.GridCellValidation
      },
      {
        field: "status",
        headerName: "Result",
        width: 100
      }
    ];

    const {
      sendRequest: templateSetsSendRequest,
      data: templateSetsData,
      apiStatus: templateSetsApiStatus
    } = useApi({ method: ApiMethodTypes.Get });

    const {
      sendRequest: algoDatesSendRequest,
      data: algoDatesData,
      apiStatus: algoDatesApiStatus
    } = useApi({ method: ApiMethodTypes.Get });

    const {
      sendRequest: ackDatesSendRequest,
      data: ackDatesData,
      apiStatus: ackDatesApiStatus
    } = useApi({ method: ApiMethodTypes.Get });

    const {
      sendRequest: draftDatesSendRequest,
      data: draftDatesData,
      apiStatus: draftDatesApiStatus
    } = useApi({ method: ApiMethodTypes.Get });

    const {
      sendRequest: offersSendRequest,
      data: offersData,
      apiStatus: offersApiStatus
    } = useApi({ method: ApiMethodTypes.Get });

    const {
      sendRequest: postSubmitSendRequest,
      apiStatus: postSubmitApiStatus,
      clear: postSubmitClear
    } = useApi({ method: ApiMethodTypes.Post });

    //fetch initial lists
    useEffect(
      () => {
        switch (offersSource) {
          case OFFERS_SOURCES.Templates:
            //fetch template sets
            const url = GetTemplateSetsUrl({
              user: loggedInUserId
            });
            templateSetsSendRequest({ url: url });
            break;
          case OFFERS_SOURCES.AlgoOffers:
            const url2 = GetAlgoSettlementDatesUrl();
            algoDatesSendRequest({ url: url2 });
            break;
          case OFFERS_SOURCES.AckOffers:
            const url3 = GetAckSettlementDatesUrl();
            ackDatesSendRequest({ url: url3 });
            break;
          case OFFERS_SOURCES.Drafts:
            const url4 = GetDraftsSettlementDatesUrl({
              user: loggedInUserId
            });
            draftDatesSendRequest({ url: url4 });
            break;

          default:
            console.error(
              `Not implemented offersource=${offersSource} for initial fetch in ActionDrawerLoadInto.jsx`
            );
            break;
        }
      },
      [
        offersSource,
        loggedInUserId,
        templateSetsSendRequest,
        algoDatesSendRequest,
        draftDatesSendRequest,
        ackDatesSendRequest
      ]
    );

    //on update algo dates data
    useEffect(
      () => {
        if (algoDatesApiStatus === ApiStatus.Success && algoDatesData) {
          const dates = algoDatesData.map(
            row => row[DBFields.BidSettlementDate]
          );
          setAllowedAlgoDates(dates);
        }
      },
      [algoDatesApiStatus, algoDatesData]
    );

    //on update ack dates data
    useEffect(
      () => {
        if (ackDatesApiStatus === ApiStatus.Success && ackDatesData) {
          const dates = ackDatesData.map(
            row => row[DBFields.BidSettlementDate]
          );
          setAllowedAckDates(dates);
        }
      },
      [ackDatesApiStatus, ackDatesData]
    );

    //on update draft dates data
    useEffect(
      () => {
        if (draftDatesApiStatus === ApiStatus.Success && draftDatesData) {
          const dates = draftDatesData.map(
            row => row[DBFields.BidSettlementDate]
          );
          setAllowedDraftDates(dates);
        }
      },
      [draftDatesApiStatus, draftDatesData]
    );

    //on update template sets data
    useEffect(
      () => {
        if (templateSetsApiStatus === ApiStatus.Success && templateSetsData) {
          const templateSetsOptions = templateSetsData.map(row => ({
            text: `${row[DBFields.TemplateSetName]} (${
              row[DBFields.IsTeam] ? "Team" : "Personal"
            })`,
            value: row[DBFields.TemplateSetId]
          }));
          setTemplateSetsOptions(templateSetsOptions);
        }
      },
      [templateSetsApiStatus, templateSetsData, setTemplateSetsOptions]
    );

    //fetch offers
    useEffect(
      () => {
        let url = null;
        switch (offersSource) {
          case OFFERS_SOURCES.Templates:
            if (templateSetId)
              url = GetTemplatesInaSetUrl({
                templateSetId: templateSetId
              });
            break;

          case OFFERS_SOURCES.AlgoOffers:
            if (algoBidSettlementDate)
              url = GetAlgoOffersUrl({
                bidSettlementDate: algoBidSettlementDate
              });
            break;

          case OFFERS_SOURCES.AckOffers:
            if (ackBidSettlementDate)
              url = GetAckOffersMetadataLatestUrl({
                bidSettlementDate: ackBidSettlementDate
              });
            break;

          case OFFERS_SOURCES.Drafts:
            if (draftBidSettlementDate && loggedInUserId)
              url = GetDraftsUrl({
                bidSettlementDate: draftBidSettlementDate,
                user: loggedInUserId
              });
            break;

          default:
            console.error(
              `Not implemented offerSource=${offersSource} for fetch offers in ActionDrawerLoadInto.jsx`
            );
            break;
        }
        if (!url) return;

        offersSendRequest({ url: url });
      },
      [
        offersSendRequest,
        templateSetId,
        algoBidSettlementDate,
        offersSource,
        loggedInUserId,
        draftBidSettlementDate,
        ackBidSettlementDate
      ]
    );

    //on update templates offers data
    useEffect(
      () => {
        if (offersApiStatus === ApiStatus.Success && offersData) {
          const dict = TransformToDictByDuidBidType(offersData);
          let changes = [];
          targets.forEach(t => {
            const offer = dict[GetDuidBidTypeKey(t.duid, t.bid_type)];

            let status = ChangeStatusTypes.NoChange;

            t.validation = {
              type: ValidationMessageType.Warning,
              message: "No change"
            };

            let lastModifiedAt = null;
            let file_uri = null;
            if (offer && offer[DBFields.FileURI]) {
              lastModifiedAt = offer[DBFields.LastModifiedAt];
              if (!lastModifiedAt) lastModifiedAt = offer[DBFields.LoadedAt]; // for ack offers
              file_uri = offer[DBFields.FileURI];
              status = ChangeStatusTypes.Update;
              if (
                t[GridDuidFields.DraftLastModifiedAt] ||
                t[GridDuidFields.TemplateLastModifiedAt]
              )
                t.validation = {
                  type: ValidationMessageType.Warning,
                  message: "Overriding existing"
                };
              else
                t.validation = {
                  type: ValidationMessageType.Success,
                  message: "Pass"
                };
            }
            t.change = lastModifiedAt; //<DisplayTimeAgo datetime={lastModifiedAt} />;
            t.file_uri = file_uri;
            t.status = status;

            changes.push(t);
          });
          setChanges(changes);
        }
      },
      [offersData, offersApiStatus, targets]
    );

    const closeDrawer = useCallback(
      () => {
        postSubmitClear();
        onDrawerClose();
      },
      [onDrawerClose, postSubmitClear]
    );

    const handleCancel = useCallback(
      () => {
        closeDrawer();
      },
      [closeDrawer]
    );

    const handleSubmit = useCallback(
      fieldsValue => {
        // const targetType = fieldsValue[FORM_FIELD.OffersTarget];
        // let targetLabel = "";
        // if (targetType === TargetTypes.Templates) {
        //   targetLabel = fieldsValue[FORM_FIELD.TemplateSetId];
        // } else if (targetType === TargetTypes.Drafts) {
        //   targetLabel = fieldsValue[FORM_FIELD.DraftDate];
        // }

        const data = [];
        changes.forEach(t => {
          if (t.status === ChangeStatusTypes.Update) {
            data.push({
              duid: t[GridDuidFields.Duid],
              bid_type: t[GridDuidFields.BidType],
              load_from_file_uri: t.file_uri
            });
          }
        });

        let url = "";
        switch (targetType) {
          case TargetTypes.Draft:
            url = PostDraftLoadFromFilesUrl({
              user: loggedInUserId,
              bidSettlementDate: targetLabel
            });
            break;
          case TargetTypes.Template:
            url = PostTemplateLoadFromFilesUrl({
              templateSetId: targetLabel
            });
            break;
          default:
            console.error(
              `targetType=${targetType} not implemented for load into`
            );
            break;
        }
        postSubmitSendRequest({ url: url, data: data });
      },
      [changes, postSubmitSendRequest, loggedInUserId, targetType, targetLabel]
    );

    //on update post
    useEffect(
      () => {
        if (postSubmitApiStatus === ApiStatus.Success) {
          closeDrawer();
        }
      },
      [postSubmitApiStatus, closeDrawer]
    );

    const onChangeAlgoSettlementDate = useCallback(
      date => {
        setAlgoBidSettlementDate(date);
      },
      [setAlgoBidSettlementDate]
    );

    const onChangeAckSettlementDate = useCallback(
      date => {
        setAckBidSettlementDate(date);
      },
      [setAckBidSettlementDate]
    );

    const onChangeDraftSettlementDate = useCallback(
      date => {
        setDraftBidSettlementDate(date);
      },
      [setDraftBidSettlementDate]
    );

    const onChangeOffersSource = useCallback(
      source => {
        setOffersSource(source);
        setChanges([]);
      },
      [setOffersSource]
    );

    const onChangeTemplateSet = useCallback(
      setId => {
        setTemplateSetId(setId);
      },
      [setTemplateSetId]
    );

    const noChanges =
      !changes ||
      changes.length < 1 ||
      changes.filter(c => c.status === ChangeStatusTypes.Update).length < 1;
    const disableEdit =
      postSubmitApiStatus === ApiStatus.Loading ||
      postSubmitApiStatus === ApiStatus.Success ||
      noChanges;

    return (
      <ActionDrawer
        title={`${getActionTextFromId(
          targetType === TargetTypes.Draft
            ? ActionTypeIds.LoadIntoDrafts
            : ActionTypeIds.LoadIntoTemplates
        )}`}
        visible={drawerVisible}
        onClose={closeDrawer}
      >
        <ActionTargetCheck targetsCount={targets.length}>
          <Form
            onSubmit={handleSubmit}
            onCancel={handleCancel}
            apiStatus={postSubmitApiStatus}
            labelSpan={8}
            disableEdit={disableEdit}
            submitButtonText={"Load"}
            disableSubmit={!changes || changes.length < 1}
            initialValues={{
              [FORM_FIELD.OffersSource]: offersSource,
              [FORM_FIELD.AlgoDate]: algoBidSettlementDate,
              [FORM_FIELD.AckDate]: ackBidSettlementDate,
              [FORM_FIELD.DraftDate]: draftBidSettlementDate
            }}
            wrapperFooter={
              noChanges ? (
                <ValidationMessage
                  message={"No bid to load"}
                  messageType={ValidationMessageType.Warning}
                />
              ) : null
            }
          >
            <FormItem
              label={"Load from source"}
              field={FORM_FIELD.OffersSource}
              isRequired={true}
              inputControl={
                <InputRadio
                  options={OFFERS_SOURCE_OPTIONS}
                  onChange={onChangeOffersSource}
                />
              }
            />
            {offersSource === OFFERS_SOURCES.Templates && (
              <ApiStatusWrapper statuses={[templateSetsApiStatus]}>
                <FormItem
                  label={"Load from set"}
                  field={FORM_FIELD.TemplateSetId}
                  isRequired={true}
                  inputControl={
                    <InputSelect
                      options={templateSetsOptions}
                      onChange={onChangeTemplateSet}
                    />
                  }
                />
              </ApiStatusWrapper>
            )}
            {offersSource === OFFERS_SOURCES.AlgoOffers && (
              <ApiStatusWrapper statuses={[algoDatesApiStatus]}>
                <FormItem
                  label={"Load from algo date"}
                  field={FORM_FIELD.AlgoDate}
                  isRequired={true}
                  inputControl={
                    <DatePickerSettlementDate
                      allowedDates={allowedAlgoDates}
                      onChange={onChangeAlgoSettlementDate}
                    />
                  }
                />
              </ApiStatusWrapper>
            )}
            {offersSource === OFFERS_SOURCES.AckOffers && (
              <ApiStatusWrapper statuses={[ackDatesApiStatus]}>
                <FormItem
                  label={"Load from acknowledged date"}
                  field={FORM_FIELD.AckDate}
                  isRequired={true}
                  inputControl={
                    <DatePickerSettlementDate
                      allowedDates={allowedAckDates}
                      onChange={onChangeAckSettlementDate}
                    />
                  }
                />
              </ApiStatusWrapper>
            )}
            {offersSource === OFFERS_SOURCES.Drafts && (
              <ApiStatusWrapper statuses={[draftDatesApiStatus]}>
                <FormItem
                  label={"Load from draft date"}
                  field={FORM_FIELD.DraftDate}
                  isRequired={true}
                  inputControl={
                    <DatePickerSettlementDate
                      allowedDates={allowedDraftDates}
                      onChange={onChangeDraftSettlementDate}
                    />
                  }
                />
              </ApiStatusWrapper>
            )}
            <ApiStatusWrapper statuses={[offersApiStatus]}>
              {changes &&
                changes.length > 0 && (
                  <ActionChanges
                    gridHeight={500}
                    cols={changesCols}
                    changes={changes}
                  />
                )}
            </ApiStatusWrapper>
          </Form>
        </ActionTargetCheck>
      </ActionDrawer>
    );
  }
);

ActionDrawerLoadInto.propTypes = {
  drawerVisible: bool.isRequired,
  onDrawerClose: func,
  targets: array,
  targetType: string.isRequired,
  targetLabel: string.isRequired,
  defaultLoadFromDate: string
};
