import { AutoStatuses } from "../../../utils";

export const GridCellStyleAutoStatus = params => {
  if (params.value === AutoStatuses.ON) {
    return { backgroundColor: "rgba(10,166,121,0.4)" };
  } else if (params.value === AutoStatuses.OFF) {
    return { backgroundColor: "rgba(255,77,79,0.4)" };
  } else {
    return null;
  }
};
