import React from "react";
import { object, string, func } from "prop-types";

import { FormResult } from "./FormResult";

///Component to show Form Success result once form action has succeeded
export const FormResultSuccess = ({
  style,
  title,
  subTitle,
  buttonPrimaryText,
  buttonPrimaryOnClick
}) => {
  return (
    <FormResult
      style={style}
      status={"success"}
      title={title ? title : "Success"}
      subTitle={subTitle}
      buttonPrimaryOnClick={buttonPrimaryOnClick}
      buttonPrimaryText={buttonPrimaryText}
    />
  );
};

FormResultSuccess.propTypes = {
  style: object,
  title: string.isRequired,
  subTitle: string,
  buttonPrimaryText: string,
  buttonPrimaryOnClick: func
};

export default FormResultSuccess;
