import React from "react";
import { func, arrayOf, string, shape, bool } from "prop-types";

import { ButtonActionPrimary } from "../index";
import { getActionTextFromId } from "./GetActionTextFromId";

export const ActionBar = React.memo(({ onClickActionButton, config }) => (
  <>
    {config &&
      Object.keys(config.actions).map(id => (
        <ButtonActionPrimary
          key={id}
          onClick={onClickActionButton(id)}
          text={getActionTextFromId(id)}
          disabled={!config.actions[id].enabled}
        />
      ))}
  </>
));

ActionBar.propTypes = {
  onClickActionButton: func.isRequired,
  config: shape({
    actions: arrayOf(
      shape({
        id: string.isRequired,
        enabled: bool.isRequired
      }).isRequired
    ).isRequired
  })
};
