import React from "react";
import { object, any } from "prop-types";
import { Divider } from "antd";

export const DividerSection = props => (
  <Divider style={props.style} type={"horizontal"} {...props}>
    {props.children}
  </Divider>
);

DividerSection.propTypes = {
  style: object,
  children: any.isRequired
};
