import {
  ValidationMessageInRange,
  ValidationMessageRequired
} from "../validation_messages";
import { CheckValueInRange, CheckValueRequired } from "../checks";
import { ValidationMessageType } from "../enums";

export const ValidateBandMW = ({
  value,
  maxLimit = null,
  minLimit = null,
  isRequired = true
}) => {
  let validations = [];

  //Validate: value is required
  if (isRequired) {
    const check1 = CheckValueRequired(value);
    if (!check1)
      validations.push(ValidationMessageRequired(ValidationMessageType.Error));
  }

  //Validate: value is in range
  if (value !== null && value !== undefined) {
    const check2 = CheckValueInRange({ value, max: maxLimit, min: minLimit });
    if (!check2)
      validations.push(
        ValidationMessageInRange({
          messageType: ValidationMessageType.Error,
          min: minLimit,
          max: maxLimit
        })
      );
  }
  // //Validate:  value is positive number
  // const check2 = CheckValuePositive(value);
  // if (!check2)
  //   validations.push(
  //     ValidationMessagePositive(
  //       check2 ? ValidationMessageType.Success : ValidationMessageType.Error
  //     )
  //   );
  //
  // //Validate:  value exceed limit
  // if (maxLimit !== null) {
  //   const check3 = CheckValueUnderLimit(value, maxLimit);
  //   if (!check3)
  //     validations.push(
  //       ValidationMessageExceedLimit(
  //         maxLimit,
  //         check3 ? ValidationMessageType.Success : ValidationMessageType.Error
  //       )
  //     );
  // }
  //
  // //Validate:  value below limit
  // if (minLimit !== null) {
  //   const check4 = CheckValueOverLimit(value, minLimit);
  //   if (!check4)
  //     validations.push(
  //       ValidationMessageUnderLimit(
  //         minLimit,
  //         check4 ? ValidationMessageType.Success : ValidationMessageType.Error
  //       )
  //     );
  // }

  return validations;
};
