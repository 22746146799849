import React from "react";
import { string, object } from "prop-types";
import { AutoStatuses } from "../../../utils/constants";

export const DisplayAutoStatus = ({ style, status, tooltip }) => {
  let color = "rgba(154,154,154,0.58)";

  switch (status) {
    case AutoStatuses.OFF:
      color = "rgba(255,77,79,0.4)";
      break;
    case AutoStatuses.ON:
      color = "rgba(10,166,121,0.4)";
      break;
    default:
      console.error(
        "Color for Auto Status not implemented in DisplayAutoStatus",
        status
      );
      break;
  }

  return (
    <div
      title={tooltip}
      style={{
        backgroundColor: color,
        textAlign: "center",
        padding: "4px 8px",
        ...style
      }}
    >
      {status}
    </div>
  );
};

DisplayAutoStatus.propTypes = {
  style: object,
  status: string.isRequired,
  tooltip: string
};
