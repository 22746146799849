import React, { useEffect, useState } from "react";
import { ApiStatusWrapper, AppContainer, OffersMenu } from "../components";
import { VofferApi } from "../utils";
import { Offer } from "../components";
import { Layout } from "antd";
import { ApiMethodTypes, useApi } from "../hooks";
import { ApiStatus, DBFields, InternalLinks } from "../utils";
import { CalcBidCategoryFromBidType } from "../utils";

const { GetAckBidUrl, GetAckSetById } = VofferApi;

export const AckSetOffer = ({ history, match }) => {
  const {
    bidType,
    bidCategory,
    duid,
    bidSettlementDate,
    aemoTransactionId
  } = match.params;

  const getAckBidUrl = GetAckBidUrl({
    bidType: bidType,
    bidCategory: bidCategory,
    duid: duid,
    bidSettlementDate: bidSettlementDate,
    aemoTransactionId: aemoTransactionId
  });

  //region Get offers
  const [offers, setOffers] = useState({});
  const {
    sendRequest: ackOffersSendRequest,
    data: ackOffersData,
    apiStatus: ackOffersApiStatus
  } = useApi({ method: ApiMethodTypes.Get });

  //fetch ack offer
  useEffect(
    () => {
      ackOffersSendRequest({
        url: GetAckSetById({ aemoTransactionId })
      });
    },
    [bidSettlementDate, ackOffersSendRequest, aemoTransactionId]
  );

  //on update ack offer data
  useEffect(
    () => {
      if (ackOffersApiStatus === ApiStatus.Success && ackOffersData) {
        let dict = {};

        ackOffersData.forEach(row => {
          if (row[DBFields.BidSettlementDate] === bidSettlementDate) {
            //filter on bid sett date as multiple dates can exists in one ack set
            const duid = row[DBFields.Duid];
            const bidType = row[DBFields.BidType];
            const link = InternalLinks.AckSetOffer({
              duid: duid,
              bidType: bidType,
              bidCategory: CalcBidCategoryFromBidType({
                bidType: row[DBFields.BidType]
              }),
              bidSettlementDate: row[DBFields.BidSettlementDate],
              aemoTransactionId: aemoTransactionId
            });
            if (!dict[duid]) dict[duid] = {};
            if (!dict[duid][bidType]) dict[duid][bidType] = {};

            dict[duid][bidType] = {
              link: link,
              metadata: row
            };
          }
        });
        setOffers(dict);
      }
    },
    [ackOffersData, ackOffersApiStatus, bidSettlementDate, aemoTransactionId]
  );

  //endregion
  return (
    <AppContainer history={history} match={match}>
      <ApiStatusWrapper statuses={[ackOffersApiStatus]}>
        <Layout>
          <Layout.Sider width={180} collapsed={false}>
            <OffersMenu availableOffers={offers} selected={{ duid, bidType }} />
          </Layout.Sider>
          <Layout style={{ overflow: "hidden" }}>
            <Layout.Content style={{ background: "#fff", padding: "8px" }}>
              <Offer
                duid={duid}
                bidSettlementDate={bidSettlementDate}
                bidType={bidType}
                bidCategory={bidCategory}
                offerLabel={bidSettlementDate}
                getOfferUrl={getAckBidUrl}
              />
            </Layout.Content>
          </Layout>
        </Layout>
      </ApiStatusWrapper>
    </AppContainer>
  );
};
