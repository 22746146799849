import React from "react";
import { object, string, bool } from "prop-types";

import { ButtonPrimary } from "../../buttons";

export const FormButtonSubmit = props => (
  <ButtonPrimary htmlType="submit" {...props}>
    {props.text ? props.text : "Submit"}
  </ButtonPrimary>
);

FormButtonSubmit.propTypes = {
  style: object,
  text: string,
  disabled: bool
};
