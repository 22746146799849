import { LogType } from "./LogType";
import { FindBandsInPriceRange } from "./FindBandsInPriceRange";
import { FindFirstBandLessThanPrice } from "./FindFirstBandLessThanPrice";
import { SpreadVolumeIntoBands } from "./SpreadVolumeIntoBands";
import { FindFirstBandGreaterThanPrice } from "./FindFirstBandGreaterThanPrice";
import { ReallocateBand10Volume } from "./ReallocateBand10Volume";

export const ApplyBidAllocationToPeriodFcas = ({
  bidPrices,
  forecastRRP,
  breakEvenRRPOV,
  breakEvenRRPNOV,
  traderPrice1,
  traderPrice2,
  traderPrice3,
  limitVolume,
  optimalVolume,
  maxCapacity,
  nonOptimalVolume,
  discretionaryVolume,
  nonDiscretionaryVolume
}) => {
  let logs = [];
  logs.push({
    type: LogType.DEBUG,
    message: `Running FCAS allocation with inputs`,
    code: JSON.stringify({
      forecastRRP,
      breakEvenRRPOV,
      breakEvenRRPNOV,
      traderPrice1,
      traderPrice2,
      traderPrice3,
      limitVolume,
      optimalVolume,
      nonOptimalVolume,
      maxCapacity,
      discretionaryVolume,
      nonDiscretionaryVolume
    })
  });

  let bidPeriod = {};
  //reset all bands to zero
  logs.push({ type: LogType.INFO, message: "Setting all bands to zero" });
  for (let i = 1; i <= 10; i++) bidPeriod[`band${i}`] = 0;
  logs.push({
    type: LogType.DEBUG,
    message: `Bid period after zero allocation.`,
    code: JSON.stringify({ bidPeriod })
  });

  if (optimalVolume > 0) {
    const { logs: logsOV, bidPeriod: bidPeriodOV } = allocateOptimalVolume({
      optimalVolume,
      bidPeriod,
      bidPrices,
      breakEvenRRP: breakEvenRRPOV,
      forecastRRP,
      traderPrice1
    });

    logs = logs.concat(logsOV);
    bidPeriod = bidPeriodOV;

    logs.push({
      type: LogType.DEBUG,
      message: `Bid period after OV allocation.`,
      code: JSON.stringify({ bidPeriod })
    });
  }

  if (nonOptimalVolume > 0) {
    const { logs: logsNOV, bidPeriod: bidPeriodNOV } = allocateNonOptimalVolume(
      {
        bidPeriod,
        bidPrices,
        nonOptimalVolume,
        breakEvenRRPNOV,
        traderPrice1,
        forecastRRP
      }
    );
    logs = logs.concat(logsNOV);
    bidPeriod = bidPeriodNOV;

    logs.push({
      type: LogType.DEBUG,
      message: `Bid period after NOV allocation.`,
      code: JSON.stringify({ bidPeriod })
    });
  }
  if (nonDiscretionaryVolume > 0) {
    const {
      logs: logsNDV,
      bidPeriod: bidPeriodNDV
    } = allocateNonDiscretionaryVolume({
      bidPeriod,
      bidPrices,
      nonDiscretionaryVolume,
      breakEvenRRPNOV,
      forecastRRP,
      traderPrice2,
      traderPrice3
    });
    logs = logs.concat(logsNDV);
    bidPeriod = bidPeriodNDV;

    logs.push({
      type: LogType.DEBUG,
      message: `Bid period after NDV allocation.`,
      code: JSON.stringify({ bidPeriod })
    });
  }

  //Final
  logs.push({
    type: LogType.INFO,
    message: "Reallocating remaining volume to last band"
  });
  const { logs: logsRM, bidPeriod: bidPeriodRM } = ReallocateBand10Volume({
    bidPeriod,
    maxCapacity
  });
  logs = logs.concat(logsRM);
  bidPeriod = bidPeriodRM;

  logs.push({
    type: LogType.DEBUG,
    message: `Bid period after remaining volume allocation.`,
    code: JSON.stringify({ bidPeriod })
  });

  return { logs, bidPeriod };
};

const allocateOptimalVolume = ({
  optimalVolume,
  bidPeriod,
  bidPrices,
  breakEvenRRP,
  forecastRRP,
  traderPrice1
}) => {
  let logs = [];
  logs.push({
    type: LogType.INFO,
    message: `Allocating FCAS optimal volume`,
    code: JSON.stringify({ optimalVolume })
  });

  //Logic #1: Spread among price bands between BERRP & max(FRRP, TP1)
  logs.push({
    type: LogType.INFO,
    message: `Logic #1: Spread optimal volume among price bands between BERRP & max(FRRP, TP1)`,
    code: JSON.stringify({ breakEvenRRP, forecastRRP, traderPrice1 })
  });

  const minBandPrice = breakEvenRRP;
  const maxBandPrice = Math.max(forecastRRP, traderPrice1);
  let selectedBandIndexes = [];

  if (minBandPrice < maxBandPrice) {
    const { selectedBandIndexes: sbi1, logs: logs1 } = FindBandsInPriceRange({
      bidPrices,
      minBandPrice,
      maxBandPrice
    });
    logs = logs.concat(logs1);
    selectedBandIndexes = sbi1;
  } else {
    logs.push({
      type: LogType.WARNING,
      message: "MinBandPrice was not less than MaxBandPrice",
      code: JSON.stringify({
        breakEvenRRP,
        forecastRRP,
        traderPrice1,
        minBandPrice,
        maxBandPrice
      })
    });
  }

  if (selectedBandIndexes.length === 0) {
    //Logic #2
    logs.push({
      type: LogType.INFO,
      message: `Logic #2: Allocate to first band less than MaxBandPrice`,
      code: JSON.stringify({ maxBandPrice })
    });
    const { selectedBand: sb2, logs: logs2 } = FindFirstBandLessThanPrice({
      bidPrices,
      priceThreshold: maxBandPrice
    });
    logs = logs.concat(logs2);
    if (sb2 === null) {
      //logic 3 - assign to band1
      logs.push({
        type: LogType.INFO,
        message: `Logic #3: Allocate to band1`
      });
      selectedBandIndexes = ["band1"];
    } else {
      selectedBandIndexes = [sb2];
    }
  }

  //Spread volume into selected band indexes
  const { bidPeriod: bidPeriodSVIB, logs: logsSVIB } = SpreadVolumeIntoBands({
    bidPeriod,
    volume: optimalVolume,
    bandIndexes: selectedBandIndexes
  });
  logs = logs.concat(logsSVIB);
  bidPeriod = bidPeriodSVIB;

  return { logs, bidPeriod };
};

const allocateNonOptimalVolume = ({
  bidPeriod,
  bidPrices,
  nonOptimalVolume,
  breakEvenRRPNOV,
  traderPrice1,
    forecastRRP
}) => {
  let logs = [];
  logs.push({
    type: LogType.INFO,
    message: "Allocating FCAS non optimal volume",
    code: JSON.stringify({ nonOptimalVolume })
  });

  //Logic #1
  logs.push({
    type: LogType.DEBUG,
    message: `Logic #1: Spread among price bands between max(BERRP,FRRP) and TP1`
  });

  let selectedBandIndexes = [];

  const minBandPrice = Math.max(breakEvenRRPNOV, forecastRRP);
  const maxBandPrice = traderPrice1;
  if (minBandPrice < maxBandPrice) {
    const { selectedBandIndexes: sbi1, logs: logs1 } = FindBandsInPriceRange({
      bidPrices,
      minBandPrice,
      maxBandPrice
    });
    logs = logs.concat(logs1);
    selectedBandIndexes = sbi1;
  } else {
    logs.push({
      type: LogType.WARNING,
      message: "MinBandPrice was not less than MaxBandPrice",
      code: JSON.stringify({
        breakEvenRRPNOV,
        traderPrice1,
        minBandPrice,
        maxBandPrice
      })
    });
  }

  if (selectedBandIndexes.length === 0) {
    //Logic 2
    logs.push({
      type: LogType.INFO,
      message: `Logic #2: Allocate to first price band greater than MinBandPrice`,
      code: JSON.stringify({ minBandPrice })
    });
    const priceThreshold = minBandPrice;
    let selectedBand = null;
    const { selectedBand: sb2, logs: logs2 } = FindFirstBandGreaterThanPrice({
      bidPrices,
      priceThreshold
    });
    logs = logs.concat(logs2);
    selectedBand = sb2;
    if (selectedBand === null) {
      //Logic 3
      logs.push({
        type: LogType.INFO,
        message: "Logic #3: Allocate to band10"
      });
      selectedBand = "band10";
    }
    selectedBandIndexes = [selectedBand];
  }
  const { bidPeriod: bidPeriodSVIB, logs: logsSVIB } = SpreadVolumeIntoBands({
    bidPeriod,
    volume: nonOptimalVolume,
    bandIndexes: selectedBandIndexes
  });
  logs = logs.concat(logsSVIB);
  bidPeriod = bidPeriodSVIB;

  return { logs, bidPeriod };
};

const allocateNonDiscretionaryVolume = ({
  bidPeriod,
  bidPrices,
  nonDiscretionaryVolume,
  breakEvenRRPNOV,
  traderPrice2,
  traderPrice3
}) => {
  let logs = [];

  logs.push({
    type: LogType.INFO,
    message: "Allocating FCAS non discretionary volume",
    code: JSON.stringify({ nonDiscretionaryVolume })
  });
  // Allocate NDV
  logs.push({
    type: LogType.INFO,
    message:
      "Logic 1:  Spread among price bands between max(TP2, BERRP) and TP3",
    code: JSON.stringify({
      traderPrice2,
      traderPrice3,
      breakEvenRRPNOV,
      bidPrices
    })
  });
  const minBandPrice = Math.max(traderPrice2, breakEvenRRPNOV);
  const maxBandPrice = traderPrice3;
  let selectedBandIndexes = [];

  if (minBandPrice < maxBandPrice) {
    const { selectedBandIndexes: sbi1, logs: logs1 } = FindBandsInPriceRange({
      bidPrices,
      minBandPrice,
      maxBandPrice
    });
    logs = logs.concat(logs1);
    selectedBandIndexes = sbi1;
  } else {
    logs.push({
      type: LogType.WARNING,
      message: `MaxBandPrice is less than MinBandPrice`,
      code: JSON.stringify({
        traderPrice2,
        traderPrice3,
        breakEvenRRPNOV,
        minBandPrice,
        maxBandPrice
      })
    });
  }

  if (selectedBandIndexes.length === 0) {
    let selectedBand = null;
    //logic 2
    logs.push({
      type: LogType.INFO,
      message:
        "Logic 2: Allocating to first band greater than price minbandprice",
      code: JSON.stringify({ minBandPrice, bidPrices })
    });
    const { selectedBand: sb2, logs: logs2 } = FindFirstBandGreaterThanPrice({
      bidPrices,
      priceThreshold: minBandPrice
    });
    logs = logs.concat(logs2);
    selectedBand = sb2;
    if (selectedBand === null) {
      //logic 3
      logs.push({
        type: LogType.INFO,
        message: "Logic 3: Allocating to band 10"
      });
      selectedBand = "band10";
    }

    selectedBandIndexes = [selectedBand];
  }

  const { bidPeriod: bidPeriodSVIB, logs: logsSVIB } = SpreadVolumeIntoBands({
    bidPeriod,
    volume: nonDiscretionaryVolume,
    bandIndexes: selectedBandIndexes
  });
  logs = logs.concat(logsSVIB);
  bidPeriod = bidPeriodSVIB;
  return { bidPeriod, logs };
};
