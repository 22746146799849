import { BidParamCategory } from "./BidParamCategory";

export const InternalLinks = {
  Login: "/auth/login",
  Callback: "/auth/callback",
  Logout: "/auth/logout",
  Unauthorised: "/unauthorised",
  ServerError: "/servererror",
  AuthError: "/auth/error",
  Home: "/app/home",
  DeleteSuccess: "/app/deletesuccess",
  Roles: "/app/role",
  Role: ({ roleId }) => `/app/role/${roleId}`,
  RoleNew: "/app/rolenew",
  Users: "/app/user",
  User: ({ userId }) => `/app/user/${userId}`,
  UserNew: "/app/usernew",
  TraderParams: `/app/param/${BidParamCategory.Trader}`,
  StandingParams: `/app/param/${BidParamCategory.Standing}`,
  BidParam: ({ categoryId, parameterId, duid, bidType }) =>
    `/app/param/${categoryId}/duid/${duid}/bidtype/${bidType}/parameter/${parameterId}`,
  ReportActualManualPerformance: "/app/report/actualmanualperformance",
  ReportUnitComparisonDetailed: "/app/report/unitcomparisondetailed",
  ReportUnitComparisonSummary: "/app/report/unitcomparisonsummary",
  ReportActualManualLiability: "/app/report/actualmanualliability",
  ReportDailySummary: "/app/report/dailysummary",
  Offers: "/app/bids",
  DraftOffer: ({ bidCategory, bidType, duid, bidSettlementDate }) =>
    `/app/bids/draft/${bidCategory}/${bidType}/${duid}/${bidSettlementDate}`,
  TemplateSets: "/app/templateset",
  TemplateSetNew: "/app/templatesetnew",
  TemplateSet: ({ templateSetId }) => `/app/templateset/${templateSetId}`,
  TemplateOffer: ({ templateSetId, bidCategory, bidType, duid }) =>
    `/app/templateset/${templateSetId}/bid/${bidCategory}/${bidType}/${duid}`,
  SubmittedSets: "/app/submittedset",
  SubmittedSet: ({ submittedSetId }) => `/app/submittedset/${submittedSetId}`,
  SubmittedOffer: ({ submittedSetId, bidCategory, bidType, duid }) =>
    `/app/submittedset/${submittedSetId}/bid/${bidCategory}/${bidType}/${duid}`,
  AckSets: "/app/ackset",
  AckSet: ({ aemoTransactionId }) => `/app/ackset/${aemoTransactionId}`,
  AckSetOffer: ({
    bidCategory,
    bidType,
    duid,
    bidSettlementDate,
    aemoTransactionId
  }) =>
    `/app/ackset/${aemoTransactionId}/${bidSettlementDate}/${bidCategory}/${bidType}/${duid}`,
  AckOffer: ({ bidCategory, bidType, duid, bidSettlementDate }) =>
    `/app/bids/ackbid/${bidCategory}/${bidType}/${duid}/${bidSettlementDate}`,
  AlgoOffer: ({ bidCategory, bidType, duid, bidSettlementDate }) =>
    `/app/bids/algobid/${bidCategory}/${bidType}/${duid}/${bidSettlementDate}`,
  JsonViewerHome: "/app/jsonviewer",
  JsonViewer: ({ fileUri }) => `/app/jsonviewer/file/${fileUri}`,
  SolveList: `/app/solve`,
  SolveResult: ({ solveId, bidSettlementDate }) =>
    `/app/solve/${bidSettlementDate}/${solveId}/result`,
  SolveCompositeResult:  `/app/compositesolveresult`,
  Solve: ({ solveId, bidSettlementDate }) =>
    `/app/solve/${bidSettlementDate}/${solveId}`,
  AutoStatusHistory: "/app/autostatushistory",
  DispatchTimesHistory: "/app/dispatchtimeshistory",
  BidAllocator: "/app/bidallocator"
};

const DraftOfferRoute = InternalLinks.DraftOffer({
  bidSettlementDate: ":bidSettlementDate",
  bidType: ":bidType",
  duid: ":duid",
  bidCategory: ":bidCategory"
});

const TemplateSetRoute = InternalLinks.TemplateSet({
  templateSetId: ":templateSetId"
});

const TemplateOfferRoute = InternalLinks.TemplateOffer({
  templateSetId: ":templateSetId",
  bidCategory: ":bidCategory",
  bidType: ":bidType",
  duid: ":duid"
});

const SubmittedSetRoute = InternalLinks.SubmittedSet({
  submittedSetId: ":submittedSetId"
});

const SubmittedOfferRoute = InternalLinks.SubmittedOffer({
  submittedSetId: ":submittedSetId",
  bidCategory: ":bidCategory",
  bidType: ":bidType",
  duid: ":duid"
});

const AlgoOfferRoute = InternalLinks.AlgoOffer({
  bidSettlementDate: ":bidSettlementDate",
  bidType: ":bidType",
  duid: ":duid",
  bidCategory: ":bidCategory"
});

const AckSetRoute = InternalLinks.AckSet({
  aemoTransactionId: ":aemoTransactionId"
});

const AckSetOfferRoute = InternalLinks.AckSetOffer({
  bidSettlementDate: ":bidSettlementDate",
  bidType: ":bidType",
  duid: ":duid",
  bidCategory: ":bidCategory",
  aemoTransactionId: ":aemoTransactionId"
});
const AckOfferRoute = InternalLinks.AckOffer({
  bidSettlementDate: ":bidSettlementDate",
  bidType: ":bidType",
  duid: ":duid",
  bidCategory: ":bidCategory"
});

export const JsonViewerRoute = InternalLinks.JsonViewer({
  fileUri: ":fileUri"
});

export const UserRoute = InternalLinks.User({
  userId: ":userId"
});

export const RoleRoute = InternalLinks.Role({
  roleId: ":roleId"
});

export const BidParamRoute = InternalLinks.BidParam({
  parameterId: ":parameterId",
  duid: ":duid",
  bidType: ":bidType",
  categoryId: ":categoryId"
});

export const SolveResultRoute = InternalLinks.SolveResult({
  solveId: ":solveId",
  bidSettlementDate: ":bidSettlementDate"
});
export const SolveRoute = InternalLinks.Solve({
  solveId: ":solveId",
  bidSettlementDate: ":bidSettlementDate"
});

export const InternalRoutes = {
  Users: InternalLinks.Users,
  User: UserRoute,
  UserNew: InternalLinks.UserNew,
  Roles: InternalLinks.Roles,
  Role: RoleRoute,
  RoleNew: InternalLinks.RoleNew,
  Home: InternalLinks.Home,
  Unauthorised: InternalLinks.Unauthorised,
  ServerError: InternalLinks.ServerError,
  DeleteSuccess: InternalLinks.DeleteSuccess,
  Login: InternalLinks.Login,
  Logout: InternalLinks.Logout,
  Callback: InternalLinks.Callback,
  AuthError: InternalLinks.AuthError,

  TraderParams: InternalLinks.TraderParams,
  StandingParams: InternalLinks.StandingParams,
  BidParam: BidParamRoute,
  ReportActualManualPerformance: InternalLinks.ReportActualManualPerformance,
  ReportUnitComparisonDetailed: InternalLinks.ReportUnitComparisonDetailed,
  ReportUnitComparisonSummary: InternalLinks.ReportUnitComparisonSummary,
  ReportActualManualLiability: InternalLinks.ReportActualManualLiability,
  ReportDailySummary: InternalLinks.ReportDailySummary,
  JsonViewerHome: InternalLinks.JsonViewerHome,
  JsonViewer: JsonViewerRoute,
  Offers: InternalLinks.Offers,
  DraftOffer: DraftOfferRoute,
  TemplateSets: InternalLinks.TemplateSets,
  TemplateSetNew: InternalLinks.TemplateSetNew,
  TemplateSet: TemplateSetRoute,
  TemplateOffer: TemplateOfferRoute,
  SubmittedSets: InternalLinks.SubmittedSets,
  SubmittedSet: SubmittedSetRoute,
  SubmittedOffer: SubmittedOfferRoute,
  AckOffer: AckOfferRoute,
  AlgoOffer: AlgoOfferRoute,
  AckSets: InternalLinks.AckSets,
  AckSet: AckSetRoute,
  AckSetOffer: AckSetOfferRoute,
  SolveList: InternalLinks.SolveList,
  Solve: SolveRoute,
  SolveResult: SolveResultRoute,
  SolveCompositeResult: InternalLinks.SolveCompositeResult,
  AutoStatusHistory: InternalLinks.AutoStatusHistory,
  DispatchTimesHistory: InternalLinks.DispatchTimesHistory,
  BidAllocator: InternalLinks.BidAllocator
};

export const InternalLinkNames = {
  [InternalLinks.Home]: "Home",
  [InternalLinks.Users]: "Users",
  [InternalLinks.Roles]: "Roles",
  [InternalLinks.Logout]: "Logout",
  [InternalLinks.TraderParams]: "Algo Bid Parameters",
  [InternalLinks.StandingParams]: "Unit Capabilities",
  [InternalLinks.ReportActualManualPerformance]: "Performance Detailed 5min",
  [InternalLinks.ReportUnitComparisonDetailed]: "Unit Comparison Detailed 5min",
  [InternalLinks.ReportUnitComparisonSummary]: "Unit Comparison Daily Summary",
  [InternalLinks.ReportActualManualLiability]:
    "Raise Contingency Liability 5min",
  [InternalLinks.ReportDailySummary]: "Performance Daily Summary",
  [InternalLinks.Offers]: "Bids",
  [InternalLinks.TemplateSets]: "Templates",
  [InternalLinks.SubmittedSets]: "Submitted Sets History",
  [InternalLinks.AckSets]: "Ack Sets History",
  [InternalLinks.AckOffers]: "Active Acknowledged Bids",
  [InternalLinks.SolveList]: "Solver Runs History",
  [InternalLinks.JsonViewerHome]: "JSON Viewer",
  [InternalLinks.AutoStatusHistory]: "Auto Status History",
  [InternalLinks.DispatchTimesHistory]: "Dispatch Times History",
  [InternalLinks.BidAllocator]: "Bid Allocator (Demo)",
  [InternalLinks.SolveCompositeResult]: "Solver Composite Results History"
};
