import React, { useState, useEffect, useCallback, useRef } from "react";
import { string, bool } from "prop-types";
import { Row, Col, Form, Collapse, Space, Table, Popconfirm } from "antd";
import { Redirect } from "react-router-dom";

import { ButtonDelete } from "../generic";
import { OfferChart } from "../offer_chart";

import {
  ApiStatus,
  BidCategory,
  InternalLinks,
  OfferActionTypes
} from "../../utils";
import { ValidationsDisplay } from "../validations";

import {
  CalcIfReverseBands,
  CalcTotalTimes,
  CalcPriceBandIndex,
  CalcAutoBand,
  CalcTimeFromPeriodId,
  CalcPeriodKey
} from "./utils/calc";
import {
  ValidateDailyEnergyConstraint,
  ValidateMinimumLoad,
  ValidatePriceBandAll,
  ValidatePriceBandEach,
  ValidateTotalTimes,
  ValidateTtimes,
  ValidateBandMW,
  ValidateLowBreakPoint,
  ValidateEnablementMin,
  ValidateHighBreakPoint,
  ValidateEnablementMax,
  ValidatePasaAvail
} from "./utils/validations";
import {
  ParseBandMW,
  ParseDailyEnergyConstraint,
  ParseMinimumLoad,
  ParsePhysicalValue,
  ParsePriceBand,
  ParseTtime
} from "./utils/parsers";
import {
  BidType,
  FieldLabels,
  GridFields,
  OtherDailyParamsLabels,
  PriceBandLabels,
  TechParamLabels,
  TechParams,
  UnitStartType,
  ValidationMessageType
} from "./utils/enums";
import { FormatPriceBand } from "./utils/formatters";
import {
  TransformValidationPriceBands,
  TransformValidationFastStartProfile,
  TransformSavePeriods
} from "./utils/transformations";
import { DefaultRows, LabelSpan, NumberOfBands } from "./utils/config";
import { ColorsCell } from "./utils/colors";

import { Grid } from "../grid";

import {
  ApiStatusWrapper,
  ButtonActionPrimary,
  ButtonSave,
  UnsavedChangesPrompt
} from "../../components";

import { ApiMethodTypes, useApi, usePreventWindowUnload } from "../../hooks";
import { FormPriceBands } from "./form_controls";
import { FormFastStartProfile, FormOtherPhysical } from "./form_controls";
import { useDebounceFn, useInterval } from "ahooks";
import moment from "moment";
import { ValidationMessage } from "../validations/ValidationMessage";

const COLUMN_TYPES = {
  NumericCol: "numericColumn" //in-built
};

const DEFAULT_COL_DEF = {
  resizable: true,
  suppressMenu: true,
  suppressMovableColumns: true,
  editable: false,
  cellStyle: {
    backgroundColor: ColorsCell.ReadOnlyBackground
  }
};
const UPDATE_TIMER_INTERVAL = 5000; //10 secs

export const OfferTable = ({
  bidCategory,
  duid,
  bidSettlementDate,
  bidType,
  allowEdit,
  allowDelete,
  getOfferUrl,
  putOfferUrl,
  deleteOfferUrl,
  offerAction,
  techParamsValues
}) => {
  //region NEM time

  const [isCurrentDay] = useState(
    bidSettlementDate ===
      moment()
        .utc()
        .add(10, "hours")
        .format("YYYY-MM-DD")
  );

  const getCurrentNemPeriod = () => {
    const utcnow = moment().utc();
    // Your moment at midnight
    const utcMidnight = utcnow.clone().startOf("day");
    // Difference in minutes
    const diffMinutes = utcnow.diff(utcMidnight, "minutes") + 1; //add 1 minutes to flip to next period on 5min interval
    const diff5min = diffMinutes / 5;
    //use mod to get periodID. note 10*12 converts to NEM time from utc, 4*12 subtracts 4 hours for start of NEM day
    const periodId = Math.max(
      1,
      Math.ceil((diff5min + 10 * 12 - 4 * 12 + 288) % 288)
    );
    return periodId;
  };

  const [now, setNow] = useState(getCurrentNemPeriod());

  useInterval(() => {
    setNow(getCurrentNemPeriod());
  }, UPDATE_TIMER_INTERVAL);

  //endregion

  const [gridValidations, setGridValidations] = useState({});

  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [runValidation, setRunValidation] = useState(false);

  usePreventWindowUnload({ preventDefault: hasUnsavedChanges }); //prevent from closing tab if has unsaved changes

  const isNew = offerAction === OfferActionTypes.New;

  const [isReverseBands, setIsReverseBands] = useState(false);
  const [rowData, setRowData] = useState(isNew ? DefaultRows() : []); //new file start with empty 288 rows

  const [cols, setCols] = useState([]);
  const [gridApi, setGridApi] = useState();

  const [showFastStartProfile, setShowFastStartProfile] = useState(false);
  const [showDailyEnergyConstraint, setShowDailyEnergyConstraint] = useState(
    false
  );

  const [chartPeriods, setChartPeriods] = useState([]);
  const [priceBands, setPriceBands] = useState({
    priceBand1: null,
    priceBand2: null,
    priceBand3: null,
    priceBand4: null,
    priceBand5: null,
    priceBand6: null,
    priceBand7: null,
    priceBand8: null,
    priceBand9: null,
    priceBand10: null
  });

  //this is to fix issue of grid band cols not refreshing band prices
  const priceBandsRef = useRef({
    priceBands
  });

  const [priceBandsValidations, setPriceBandsValidations] = useState({
    priceBand1: [],
    priceBand2: [],
    priceBand3: [],
    priceBand4: [],
    priceBand5: [],
    priceBand6: [],
    priceBand7: [],
    priceBand8: [],
    priceBand9: [],
    priceBand10: []
  });

  const [fastStartProfile, setFastStartProfile] = useState({
    minimumLoad: null,
    t1: null,
    t2: null,
    t3: null,
    t4: null,
    totalT: null
  });
  const [
    fastStartProfileValidations,
    setFastStartProfileValidations
  ] = useState({
    minimumLoad: [],
    t1: [],
    t2: [],
    t3: [],
    t4: [],
    totalT: []
  });

  const [dailyEnergyConstraint, setDailyEnergyConstraint] = useState(null);
  const [
    dailyEnergyConstraintValidations,
    setDailyEnergyConstraintValidations
  ] = useState([]);

  const [
    validationDisplayPriceBands,
    setValidationDisplayPricesBands
  ] = useState([]);
  const [validationDisplayGrid, setValidationDisplayGrid] = useState([]);
  const [
    validationDisplayFastStartProfile,
    setValidationDisplayFastStartProfile
  ] = useState([]);
  const [
    validationDisplayDailyEnergyConstraint,
    setValidationDisplayDailyEnergyConstraint
  ] = useState([]);

  const [containerSize, setContainerSize] = useState({
    width: 1000,
    height: 1000
  });

  const [isPasting, setIsPasting] = useState(false);

  const {
    sendRequest: offersSendRequest,
    data: offersData,
    apiStatus: offersApiStatus
  } = useApi({ method: ApiMethodTypes.Get });

  const {
    sendRequest: saveOfferSendRequest,
    //data: saveOfferData,
    apiStatus: saveOfferApiStatus,
    clear: saveOfferClear
  } = useApi({ method: ApiMethodTypes.Put });

  const {
    sendRequest: deleteOfferSendRequest,
    apiStatus: deleteOfferApiStatus
  } = useApi({ method: ApiMethodTypes.Delete });

  //region New grid validations

  const validateGridRow = useCallback(
    () => ({ row }) => {
      //validate row in the grid

      // const row = gridApi.getRowNode(periodId);
      // console.log("FOUND ROW", row);
      const periodId = row[GridFields.PeriodId];

      //do validation
      let objValidation = {};

      const hasAutoBand = allowEdit; //Auto band only if in edit mode, otherwise get from bid
      const totalBands = hasAutoBand ? NumberOfBands - 1 : NumberOfBands; //if has auto band, then editable bands is one less

      //band cols without auto band
      for (let i = 0; i < totalBands; i++) {
        const colIndex = CalcPriceBandIndex(i, isReverseBands);
        const field = `band${colIndex}`;
        const validations = ValidateBandMW({
          value: ParseBandMW(row[field]),
          minLimit: 0,
          maxLimit: techParamsValues[TechParams.MaxCap]
        });
        if (validations && validations.length > 0)
          objValidation[field] = validations;
      }
      if (hasAutoBand) {
        //auto band
        const autoBandValue = isReverseBands
          ? CalcAutoBand(
              techParamsValues[TechParams.MaxCap],
              row.band10,
              row.band2,
              row.band3,
              row.band4,
              row.band5,
              row.band6,
              row.band7,
              row.band8,
              row.band9
            )
          : CalcAutoBand(
              techParamsValues[TechParams.MaxCap],
              row.band1,
              row.band2,
              row.band3,
              row.band4,
              row.band5,
              row.band6,
              row.band7,
              row.band8,
              row.band9
            );
        const autoBandValidations = ValidateBandMW({
          value: ParseBandMW(autoBandValue),
          minLimit: 0,
          maxLimit: techParamsValues[TechParams.MaxCap]
        });
        if (autoBandValidations && autoBandValidations.length > 0)
          objValidation[
            isReverseBands ? GridFields.Band1 : GridFields.Band10
          ] = autoBandValidations;
      }
      //max avail
      const maxAvailValidations = ValidateBandMW({
        value: ParsePhysicalValue(row[GridFields.MaxAvail]),
        minLimit: 0,
        maxLimit: techParamsValues[TechParams.MaxCap]
      });

      if (maxAvailValidations && maxAvailValidations.length > 0)
        objValidation[GridFields.MaxAvail] = maxAvailValidations;

      if (bidType === BidType.Energy) {
        // pasa avail
        const pasaAvailValidations = ValidatePasaAvail({
          value: ParsePhysicalValue(row[GridFields.PasaAvail]),
          maxCap: techParamsValues[TechParams.MaxCap],
          maxAvailValue: ParsePhysicalValue(row[GridFields.MaxAvail])
        });

        if (pasaAvailValidations && pasaAvailValidations.length > 0)
          objValidation[GridFields.PasaAvail] = pasaAvailValidations;

        //ROC UP
        const rocUpValidations = ValidateBandMW({
          value: ParsePhysicalValue(row[GridFields.RampRateUp]),
          minLimit: 0,
          maxLimit: techParamsValues[TechParams.MaxRocUp]
        });

        if (rocUpValidations && rocUpValidations.length > 0)
          objValidation[GridFields.RampRateUp] = rocUpValidations;

        //ROC DOWN
        const rocDownValidations = ValidateBandMW({
          value: ParsePhysicalValue(row[GridFields.RampRateDown]),
          minLimit: 0,
          maxLimit: techParamsValues[TechParams.MaxRocDown]
        });

        if (rocDownValidations && rocDownValidations.length > 0)
          objValidation[GridFields.RampRateDown] = rocDownValidations;

        //FIXED LOAD
        const fixedLoadValidations = ValidateBandMW({
          value: ParsePhysicalValue(row[GridFields.FixedLoad]),
          minLimit: 0,
          maxLimit: techParamsValues[TechParams.MaxCap],
          isRequired: false
        });

        if (fixedLoadValidations && fixedLoadValidations.length > 0)
          objValidation[GridFields.FixedLoad] = fixedLoadValidations;
      } else {
        //FCAS physical cols

        //ENABLEMENT MIN
        const enabMinValidations = ValidateEnablementMin({
          value: ParsePhysicalValue(row[GridFields.EnablementMin]),
          minEnablementMin: techParamsValues[TechParams.MinEnablementMin],
          maxEnablementMax: techParamsValues[TechParams.MaxEnablementMax]
        });

        if (enabMinValidations && enabMinValidations.length > 0)
          objValidation[GridFields.EnablementMin] = enabMinValidations;

        //LOW BP
        const lowbpValidations = ValidateLowBreakPoint({
          value: ParsePhysicalValue(row[GridFields.LowBreakPoint]),
          maxAvailValue: ParsePhysicalValue(row[GridFields.MaxAvail]),
          enablementMinValue: ParsePhysicalValue(row[GridFields.EnablementMin]),
          maxLowerAngle: techParamsValues[TechParams.MaxLowerAngle]
        });

        if (lowbpValidations && lowbpValidations.length > 0)
          objValidation[GridFields.LowBreakPoint] = lowbpValidations;

        //HIGH BP
        const highbpValidations = ValidateHighBreakPoint({
          value: ParsePhysicalValue(row[GridFields.HighBreakPoint]),
          lowBreakPointValue: ParsePhysicalValue(row[GridFields.LowBreakPoint]),
          maxAvailValue: ParsePhysicalValue(row[GridFields.MaxAvail]),
          maxUpperAngle: techParamsValues[TechParams.MaxUpperAngle],
          enablementMaxValue: ParsePhysicalValue(row[GridFields.EnablementMax])
        });

        if (highbpValidations && highbpValidations.length > 0)
          objValidation[GridFields.HighBreakPoint] = highbpValidations;

        //ENAB MAX
        const enabMaxValidations = ValidateEnablementMax({
          value: ParsePhysicalValue(row[GridFields.EnablementMax]),
          highBreakPointValue: ParsePhysicalValue(
            row[GridFields.HighBreakPoint]
          ),
          maxEnablementMax: techParamsValues[TechParams.MaxEnablementMax],
          minEnablementMin: techParamsValues[TechParams.MinEnablementMin]
        });

        if (enabMaxValidations && enabMaxValidations.length > 0)
          objValidation[GridFields.EnablementMax] = enabMaxValidations;
      }

      //update state in own object
      setGridValidations(prevState => ({
        ...prevState,
        [periodId]: objValidation
      }));
    },
    [allowEdit, bidType, isReverseBands, techParamsValues]
  );

  const validateGrid = useCallback(
    () => {
      if (gridApi) {
        gridApi.forEachNode(node => {
          validateGridRow()({ row: node.data });
        });
        setRunValidation(false);
      }
    },
    [gridApi, validateGridRow, setRunValidation]
  );

  // const onRowValueChanged = useCallback(
  //   row => {
  //     console.log("ROW CHANGEd", row);
  //     validateGridRow()({ row: row });
  //   },
  //   [validateGridRow]
  // );
  //
  // const onDragStopped = useCallback(
  //   event => {
  //     if (
  //       event &&
  //       event.target &&
  //       event.target.className &&
  //       event.target.className === "ag-fill-handle"
  //     ) {
  //       console.log("FILL STOPPED");
  //       validateGrid();
  //     }
  //   },
  //   [validateGrid]
  // );
  //endregion

  const getPriceBandFieldByIndex = useCallback(index => {
    if (index >= 1 && index <= NumberOfBands) return `priceBand${index}`;
    return null;
  }, []);

  const getPriceBandValue = useCallback(
    key => {
      if (
        key &&
        priceBandsRef &&
        priceBandsRef.current[key] !== null &&
        priceBandsRef.current[key] !== undefined
      )
        return priceBandsRef.current[key];
      return null;
    },
    [priceBandsRef]
  );

  const getBandHeaderValue = useCallback(
    colIndex => params => {
      const priceBandValue = getPriceBandValue(
        getPriceBandFieldByIndex(colIndex)
      );
      return priceBandValue !== null
        ? ` ${FormatPriceBand(priceBandValue)}`
        : `PB ${colIndex}`;
    },
    [getPriceBandValue, getPriceBandFieldByIndex]
  );

  const getColProps = useCallback(
    ({ field }) => {
      return {
        field: field,
        headerName: FieldLabels[field],
        type: COLUMN_TYPES.NumericCol,
        editable: allowEdit,
        valueFormatter: params =>
          params.value !== undefined ? params.value.toString() : params.value, //this fixes the zero showing as blank issue
        cellStyle: params => ({
          backgroundColor: !allowEdit
            ? ColorsCell.ReadOnlyBackground
            : ColorsCell.EditableBackground,
          color:
            isCurrentDay && params.data[GridFields.PeriodId] <= now
              ? "#bbb"
              : "#000",
          borderBottom:
            params.data[GridFields.PeriodId] % 6 === 0
              ? `1px solid ${ColorsCell.ThirtyMinuteRow}`
              : null
        }),
        width: 80
      };
    },
    [allowEdit, now, isCurrentDay]
  );

  const getBandColProps = useCallback(
    ({ colIndex }) => {
      const field = `band${colIndex}`;
      return {
        ...getColProps({ field: field }),
        headerValueGetter: getBandHeaderValue(colIndex),
        width: 80
      };
    },
    [getBandHeaderValue, getColProps]
  );

  const getRowNodeId = useCallback(data => {
    return data.periodId;
  }, []);

  useEffect(
    () => {
      let cols = [
        {
          headerName: "Period",
          marryChildren: true,
          //pinned: "left",
          children: [
            {
              headerName: "Time",
              field: GridFields.Time,
              width: 60,
              pinned: "left",
              valueGetter: params =>
                CalcTimeFromPeriodId(params.data[GridFields.PeriodId]),
              cellStyle: params => ({
                backgroundColor: ColorsCell.ReadOnlyBackground,
                borderBottom:
                  params.data[GridFields.PeriodId] % 6 === 0
                    ? `1px solid ${ColorsCell.ThirtyMinuteRow}`
                    : null
              })
            },
            {
              headerName: "Id",
              headerTooltip: "Period Id",
              field: GridFields.PeriodId,
              width: 50,
              pinned: "left",
              type: "numericColumn",
              cellStyle: params => ({
                backgroundColor: ColorsCell.ReadOnlyBackground,
                borderBottom:
                  params.data[GridFields.PeriodId] % 6 === 0
                    ? `1px solid ${ColorsCell.ThirtyMinuteRow}`
                    : null
              })
              // columnGroupShow: "open"
            }
          ]
        }
      ];

      //add price bands cols
      let financialCols = [];
      const hasAutoBand = allowEdit; //Auto band only if in edit mode, otherwise get from bid
      const totalBands = hasAutoBand ? NumberOfBands - 1 : NumberOfBands; //if has auto band, then editable bands is one less

      //add band cols
      for (let i = 0; i < totalBands; i++) {
        const colIndex = CalcPriceBandIndex(i, isReverseBands);
        financialCols.push({
          headerName: `Band ${colIndex}`,
          marryChildren: true,
          children: [
            getBandColProps({
              colIndex: colIndex
            })
          ]
        });
      }
      if (hasAutoBand) {
        //last auto band col
        const lastBandColIndex = CalcPriceBandIndex(
          NumberOfBands - 1,
          isReverseBands
        );
        //const field = `band${lastBandColIndex}`;
        financialCols.push({
          headerName: `Band ${lastBandColIndex}`,
          marryChildren: true,
          children: [
            {
              ...getBandColProps({
                colIndex: lastBandColIndex
              }),
              editable: false,
              cellStyle: params => ({
                backgroundColor: ColorsCell.ReadOnlyBackground,
                color: ColorsCell.AutoColColor,
                borderBottom:
                  params.data[GridFields.PeriodId] % 6 === 0
                    ? `1px solid ${ColorsCell.ThirtyMinuteRow}`
                    : null
              }),
              valueGetter: params =>
                isReverseBands
                  ? CalcAutoBand(
                      techParamsValues[TechParams.MaxCap],
                      params.data.band10,
                      params.data.band2,
                      params.data.band3,
                      params.data.band4,
                      params.data.band5,
                      params.data.band6,
                      params.data.band7,
                      params.data.band8,
                      params.data.band9
                    )
                  : CalcAutoBand(
                      techParamsValues[TechParams.MaxCap],
                      params.data.band1,
                      params.data.band2,
                      params.data.band3,
                      params.data.band4,
                      params.data.band5,
                      params.data.band6,
                      params.data.band7,
                      params.data.band8,
                      params.data.band9
                    )
            }
          ]
        });
      }

      //add physical cols
      //add shared physical cols
      let physicalCols = [];
      //Max avail
      physicalCols.push({
        ...getColProps({ field: GridFields.MaxAvail })
      });
      //add physical cols for energy

      if (bidType === BidType.Energy) {
        physicalCols = physicalCols.concat([
          {
            ...getColProps({ field: GridFields.PasaAvail }),
            columnGroupShow: "open"
          },
          {
            ...getColProps({ field: GridFields.RampRateUp })
          },
          {
            ...getColProps({ field: GridFields.RampRateDown })
          },
          {
            ...getColProps({ field: GridFields.FixedLoad }),
            columnGroupShow: "open"
          }
        ]);
      } else {
        //fcas physical cols
        physicalCols = physicalCols.concat([
          {
            ...getColProps({ field: GridFields.EnablementMin }),
            columnGroupShow: "open"
          },
          {
            ...getColProps({ field: GridFields.LowBreakPoint }),
            columnGroupShow: "open"
          },
          {
            ...getColProps({ field: GridFields.HighBreakPoint }),
            columnGroupShow: "open"
          },
          {
            ...getColProps({ field: GridFields.EnablementMax }),
            columnGroupShow: "open"
          }
        ]);
      }
      cols = cols.concat(financialCols);
      cols = cols.concat({
        headerName: "Physical",
        children: physicalCols,
        openByDefault: true,
        marryChildren: true
      });

      setCols(cols);
    },
    [
      bidType,
      getBandColProps,
      isReverseBands,
      techParamsValues,
      allowEdit,

      getColProps
    ]
  );

  useEffect(
    () => {
      setContainerSize({
        width: window.innerWidth - 700,
        height: window.innerHeight - 130
      });
    },
    [setContainerSize]
  );

  useEffect(
    () => {
      const isReverse = CalcIfReverseBands(
        bidType,
        techParamsValues[TechParams.DispatchType]
      );
      setIsReverseBands(isReverse);
    },
    [bidType, techParamsValues]
  );

  useEffect(
    () => {
      setShowFastStartProfile(
        techParamsValues[TechParams.StartType] === UnitStartType.Fast &&
          bidCategory === BidCategory.ENERGY
      );
    },
    [techParamsValues, bidCategory]
  );

  useEffect(
    () => {
      setShowDailyEnergyConstraint(
        techParamsValues[TechParams.IsDailyEnergyConstraint] === "TRUE" &&
          bidCategory === BidCategory.ENERGY
      );
    },
    [techParamsValues, bidCategory]
  );

  const onGridReady = useCallback(params => {
    setGridApi(params.api);
    // this.gridColumnApi = params.columnApi;
  }, []);

  const onFirstDataRendered = useCallback(
    () => {
      if (gridApi && isCurrentDay && now) {
        const rowIndex = now - 1;
        gridApi.ensureIndexVisible(rowIndex, "top");
      }
    },
    [gridApi, now, isCurrentDay]
  );

  const { run: updateChartData } = useDebounceFn(
    useCallback(
      () => {
        if (!isPasting) {
          // console.log("updateChartData");
          let periods = [];

          //parse values for each row
          rowData.forEach(row => {
            let obj = {};
            if (row[GridFields.PeriodId] !== null)
              obj[GridFields.PeriodId] = row[GridFields.PeriodId];

            [
              GridFields.Band2,
              GridFields.Band3,
              GridFields.Band4,
              GridFields.Band5,
              GridFields.Band6,
              GridFields.Band7,
              GridFields.Band8,
              GridFields.Band9
            ].forEach(col => {
              if (row[col] !== null && row[col] !== undefined) {
                obj[col] = ParseBandMW(row[col]);
              }
            });
            //BAND 1
            obj[GridFields.Band1] =
              allowEdit && isReverseBands //if in edit mode, only then auto calc band
                ? CalcAutoBand(
                    techParamsValues[TechParams.MaxCap],
                    row.band10,
                    row.band2,
                    row.band3,
                    row.band4,
                    row.band5,
                    row.band6,
                    row.band7,
                    row.band8,
                    row.band9
                  )
                : ParseBandMW(row[GridFields.Band1]);

            //BAND 10
            obj[GridFields.Band10] =
              allowEdit && !isReverseBands //if in edit mode, only then auto calc band
                ? CalcAutoBand(
                    techParamsValues[TechParams.MaxCap],
                    row.band1,
                    row.band2,
                    row.band3,
                    row.band4,
                    row.band5,
                    row.band6,
                    row.band7,
                    row.band8,
                    row.band9
                  )
                : ParseBandMW(row[GridFields.Band10]);
            [
              GridFields.MaxAvail,
              GridFields.FixedLoad,
              GridFields.RampRateUp,
              GridFields.RampRateDown,
              GridFields.PasaAvail,
              GridFields.EnablementMin,
              GridFields.LowBreakPoint,
              GridFields.HighBreakPoint,
              GridFields.EnablementMax
            ].forEach(col => {
              if (row[col] !== null && row[col] !== undefined) {
                obj[col] = ParsePhysicalValue(row[col]);
              }
            });
            periods.push(obj);
          });
          setChartPeriods(periods);
        }
      },
      [isPasting, rowData, isReverseBands, techParamsValues, allowEdit]
    ),
    {
      wait: 1000
    }
  );

  const onCellValueChanged = useCallback(
    () => {
      setHasUnsavedChanges(true);
      setRunValidation(true);
      // validateGrid();
      updateChartData();
    },
    [setHasUnsavedChanges, updateChartData, setRunValidation]
  );

  //update  chart data
  useEffect(
    () => {
      if (!isPasting) {
        updateChartData();
      }
    },
    [updateChartData, isPasting]
  );

  const onPasteStart = useCallback(
    () => {
      // console.log("onPasteStart");
      setIsPasting(true);
      setHasUnsavedChanges(true);
      setRunValidation(true);
    },
    [setIsPasting, setHasUnsavedChanges, setRunValidation]
  );

  const onPasteEnd = useCallback(
    () => {
      setIsPasting(false);
    },
    [setIsPasting]
  );

  const onChangeDailyEnergyConstraint = useCallback(
    value => {
      setDailyEnergyConstraint(ParseDailyEnergyConstraint(value));
      setHasUnsavedChanges(true);
      setRunValidation(true);
    },
    [setDailyEnergyConstraint, setHasUnsavedChanges, setRunValidation]
  );

  const onChangePriceBand = useCallback(
    band => value => {
      priceBandsRef.current = {
        ...priceBandsRef.current,
        [band]: ParsePriceBand(value)
      };
      setPriceBands(prev => ({
        ...prev,
        [band]: ParsePriceBand(value)
      }));
      setHasUnsavedChanges(true);
      setRunValidation(true);
    },
    [setPriceBands, setHasUnsavedChanges, setRunValidation]
  );

  //refresh header when price bands have changed
  useEffect(
    () => {
      if (priceBands && gridApi) {
        gridApi.refreshHeader();
      }
    },
    [gridApi, priceBands]
  );

  //validate all price bands
  useEffect(
    () => {
      for (let i = 1; i <= Object.keys(priceBands).length; i++) {
        const currentField = getPriceBandFieldByIndex(i);
        const prevField = getPriceBandFieldByIndex(i - 1);
        const nextField = getPriceBandFieldByIndex(i + 1);

        const currentValue = getPriceBandValue(currentField);
        const prevValue = getPriceBandValue(prevField);
        const nextValue = getPriceBandValue(nextField);
        const currentValidations = ValidatePriceBandEach(currentValue).concat(
          ValidatePriceBandAll(
            currentValue,
            prevValue,
            nextValue,
            PriceBandLabels[currentField],
            PriceBandLabels[prevField],
            PriceBandLabels[nextField]
          )
        );

        setPriceBandsValidations(prevState => ({
          ...prevState,
          //not merging as it creates bug with not resettings [currentField]: prevState[currentField].concat(currentValidations) //Note merging validations with each price band ones
          [currentField]: currentValidations
        }));
      }
    },
    [
      priceBands,
      setPriceBandsValidations,
      getPriceBandFieldByIndex,
      getPriceBandValue
    ]
  );

  //validate fast start profile
  useEffect(
    () => {
      setFastStartProfileValidations({
        t1: ValidateTtimes(fastStartProfile.t1),
        t2: ValidateTtimes(fastStartProfile.t2),
        t3: ValidateTtimes(fastStartProfile.t3),
        t4: ValidateTtimes(fastStartProfile.t4),
        totalT: ValidateTotalTimes(fastStartProfile.totalT),
        minimumLoad: ValidateMinimumLoad(fastStartProfile.minimumLoad)
      });
    },
    [fastStartProfile, setFastStartProfileValidations]
  );

  //validate daily energy constraint
  useEffect(
    () => {
      setDailyEnergyConstraintValidations(
        ValidateDailyEnergyConstraint(dailyEnergyConstraint)
      );
    },
    [setDailyEnergyConstraintValidations, dailyEnergyConstraint]
  );

  //validation displays priceBands
  useEffect(
    () => {
      setValidationDisplayPricesBands(
        TransformValidationPriceBands(priceBandsValidations)
      );
    },
    [priceBandsValidations, setValidationDisplayPricesBands]
  );

  //validation display grid
  useEffect(
    () => {
      if (gridValidations) {
        // console.log("validation display grid");
        let data = [];

        Object.keys(gridValidations).forEach(periodId => {
          Object.keys(gridValidations[periodId]).forEach(col => {
            data.push({
              fieldLabel: `${FieldLabels[col]} [${CalcTimeFromPeriodId(
                periodId
              )} (${periodId})]`,
              validations: gridValidations[periodId][col]
            });
          });
        });
        setValidationDisplayGrid(data);
        //setRunValidation(false);
      }
    },
    [runValidation, gridValidations]
  );

  //validation display fast start profile
  useEffect(
    () => {
      setValidationDisplayFastStartProfile(
        TransformValidationFastStartProfile(fastStartProfileValidations)
      );
    },
    [fastStartProfileValidations, setValidationDisplayFastStartProfile]
  );

  //validation display daily energy constraint
  useEffect(
    () => {
      setValidationDisplayDailyEnergyConstraint([
        {
          fieldLabel: OtherDailyParamsLabels["dailyEnergyConstraint"],
          validations: dailyEnergyConstraintValidations
        }
      ]);
    },
    [
      dailyEnergyConstraintValidations,
      setValidationDisplayDailyEnergyConstraint
    ]
  );

  const onChangeT1 = useCallback(
    value => {
      setFastStartProfile(prev => ({
        ...prev,
        t1: ParseTtime(value)
      }));
      setHasUnsavedChanges(true);
      setRunValidation(true);
    },
    [setHasUnsavedChanges, setRunValidation]
  );

  const onChangeT2 = useCallback(
    value => {
      setFastStartProfile(prev => ({
        ...prev,
        t2: ParseTtime(value)
      }));
      setHasUnsavedChanges(true);
      setRunValidation(true);
    },
    [setHasUnsavedChanges, setRunValidation]
  );

  const onChangeT3 = useCallback(
    value => {
      setFastStartProfile(prev => ({
        ...prev,
        t3: ParseTtime(value)
      }));
      setHasUnsavedChanges(true);
      setRunValidation(true);
    },
    [setHasUnsavedChanges, setRunValidation]
  );

  const onChangeT4 = useCallback(
    value => {
      setFastStartProfile(prev => ({
        ...prev,
        t4: ParseTtime(value)
      }));
      setHasUnsavedChanges(true);
      setRunValidation(true);
    },
    [setHasUnsavedChanges, setRunValidation]
  );

  //auto calc total T
  useEffect(
    () => {
      setFastStartProfile(prev => ({
        ...prev,
        totalT: CalcTotalTimes(
          fastStartProfile.t1,
          fastStartProfile.t2,
          fastStartProfile.t3,
          fastStartProfile.t4
        )
      }));
    },
    [
      fastStartProfile.t1,
      fastStartProfile.t2,
      fastStartProfile.t3,
      fastStartProfile.t4,
      setFastStartProfile
    ]
  );

  const onChangeMinimumLoad = useCallback(
    value => {
      setFastStartProfile(prev => ({
        ...prev,
        minimumLoad: ParseMinimumLoad(value)
      }));
      setHasUnsavedChanges(true);
      setRunValidation(true);
    },
    [setHasUnsavedChanges, setRunValidation]
  );

  const onSaveBid = useCallback(
    () => {
      if (!putOfferUrl) {
        console.error(`Not passed in putOfferUrl`);
        return;
      }

      //construct data
      let data = {};
      //price bands
      data["prices"] = priceBands;

      //periods
      const periodKey = CalcPeriodKey({
        bidCategory: bidCategory
      });
      data[periodKey] = TransformSavePeriods({
        rowData,
        maxMW: techParamsValues[TechParams.MaxCap],
        bidType,
        dispatchType: techParamsValues[TechParams.DispatchType],
        bidCategory
      });

      //fast start
      if (showFastStartProfile) {
        data["fastStartProfile"] = {
          t1: fastStartProfile.t1,
          t2: fastStartProfile.t2,
          t3: fastStartProfile.t3,
          t4: fastStartProfile.t4,
          minimumLoad: fastStartProfile.minimumLoad
        };
      }
      //daily energy constraint
      if (showDailyEnergyConstraint) {
        data["dailyEnergyConstraint"] = dailyEnergyConstraint;
      }

      //send request
      saveOfferSendRequest({ url: putOfferUrl, data: data });
    },
    [
      bidCategory,
      bidType,
      dailyEnergyConstraint,
      fastStartProfile,
      putOfferUrl,
      priceBands,
      rowData,
      showDailyEnergyConstraint,
      showFastStartProfile,
      techParamsValues,
      saveOfferSendRequest
    ]
  );

  //on getting save offer response
  useEffect(
    () => {
      if (saveOfferApiStatus === ApiStatus.Success) {
        setHasUnsavedChanges(false);
      }
    },
    [saveOfferApiStatus]
  );

  //resetting save offer api status on new changes to page
  useEffect(
    () => {
      if (hasUnsavedChanges) {
        saveOfferClear();
      }
    },
    [hasUnsavedChanges, saveOfferClear]
  );

  //delete bid
  const onDeleteBid = useCallback(
    () => {
      if (!deleteOfferUrl) {
        console.error(`Not passed in deleteOfferUrl`);
        return;
      }

      deleteOfferSendRequest({ url: deleteOfferUrl });
    },
    [deleteOfferUrl, deleteOfferSendRequest]
  );

  //fetch financial/physical offer
  useEffect(
    () => {
      if (!getOfferUrl) {
        console.error("Not passed in getOfferUrl");
        return;
      }
      if (!isNew) offersSendRequest({ url: getOfferUrl });
    },
    [getOfferUrl, offersSendRequest, isNew]
  );

  //on update offer data
  useEffect(
    () => {
      if (offersApiStatus === ApiStatus.Success && offersData) {
        let bid = offersData;
        if (offersData.bid)
          //if ack offer
          bid = offersData.bid;

        const periodKey = CalcPeriodKey({
          bidCategory: bidCategory
        });
        const periods = bid[periodKey];

        if (periods)
          periods.forEach(row => {
            validateGridRow()({ row });
          });

        setRowData(periods);

        if (bid.prices !== undefined) {
          priceBandsRef.current = bid.prices;
          setPriceBands(bid.prices);
        }
        if (bid.fastStartProfile !== undefined)
          setFastStartProfile(bid.fastStartProfile);
        if (bid.dailyEnergyConstraint !== undefined)
          setDailyEnergyConstraint(bid.dailyEnergyConstraint);
      }
    },
    [
      offersApiStatus,
      offersData,
      bidCategory,
      techParamsValues,
      allowEdit,
      isReverseBands,
      validateGridRow
    ]
  );

  //region unit capabilities
  const [techCols] = useState([
    { title: "Parameter", key: "name", dataIndex: "name" },
    { title: "Value", key: "value", dataIndex: "value" }
  ]);
  const [techRows] = useState(() =>
    Object.keys(techParamsValues).map(tp => ({
      key: tp,
      name: TechParamLabels[tp],
      value: techParamsValues[tp]
    }))
  );

  //endregion
  return (
    <div>
      <UnsavedChangesPrompt hasUnsavedChanges={hasUnsavedChanges} />
      <ApiStatusWrapper statuses={[deleteOfferApiStatus]}>
        <Redirect to={InternalLinks.DeleteSuccess} />
      </ApiStatusWrapper>
      <ApiStatusWrapper statuses={isNew ? [] : [offersApiStatus]}>
        <div>
          <OfferChart
            nemPeriodId={isCurrentDay ? now : null}
            // width={containerSize.width}
            height={containerSize.height / 3 - 30}
            priceBands={priceBands}
            bidCategory={bidCategory}
            periods={chartPeriods}
            isReverseBands={isReverseBands}
          />
          <Row gutter={8}>
            <Col span={18}>
              <Grid
                //context={{ validations: gridValidations }}
                // editType={"fullRow"}
                gridHeight={containerSize.height / 1.5 - 90}
                defaultColDef={DEFAULT_COL_DEF}
                columnDefs={cols}
                rowData={rowData}
                enableRangeSelection={true}
                enableFillHandle={true}
                //enableRangeHandle={true}
                enableCharts={false}
                enableCellChangeFlash={false}
                // editType={"fullRow"}
                // onRowValueChanged={onRowValueChanged}
                // onDragStopped={onDragStopped}
                enterMovesDown={true}
                enterMovesDownAfterEdit={true}
                onGridReady={onGridReady}
                // onRowDataUpdated={event =>
                //   console.log("row data updated", event)
                // }
                // onRowDataChanged={event => console.log("rowdatachanged", event)}
                onFirstDataRendered={onFirstDataRendered}
                getRowNodeId={getRowNodeId}
                onPasteStart={onPasteStart}
                onPasteEnd={onPasteEnd}
                //copyHeadersToClipboard={true}
                //not needed, seems they added a fix see suppressLastEmptyLineOnPaste processDataFromClipboard={processDataFromClipboard}
                onCellValueChanged={onCellValueChanged}

                // frameworkComponents={{
                //   GridCellTooltipValidations: GridCellTooltipValidations
                // }}
              />
            </Col>
            <Col span={6}>
              <div
                style={{
                  background: "white",
                  // padding: "8px 16px",
                  border: "1px solid #ccc",
                  margin: "8px 0",
                  height: containerSize.height / 1.5 - 90,
                  overflow: "auto"
                }}
              >
                <Collapse
                  defaultActiveKey={[
                    allowEdit ? "validations" : "unit_capabilities"
                  ]}
                  bordered={false}
                >
                  <Collapse.Panel
                    header={"Unit Capabilities"}
                    key={"unit_capabilities"}
                  >
                    <Table
                      dataSource={techRows}
                      columns={techCols}
                      pagination={false}
                      size={"small"}
                    />
                  </Collapse.Panel>

                  <Collapse.Panel header={"Price Bands"} key={"price_bands"}>
                    <Form
                      labelCol={{ span: LabelSpan }}
                      wrapperCol={{ span: 24 - LabelSpan }}
                    >
                      <FormPriceBands
                        showNodePrices={bidCategory === BidCategory.ENERGY}
                        canEdit={allowEdit}
                        priceBands={priceBands}
                        validationsPriceBands={priceBandsValidations}
                        onChangePriceBand={onChangePriceBand} //note for each band change
                        mlf={techParamsValues[TechParams.MLF]}
                        minPriceBand={techParamsValues[TechParams.MinBidPrice]}
                        maxPriceBand={techParamsValues[TechParams.MaxBidPrice]}
                        ifReverseBands={isReverseBands}
                      />
                    </Form>
                  </Collapse.Panel>
                  {showFastStartProfile && (
                    <Collapse.Panel
                      header={"Fast Start Profile"}
                      key={"fast_start_profile"}
                    >
                      <Form
                        labelCol={{ span: LabelSpan }}
                        wrapperCol={{ span: 24 - LabelSpan }}
                      >
                        <FormFastStartProfile
                          canEdit={allowEdit}
                          t1={fastStartProfile.t1}
                          t2={fastStartProfile.t2}
                          t3={fastStartProfile.t3}
                          t4={fastStartProfile.t4}
                          totalT={fastStartProfile.totalT}
                          minimumLoad={fastStartProfile.minimumLoad}
                          onChangeT1={onChangeT1}
                          onChangeT2={onChangeT2}
                          onChangeT3={onChangeT3}
                          onChangeT4={onChangeT4}
                          onChangeMinimumLoad={onChangeMinimumLoad}
                          t1Validations={fastStartProfileValidations.t1}
                          t2Validations={fastStartProfileValidations.t2}
                          t3Validations={fastStartProfileValidations.t3}
                          t4Validations={fastStartProfileValidations.t4}
                          totalTValidations={fastStartProfileValidations.totalT}
                          minimumLoadValidations={
                            fastStartProfileValidations.minimumLoad
                          }
                        />
                      </Form>
                    </Collapse.Panel>
                  )}
                  {showDailyEnergyConstraint && (
                    <Collapse.Panel
                      header={"Daily Energy Constraint"}
                      key={"daily_energy_constraint"}
                    >
                      <Form
                        labelCol={{ span: LabelSpan }}
                        wrapperCol={{ span: 24 - LabelSpan }}
                      >
                        <FormOtherPhysical
                          canEdit={allowEdit}
                          dailyEnergyConstraint={dailyEnergyConstraint}
                          dailyEnergyConstraintValidations={
                            dailyEnergyConstraintValidations
                          }
                          onChangeDailyEnergyConstraint={
                            onChangeDailyEnergyConstraint
                          }
                        />
                      </Form>
                    </Collapse.Panel>
                  )}

                  {allowEdit && (
                    <Collapse.Panel header={"Validations"} key={"validations"}>
                      {runValidation && (
                        <ValidationMessage
                          messageType={ValidationMessageType.Warning}
                          message={
                            "Validations below may not be up to date. Please click 'Validate' to re-run validations"
                          }
                        />
                      )}
                      <ValidationsDisplay
                        section={"Grid"}
                        data={validationDisplayGrid}
                      />
                      <ValidationsDisplay
                        section={"Price Bands"}
                        data={validationDisplayPriceBands}
                      />
                      {showFastStartProfile && (
                        <ValidationsDisplay
                          section={"Fast Start Profile"}
                          data={validationDisplayFastStartProfile}
                        />
                      )}
                      {showDailyEnergyConstraint && (
                        <ValidationsDisplay
                          section={"Daily Energy Constraint"}
                          data={validationDisplayDailyEnergyConstraint}
                        />
                      )}
                    </Collapse.Panel>
                  )}
                </Collapse>
              </div>
            </Col>
          </Row>
          {allowEdit && (
            <Space>
              <ButtonActionPrimary
                disabled={!runValidation}
                onClick={validateGrid}
                text={"Validate"}
              />
              <ButtonSave
                disabled={!hasUnsavedChanges}
                onClick={onSaveBid}
                apiStatus={saveOfferApiStatus}
                text={"Save Changes"}
              />
              {hasUnsavedChanges && (
                <span style={{ fontStyle: "italic" }}> Unsaved changes</span>
              )}{" "}
            </Space>
          )}
          {allowDelete && (
            <Popconfirm
              placement={"topRight"}
              title={"Are you sure to delete this bid permanently?"}
              onConfirm={onDeleteBid}
              okText={"Yes"}
              cancelText={"No"}
            >
              <ButtonDelete
                //onClick={onDeleteBid}
                style={{ float: "right", marginRight: "48px" }}
              >
                Delete Offer
              </ButtonDelete>
            </Popconfirm>
          )}
        </div>
      </ApiStatusWrapper>
    </div>
  );
};

OfferTable.propTypes = {
  bidType: string.isRequired,
  bidCategory: string.isRequired,
  getOfferUrl: string.isRequired,
  putOfferUrl: string,
  deleteOfferUrl: string,
  allowEdit: bool.isRequired,
  allowDelete: bool.isRequired,
  duid: string.isRequired,
  bidSettlementDate: string
};

OfferTable.defaultProps = {
  allowEdit: false,
  allowDelete: false
};
