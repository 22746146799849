export const MonitorTables = {
  AutoStatus: "settings_auto",
  Drafts: "draft_bid",
  Template: "template_bid",
  TemplateSet: "template_bid_set",
  BidStatus: "submitted_bid_set_status",
  AckOffers: "ehub_ack_bid",
  AckOffersSet: "ehub_ack_bid_set",
  DefaultOffers: "default_bid",
  SolverBidSet: "solver_bid_set",
  SolverBid: "solver_bid",
  CheckBid: "check_bid",
  EventLog: "event_log"
};
