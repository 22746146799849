import React, { useState, useEffect, useContext, useCallback } from "react";
import PropTypes from "prop-types";
import moment from "moment";

import { Chart, ChartHelper } from "../generic";

import { ThemeContext } from "../../contexts";
import {ApiStatus, GridFields, ParametersApi} from "../../utils";
import {CalcIfReverseBands, CalcPriceBandIndex} from "../offer_table/utils/calc";
import { Colors } from "../offer_chart/colors";
import {ApiMethodTypes, useApi} from "../../hooks";

const UPDATE_TIMER_INTERVAL = 5000; //10 secs
const NEM_DT_FORMAT = "YYYY-MM-DDTHH:mm:00";

export const SolveResultChart = ({
  chartWidth,
  chartHeight,
  data,
  duid,
  bidSource,
  bidType,
  }) => {
  const theme = useContext(ThemeContext);
  const [isReverseBands, setIsReverseBands] = useState(false);

  const [series, setSeries] = useState([]);
  //region Tech params

  //fetch technical params
  //DISPATCH TYPE
  const [dispatchType, setDispatchType] = useState(null);
  const {
    sendRequest: techParamsDispatchTypeSendRequest,
    data: techParamsDispatchTypeData,
    apiStatus: techParamsDispatchTypeApiStatus
  } = useApi({ method: ApiMethodTypes.Get });

  useEffect(
      () => {
        techParamsDispatchTypeSendRequest({
          url: ParametersApi.GetDispatchTypeUrl({
            duid,
            bidType
          })
        });
      },
      [techParamsDispatchTypeSendRequest, duid, bidType]
  );

  //on update technical params data
  useEffect(
      () => {
        if (
            techParamsDispatchTypeApiStatus === ApiStatus.Success &&
            techParamsDispatchTypeData
        ) {
          if (techParamsDispatchTypeData.length > 0)
            setDispatchType(techParamsDispatchTypeData[0]["val"]);
          else {
            console.error("Unable to find tech params");
          }
        }
      },
      [techParamsDispatchTypeData, techParamsDispatchTypeApiStatus]
  );

  useEffect(
      () => {
        if (dispatchType !== null) {
          const isReverse = CalcIfReverseBands(bidType, dispatchType);
          setIsReverseBands(isReverse);
        }
      },
      [bidType, dispatchType]
  );
  //endregion
  //region NEM time
  const [now, setNow] = useState(
    moment()
      .utc()
      .add(10, "hours")
      .format(NEM_DT_FORMAT)
  );

  const updateNow = useCallback(
    () => {
      setNow(
        moment()
          .utc()
          .add(10, "hours")
          .format(NEM_DT_FORMAT)
      ); //update now nem time
    },
    [setNow]
  );

  //start update timer
  useEffect(
    () => {
      const timer = setInterval(updateNow, UPDATE_TIMER_INTERVAL);
      return () => clearInterval(timer);
    },
    [updateNow]
  );
  //endregion

  useEffect(
    () => {
      if (data && data.length > 0) {
        let ovSeriesData = [];
        let frrpSeriesData = [];
        let berrpSeriesData = [];
        let maxAvailData = [];
        let seriesDataByBand = {};
        let priceBands= {}

        data.forEach(row => {
          if (
              (bidSource !== null && row[GridFields.BidSource] === bidSource) &&
            row[GridFields.Duid] === duid &&
            row[GridFields.BidType] === bidType
          ) {
            const dt = ChartHelper.formatDateTime(row[GridFields.PeriodTime]);
            ovSeriesData.push([dt, parseFloat(row[GridFields.OptimalVolume])]);

            frrpSeriesData.push([dt, row[GridFields.ForecastRRP]]);
            berrpSeriesData.push([dt, row[GridFields.BreakEvenRRP]]);
            berrpSeriesData.push([dt, row[GridFields.BreakEvenRRP]]);
            maxAvailData.push([dt, row[GridFields.MaxAvail]])
            //bands
            for (let i = 1; i <= 10; i++) {
              if (!seriesDataByBand[`band${i}`])
                seriesDataByBand[`band${i}`] = [];
              seriesDataByBand[`band${i}`].push([dt, row[`band${i}`]]);
            }

            //price bands by bid type

              for(let i = 1; i <=10; i++){
                priceBands[`priceBand${i}`] = row[`PriceBand${i}`]
              }

          }
        });


        let series = [];
        //add bands series
        for (let i = 0; i < 10; i++) {
          const pbIdx = CalcPriceBandIndex(i, isReverseBands);
          //let data = [[0, 0]].concat(); //hack: to make sure that first row change is visible in area stack without line width, added a period 0, with 0 value
          const data = seriesDataByBand[`band${pbIdx}`]; //todo above hack, but have to change first period id to time
          series.push({
            name: `$${priceBands[`priceBand${pbIdx}`]}`,// `Band ${pbIdx}`,
            type: "area",
            step: "right",
            data: data, //seriesDataByBand[`band${pbIdx}`],
            color: Colors[`band${pbIdx}`],
            yAxis: 0
          });
        }
        series = series.concat([

          {
            name: "Max Avail",
            data: maxAvailData,
            step: "right",
            color: Colors.maxAvail,
            yAxis: 0
          },
          {
            name: "Optimal Volume",
            data: ovSeriesData,
            step: "right",
            color: "#000", //theme.appMasterColor,
            lineWidth: 4,
            yAxis: 0
          },

          {
            name: "Forecast RRP",
            data: frrpSeriesData,
            step: "right",
            color: "#888",
            lineWidth: 2,
            yAxis: 1
          },
          {
            name: "Break Even RRP",
            data: berrpSeriesData,
            step: "right",
            color: "#444",
            lineWidth: 2,
            yAxis: 1
          }
        ]);
        setSeries(series);
      }
    },
    [data, theme.appMasterColor, duid, bidSource, bidType, isReverseBands]
  );

  return (
    <Chart
      chartWidth={chartWidth}
      chartHeight={chartHeight}
      title={`${duid} ${bidType} ${bidSource ? `(${bidSource})` : ''}`}
      series={series}
      xAxis={[
        {
          type: "datetime",
          plotLines: [
            {
              value: ChartHelper.formatDateTime(
                moment(now).format("YYYY-MM-DD HH:mm:ss")
              ),
              dashStyle: "dash",
              width: 2,
              color: "#000",
              label: { text: moment(now).format("HH:mm") }
            }
          ]
        }
      ]}
      yAxis={[
        {
          reversedStacks: false,
          title: { text: "MW" }
        },
        {
          title: { text: "$" },
          opposite: true
        }
      ]}
      plotOptions={{
        area: {
          stacking: "normal",
          step: "right",
          marker: {
            enabled: false
          },
          lineWidth: 0
        }
      }}
    />
  );
};

SolveResultChart.propTypes = {
  chartWidth: PropTypes.number,
  chartHeight: PropTypes.number,
  bidSource: PropTypes.string,
  duid: PropTypes.string.isRequired,
  bidType: PropTypes.string.isRequired
};

SolveResultChart.defaultProps = {
  chartWidth: 1600,
  chartHeight: 900
};
