import { ActionTypeIds } from "../ActionTypeIds";

export const ActionConfigOffers = {
  actions: {
    [ActionTypeIds.SwitchAutoBidding]: {
      enabled: true
    },
    [ActionTypeIds.LoadIntoDrafts]: {
      enabled: true
    },
    [ActionTypeIds.SubmitDrafts]: {
      enabled: false
    }
  }
};
