import { Domain } from "../../config";
const BASE_URL = `https://api.auth.${Domain}`;
export const AuthApi = {
  GetHelloWorldUrl: () => `${BASE_URL}/helloworld`,
  GetUsersUrl: () => `${BASE_URL}/v1/users`,
  GetUserByIdUrl: ({ userId }) => `${BASE_URL}/v1/user/${userId}`,
  PostUserUrl: () => `${BASE_URL}/v1/user`,
  PutUserByIdUrl: ({ userId }) => `${BASE_URL}/v1/user/${userId}`,
  GetUserTypesUrl: () => `${BASE_URL}/v1/config/user_types`,
  GetAppsUrl: () => `${BASE_URL}/v1/settings/apps`,
  GetRolesUrl: () => `${BASE_URL}/v1/settings/roles`,
  PostRoleUrl: () => `${BASE_URL}/v1/settings/role`,
  GetRoleByIdUrl: ({ roleId }) => `${BASE_URL}/v1/settings/role/${roleId}`,
  PutRoleByIdUrl: ({ roleId }) => `${BASE_URL}/v1/settings/role/${roleId}`,
  GetScopesByRoleIdUrl: ({ roleId }) =>
    `${BASE_URL}/v1/settings/role/${roleId}/scopes`,
  GetScopesUrl: () => `${BASE_URL}/v1/config/scopes`
};
