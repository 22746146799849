export const TechParams = {
  MaxCap: "MaxCap",
  MaxBidPrice: "MaxBidPrice",
  MinBidPrice: "MinBidPrice",
  MLF: "MLF",
  MaxRocUp: "MaxRocUp",
  MaxRocDown: "MaxRocDown",
  MaxUpperAngle: "MaxUpperAngle",
  MaxLowerAngle: "MaxLowerAngle",
  MaxEnablementMax: "MaxEnablementMax",
  MinEnablementMin: "MinEnablementMin",
  DispatchType: "DispatchType",
  StartType: "StartType",
  IsDailyEnergyConstraint: "IsDailyEnergyConstraint"
};
