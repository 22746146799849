import { GridCellTypes } from "../../grid_cells";
import { GridDuidFields } from "../GridDuidFields";

export const GridColDefLastAlgoSubmission = {
  headerName: "Algo Last Submission",
  headerTooltip: "Detail on the latest submitted Algo Bid.  Note that this preceeds AEMO acknowledgement and hence the bid may have been rejected (or not even received) by AEMO, rather a bid has been submitted if the process has been initiated.", 
  children: [
    {
      headerName: "Submission Status",
      headerTooltip: "Shows the pass/fail of all 5 stages in submitting a bid.  Normally this field will have a green tick and state 5/5 send to AEMO which means the bid was received and acknowledged by AEMO.", 
      field: GridDuidFields.LastSubmissionStageIdStatusComboAlgo,
      cellRenderer: GridCellTypes.GridCellSubmissionStatus,
      width: 130,
      cellRendererParams: params =>
        params.data
          ? {
              stage: params.data[GridDuidFields.LastSubmissionStageIdAlgo],
              status: params.data[GridDuidFields.LastSubmissionStageStatusAlgo]
              //link: params.data[GridDuidFields.LastSubmissionLinkAlgo]
            }
          : null
    },
    {
      headerName: "Submitted At",
      headerTooltip: "How long ago an Algo Bid was last submitted to AEMO.", 
      field: GridDuidFields.LastSubmissionSubmittedAtAlgo,
      cellRenderer: GridCellTypes.GridCellTimeAgo,
      width: 110
      // columnGroupShow: "open"
    },
    {
      headerName: "Submission Id",
      headerTooltip: "An identifier assigned by pdBidr to every Algo Bid that is Submitted to AEMO.  Click the link to open a new tab to show the Submission Info and Submission Progress",
      field: GridDuidFields.LastSubmissionSubmissionIdAlgo,
      cellRenderer: GridCellTypes.GridCellLink,
      cellRendererParams: params =>
        params.data
          ? {
              link: params.data[GridDuidFields.LastSubmissionLinkAlgo],
              openInNewTab: true
            }
          : null,
      columnGroupShow: "open",
      width: 250
    }
  ]
};
