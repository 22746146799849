import { GridCellTypes } from "../../grid_cells";
import { GridDuidFields } from "../GridDuidFields";

export const GridColDefAlgoOffers = {
  headerName: "Algo Bids",
  headerTooltip: "Algo Bids are formulated after every solver run (generally every five minutes) this Algo Bid may or may not have been submitted.",

  children: [
    {
      headerName: "Modified At",
      headerTooltip: "How long ago the Algo Bid was created.",  
      field: GridDuidFields.LatestAlgoDraftLastModifiedAt,
      width: 120,
      cellRenderer: GridCellTypes.GridCellTimeAgoLink,
      cellRendererParams: params =>
        params.data
          ? {
              time: params.data[GridDuidFields.LatestAlgoDraftLastModifiedAt],
              link: params.data[GridDuidFields.AlgoDraftViewLink]
            }
          : null
    },
    {
      headerName: "Solve Id",
      headerTooltip: "The identifier of the solver run that created the set of optimal Bids (all bids should have the same Solve Id because the solution is cooptimised).  Click on a Solve Id to if the Algo Bid was submitted and if not why it was skipped.",    
      field: GridDuidFields.LatestAlgoDraftSolveId,
      columnGroupShow: "open",

      width: 280,
      cellRenderer: GridCellTypes.GridCellLink,
      cellRendererParams: params =>
        params.data
          ? {
              link: params.data[GridDuidFields.SolveHistoryViewLink],
              openInNewTab: true
            }
          : null
    }
  ]
};
