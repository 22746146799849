import React, { useContext } from "react";
import { object, string } from "prop-types";

import { ThemeContext } from "../../contexts";

export const TitlePage = React.memo(({ style, title }) => {
  const theme = useContext(ThemeContext);
  return (
    <h1
      style={{
        color: theme.titleColor,
        margin: "0 0 8px 0",
        padding: "8px 0",
        fontWeight: "bold",
        ...style
      }}
    >
      {title}
    </h1>
  );
});

TitlePage.propTypes = {
  style: object,
  title: string.isRequired
};
