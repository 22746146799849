import React, { useCallback, useState } from "react";
import { Redirect } from "react-router-dom";
import { AppContainer } from "../components";
import { DBFields, InternalLinks } from "../utils/constants";
import { UserForm } from "../components/forms";
import { Col, Row } from "antd";
import { ApiMethodTypes } from "../hooks";
import { AuthApi } from "../utils/api";

const POST_URL = AuthApi.PostUserUrl();

export const UserNew = ({ history, match }) => {
  const [userId, setUserId] = useState("");

  const onSaveSuccess = useCallback(
    ({ data }) => {
      setUserId(data[DBFields.UserId]);
    },
    [setUserId]
  );

  return (
    <AppContainer match={match} history={history}>
      {userId && <Redirect to={InternalLinks.User({ userId })} />}
      <Row gutter={16}>
        <Col xl={12} sm={24}>
          <UserForm
            submitUrl={POST_URL}
            submitUrlMethodType={ApiMethodTypes.Post}
            onSaveSuccess={onSaveSuccess}
          />
        </Col>
      </Row>
    </AppContainer>
  );
};
