import { LogType } from "./LogType";

export const ReallocateBand10Volume = ({ bidPeriod, maxCapacity }) => {
  let logs = [];
  logs.push({
    type: LogType.DEBUG,
    message: `Reallocate remaining volume to band10`,
    code: JSON.stringify({ bidPeriod, maxCapacity })
  });

  // Re-allocate remaining volume in last band if total volume not equal to capacity
  // Band 10  = max capacity - SUM(Band1...9)
  let total_band1to9 = 0;
  for (let i = 1; i <= 9; i++) {
    total_band1to9 = total_band1to9 + bidPeriod[`band${i}`];
  }
  const totalAllBands = total_band1to9 + bidPeriod["band10"];
  if (totalAllBands !== maxCapacity) {
    const remainingVolume = maxCapacity - total_band1to9;
    logs.push({
      type: LogType.DEBUG,
      message: "Found remaining volume for band10",
      code: JSON.stringify({
        remainingVolume,
        maxCapacity,
        totalAllBands,
        total_band1to9
      })
    });
    bidPeriod["band10"] = remainingVolume;
  }

  return { bidPeriod, logs };
};
