import React from "react";
import { string, object } from "prop-types";
import moment from "moment";

export const DisplayTime = React.memo(({ style, datetime, dtFormat }) => {
  const formattedDt = moment(datetime).format(dtFormat);

  return <span style={style}>{formattedDt}</span>;
});

DisplayTime.defaultProps = {
  dtFormat: "YYYY-MM-DD HH:mm:ss"
};

DisplayTime.propTypes = {
  datetime: string.isRequired,
  dtFormat: string,
  style: object
};
