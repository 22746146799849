import React from "react";
import { Result } from "antd";

import { AppContainer, ButtonBackOffers } from "../components";

export const DeleteSuccess = ({ history, match }) => (
  <AppContainer history={history} match={match}>
    <Result
      status={"success"}
      title={"Item Deleted"}
      subTitle={
        "Item has been successfully deleted, click below to go back to bids page"
      }
      extra={<ButtonBackOffers />}
    />
  </AppContainer>
);
