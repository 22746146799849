import React from "react";
import { string } from "prop-types";

import { DisplayTime } from "../../generic/displays";

export const GridCellTime = React.memo(({ value }) => {
  if (value) return <DisplayTime datetime={value} />;

  return value;
});

GridCellTime.propTypes = {
  value: string
};
