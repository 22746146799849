import React from "react";
import { object, string } from "prop-types";
import { Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";

export const InfoTooltip = ({ style, text }) => (
  <Tooltip title={text}>
    <InfoCircleOutlined style={{ cursor: "pointer", ...style }} />
  </Tooltip>
);

InfoTooltip.propTypes = {
  style: object,
  text: string.isRequired
};
