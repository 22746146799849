import { GridCellTypes } from "../../grid_cells";
import { GridDuidFields } from "../GridDuidFields";

export const GridColDefTemplateOffer = ({ userMappings }) => ({
  headerName: "Templates",
  children: [
    {
      headerName: "Last Modified At",
      field: GridDuidFields.TemplateLastModifiedAt,
      width: 200,
      cellRenderer: GridCellTypes.GridCellTimeAgoLink,
      cellRendererParams: params =>
        params.data
          ? {
              time: params.data[GridDuidFields.TemplateLastModifiedAt],
              link: params.data[GridDuidFields.TemplateEditLink]
            }
          : null
    },
    {
      headerName: "Last Modified By",
      field: GridDuidFields.TemplateLastModifiedBy,
      width: 200,
      refData: userMappings
    }
  ]
});
