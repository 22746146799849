import React, { useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { useStateWithSessionStorage } from "../../hooks";
import moment from "moment";
import { Space } from "antd";
import { InputRadioButtons } from "../generic/form/inputs";
import { DateRangeTimePeriodPicker } from "../DateRangeTimePeriodPicker";
const TIME_STEP = {
  MIN5: "5", //storing as string not number as in session storage it gets converted to strings
  MIN30: "30",
  DAY1: "1440"
};

const timeStepOptions = [
  {
    value: TIME_STEP.MIN5,
    text: "5 min"
  },
  { value: TIME_STEP.MIN30, text: "30 min" },
  { value: TIME_STEP.DAY1, text: "1 day" }
];

const TIME_PERIOD_OPTIONS = {
  [TIME_STEP.MIN5]: [
    {
      value: "2 d",
      text: "2 days"
    },
    {
      value: "4 d",
      text: "4 days"
    },
    {
      value: "7 d",
      text: "7 days"
    }
  ],
  [TIME_STEP.MIN30]: [
    {
      value: "2 d",
      text: "2 days"
    },
    {
      value: "4 d",
      text: "4 days"
    },
    {
      value: "7 d",
      text: "7 days"
    },
    {
      value: "14 d",
      text: "14 days"
    }
  ],
  [TIME_STEP.DAY1]: [
    { value: "1 w", text: "1 week" },
    { value: "1 M", text: "1 month" },
    { value: "3 M", text: "3 months" },
    { value: "12 M", text: "12 months" }
  ]
};
export const DateTimePeriodStepPicker = ({ onChange }) => {
  const [startTime, setStartTime] = useStateWithSessionStorage(
    "chart.startTime"
  );

  const [timeStep, setTimeStep] = useStateWithSessionStorage("chart.timeStep");
  const [timePeriod, setTimePeriod] = useStateWithSessionStorage(
    "chart.timePeriod"
  );

  //on load
  useEffect(
    () => {
      if (timeStep === "") {
        setTimeStep(TIME_STEP.MIN5);
      }
    },
    [setTimeStep, timeStep]
  );

  useEffect(
    () => {
      if (startTime === "") {
        setStartTime(moment().format("YYYY-MM-DD 04:00:00"));
      }
    },
    [setStartTime, startTime]
  );

  useEffect(
    () => {
      if (
        timePeriod === "" &&
        TIME_PERIOD_OPTIONS &&
        TIME_PERIOD_OPTIONS.length > 0
      ) {
        setTimePeriod(TIME_PERIOD_OPTIONS[0].value);
      }
    },
    [setTimePeriod, timePeriod]
  );

  //onclick time step button
  const onClickTimeStepButton = useCallback(
    value => {
      setTimeStep(value);
      // setTimePeriod(TIME_PERIOD_OPTIONS[value][0].value);
    },
    [setTimeStep]
  );

  const onChangeDateRangeTimePeriodPicker = useCallback(
    (fromDate, toDate, timePeriod) => {
      setStartTime(fromDate);
      setTimePeriod(timePeriod);
      onChange(fromDate, toDate, timeStep);
    },
    [setStartTime, setTimePeriod, timeStep, onChange]
  );

  return (
    <Space>
      Time step:
      <InputRadioButtons
        value={timeStep}
        onChange={onClickTimeStepButton}
        options={timeStepOptions}
      />
      {startTime && (
        <DateRangeTimePeriodPicker
          fromDateTime={startTime}
          selectedTimePeriod={timePeriod}
          timePeriodOptions={TIME_PERIOD_OPTIONS[timeStep]}
          timePeriodProps={{ style: { minWidth: "120px" } }}
          onChange={onChangeDateRangeTimePeriodPicker}
        />
      )}
    </Space>
  );
};

DateTimePeriodStepPicker.propTypes = { onChange: PropTypes.func.isRequired };
