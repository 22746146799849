import React, { useCallback } from "react";
import { object, any, func, string, bool, shape, number } from "prop-types";
import { Form as FormA, Row, Col } from "antd";

import { FormButtonSubmit, FormButtonCancel } from "./form_buttons";
import { FormResultSuccess } from "./form_results";
import { ApiStatus } from "../../../utils";
import { ApiStatusWrapper } from "../../ApiStatusWrapper";

const Form = ({
  children,
  apiStatus,
  disableEdit,
  resultSuccess,
  resultWarning,
  submitButtonText,
  cancelButtonText,
  disableSubmit,
  onSubmit,
  onCancel,
  form,
  initialValues,
  labelSpan,
  showCancelButton,
  layout,
  wrapperFooter,
  labelFooter
}) => {
  const onFinish = useCallback(
    values => {
      // console.log("Success:", values);
      onSubmit(values);
    },
    [onSubmit]
  );

  const onFinishFailed = useCallback(errorInfo => {
    console.error("Failed:", errorInfo);
  }, []);

  // const handleSubmit = useCallback(
  //   e => {
  //     e.preventDefault();
  //     form.validateFieldsAndScroll((err, fieldsValue) => {
  //       if (!err) onSubmit(fieldsValue);
  //     });
  //   },
  //   [form, onSubmit]
  // );

  const handleCancel = useCallback(
    e => {
      // form.resetFields();//todo tofix
      onCancel();
    },
    [onCancel]
  );

  const wrapperSpan = labelSpan !== null ? 24 - labelSpan : null;

  return (
    <FormA
      labelCol={{ span: labelSpan }}
      wrapperCol={{ span: wrapperSpan }}
      initialValues={initialValues}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      layout={layout}
    >
      {children}
      <FormA.Item wrapperCol={{ span: wrapperSpan, offset: labelSpan }}>
        <FormButtonSubmit
          disabled={disableEdit || disableSubmit}
          style={{ marginRight: "8px", minWidth: "100px" }}
          text={submitButtonText}
        />
        {showCancelButton && (
          <FormButtonCancel
            onClick={handleCancel}
            disabled={apiStatus === ApiStatus.Loading}
            text={cancelButtonText}
            style={{ marginRight: "8px", minWidth: "100px" }}
          />
        )}
      </FormA.Item>
      <Row>
        <Col span={labelSpan}>{labelFooter}</Col>
        <Col span={wrapperSpan}>{wrapperFooter}</Col>
      </Row>
      <ApiStatusWrapper statuses={[apiStatus]}>
        <FormResultSuccess {...resultSuccess} />
      </ApiStatusWrapper>
      {/*{apiStatus === ApiStatus.Loading && <FormResultProcessing />}*/}
      {/*{apiStatus === ApiStatus.Success && (*/}
      {/*<FormResultSuccess {...resultSuccess} />*/}
      {/*)}*/}
      {/*{apiStatus === ApiStatus.Failed && (*/}
      {/*<FormResultWarning {...resultWarning} />*/}
      {/*)}*/}
    </FormA>
  );
};

Form.propTypes = {
  style: object,
  children: any,
  onSubmit: func.isRequired,
  onCancel: func.isRequired,
  apiStatus: string.isRequired,
  layout: string,
  labelSpan: number,
  disabledEdit: bool,
  initialValues: object,
  resultSuccess: shape({
    title: string,
    subTitle: string,
    buttonPrimaryText: string,
    buttonPrimaryOnClick: func
  }),
  resultWarning: shape({
    title: string,
    subTitle: string,
    buttonPrimaryText: string,
    buttonPrimaryOnClick: func,
    buttonSecondaryText: string,
    buttonSecondaryOnClick: func
  }),
  submitButtonText: string,
  cancelButtonText: string,
  disableSubmit: bool,
  showCancelButton: bool.isRequired
};

Form.defaultProps = {
  submitButtonText: "Submit",
  cancelButtonText: "Cancel",
  showCancelButton: true
};

export default Form;
