import { GridFields } from "./GridFields";

export const FieldLabels = {
  [GridFields.EnablementMax]: "Enablement Max",
  [GridFields.EnablementMin]: "Enablement Min",
  [GridFields.FixedLoad]: "Fixed Load",
  [GridFields.HighBreakPoint]: "High Break Point",
  [GridFields.LowBreakPoint]: "Low Break Point",
  [GridFields.RampRateDown]: "ROC down",
  [GridFields.RampRateUp]: "ROC up",
  [GridFields.FixedLoad]: "Fixed Load",
  [GridFields.PasaAvail]: "Pasa Avail",
  [GridFields.MaxAvail]: "Max Avail",
  [GridFields.Band1]: "Band 1",
  [GridFields.Band2]: "Band 2",
  [GridFields.Band3]: "Band 3",
  [GridFields.Band4]: "Band 4",
  [GridFields.Band5]: "Band 5",
  [GridFields.Band6]: "Band 6",
  [GridFields.Band7]: "Band 7",
  [GridFields.Band8]: "Band 8",
  [GridFields.Band9]: "Band 9",
  [GridFields.Band10]: "Band 10"
};
