import React from "react";
import { arrayOf, string, shape } from "prop-types";

export const DisplayLabelValueList = ({ rows }) => {
  return (
    <table style={{ width: "100%" }}>
      <tbody>
        {rows.map(row => (
          <tr key={row.label} style={{ borderBottom: "1px solid #eee" }}>
            <td style={{ padding: "4px 8px" }}>
              <b>{row.label}: </b>
            </td>
            <td style={{ padding: "4px 8px" }}>{row.value}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

DisplayLabelValueList.propTypes = {
  rows: arrayOf(
    shape({
      label: string.isRequired,
      value: string
    })
  )
};
