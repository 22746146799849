import React, { useCallback, useEffect, useState } from "react";
import { DatePicker, Col, Row, Space, Layout } from "antd";
import moment from "moment";
import {
  ApiStatusWrapper,
  AppContainer,
  BidParamsMenu,
  BidParamValueChart,
  DateTimePeriodStepPicker,
  Form,
  FormItem,
  Grid,
  GridCellTimeAgo,
  GridCellTypes,
  InputNumber,
  InputTextArea,
  TitleSection
} from "../components";
import {
  ApiStatus,
  DBFields,
  GridFields,
  AuthApi,
  ParametersApi,
  GridConfigDefault,
  CalcRefreshId
} from "../utils";
import { ApiMethodTypes, useApi } from "../hooks";

const FIELDS = {
  Val: "val",
  DateTimeRange: "dateTimeRange",
  Notes: "notes"
};

export const BidParam = ({ match, history }) => {
  const { parameterId, bidType, duid, categoryId } = match.params;

  //region bid param config
  const {
    sendRequest: bidParamConfigSendRequest,
    data: bidParamConfigData,
    apiStatus: bidParamConfigApiStatus
  } = useApi({ method: ApiMethodTypes.Get });

  const [bidParamConfig, setBidParamConfig] = useState({});

  //fetch
  useEffect(
    () => {
      bidParamConfigSendRequest({
        url: ParametersApi.GetConfigBidParamById({
          bidParameterId: parameterId
        })
      });
    },
    [bidParamConfigSendRequest, parameterId]
  );
  //update
  useEffect(
    () => {
      if (
        bidParamConfigApiStatus === ApiStatus.Success &&
        bidParamConfigData &&
        bidParamConfigData.length > 0
      ) {
        let config = bidParamConfigData[0]; //should be only one
        setBidParamConfig(config);
      }
    },
    [bidParamConfigData, bidParamConfigApiStatus]
  );

  //endregion

  //region Chart

  //adding a prop to force re-render of chart
  const [chartRefreshId, setChartRefreshId] = useState(() => CalcRefreshId());

  const [chartWidth, setChartWidth] = useState(800);
  const [chartHeight, setChartHeight] = useState(450);

  //once on mount
  useEffect(() => {
    setChartWidth(window.innerWidth - 320);
    const availHeight = window.innerHeight - 270;
    setChartHeight(availHeight / 2);
    setGridHeight(availHeight / 2);
  }, []);

  //endregion

  //region Chart Time

  const [startTime, setStartTime] = useState();
  const [stopTime, setStopTime] = useState();
  const [timeStep, setTimeStep] = useState();

  const onChangeDates = useCallback(
    (startTime, stopTime, timeStep) => {
      setStartTime(startTime);
      setStopTime(stopTime);
      setTimeStep(timeStep);
    },
    [setStartTime, setStopTime]
  );
  //endregion

  //region UserMappings
  const {
    sendRequest: usersSendRequest,
    data: usersData,
    apiStatus: usersApiStatus
  } = useApi({ method: ApiMethodTypes.Get });
  const [userMappings, setUserMappings] = useState({});

  //fetch users
  useEffect(
    () => {
      usersSendRequest({ url: AuthApi.GetUsersUrl() });
    },
    [usersSendRequest]
  );
  //update users data
  useEffect(
    () => {
      if (usersApiStatus === ApiStatus.Success && usersData) {
        let userMappings = {};

        usersData.forEach(row => {
          userMappings[row[DBFields.UserId]] = row[DBFields.UserDisplayName];
        });
        setUserMappings(userMappings);
      }
    },
    [usersData, usersApiStatus]
  );

  //endregion

  //region Grid

  const [gridHeight, setGridHeight] = useState(600);

  const [columnDefs, setColumnDefs] = useState([]);
  const [rowData, setRowData] = useState([]);

  //define columns
  useEffect(
    () => {
      const cols = [
        {
          headerName: "From Date Time",
          field: GridFields.FromDateTime,
          width: 150
        },
        {
          headerName: "To Date Time",
          field: GridFields.ToDateTime,
          width: 150
        },
        {
          headerName: "Val",
          field: GridFields.Val,
          width: 100,
          type: "numericColumn"
        },
        {
          headerName: "Last Modified",
          children: [
            {
              headerName: "Notes",
              field: GridFields.LastModifiedNotes,
              width: 250
            },
            {
              headerName: "By",
              field: GridFields.LastModifiedBy,
              width: 120,
              refData: userMappings
              //  columnGroupShow: "open"
            },
            {
              headerName: "At",
              field: GridFields.LastModifiedAt,
              cellRenderer: GridCellTypes.GridCellTimeAgo,
              width: 120
              //columnGroupShow: "open"
            }
          ]
        }
      ];
      setColumnDefs(cols);
    },
    [setColumnDefs, userMappings]
  );

  const onGridReady = useCallback(params => {
    // setGridApi(params.api);
    params.api.sizeColumnsToFit();
  }, []);

  //get param values records data
  const {
    sendRequest: pvRecordsSendRequest,
    data: pvRecordsData,
    apiStatus: pvRecordsApiStatus
  } = useApi({ method: ApiMethodTypes.Get });

  //fetch
  const fetchPvRecords = useCallback(
    () => {
      pvRecordsSendRequest({
        url: ParametersApi.GetBidParamValuesRecordsUrl({
          duid,
          bidType,
          parameterId
        })
      });
    },
    [pvRecordsSendRequest, duid, bidType, parameterId]
  );

  //once on mount
  useEffect(
    () => {
      fetchPvRecords();
    },
    [fetchPvRecords]
  );

  //received response
  useEffect(
    () => {
      if (pvRecordsApiStatus === ApiStatus.Success && pvRecordsData) {
        const rows = pvRecordsData.map(row => {
          return {
            [GridFields.Val]: row[DBFields.Val],
            [GridFields.FromDateTime]: row[DBFields.FromDateTime],
            [GridFields.ToDateTime]: row[DBFields.ToDateTime],
            [GridFields.LastModifiedNotes]: row[DBFields.LastModifiedNotes],
            [GridFields.LastModifiedAt]: row[DBFields.LastModifiedAt],
            [GridFields.LastModifiedBy]: row[DBFields.LastModifiedBy]
          };
        });

        setRowData(rows);
      }
    },
    [pvRecordsData, pvRecordsApiStatus]
  );

  //endregion
  //region Update Form

  const [dtRanges] = useState(() => {
    const now = moment()
      .utc()
      .add(10, "hours")
      .set("m", Math.ceil(moment().minutes() / 5) * 5) // round up to nearest 5minute
      .set("s", 0); //now with seconds set to zero

    return {
      "Next Hour": [now, moment(now).add(1, "h")], //note creating a copy before manipulating date
      "Next Day": [now, moment(now).add(1, "d")],
      "Next Month": [now, moment(now).add(1, "M")],
      "Next Year": [now, moment(now).add(1, "y")]
    };
  });

  const {
    sendRequest: postSubmitSendRequest,
    apiStatus: postSubmitApiStatus
    // data: postSubmitData
    // clear: postSubmitClear
  } = useApi({ method: ApiMethodTypes.Post });

  const getAPIDtFormat = mdt => (mdt ? mdt.format("YYYY-MM-DD HH:mm:ss") : "");

  //submit
  const handleSubmit = useCallback(
    fieldsValue => {
      const data = {
        [DBFields.Duid]: duid,
        [DBFields.BidType]: bidType,
        [DBFields.ParameterId]: parameterId,
        [DBFields.FromDateTime]: getAPIDtFormat(
          fieldsValue[FIELDS.DateTimeRange][0]
        ),
        [DBFields.ToDateTime]: getAPIDtFormat(
          fieldsValue[FIELDS.DateTimeRange][1]
        ),
        [DBFields.Val]: fieldsValue[FIELDS.Val],

        [DBFields.LastModifiedNotes]: fieldsValue[FIELDS.Notes]
      };
      postSubmitSendRequest({
        url: ParametersApi.PostBidParamValueRecordUrl(),
        data: data
      });
    },
    [postSubmitSendRequest, duid, bidType, parameterId]
  );

  useEffect(
    () => {
      if (
        postSubmitApiStatus === ApiStatus.Success //&&
        //postSubmitData // &&
        //onSaveSuccess
      ) {
        // onSaveSuccess({ data: postSubmitData });
        //update chart data
        setChartRefreshId(CalcRefreshId());
        //update grid data
        fetchPvRecords();
      }
    },
    [postSubmitApiStatus, fetchPvRecords]
  );
  //endregion

  //region pv info dates
  const [minDate, setMinDate] = useState();
  const [maxDate, setMaxDate] = useState();

  //get pv info dates
  const {
    sendRequest: pvInfoDatesSendRequest,
    data: pvInfoDatesData,
    apiStatus: pvInfoDatesApiStatus
  } = useApi({ method: ApiMethodTypes.Get });

  //fetch
  useEffect(
    () => {
      pvInfoDatesSendRequest({
        url: ParametersApi.GetBidParamValueInfoDatesUrl({
          duid,
          bidType,
          parameterId
        })
      });
    },
    [pvInfoDatesSendRequest, duid, bidType, parameterId]
  );

  //received response
  useEffect(
    () => {
      if (pvInfoDatesApiStatus === ApiStatus.Success && pvInfoDatesData) {
        if (pvInfoDatesData && pvInfoDatesData[0]) {
          setMinDate(pvInfoDatesData[0][DBFields.MinFromDateTime]);
          setMaxDate(pvInfoDatesData[0][DBFields.MaxToDateTime]);
        }
      }
    },
    [pvInfoDatesData, pvInfoDatesApiStatus]
  );
  //endregion
  return (
    <AppContainer match={match} history={history}>
      <Layout>
        <Layout.Sider width={250} collapsed={false}>
          <BidParamsMenu
            categoryId={categoryId}
            selected={{
              duid: duid,
              bidType: bidType,
              parameterId: parameterId
            }}
          />
        </Layout.Sider>
        <Layout style={{ overflow: "hidden" }}>
          <Layout.Content style={{ background: "#fff", padding: "8px" }}>
            <ApiStatusWrapper statuses={[bidParamConfigApiStatus]}>
              <Space direction={"vertical"} style={{ width: "100%" }}>
                <DateTimePeriodStepPicker onChange={onChangeDates} />

                <BidParamValueChart
                  title={`${
                    bidParamConfig[DBFields.ParameterName]
                  } (${duid}-${bidType})`}
                  bidType={bidType}
                  parameterId={parameterId}
                  duid={duid}
                  startTime={startTime}
                  stopTime={stopTime}
                  timeStep={timeStep}
                  refreshId={chartRefreshId}
                  chartHeight={chartHeight}
                  chartWidth={chartWidth}
                  units={bidParamConfig[DBFields.Units]}
                />
                <Row gutter={16}>
                  <Col md={16}>
                    <TitleSection
                      title={`Param Values History (${minDate} - ${maxDate})`}
                    />
                    <ApiStatusWrapper statuses={[usersApiStatus]}>
                      <Grid
                        gridHeight={gridHeight}
                        defaultColDef={GridConfigDefault}
                        columnDefs={columnDefs}
                        rowData={rowData}
                        groupDefaultExpanded={-1}
                        suppressCellSelection={true}
                        suppressContextMenu={true}
                        suppressRowClickSelection={true}
                        enableCellChangeFlash={true}
                        onGridReady={onGridReady}
                        //  getRowNodeId={getRowNodeId}
                        frameworkComponents={{
                          GridCellTimeAgo: GridCellTimeAgo
                        }}
                      />
                    </ApiStatusWrapper>
                  </Col>
                  <Col md={8}>
                    <TitleSection title={"Change Param Value"} />
                    <Form
                      layout={"vertical"}
                      onSubmit={handleSubmit}
                      apiStatus={postSubmitApiStatus}
                      submitButtonText={"Add Changes"}
                      showCancelButton={false}
                    >
                      <FormItem
                        label={"Date Time Range"}
                        field={FIELDS.DateTimeRange}
                        isRequired={true}
                        inputControl={
                          <DatePicker.RangePicker
                            showTime={{ format: "HH:mm" }}
                            format="YYYY-MM-DD HH:mm"
                            ranges={dtRanges}
                            minuteStep={5}
                          />
                        }
                      />

                      <FormItem
                        label={`Value ${
                          bidParamConfig[DBFields.Units]
                            ? `(${bidParamConfig[DBFields.Units]})`
                            : ""
                        }`}
                        extra={`Enter a value between ${
                          bidParamConfig[DBFields.AbsoluteMin]
                        } and ${bidParamConfig[DBFields.AbsoluteMax]}`}
                        field={FIELDS.Val}
                        isRequired={true}
                        inputControl={
                          <InputNumber
                            max={bidParamConfig[DBFields.AbsoluteMax]}
                            min={bidParamConfig[DBFields.AbsoluteMin]}
                          />
                        }
                      />

                      <FormItem
                        label={"Notes"}
                        field={FIELDS.Notes}
                        isRequired={false}
                        inputControl={<InputTextArea maxLength={100}/>}
                      />
                    </Form>
                  </Col>
                </Row>
              </Space>
            </ApiStatusWrapper>
          </Layout.Content>
        </Layout>
      </Layout>
    </AppContainer>
  );
};
