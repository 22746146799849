export const ActionTypeIds = {
  SwitchAutoBidding: "SwitchAutoBidding",
  SubmitDrafts: "SubmitDrafts",
  SubmitTemplates: "SubmitTemplates",
  LoadIntoDrafts: "LoadIntoDrafts",
  // LoadTemplate: "LoadTemplate",
  // LoadAckOffer: "LoadAckOffer",
  // LoadAlgoOffer: "LoadAlgoOffer",
  // SaveDraftsAs: "SaveDraftsAs",
  LoadIntoTemplates: "LoadIntoTemplates"
  // SaveTemplatesAs: "SaveTemplatesAs"
};
