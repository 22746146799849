import {
  ValidationMessageInRange,
  ValidationMessageRequired
} from "../validation_messages";
import { CheckValueInRange, CheckValueRequired } from "../checks";
import { ValidationMessageType } from "../enums";

//Range (0 - maxEnableMin)
export const ValidateEnablementMin = ({
  value,
  minEnablementMin,
  maxEnablementMax
}) => {
  let validations = [];

  //Validate: value is required
  const check1 = CheckValueRequired(value);
  if (!check1)
    validations.push(ValidationMessageRequired(ValidationMessageType.Error));

  //Validate: value is in range
  const check2 = CheckValueInRange({
    value,
    min: minEnablementMin,
    max: maxEnablementMax
  });

  if (!check2)
    validations.push(
      ValidationMessageInRange({
        messageType: ValidationMessageType.Error,
        min: minEnablementMin,
        max: maxEnablementMax
      })
    );

  return validations;
};
