import React from "react";
import { string } from "prop-types";
import {
  CheckCircleTwoTone,
  ExclamationCircleTwoTone
} from "@ant-design/icons";

import { SubmissionStatus } from "../../../utils";
import { NavLink } from "react-router-dom";

const renderIcon = status => {
  switch (status) {
    case SubmissionStatus.PASS:
      return <CheckCircleTwoTone twoToneColor={"#52c41a"} />;
    case SubmissionStatus.FAIL:
      return <ExclamationCircleTwoTone twoToneColor={"#f5222d"} />;
    // case SubmissionStatus.IN_PROGRESS:
    //   return <LoadingOutlined spin style={{ color: "#13a89e" }} />;

    default:
      console.error(`Not implemented status=${status} in renderIcon`);
      return null;
  }
};
export const GridCellSubmissionStatus = React.memo(
  ({ stage, status, link }) => (
    <span>
      {link
        ? status &&
          stage && (
            <NavLink to={link}>
              {renderIcon(status)} {stage}
            </NavLink>
          )
        : status &&
          stage && (
            <span>
              {renderIcon(status)} {stage}
            </span>
          )}
    </span>
  )
);

GridCellSubmissionStatus.propTypes = {
  status: string,
  stage: string,
  link: string
};
