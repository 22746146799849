import { CalcTanDeg } from "./CalcTanDeg";

export const CalcLowBreakPoint = ({
  lowerAngle,
  minEnablement,
  currentFcasAvail
}) => {
  return Math.ceil(
    parseFloat(minEnablement) +
      parseFloat(currentFcasAvail) * CalcTanDeg({ degree: 90 - lowerAngle })
  );
};
