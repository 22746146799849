import React from "react";
import { object, string, bool, node } from "prop-types";
import { Form } from "antd";

export const FormItem = ({
  style,
  label,
  field,
  isRequired,
  itemProps,
  inputControl,
  extra,
  disabled
}) => {
  return (
    <Form.Item
      key={field}
      name={field}
      label={label}
      style={style}
      extra={extra}
      rules={[
        {
          required: isRequired,
          message: `${label} is required`
        }
      ]}
      {...itemProps}
    >
      {inputControl}
    </Form.Item>
  );
};

FormItem.propTypes = {
  label: string.isRequired,
  field: string.isRequired,
  isRequired: bool,
  style: object,
  inputControl: node.isRequired,
  itemProps: object
};
