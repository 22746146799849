import React, { useCallback, useState } from "react";
import { ActionBar } from "./ActionBar";
import { ActionTypeIds } from "./ActionTypeIds";
import {
  ActionDrawerLoadInto,
  ActionDrawerSubmit,
  ActionDrawerSwitchAutoBidding
} from "./action_drawers";
import { TargetTypes } from "../../utils";
import { array, object, string } from "prop-types";

export const Actions = React.memo(
  ({ actionConfig, targets, targetLabel, bidSettlementDate }) => {
    const [drawerVisible, setDrawerVisible] = useState(false);
    const [action, setAction] = useState("");

    const onClickActionButton = useCallback(
      action => () => {
        setAction(action);
        setDrawerVisible(true);
      },
      [setDrawerVisible, setAction]
    );

    const onCloseActionDrawer = useCallback(
      () => {
        setDrawerVisible(false);
      },
      [setDrawerVisible]
    );

    const renderActionDrawer = useCallback(
      action => {
        switch (action) {
          case ActionTypeIds.LoadIntoDrafts:
            return (
              <ActionDrawerLoadInto
                targets={targets}
                onDrawerClose={onCloseActionDrawer}
                drawerVisible={drawerVisible}
                targetLabel={targetLabel}
                targetType={TargetTypes.Draft}
                defaultLoadFromDate={bidSettlementDate}
              />
            );
          case ActionTypeIds.LoadIntoTemplates:
            return (
              <ActionDrawerLoadInto
                targets={targets}
                onDrawerClose={onCloseActionDrawer}
                drawerVisible={drawerVisible}
                targetLabel={targetLabel}
                targetType={TargetTypes.Template}
              />
            );

          case ActionTypeIds.SubmitDrafts:
            return (
              <ActionDrawerSubmit
                targets={targets}
                onDrawerClose={onCloseActionDrawer}
                drawerVisible={drawerVisible}
                targetLabel={targetLabel}
                targetType={TargetTypes.Draft}
                defaultSubmitDate={bidSettlementDate}
              />
            );
          case ActionTypeIds.SubmitTemplates:
            return (
              <ActionDrawerSubmit
                targets={targets}
                onDrawerClose={onCloseActionDrawer}
                drawerVisible={drawerVisible}
                targetLabel={targetLabel}
                targetType={TargetTypes.Template}
              />
            );
          case ActionTypeIds.SwitchAutoBidding:
            return (
              <ActionDrawerSwitchAutoBidding
                targets={targets}
                onDrawerClose={onCloseActionDrawer}
                drawerVisible={drawerVisible}
              />
            );
          case "":
            return null;
          default:
            console.error(
              `Not implemented action=${action} in renderActionDrawer in Actions.jsx`
            );
            return <div>Not implemented {action}</div>;
        }
      },
      [
        targetLabel,
        drawerVisible,
        onCloseActionDrawer,
        targets,
        bidSettlementDate
      ]
    );

    return (
      <div>
        <ActionBar
          onClickActionButton={onClickActionButton}
          config={actionConfig}
        />

        {renderActionDrawer(action)}
      </div>
    );
  }
);

Actions.propTypes = {
  targets: array,
  targetLabel: string,
  actionConfig: object.isRequired,
  bidSettlementDate: string
};
