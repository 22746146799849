import React, { useEffect, useState, useCallback, useContext } from "react";
import commaNumber from "comma-number";
import moment from "moment";
import {
  ApiStatusWrapper,
  AppContainer,
  Grid,
  InputSelect,
  DatePickerSettlementDate,
  ButtonPrimary
} from "../components";
import { ApiMethodTypes, useApi } from "../hooks";
import {
  ApiStatus,
  DBFields,
  GridConfigDefault,
  GridFields,
  VadvisorApi
} from "../utils";
import { ThemeContext } from "../contexts";
import { Space, Form } from "antd";

const GRID_HEIGHT_OFFSET = 250;

const SourceId = {
  ManualActual: "manual_actual",
  AlgoWhatIf: "algo_whatif"
};
const sources = [
  { name: "Algo What If", key: SourceId.AlgoWhatIf },
  { name: "Manual Actual", key: SourceId.ManualActual }
];

const AggFunc = {
  Sum: "sum",
  Avg: "avg"
};

const variables = [
  {
    name: "Gen Liability",
    key: DBFields.GenLiability,
    decimalPlaces: 0,
    aggFunc: AggFunc.Sum
  },
  {
    name: "Gen Liability $/MW",
    key: DBFields.GenLiabilityDollarsPerMW,
    decimalPlaces: 2,
    aggFunc: AggFunc.Avg,
    hide: true
  }
];

export const ReportActualManualLiability = ({ history, match }) => {
  const theme = useContext(ThemeContext);
  const [fromDateTime, setFromDateTime] = useState();
  const [toDateTime, setToDateTime] = useState();
  const [duids, setDuids] = useState([]);
  const [filteredDuids, setFilteredDuids] = useState([]);
  // const [algos, setAlgos] = useState([]);
  const [selectedAlgoId] = useState("pd_sutil");
  const [settDate, setSettDate] = useState(
    moment()
      //.add(-3, "d") //temp set to load 3 days ago
      .format("YYYY-MM-DD")
  );

  //region DUIDs

  const {
    sendRequest: settingsDuidBidTypesSendRequest,
    data: settingsDuidBidTypesData,
    apiStatus: settingsDuidBidTypesApiStatus
  } = useApi({ method: ApiMethodTypes.Get });

  //fetch data
  useEffect(
    () => {
      if (selectedAlgoId)
        settingsDuidBidTypesSendRequest({
          url: VadvisorApi.SettingsDuidBidTypesByAlgoId({
            algoId: selectedAlgoId
          })
        });
    },
    [settingsDuidBidTypesSendRequest, selectedAlgoId]
  );

  //on getting data
  useEffect(
    () => {
      if (settingsDuidBidTypesApiStatus === ApiStatus.Success) {
        let dataSet = new Set();
        settingsDuidBidTypesData.forEach(row => {
          dataSet.add(row[DBFields.Duid]);
        });

        let data = [];
        dataSet.forEach(duid => {
          data.push({
            value: duid,
            text: duid
          });
        });
        setDuids(data);
        setFilteredDuids(data.map(d => d.value)); //selected all by default on load
      }
    },
    [settingsDuidBidTypesApiStatus, settingsDuidBidTypesData]
  );

  const onChangeFilteredDuids = useCallback(values => {
    setFilteredDuids(values);
  }, []);
  //endregion

  //region Dates
  const onChangeSettDate = useCallback(date => {
    setSettDate(date);
  }, []);

  //on sett date change
  useEffect(
    () => {
      const fromDateTime = moment(settDate).format("YYYY-MM-DD 00:00:00");
      const toDateTime = moment(settDate)
        .add(1, "d")
        .format("YYYY-MM-DD 00:00:00");

      setFromDateTime(fromDateTime);
      setToDateTime(toDateTime);
    },
    [settDate]
  );

  //endregion

  //region config algos
  //
  // const {
  //   sendRequest: configAlgoSendRequest,
  //   data: configAlgoData,
  //   apiStatus: configAlgoApiStatus
  // } = useApi({ method: ApiMethodTypes.Get });
  //
  // //fetch data
  // useEffect(
  //   () => {
  //     configAlgoSendRequest({
  //       url: VadvisorApi.ConfigAlgos()
  //     });
  //   },
  //   [configAlgoSendRequest]
  // );
  //
  // //on getting data
  // useEffect(
  //   () => {
  //     if (configAlgoApiStatus === ApiStatus.Success) {
  //       let data = [];
  //       configAlgoData.forEach(row => {
  //         if (row[DBFields.IsActive])
  //           data.push({
  //             value: row[DBFields.AlgoId],
  //             text: row[DBFields.AlgoName]
  //           });
  //       });
  //       setAlgos(data);
  //       if (data.length > 0) setSelectedAlgoId(data[0].value); //select first one
  //     }
  //   },
  //   [configAlgoApiStatus, configAlgoData]
  // );

  // const onChangeAlgoId = useCallback(value => {
  //   setSelectedAlgoId(value);
  // }, []);

  //endregion

  const {
    sendRequest: reportSendRequest,
    data: reportData,
    apiStatus: reportApiStatus
  } = useApi({ method: ApiMethodTypes.Get });

  //fetch data
  const fetchReport = useCallback(
    () => {
      if (
        fromDateTime &&
        toDateTime &&
        fromDateTime !== "Invalid date" &&
        toDateTime !== "Invalid date"
      )
        reportSendRequest({
          url: VadvisorApi.ReportActualManualLiability5m({
            fromDateTime: fromDateTime,
            toDateTime: toDateTime
          })
        });
    },
    [reportSendRequest, fromDateTime, toDateTime]
  );
  useEffect(
    () => {
      fetchReport();
    },
    [fetchReport]
  );

  const getVariableKey = ({ source, variableName }) =>
    `${source}_${variableName}`;

  //on getting data
  useEffect(
    () => {
      if (reportApiStatus === ApiStatus.Success) {
        let newRows = [];

        //convert array to dict
        let dataDict = {};
        reportData.forEach(row => {
          const settDate = row[DBFields.SettlementDate];
          if (!dataDict[settDate]) dataDict[settDate] = {};

          const duid = row[DBFields.Duid];
          if (!dataDict[settDate][duid]) dataDict[settDate][duid] = {};

          const source = row[DBFields.SourceName];

          [DBFields.GenLiability, DBFields.GenLiabilityDollarsPerMW].forEach(
            variableName => {
              const vKey = getVariableKey({ source, variableName });
              dataDict[settDate][duid][vKey] = row[variableName];
            }
          );
        });
        Object.keys(dataDict).forEach(dt => {
          let row = {};
          row[GridFields.SettlementDate] = dt;
          sources.forEach(source => {
            variables.forEach(variable => {
              const vKey = getVariableKey({
                source: source.key,
                variableName: variable.key
              });
              let value = 0;
              let anyMatch = false;

              filteredDuids.forEach(duid => {
                if (
                  dataDict[dt] &&
                  dataDict[dt][duid] &&
                  dataDict[dt][duid][vKey] !== undefined
                ) {
                  anyMatch = true;
                  value += dataDict[dt][duid][vKey];
                }
              });
              switch (variable.aggFunc) {
                case AggFunc.Sum:
                  row[vKey] = anyMatch ? value : null;
                  break;
                case AggFunc.Avg:
                  const avgValue = value / filteredDuids.length;

                  row[vKey] = anyMatch ? avgValue : null;
                  break;
                default:
                  console.error(`AggFunc not defined`, variable);
                  row[vKey] = null;
              }
            });
          });
          newRows.push(row);
        });
        setRowData(newRows);
      }
    },
    [reportApiStatus, reportData, filteredDuids, duids]
  );
  //region Grid
  const [gridHeight, setGridHeight] = useState(600);
  const [rowData, setRowData] = useState([]);
  const onGridReady = useCallback(params => {
    // setGridApi(params.api);
    //params.api.sizeColumnsToFit();
  }, []);

  const getRowNodeId = useCallback(data => {
    return data[GridFields.SettlementDate];
  }, []);

  const resizeGrid = useCallback(() => {
    setGridHeight(window.innerHeight - GRID_HEIGHT_OFFSET);
  }, []);

  //once on mount
  useEffect(
    () => {
      resizeGrid();
    },
    [resizeGrid]
  );

  const [columnDefs, setColumnDefs] = useState([]);

  useEffect(
    () => {
      let cols = [
        {
          headerName: "Settlement Date",
          headerTooltip: "Settlement Date",
          pinned: "left",
          field: GridFields.SettlementDate,
          width: 140,
          valueFormatter: params => {
            return params.value.slice(0, 16); //first 16 characters YYYY-MM-DD HH:mm
          }
        },
        {
          headerName: "VIC", //tofix to have per region in future, hardcoding for VIC only for now
          children: [
            {
              headerName: "Gen Liability $ Per MW",
              type: "numericColumn",
              valueFormatter: formatNumber({
                decimalPlaces: 2
              }),
              valueGetter: params => {
                const algoGenLiabilitiesDollars = params.getValue(
                  //returning algo one for now as it is same for both manual and actual and only change by region
                  getVariableKey({
                    source: SourceId.AlgoWhatIf,
                    variableName: DBFields.GenLiabilityDollarsPerMW
                  })
                );
                return algoGenLiabilitiesDollars;
              }
            }
          ]
        }
      ];

      //add diff cols
      let diffChildren = [];
      variables.forEach(variable => {
        diffChildren.push({
          headerName: variable.name,
          hide: variable.hide,
          colId: getVariableKey({
            source: "diff",
            variableName: variable.key
          }),
          // field: vKey,
          //width: 120,
          columnGroupShow: variable.columnGroupShow,
          // cellStyle: params => {
          //   if (params.value < 0)
          //     return { backgroundColor: theme.negativeValueBackgroundColor };
          //   else return { backgroundColor: "#ccc" };
          // },
          valueGetter: params => {
            const source1Value = params.getValue(
              getVariableKey({
                source: sources[0].key,
                variableName: variable.key
              })
            );
            const source2Value = params.getValue(
              getVariableKey({
                source: sources[1].key,
                variableName: variable.key
              })
            );
            let diff;
            if (source1Value !== null && source2Value !== null)
              diff = source1Value - source2Value;
            return diff;
          },
          type: "numericColumn",
          valueFormatter: formatNumber({
            decimalPlaces: variable.decimalPlaces
          })
        });
      });

      cols.push({
        headerName: `Difference (${sources[0].name} - ${sources[1].name})`,
        children: diffChildren
      });

      //add each source cols
      sources.forEach(source => {
        let sourceChildren = [];

        variables.forEach(variable => {
          const vKey = getVariableKey({
            source: source.key,
            variableName: variable.key
          });
          sourceChildren.push({
            headerName: variable.name,
            hide: variable.hide,
            colId: vKey,
            width: 120,
            columnGroupShow: variable.columnGroupShow,
            // cellStyle: params => {
            //   if (params.value < 0)
            //     return { backgroundColor: theme.negativeValueBackgroundColor };
            //   else return { backgroundColor: "#ddd" };
            // },
            valueGetter: params => {
              const value =
                params.data[
                  getVariableKey({
                    source: source.key,
                    variableName: variable.key
                  })
                ];

              return value;
            },
            type: "numericColumn",
            valueFormatter: formatNumber({
              decimalPlaces: variable.decimalPlaces
            })
          });
        });

        cols.push({
          headerName: source.name,
          children: sourceChildren
        });
      });
      setColumnDefs(cols);
    },
    [theme]
  );

  const formatNumber = ({ decimalPlaces = 0 }) => params => {
    return params.value !== undefined && params.value !== null
      ? commaNumber(parseFloat(params.value).toFixed(decimalPlaces))
      : params.value;
  };
  //endregion

  return (
    <AppContainer history={history} match={match}>
      <ApiStatusWrapper statuses={[settingsDuidBidTypesApiStatus]}>
        <Space size={"large"}>
          {/*<Form.Item label="Algo">*/}
          {/*  <InputSelect*/}
          {/*    value={selectedAlgoId}*/}
          {/*    options={algos}*/}
          {/*    style={{ width: "150px" }}*/}
          {/*    onChange={onChangeAlgoId}*/}
          {/*  />*/}
          {/*</Form.Item>*/}
          <Form.Item label="Date">
            <DatePickerSettlementDate
              value={settDate}
              onChange={onChangeSettDate}
            />
          </Form.Item>

          <Form.Item
            label="DUIDs"
            validateStatus={
              !filteredDuids || filteredDuids.length < 1 ? "error" : null
            }
            help={
              !filteredDuids || filteredDuids.length < 1
                ? "Please select a DUID"
                : null
            }
          >
            <InputSelect
              style={{ width: "450px" }}
              value={filteredDuids}
              mode={"multiple"}
              options={duids}
              onChange={onChangeFilteredDuids}
            />
          </Form.Item>
          <Form.Item>
            <ButtonPrimary onClick={fetchReport}>Refresh</ButtonPrimary>
          </Form.Item>
        </Space>
      </ApiStatusWrapper>
      <ApiStatusWrapper
        statuses={[reportApiStatus]}
        style={{ position: "absolute", top: "49%", left: "49%", zIndex: 10 }}
      />
      <Grid
        gridHeight={gridHeight}
        defaultColDef={GridConfigDefault}
        columnDefs={columnDefs}
        rowData={rowData}
        defaultExportParams={{
          allColumns: true
        }}
        enableRangeSelection={true}
        // groupDefaultExpanded={-1}
        // suppressCellSelection={true}
        // suppressContextMenu={true}
        // suppressRowClickSelection={true}
        rowSelection={"multiple"}
        enableCellChangeFlash={true}
        onGridReady={onGridReady}
        getRowNodeId={getRowNodeId}
        // frameworkComponents={{
        //   GridCellLink: GridCellLink,
        //   GridCellTimeAgoLink: GridCellTimeAgoLink
        // }}
        sideBar={{
          toolPanels: [
            {
              id: "columns",
              labelDefault: "Columns",
              labelKey: "columns",
              iconKey: "columns",
              toolPanel: "agColumnsToolPanel",
              toolPanelParams: {
                suppressRowGroups: true,
                suppressValues: true,
                suppressPivotMode: true
              }
            }
          ]
        }}
        statusBar={{
          statusPanels: [
            {
              statusPanel: "agAggregationComponent",
              statusPanelParams: {
                // possible values are: 'count', 'sum', 'min', 'max', 'avg'
                aggFuncs: ["sum"]
              }
              // align: "left"
            },
            {
              statusPanel: "agTotalRowCountComponent",
              align: "left"
            }
            // {
            //   statusPanel: "agFilteredRowCountComponent",
            //   align: "left"
            // }
          ]
        }}
      />
    </AppContainer>
  );
};
