import React from "react";
import { object, string, bool } from "prop-types";

import { ButtonSecondary } from "../../buttons";

export const FormButtonCancel = props => (
  <ButtonSecondary {...props}>
    {props.text ? props.text : "Cancel"}
  </ButtonSecondary>
);

FormButtonCancel.propTypes = {
  style: object,
  text: string,
  disabled: bool
};
