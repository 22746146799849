import React from "react";
import { Route, Router, Switch, Redirect } from "react-router-dom";

import { history } from "utils/history";
import { Auth } from "./utils";
import {
  NotFound,
  Home,
  Login,
  Callback,
  Forbidden,
  Users,
  User,
  Roles,
  Role,
  UserNew,
  RoleNew,
  LogOut,
  LoggedOut,
  AuthError,
  StandingParams,
  TraderParams,
  BidParam,
  ReportActualManualPerformance,
  ReportActualManualLiability,
  TemplateSets,
  TemplateOffer,
  TemplateSet,
  TemplateSetNew,
  SubmittedOffer,
  SubmittedSet,
  SubmittedSets,
  JsonViewer,
  Offers,
  AckOffer,
  AlgoOffer,
  DraftOffer,
  AckSets,
  SolveList,
  SolveResult,
  AckSet,
  DeleteSuccess,
  AckSetOffer,
  Solve,
  ReportDailySummary,
  AutoStatusHistory,
  BidAllocator,
  ReportUnitComparisonDetailed,
  ReportUnitComparisonSummary,
  DispatchTimesHistory, SolveResultComposite
} from "./views";
import { AuthenticatedRoute } from "./components";
import { InternalRoutes } from "./utils";

const auth = new Auth();

const MainRoutes = props => {
  return (
    <Router history={history}>
      <Switch>
        <Redirect exact from={"/"} to={InternalRoutes.Home} />
        <AuthenticatedRoute
          auth={auth}
          path={InternalRoutes.Home}
          exact
          component={Home}
        />
        <AuthenticatedRoute
          auth={auth}
          path={InternalRoutes.Offers}
          exact
          component={Offers}
        />
        <AuthenticatedRoute
          auth={auth}
          path={InternalRoutes.DraftOffer}
          exact
          component={DraftOffer}
        />
        <AuthenticatedRoute
          auth={auth}
          path={InternalRoutes.AckOffer}
          exact
          component={AckOffer}
        />
        <AuthenticatedRoute
          auth={auth}
          path={InternalRoutes.AlgoOffer}
          exact
          component={AlgoOffer}
        />
        <AuthenticatedRoute
          auth={auth}
          exact
          path={InternalRoutes.TemplateSets}
          component={TemplateSets}
        />
        <AuthenticatedRoute
          auth={auth}
          exact
          path={InternalRoutes.TemplateSetNew}
          component={TemplateSetNew}
        />
        <AuthenticatedRoute
          auth={auth}
          exact
          path={InternalRoutes.TemplateSet}
          component={TemplateSet}
        />
        <AuthenticatedRoute
          auth={auth}
          path={InternalRoutes.TemplateOffer}
          exact
          component={TemplateOffer}
        />
        <AuthenticatedRoute
          auth={auth}
          exact
          path={InternalRoutes.AckSets}
          component={AckSets}
        />
        <AuthenticatedRoute
          auth={auth}
          exact
          path={InternalRoutes.AckSet}
          component={AckSet}
        />{" "}
        <AuthenticatedRoute
          auth={auth}
          exact
          path={InternalRoutes.AckSetOffer}
          component={AckSetOffer}
        />
        <AuthenticatedRoute
          auth={auth}
          exact
          path={InternalRoutes.SolveList}
          component={SolveList}
        /> <AuthenticatedRoute
          auth={auth}
          exact
          path={InternalRoutes.SolveCompositeResult}
          component={SolveResultComposite}
        />
        <AuthenticatedRoute
          auth={auth}
          exact
          path={InternalRoutes.Solve}
          component={Solve}
        />{" "}
        <AuthenticatedRoute
          auth={auth}
          exact
          path={InternalRoutes.SolveResult}
          component={SolveResult}
        />
        <AuthenticatedRoute
          auth={auth}
          exact
          path={InternalRoutes.SubmittedSets}
          component={SubmittedSets}
        />
        <AuthenticatedRoute
          auth={auth}
          exact
          path={InternalRoutes.SubmittedSet}
          component={SubmittedSet}
        />
        <AuthenticatedRoute
          auth={auth}
          path={InternalRoutes.SubmittedOffer}
          exact
          component={SubmittedOffer}
        />
        <AuthenticatedRoute
          auth={auth}
          path={InternalRoutes.JsonViewer}
          exact
          component={JsonViewer}
        />
        <AuthenticatedRoute
          auth={auth}
          path={InternalRoutes.StandingParams}
          exact
          component={StandingParams}
        />
        <AuthenticatedRoute
          auth={auth}
          path={InternalRoutes.TraderParams}
          exact
          component={TraderParams}
        />
        <AuthenticatedRoute
          auth={auth}
          path={InternalRoutes.BidParam}
          exact
          component={BidParam}
        />
        <AuthenticatedRoute
          auth={auth}
          path={InternalRoutes.AutoStatusHistory}
          exact
          component={AutoStatusHistory}
        />
        <AuthenticatedRoute
          auth={auth}
          path={InternalRoutes.DispatchTimesHistory}
          exact
          component={DispatchTimesHistory}
        />
        <AuthenticatedRoute
          auth={auth}
          path={InternalRoutes.BidAllocator}
          exact
          component={BidAllocator}
        />
        <AuthenticatedRoute
          auth={auth}
          path={InternalRoutes.ReportActualManualPerformance}
          exact
          component={ReportActualManualPerformance}
        />
        <AuthenticatedRoute
          auth={auth}
          path={InternalRoutes.ReportUnitComparisonDetailed}
          exact
          component={ReportUnitComparisonDetailed}
        />{" "}
        <AuthenticatedRoute
          auth={auth}
          path={InternalRoutes.ReportUnitComparisonSummary}
          exact
          component={ReportUnitComparisonSummary}
        />
        <AuthenticatedRoute
          auth={auth}
          path={InternalRoutes.ReportActualManualLiability}
          exact
          component={ReportActualManualLiability}
        />
        <AuthenticatedRoute
          auth={auth}
          path={InternalRoutes.ReportDailySummary}
          exact
          component={ReportDailySummary}
        />
        <AuthenticatedRoute
          auth={auth}
          path={InternalRoutes.Users}
          exact
          component={Users}
        />
        <AuthenticatedRoute
          auth={auth}
          path={InternalRoutes.User}
          exact
          component={User}
        />
        <AuthenticatedRoute
          auth={auth}
          path={InternalRoutes.UserNew}
          exact
          component={UserNew}
        />
        <AuthenticatedRoute
          auth={auth}
          path={InternalRoutes.Roles}
          exact
          component={Roles}
        />
        <AuthenticatedRoute
          auth={auth}
          path={InternalRoutes.Role}
          exact
          component={Role}
        />
        <AuthenticatedRoute
          auth={auth}
          path={InternalRoutes.RoleNew}
          exact
          component={RoleNew}
        />
        <AuthenticatedRoute
          auth={auth}
          path={InternalRoutes.DeleteSuccess}
          exact
          component={DeleteSuccess}
        />
        <Route
          path={"/auth/login/:pdClientType?"}
          render={props => <Login {...props} auth={auth} />}
        />
        <Route
          path={"/auth/logout"}
          render={props => <LogOut {...props} auth={auth} />}
        />
        <Route path={"/auth/loggedout"} component={LoggedOut} />
        <Route
          path={"/auth/callback"}
          render={props => <Callback {...props} auth={auth} />}
        />
        <Route
          path={"/auth/forbidden"}
          render={props => <Forbidden {...props} auth={auth} />}
        />
        <Route
          path={InternalRoutes.AuthError}
          render={props => <AuthError {...props} />}
        />
        <Route component={NotFound} />
      </Switch>
    </Router>
  );
};

export default MainRoutes;
