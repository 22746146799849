import { ActionTypeIds } from "./ActionTypeIds";

const GetActionTextFromId = {
  [ActionTypeIds.SwitchAutoBidding]: {
    text: "Switch Algo Auto Submit Mode"
  },
  [ActionTypeIds.SubmitDrafts]: {
    text: "Prepare to submit drafts"
  },
  [ActionTypeIds.SubmitTemplates]: {
    text: "Prepare to submit templates"
  },
  [ActionTypeIds.LoadIntoDrafts]: {
    text: "Load into drafts"
  },
  // [ActionTypeIds.LoadTemplate]: {
  //   text: "Load template"
  // },
  // [ActionTypeIds.LoadAckOffer]: {
  //   text: "Load acknowledged offer"
  // },
  // [ActionTypeIds.LoadAlgoOffer]: {
  //   text: "Load algo offer"
  // },
  [ActionTypeIds.SaveDraftsAs]: {
    text: "Save drafts as"
  },
  [ActionTypeIds.LoadIntoTemplates]: {
    text: "Load into templates"
  },
  [ActionTypeIds.SaveTemplatesAs]: {
    text: "Save templates as"
  }
};

export const getActionTextFromId = actionTypeId =>
  GetActionTextFromId[actionTypeId].text;
