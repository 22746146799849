import React from "react";
import { object, number, func, bool, any } from "prop-types";
import { InputNumber as InputNumberA } from "antd";

export const InputNumber = ({
  style,
  onChange,
  suffix,
  prefix,
  value,
  disabled,
  min,
  max
}) => {
  const onChangeHandler = value => {
    onChange(value);
  };

  return (
    <InputNumberA
      style={style}
      onChange={onChangeHandler}
      suffix={suffix}
      prefix={prefix}
      value={value}
      disabled={disabled}
      min={min}
      max={max}
    />
  );
};

InputNumber.propTypes = {
  style: object,
  onChange: func,
  suffix: any,
  prefix: any,
  value: number,
  disabled: bool
};
