import React from "react";
import { string, number, object } from "prop-types";
import { IconTypes } from "./IconTypes";
import { IconBid, IconSummary, IconWarning } from "./icon_types/index";

const getIconPath = (iconType, size, color) => {
  switch (iconType) {
    case IconTypes.Summary:
      return <IconSummary size={size} color={color} />;

    case IconTypes.Warning:
      return <IconWarning size={size} color={color} />;

    case IconTypes.Bid:
      return <IconBid color={color} size={size} />;
    default:
      console.error("Icon type not found", iconType);
      return null;
  }
};

const Icon = ({ style, color, size, className, iconType }) => (
  <i style={style} className={className}>
    {getIconPath(iconType, size, color)}
  </i>
);

Icon.propTypes = {
  size: number,
  color: string,
  style: object,
  className: string,
  iconType: string.isRequired
};

Icon.defaultProps = {
  size: 24,
  color: "#000000"
};

export default Icon;
