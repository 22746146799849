import React, { useEffect } from "react";
import { ApiStatusWrapper, AppContainer } from "../components";
import ReactJson from "react-json-view";
import { ApiMethodTypes, useApi } from "../hooks";
import { VofferApi } from "../utils";

const { GetReadJsonFile } = VofferApi;
export const JsonViewer = ({ history, match }) => {
  const { fileUri } = match.params;

  //region Load file
  //load json file from file uri
  const {
    sendRequest: loadFileSendRequest,
    data: loadFileData,
    apiStatus: loadFileApiStatus
  } = useApi({ method: ApiMethodTypes.Get });

  //fetch
  useEffect(
    () => {
      loadFileSendRequest({
        url: GetReadJsonFile({ encodedFileUri: fileUri })
      });
    },
    [loadFileSendRequest, fileUri]
  );

  //endregion
  return (
    <AppContainer history={history} match={match}>
      <ApiStatusWrapper statuses={[loadFileApiStatus]}>
        {loadFileData && <ReactJson src={loadFileData} />}
      </ApiStatusWrapper>
    </AppContainer>
  );
};
