import {
  BidType,
  GridFields,
  UnitDispatchType,
  UnitStartType,
  ValidationMessageType
} from "../enums";
import { CalcTimeFromPeriodId } from "../calc";

export const IntervalInMins = 5;

export const TotalTtimesLimit = 60; //Total of T1, T2, T3, T4 limit
export const NumberOfBands = 10; //Total number of bands

export const UnitStartTypeValues = Object.values(UnitStartType);
export const UnitDispatchTypeValues = Object.values(UnitDispatchType);
export const BidTypeValues = Object.values(BidType);

export const LabelSpan = 8; //Form control label span in the the drawer

export const ValidationMessageTypeValues = Object.values(ValidationMessageType);

export const DefaultRows = () => {
  let rowCount = ExpectedGridRowCount;
  let rows = [];
  for (let i = 1; i <= rowCount; i++) {
    rows.push({
      [GridFields.PeriodId]: i,
      [GridFields.Time]: CalcTimeFromPeriodId(i)
    });
  }
  return rows;
};

export const ExpectedGridRowCount = 24 * 60 / IntervalInMins;
