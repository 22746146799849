import React, { useCallback, useEffect, useContext, useState } from "react";
import { bool, string, func } from "prop-types";
import {
  ApiStatusWrapper,
  ButtonDelete,
  Form,
  FormItem,
  InputSelect,
  InputText,
  TitleSection,
  UnsavedChangesPrompt
} from "../../components";
import { DBFields, InternalLinks } from "../../utils";
import { ApiMethodTypes, useApi } from "../../hooks";
import { InputTextArea } from "../generic";
import { ApiStatus } from "../../utils";
import { Redirect } from "react-router-dom";
import { ThemeContext } from "../../contexts";
import { Popconfirm } from "antd";

const FIELDS = {
  TemplateSetName: "TemplateSetName",
  Description: "Description",
  IsTeam: "IsTeam"
};

export const TemplateSetInfo = ({
  showDeleteButton,
  submitUrl,
  submitUrlMethodType,
  deleteUrl,
  templateSetName,
  templateSetDesc,
  templateIsTeam,
  onSaveSuccess
}) => {
  //region Unsaved Changes
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  //endregion

  const theme = useContext(ThemeContext);
  const {
    sendRequest: postSubmitSendRequest,
    apiStatus: postSubmitApiStatus,
    data: postSubmitData
    // clear: postSubmitClear
  } = useApi({ method: submitUrlMethodType });

  const {
    sendRequest: deleteOfferSendRequest,
    apiStatus: deleteOfferApiStatus
  } = useApi({ method: ApiMethodTypes.Delete });

  //submit
  const handleSubmit = useCallback(
    fieldsValue => {
      const data = {
        [DBFields.TemplateSetName]: fieldsValue[FIELDS.TemplateSetName],
        [DBFields.Description]: fieldsValue[FIELDS.Description],
        [DBFields.IsTeam]: fieldsValue[FIELDS.IsTeam]
      };

      if (!submitUrl) {
        console.error(
          "submitUrl not passed in handleSubmit in TemplateSetInfo.jsx"
        );
        return;
      }
      postSubmitSendRequest({ url: submitUrl, data: data });
    },
    [postSubmitSendRequest, submitUrl]
  );

  //on update post submit
  useEffect(
    () => {
      if (postSubmitApiStatus === ApiStatus.Success && postSubmitData) {
        setHasUnsavedChanges(false);
        if (onSaveSuccess) onSaveSuccess({ data: postSubmitData });
      }
    },
    [onSaveSuccess, postSubmitApiStatus, postSubmitData, setHasUnsavedChanges]
  );

  //delete set
  const onDeleteSetInfo = useCallback(
    () => {
      if (deleteUrl) {
        deleteOfferSendRequest({ url: deleteUrl });
        setHasUnsavedChanges(false);
      }
    },
    [deleteUrl, deleteOfferSendRequest, setHasUnsavedChanges]
  );
  // //on update delete
  // useEffect(
  //   () => {
  //     if (deleteOfferApiStatus === ApiStatus.Success) {
  //       setHasUnsavedChanges(false);
  //     }
  //   },
  //   [deleteOfferApiStatus, setHasUnsavedChanges]
  // );
  const onChangeInput = useCallback(
    value => {
      //do nothing with value update

      //track unsaved changed
      setHasUnsavedChanges(true);
    },
    [setHasUnsavedChanges]
  );

  return (
    <>
      <UnsavedChangesPrompt hasUnsavedChanges={hasUnsavedChanges} />

      <ApiStatusWrapper statuses={[deleteOfferApiStatus]}>
        <Redirect to={InternalLinks.DeleteSuccess} />
      </ApiStatusWrapper>

      <TitleSection title={"Template Set Info"} style={{ marginTop: 0 }} />
      <div
        style={{
          backgroundColor: theme.containerBackgroundColor,
          border: `1px solid ${theme.borderColorSubtle}`,
          padding: "8px"
        }}
      >
        <Form
          wrapperFooter={
            <div style={{ marginTop: "-16px" }}>
              {hasUnsavedChanges && (
                <div style={{ fontStyle: "italic", marginBottom: "8px" }}>
                  Unsaved changes
                </div>
              )}
              {showDeleteButton && (
                <Popconfirm
                  placement={"bottomLeft"}
                  title={
                    "Are you sure to delete this template set permanently?"
                  }
                  onConfirm={onDeleteSetInfo}
                  okText={"Yes"}
                  cancelText={"No"}
                >
                  <ButtonDelete

                  //style={{ float: "right", marginTop: "-56px" }}
                  >
                    Delete Template Set
                  </ButtonDelete>
                </Popconfirm>
              )}
            </div>
          }
          onSubmit={handleSubmit}
          // onCancel={handleCancel}
          apiStatus={postSubmitApiStatus}
          labelSpan={8}
          initialValues={{
            [FIELDS.TemplateSetName]: templateSetName,
            [FIELDS.Description]: templateSetDesc,
            [FIELDS.IsTeam]: templateIsTeam
          }}
          // disableEdit={disableEdit}
          submitButtonText={"Save Changes"}
          showCancelButton={false}
        >
          <FormItem
            label={"Name"}
            field={FIELDS.TemplateSetName}
            isRequired={true}
            inputControl={<InputText onChange={onChangeInput} />}
          />
          <FormItem
            label={"Description"}
            field={FIELDS.Description}
            isRequired={false}
            inputControl={<InputTextArea rows={5} onChange={onChangeInput} />}
          />
          <FormItem
            label={"Owner"}
            field={FIELDS.IsTeam}
            isRequired={true}
            inputControl={
              <InputSelect
                onChange={onChangeInput}
                defaultValue={templateIsTeam}
                options={[
                  { text: "Personal", value: false },
                  { text: "Team", value: true }
                ]}
              />
            }
          />
        </Form>
      </div>
    </>
  );
};

TemplateSetInfo.propTypes = {
  showDeleteButton: bool.isRequired,
  submitUrl: string.isRequired,
  deleteUrl: string,
  templateSetName: string,
  templateSetDesc: string,
  templateIsTeam: bool,
  onSaveSuccess: func,
  submitUrlMethodType: string.isRequired
};

TemplateSetInfo.defaultProps = {
  showDeleteButton: false
};
