import React, { useCallback } from "react";
import { object, arrayOf, string, func } from "prop-types";
import moment from "moment";
import { DatePicker } from "./DatePicker";

export const DatePickerSettlementDate = ({
  style,
  value,
  allowedDates,
  onChange
}) => {
  const disabledDate = date => {
    if (!allowedDates) return false;

    let isDisable = true;
    allowedDates.forEach(d => {
      if (moment(d).format("YYYY-MM-DD") === date.format("YYYY-MM-DD"))
        isDisable = false;
    });

    return isDisable;
  };

  const onChangeDate = useCallback(
    (mdt, dtString) => {
      onChange(dtString);
    },
    [onChange]
  );

  return (
    <DatePicker
      style={style}
      disabledDate={disabledDate}
      defaultValue={moment(value)}
      format={"YYYY-MM-DD"}
      onChange={onChangeDate}
    />
  );
};

DatePickerSettlementDate.propTypes = {
  style: object,
  value: string,
  allowedDates: arrayOf(string.isRequired),
  onChange: func.isRequired
};
