import { Domain } from "../../config";
const BASE_URL = `https://api.vadvisor.${Domain}`;

export const VadvisorApi = {
  ReportActualManualPerformance5m: ({ fromDateTime, toDateTime }) =>
    `${BASE_URL}/v1/reports/actual_manual_performance_5m?from_date_time=${fromDateTime}&to_date_time=${toDateTime}`,
  ReportActualManualLiability5m: ({ fromDateTime, toDateTime }) =>
    `${BASE_URL}/v1/reports/actual_manual_liability_5m?from_date_time=${fromDateTime}&to_date_time=${toDateTime}`,
  ReportActualManualPerformanceDay: ({ fromDateTime, toDateTime }) =>
    `${BASE_URL}/v1/reports/actual_manual_performance_day?from_date_time=${fromDateTime}&to_date_time=${toDateTime}`,
  ConfigAlgos: () => `${BASE_URL}/v1/config/algos`,
  SettingsDuidBidTypesByAlgoId: ({ algoId }) =>
    `${BASE_URL}/v1/settings/algos_duid_bid_types/${algoId}`,
  //SOLVES
  GetSolvesResult: ({ bidSettlementDateStr }) =>
    `${BASE_URL}/v1/solves/result/${bidSettlementDateStr}`,
  GetSolvesCompositeResult: ({ bidSettlementDateStr }) =>
    `${BASE_URL}/v1/solves/result_composite/${bidSettlementDateStr}`,
  GetSolveResultById: ({ bidSettlementDateStr, solveId }) =>
    `${BASE_URL}/v1/solves/result/${bidSettlementDateStr}/${solveId}`,
  GetSolveBidSets: ({ bidSettlementDate }) =>
    `${BASE_URL}/v1/solves/bid_set/${bidSettlementDate}`,
  GetSolveBidsBySolveId: ({ solveId, bidSettlementDate }) => `
  ${BASE_URL}/v1/solves/bids/${bidSettlementDate}/${solveId}`
};
