import { TotalTtimesLimit } from "../config";
import { CheckValueUnderLimit } from "../checks";
import { ValidationMessageExceedLimit } from "../validation_messages";
import { ValidationMessageType } from "../enums";

export const ValidateTotalTimes = totalT => {
  let validations = [];
  //Validate: total T time is less than or equal to 60

  const check1 = CheckValueUnderLimit(totalT, TotalTtimesLimit);

  if (!check1)
    validations.push(
      ValidationMessageExceedLimit(
        TotalTtimesLimit,
        ValidationMessageType.Error
      )
    );

  return validations;
};
