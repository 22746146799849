//transform in-state fast start profile data structure to format for validation display

import { FastStartProfileLabels } from "../enums";

export const TransformValidationFastStartProfile = fastStartProfile => {
  let data = [];
  const fields = ["minimumLoad", "t1", "t2", "t3", "t4", "totalT"];
  fields.forEach(field => {
    const validations = fastStartProfile[field]
      ? fastStartProfile[field]
      : null;
    if (validations && validations.length > 0)
      data.push({
        fieldLabel: FastStartProfileLabels[field],
        validations: validations
      });
  });

  return data;
};
