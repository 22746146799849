import React, { useCallback, useEffect, useState } from "react";
import {
  ApiStatusWrapper,
  AppContainer,
  GridCellTypes,
  GridCellTimeAgoLink,
  ButtonSecondary,
  Grid
} from "../components";
import { ApiMethodTypes, useApi } from "../hooks";
import {
  GridFields,
  AuthApi,
  ApiStatus,
  DBFields,
  InternalLinks,
  GridConfigDefault
} from "../utils";
import { NavLink } from "react-router-dom";
import { PlusCircleOutlined } from "@ant-design/icons";
const GRID_HEIGHT_OFFSET = 225;

export const Roles = ({ history, match }) => {
  const [gridHeight, setGridHeight] = useState(600);
  const [rowData, setRowData] = useState([]);
  const [columnDefs, setColumnDefs] = useState([]);

  const {
    sendRequest: rolesSendRequest,
    data: rolesData,
    apiStatus: rolesApiStatus
  } = useApi({ method: ApiMethodTypes.Get });

  useEffect(
    () => {
      const cols = [
        {
          headerName: "Role Name",
          field: GridFields.RoleName
        },
        {
          headerName: "Role Id",
          field: GridFields.RoleId
        },
        {
          headerName: "Description",
          field: GridFields.Description
        },
        {
          headerName: "Last modified at",
          field: GridFields.LastModifiedAt,
          cellRenderer: GridCellTypes.GridCellTimeAgoLink,
          cellRendererParams: params =>
            params.data
              ? {
                  time: params.data[GridFields.LastModifiedAt],
                  link: params.data[GridFields.RoleEditLink]
                }
              : null
        }
      ];
      setColumnDefs(cols);
    },
    [setColumnDefs]
  );

  const onGridReady = useCallback(params => {
    // setGridApi(params.api);
    params.api.sizeColumnsToFit();
  }, []);

  const getRowNodeId = useCallback(data => {
    return data[GridFields.RoleId];
  }, []);

  const resizeGrid = useCallback(() => {
    setGridHeight(window.innerHeight - GRID_HEIGHT_OFFSET);
  }, []);

  const fetchRoles = useCallback(
    () => {
      rolesSendRequest({ url: AuthApi.GetRolesUrl() });
    },
    [rolesSendRequest]
  );

  //once on mount
  useEffect(
    () => {
      resizeGrid();
      fetchRoles();
    },
    [resizeGrid, fetchRoles]
  );

  //on role data
  useEffect(
    () => {
      if (rolesApiStatus === ApiStatus.Success) {
        let newRows = rolesData.map(row => ({
          [GridFields.RoleId]: row[DBFields.RoleId],
          [GridFields.RoleName]: row[DBFields.RoleName],
          [GridFields.Description]: row[DBFields.Description],
          [GridFields.RoleEditLink]: InternalLinks.Role({
            roleId: row[DBFields.RoleId]
          }),
          [GridFields.LastModifiedAt]: row[DBFields.LastModifiedAt]
        }));

        setRowData(newRows);
      }
    },
    [rolesApiStatus, rolesData]
  );

  return (
    <AppContainer match={match} history={history}>
      <ButtonSecondary icon={<PlusCircleOutlined />}>
        <NavLink to={InternalLinks.RoleNew}>&nbsp;Add new Role</NavLink>
      </ButtonSecondary>

      <ApiStatusWrapper statuses={[rolesApiStatus]}>
        <Grid
          gridHeight={gridHeight}
          defaultColDef={GridConfigDefault}
          columnDefs={columnDefs}
          rowData={rowData}
          groupDefaultExpanded={-1}
          suppressCellSelection={true}
          suppressContextMenu={true}
          suppressRowClickSelection={true}
          enableCellChangeFlash={true}
          onGridReady={onGridReady}
          getRowNodeId={getRowNodeId}
          frameworkComponents={{
            GridCellTimeAgoLink: GridCellTimeAgoLink
          }}
        />
      </ApiStatusWrapper>
    </AppContainer>
  );
};
