import React, { useEffect, useState } from "react";
import { Menu } from "antd";
import { string, shape } from "prop-types";
import { ApiMethodTypes, useApi } from "../../hooks";
import { ApiStatus, DBFields, InternalLinks, ParametersApi } from "../../utils";
import { NavLink } from "react-router-dom";
const { SubMenu } = Menu;
export const BidParamsMenu = ({
  categoryId,
  selected: { duid, bidType, parameterId }
}) => {
  // region Params Settings
  const [menuItems, setMenuItems] = useState({});

  //get settings data
  const {
    sendRequest: settingsParamsSendRequest,
    data: settingsParamsData,
    apiStatus: settingsParamsApiStatus
  } = useApi({ method: ApiMethodTypes.Get });

  //fetch
  useEffect(
    () => {
      settingsParamsSendRequest({
        url: ParametersApi.GetSettingsParamsDuidBidTypesUrl({
          categoryId
        })
      });
    },
    [settingsParamsSendRequest, categoryId]
  );

  //received response
  useEffect(
    () => {
      if (settingsParamsApiStatus === ApiStatus.Success && settingsParamsData) {
        let dict = {};
        settingsParamsData.forEach(row => {
          const parameterId = row[DBFields.ParameterId];
          const duid = row[DBFields.Duid];
          const bidType = row[DBFields.BidType];

          if (!dict[duid]) dict[duid] = {};

          if (!dict[duid][bidType]) dict[duid][bidType] = [];
          dict[duid][bidType].push(parameterId);
        });
        setMenuItems(dict);
      }
    },
    [settingsParamsData, settingsParamsApiStatus]
  );

  //endregion

  //region param mappings
  const [paramMappings, setParamMappings] = useState({});

  const {
    sendRequest: configParamsSendRequest,
    data: configParamsData,
    apiStatus: configParamsApiStatus
  } = useApi({ method: ApiMethodTypes.Get });

  //fetch
  useEffect(
    () => {
      configParamsSendRequest({
        url: ParametersApi.GetConfigParams({ categoryId })
      });
    },
    [configParamsSendRequest, categoryId]
  );

  //received response
  useEffect(
    () => {
      if (configParamsApiStatus === ApiStatus.Success && configParamsData) {
        let dict = {};
        configParamsData.forEach(row => {
          dict[row[DBFields.ParameterId]] = row[DBFields.ParameterName];
        });
        setParamMappings(dict);
      }
    },
    [configParamsData, configParamsApiStatus]
  );

  //endregion
  const renderMenuItem = ({ duid, bidType, parameterId, categoryId }) => {
    const link = InternalLinks.BidParam({
      parameterId,
      duid,
      bidType,
      categoryId
    });
    return (
      <Menu.Item key={GetMenuKey({ duid, bidType, parameterId })}>
        <NavLink to={link}>{paramMappings[parameterId]}</NavLink>
      </Menu.Item>
    );
  };

  return (
    <Menu
      mode={"inline"}
      defaultSelectedKeys={[GetMenuKey({ duid, bidType, parameterId })]}
      defaultOpenKeys={[GetMenuKey({ duid }), GetMenuKey({ duid, bidType })]}
      style={{ height: "100%" }}
    >
      {Object.keys(menuItems).map(duid => (
        <SubMenu key={duid} title={duid}>
          {Object.keys(menuItems[duid]).map(bidType => (
            <SubMenu key={GetMenuKey({ duid, bidType })} title={bidType}>
              {menuItems[duid][bidType].map(parameterId =>
                renderMenuItem({ duid, bidType, parameterId, categoryId })
              )}
            </SubMenu>
          ))}
        </SubMenu>
      ))}
    </Menu>
  );
};
const GetMenuKey = ({ duid, bidType, parameterId }) => {
  let key = duid;
  if (bidType) key += `.${bidType}`;
  if (parameterId) key += `.${parameterId}`;
  return key;
};

BidParamsMenu.propTypes = {
  selected: shape({
    duid: string.isRequired,
    bidType: string.isRequired,
    paramterId: string.isRequired
  }).isRequired
};
