import React from "react";
import { any } from "prop-types";
import { Button } from "antd";

//Delete button
export const ButtonDelete = props => (
  <Button type={"danger"} ghost={true} {...props}>
    {props.children}
  </Button>
);

ButtonDelete.propTypes = {
  children: any
};
