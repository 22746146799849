import React from "react";
import { object, string, arrayOf, any } from "prop-types";
import { ApiStatus } from "../utils";
import { Spinner, DisplayError } from "./generic";

export const ApiStatusWrapper = ({ style, statuses, children }) => {
  const renderStatus = ({ status }) => {
    switch (status) {
      case ApiStatus.Loading:
        return <Spinner />;

      case ApiStatus.Success:
        return children;

      case ApiStatus.ConfigError:
        return (
          <DisplayError
            title={"Configuration Error"}
            subTitle={
              "There has been some configuration error. Contact the software developer."
            }
          />
        );
      case ApiStatus.Forbidden:
        return (
          <DisplayError
            title={"Forbidden"}
            subTitle={
              "Sorry, you are not authorized to access this section. Contact your system administrator."
            }
          />
        );

      case ApiStatus.Failed:
        return (
          <DisplayError
            title={"Error loading data"}
            subTitle={
              "Please trying refreshing the page (Ctrl + F5). If error persists, contact support."
            }
          />
        );
      default:
        return null;
    }
  };

  //get highest status from all statuses.
  let status = null;
  if (statuses && statuses.length > 0) {
    for (let i = 0; i < statuses.length; i++) {
      const st = statuses[i];
      if (st !== ApiStatus.Success) {
        status = st;
        break;
      } else {
        status = st;
      }
    }
  }
  // let isNull = false;
  // let isLoading = false;
  // let isError = false;
  // let isForbidden = false;
  // let isSuccess = false;
  //
  // statuses.forEach(st => {
  //   if (st === null) isNull = true;
  //   else if (st === ApiStatus.Loading) isLoading = true;
  //   else if (st === ApiStatus.Failed) isError = true;
  //   else if (st === ApiStatus.Forbidden) isForbidden = true;
  //   else if (st === ApiStatus.Success) isSuccess = true;
  //   else
  //     console.error(
  //       `PageApiStatus component does not implement ApiStatus=${st}`
  //     );
  // });
  return <div style={style}>{renderStatus({ status })}</div>;
};

ApiStatusWrapper.propTypes = {
  style: object,
  statuses: arrayOf(string).isRequired,
  children: any.isRequired
};
