import { Breadcrumb } from "antd";
import React, { useCallback, useContext } from "react";
import { NavLink } from "react-router-dom";
import { InternalLinkNames, InternalLinks, InternalRoutes } from "../utils";
import { ThemeContext } from "../contexts";
import { object } from "prop-types";

export const BreadCrumbRouter = React.memo(({ history, match }) => {
  const theme = useContext(ThemeContext);
  const currentPath = history.location.pathname;

  let routes = [];
  const rootPaths = [
    // InternalRoutes.Home,
    InternalRoutes.Users,
    InternalRoutes.Roles,
    InternalRoutes.TraderParams,
    InternalRoutes.StandingParams,
    InternalRoutes.Offers,
    InternalRoutes.SubmittedSets,
    InternalRoutes.TemplateSets,
    InternalRoutes.AckSets,
    InternalRoutes.SolveList,
    InternalRoutes.JsonViewerHome,
    InternalRoutes.ReportActualManualPerformance,
    InternalRoutes.ReportUnitComparisonDetailed,
    InternalRoutes.ReportUnitComparisonSummary,
    InternalRoutes.DispatchTimesHistory,
    InternalRoutes.SolveCompositeResult,
    InternalRoutes.ReportActualManualLiability,
    InternalRoutes.ReportDailySummary,
    InternalRoutes.AutoStatusHistory
  ];
  //add rootPaths routes
  rootPaths.forEach(path => {
    if (currentPath.indexOf(path) >= 0)
      routes.push({
        path: path,
        breadcrumbName: InternalLinkNames[path]
      });
  });

  if (match.path.indexOf(InternalRoutes.Solve) >= 0) {
    const solveId = match.params["solveId"];
    const bidSettlementDate = match.params["bidSettlementDate"];
    routes.push({
      breadcrumbName: `Solver Run - ${solveId} (${bidSettlementDate})`,
      path: InternalLinks.Solve({
        solveId,
        bidSettlementDate
      })
    });
  }
  if (match.path.indexOf(InternalRoutes.SolveResult) >= 0) {
    const solveId = match.params["solveId"];
    const bidSettlementDate = match.params["bidSettlementDate"];
    routes.push({
      breadcrumbName: `Result`,
      path: InternalLinks.Solve({
        solveId,
        bidSettlementDate
      })
    });
  }
  if (match.path.indexOf(InternalRoutes.AckSet) >= 0) {
    const aemoTransactionId = match.params["aemoTransactionId"];
    routes.push({
      breadcrumbName: `Ack Set - ${aemoTransactionId}`,
      path: InternalLinks.AckSet({
        aemoTransactionId
      })
    });
  }
  if (match.path.indexOf(InternalRoutes.TemplateSet) >= 0) {
    const setId = match.params["templateSetId"];
    routes.push({
      breadcrumbName: `Template Set - ${setId}`,
      path: InternalLinks.TemplateSet({
        templateSetId: setId
      })
    });
  }
  if (match.path.indexOf(InternalRoutes.SubmittedSet) >= 0) {
    const setId = match.params["submittedSetId"];
    routes.push({
      breadcrumbName: `Submitted Set - ${setId}`,
      path: InternalLinks.SubmittedSet({
        submittedSetId: setId
      })
    });
  }

  if (match.path.indexOf(InternalRoutes.BidParam) >= 0) {
    const parameterId = match.params["parameterId"];
    const duid = match.params["duid"];
    const bidType = match.params["bidType"];
    routes.push({
      breadcrumbName: `Parameter (${duid} - ${bidType} - ${parameterId})`,
      path: InternalLinks.BidParam({
        parameterId,
        duid,
        bidType
      })
    });
  }

  if (match.path.indexOf(InternalRoutes.User) >= 0) {
    const userId = match.params["userId"];
    routes.push({
      breadcrumbName: `User - ${userId}`,
      path: InternalLinks.User({
        userId: userId
      })
    });
  }
  if (match.path.indexOf(InternalRoutes.Role) >= 0) {
    const roleId = match.params["roleId"];
    routes.push({
      breadcrumbName: `Role - ${roleId}`,
      path: InternalLinks.Role({
        roleId: roleId
      })
    });
  }

  switch (match.path) {
    case InternalRoutes.DraftOffer:
      routes.push({
        breadcrumbName: `Draft Bid (${match.params.duid} ${match.params.bidType} ${match.params.bidSettlementDate})`
      });
      break;

    case InternalRoutes.AlgoOffer:
      routes.push({
        breadcrumbName: `Algo Bid (${match.params.duid} ${match.params.bidType} ${match.params.bidSettlementDate})`
      });
      break;

    case InternalRoutes.AckOffer:
      routes.push({
        breadcrumbName: `Active Acknowledged Bid (${match.params.duid} ${match.params.bidType} ${match.params.bidSettlementDate})`
      });
      break;

    case InternalRoutes.AckSetOffer:
      routes.push({
        breadcrumbName: `Ack Set Bid (${match.params.duid} ${match.params.bidType} ${match.params.bidSettlementDate})`
      });
      break;

    case InternalRoutes.SubmittedOffer:
      routes.push({
        breadcrumbName: `Submitted Bid (${match.params.duid} ${match.params.bidType})`
      });
      break;

    case InternalRoutes.TemplateSetNew:
      routes.push({ breadcrumbName: "New Template Set" });
      break;

    case InternalRoutes.TemplateOffer:
      routes.push({
        breadcrumbName: `Template Bid (${match.params.duid} ${match.params.bidType})`
      });
      break;
    case InternalRoutes.BidAllocator:
    case InternalRoutes.Home:
      routes.push({
        path: match.path,
        breadcrumbName: InternalLinkNames[match.path]
      });
      break;
    default:
      console.error("not implemented bread crumb", match.path);
      break;
  }
  const itemRenderer = useCallback((route, params, routes, paths) => {
    const last = routes.indexOf(route) === routes.length - 1;
    return last ? (
      <span>{route.breadcrumbName}</span>
    ) : (
      <NavLink to={route.path}>{route.breadcrumbName}</NavLink>
    );
  }, []);

  return (
    <Breadcrumb
      style={{
        backgroundColor: theme.breadCrumbBackgroundColor,
        padding: "16px"
      }}
      itemRender={itemRenderer}
      routes={routes}
    />
  );
});

BreadCrumbRouter.propTypes = {
  history: object.isRequired,
  match: object.isRequired
};
