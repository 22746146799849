import React, { useContext } from "react";
import { object, bool, string, func, any, number } from "prop-types";
import { Drawer } from "antd";

import { ThemeContext } from "../../../contexts";

export const ActionDrawer = ({
  style,
  visible,
  title,
  onClose,
  children,
  width
}) => {
  const theme = useContext(ThemeContext);
  return (
    <Drawer
      style={style}
      visible={visible}
      title={
        <span
          style={{ fontWeight: "bold", color: theme.drawerHeaderTitleColor }}
        >
          {title}
        </span>
      }
      onClose={onClose}
      width={width}
      drawerStyle={{ backgroundColor: theme.drawerBackgroundColor }}
      headerStyle={{
        backgroundColor: theme.drawerHeaderBackgroundColor,
        borderRadius: 0
      }}
    >
      {visible && children}
      {/*  Added visible check to force re-render on closing drawer.*/}
    </Drawer>
  );
};

ActionDrawer.propTypes = {
  style: object,
  visible: bool.isRequired,
  title: string,
  onClose: func.isRequired,
  children: any.isRequired,
  width: number
};

ActionDrawer.defaultProps = {
  width: 800,
  title: "Action"
};
