import React from "react";
import { object, string, any, bool, array } from "prop-types";
import { Form } from "antd";
import { ValidationMessageType } from "../utils/enums";

export const FormItem = ({ label, style, children, required, validations }) => {
  let validationStatus = "";
  let validationMessage = "";
  let errors = [];
  let warnings = [];
  let infos = [];
  if (validations && validations.length > 0) {
    validations.forEach(v => {
      const messageType = v.messageType;
      const message = v.message;
      switch (messageType) {
        case ValidationMessageType.Error:
          errors.push(message);
          break;

        case ValidationMessageType.Warning:
          warnings.push(message);
          break;

        case ValidationMessageType.Info:
          infos.push(message);
          break;

        default:
          console.error(
            `Validation display not done for message type: ${messageType}`
          );
          break;
      }
    });
    if (errors && errors.length > 0) {
      validationStatus = "error";
      validationMessage = `Error: ${errors.join(" ")}`;
    } else if (warnings && warnings.length > 0) {
      validationStatus = "warning";
      validationMessage = `Warning: ${warnings.join(" ")}`;
    } else if (infos && infos.length > 0) {
      validationStatus = "info";
      validationMessage = `Info: ${infos.join(" ")}`;
    }
  }
  return (
    <Form.Item
      label={label}
      style={{ marginBottom: 0, ...style }}
      required={required}
      validateStatus={validationStatus}
      help={validationMessage}
    >
      {children}
    </Form.Item>
  );
};

FormItem.propTypes = {
  label: string.isRequired,
  style: object,
  children: any.isRequired,
  required: bool,
  validations: array
};
