import React, { useCallback, useContext, useEffect, useState } from "react";
import { number, bool, object, string } from "prop-types";
import moment from "moment";
import { Space } from "antd";
import {
  GridCellBidCheck,
  GridCellLink,
  GridCellSubmissionStatus,
  GridCellTimeAgo,
  GridCellTimeAgoLink
} from "../grid_cells";
import { Grid } from "../Grid";
import {
  ApiStatus,
  DBFields,
  VofferApi,
  AuthApi,
  InternalLinks,
  MonitorTables,
  EventIds
} from "../../../utils";
import { GetDuidBidTypeKey, TransformToMonitorDict } from "../../../utils";

import {
  ApiMethodTypes,
  useApi,
  useStateWithSessionStorage
} from "../../../hooks";
import { AppContext } from "../../../contexts";
import { CalcBidCategoryFromBidType } from "../../../utils";
import { ApiStatusWrapper } from "../../ApiStatusWrapper";
import { DatePickerSettlementDate, DisplayTimeAgo } from "../../generic";
import { Actions } from "../../actions";
import {
  GridColDefAlgoOffers,
  GridColDefAutoStatus,
  GridColDefDraftOffers,
  GridColDefLastSubmission,
  GridColDefTemplateOffer,
  GridColDefAckOffer,
  GridColDefLastAlgoSubmission
} from "./grid_col_defs";
import { GridDuidFields } from "./GridDuidFields";
import { useInterval } from "ahooks";
import { ActionTypeIds } from "../../actions/ActionTypeIds";
const {
  GetAlgoOffersUrl,
  GetAutoStatusUrl,
  GetConfigSubmissionStagesUrl,
  GetDraftsUrl,
  GetEnabledDuidBidTypesUrl,
  GetLatestStageBySubmissionByUserUrl,
  GetMonitorTablesUrl,
  GetTemplatesInaSetUrl,
  GetLatestStageBySubmissionAlgoUrl,
  GetAckOffersMetadataLatestWithCheckUrl
} = VofferApi;
const { GetUsersUrl } = AuthApi;
export const GridDuid = ({
  monitorInterval,
  gridHeightOffset,
  showAckOffers,
  showDraftOffers,
  showLastSubmission,
  showAlgoOffers,
  showAutoStatus,
  actionConfig,
  allowSelection,
  showTemplateOffers,
  templateSetId,
  openColsByDefault
}) => {
  const showBidSettlementDate =
    showAckOffers || showAlgoOffers || showDraftOffers || showLastSubmission;
  const [actionBarConfig, setActionBarConfig] = useState(actionConfig);

  const [gridHeight, setGridHeight] = useState(600);
  const [rowData, setRowData] = useState([]);
  const [cols, setCols] = useState([]);
  const [gridApi, setGridApi] = useState();
  const [selectedRows, setSelectedRows] = useState([]);
  const [userMappings, setUserMappings] = useState({});

  const [bidSettlementDate, setBidSettlementDate] = useStateWithSessionStorage(
    "GridDuid.bidSettlementDate"
  );
  //on load
  useEffect(
    () => {
      if (bidSettlementDate === "") {
        setBidSettlementDate(moment().format("YYYY-MM-DD"));
      }
    },
    [bidSettlementDate, setBidSettlementDate]
  );

  const [stagesOptions, setStagesOptions] = useState();
  const { loggedInUserId } = useContext(AppContext);

  const {
    sendRequest: usersSendRequest,
    data: usersData,
    apiStatus: usersApiStatus
  } = useApi({ method: ApiMethodTypes.Get });

  const {
    sendRequest: enabledDuidsSendRequest,
    data: enabledDuidsData,
    apiStatus: enabledDuidsApiStatus
  } = useApi({ method: ApiMethodTypes.Get });

  const {
    sendRequest: monitorSendRequest,
    data: monitorData,
    apiStatus: monitorApiStatus
  } = useApi({ method: ApiMethodTypes.Get });

  const {
    sendRequest: autoStatusSendRequest,
    data: autoStatusData,
    apiStatus: autoStatusApiStatus
  } = useApi({ method: ApiMethodTypes.Get });
  const [autoStatusUpdateId, setAutoStatusUpdateId] = useState(null);

  const {
    sendRequest: draftsSendRequest,
    data: draftsData,
    apiStatus: draftsApiStatus
  } = useApi({ method: ApiMethodTypes.Get });
  const [draftsUpdateId, setDraftsUpdateId] = useState(null);

  const {
    sendRequest: templatesSendRequest,
    data: templatesData,
    apiStatus: templatesApiStatus
  } = useApi({ method: ApiMethodTypes.Get });
  const [templatesUpdateId, setTemplatesUpdateId] = useState(null);

  const {
    sendRequest: algoOffersSendRequest,
    data: algoOffersData,
    apiStatus: algoOffersApiStatus
  } = useApi({ method: ApiMethodTypes.Get });
  const [algoOffersUpdateId, setAlgoOffersUpdateId] = useState();

  const {
    sendRequest: ackOffersSendRequest,
    data: ackOffersData,
    apiStatus: ackOffersApiStatus
  } = useApi({ method: ApiMethodTypes.Get });
  const [ackOffersUpdateId, setAckOffersUpdateId] = useState(null);

  const {
    sendRequest: submittedStagesSendRequest,
    data: submittedStagesData,
    apiStatus: submittedStagesApiStatus
  } = useApi({ method: ApiMethodTypes.Get });
  const [submittedStagesUpdateId, setSubmittedStagesUpdateId] = useState(null);

  const {
    sendRequest: submittedStagesAlgoSendRequest,
    data: submittedStagesAlgoData,
    apiStatus: submittedStagesAlgoApiStatus
  } = useApi({ method: ApiMethodTypes.Get });

  const {
    sendRequest: configSubmissionStagesSendRequest,
    data: configSubmissionStagesData,
    apiStatus: configSubmissionStagesApiStatus
  } = useApi({ method: ApiMethodTypes.Get });

  //set up col defs
  useEffect(
    () => {
      if (Object.keys(userMappings).length > 0) {
        //if usermappings are loaded, otherwise it loads twice
        let columnDefs = [
          {
            headerName: "DUID",
            field: GridDuidFields.Duid,
            width: 100,
            rowGroupIndex: 0,
            hide: true
          }
        ];
        if (showAutoStatus) {
          columnDefs.push(
            GridColDefAutoStatus({
              userMappings,
              openByDefault: openColsByDefault
            })
          );
        }
        if (showAlgoOffers) {
          columnDefs.push(GridColDefAlgoOffers);
          columnDefs.push(GridColDefLastAlgoSubmission);
        }

        if (showDraftOffers) {
          columnDefs.push(GridColDefDraftOffers);
        }

        if (showTemplateOffers) {
          columnDefs.push(GridColDefTemplateOffer({ userMappings }));
        }

        if (showLastSubmission) {
          columnDefs.push(GridColDefLastSubmission);
        }
        if (showAckOffers) {
          columnDefs.push(
            GridColDefAckOffer({
              userMappings,
              openByDefault: openColsByDefault
            })
          );
        }
        setCols(columnDefs);
      }
    },
    [
      setCols,
      showDraftOffers,
      showLastSubmission,
      showAckOffers,
      showAlgoOffers,
      showAutoStatus,
      showTemplateOffers,
      userMappings,
      openColsByDefault
    ]
  );

  const onChangeBidSettlementDate = useCallback(
    date => {
      setBidSettlementDate(date);
    },
    [setBidSettlementDate]
  );

  const onGridReady = useCallback(params => {
    setGridApi(params.api);
    // params.api.sizeColumnsToFit();

    // this.gridColumnApi = params.columnApi;
  }, []);

  const getRowNodeId = useCallback(data => {
    let duid = data[GridDuidFields.Duid];
    let bidType = data[GridDuidFields.BidType];

    return GetDuidBidTypeKey(duid, bidType);
  }, []);

  const onSelectionChanged = useCallback(e => {
    setSelectedRows(e.api.getSelectedRows());
  }, []);

  const updateGridData = useCallback(
    ({ gridFields, dataDict }) => {
      let newRows = [];

      //update grid
      gridApi.forEachNode(node => {
        if (node && node.group === false && node.data) {
          const nodeId = node.id;

          const rowData = dataDict[nodeId];

          let data = node.data; //copy data
          gridFields.forEach(gf => {
            let value = ""; //default set fields to null, to empty string as ag recommends
            if (rowData) {
              value = rowData[gf];
            }
            data[gf] = value;
          });
          newRows.push(data); //only need to send data changes, not whole row node
        }
      });
      if (gridApi) gridApi.applyTransaction({ update: newRows });
    },
    [gridApi]
  );

  //once on mount
  useEffect(
    () => {
      setGridHeight(window.innerHeight - gridHeightOffset);
    },
    [gridHeightOffset]
  );

  //region last bid sync
  const [lastSyncInfo, setLastSyncInfo] = useState({
    lastSyncedRunAt: null,
    lastSyncedTillRunDt: null
  });
  const {
    sendRequest: lastBidSyncSendRequest,
    data: lastBidSyncData,
    apiStatus: lastBidSyncApiStatus
  } = useApi({ method: ApiMethodTypes.Get });
  const [bidSyncUpdateId, setBidSyncUpdateId] = useState(null);

  //fetch users
  useEffect(
    () => {
      if (bidSyncUpdateId)
        lastBidSyncSendRequest({
          url: VofferApi.GetEventLogLatestByIdUrl({
            eventId: EventIds.BidSyncFinished
          })
        });
    },
    [lastBidSyncSendRequest, bidSyncUpdateId]
  );

  //update bid sync data
  useEffect(
    () => {
      if (lastBidSyncApiStatus === ApiStatus.Success && lastBidSyncData) {
        if (lastBidSyncData && lastBidSyncData.length > 0) {
          const data = lastBidSyncData[0];
          const lastSyncedRunAt = data["last_modified_at"];
          const lastSyncedTillRunDt = data["run_date_time"];
          setLastSyncInfo({
            lastSyncedRunAt,
            lastSyncedTillRunDt
          });
        }
      }
    },
    [lastBidSyncData, lastBidSyncApiStatus]
  );
  //endregion

  //fetch  bid sync
  useEffect(
    () => {
      usersSendRequest({ url: GetUsersUrl() });
    },
    [usersSendRequest]
  );
  //update users data
  useEffect(
    () => {
      if (usersApiStatus === ApiStatus.Success && usersData) {
        let userMappings = {};

        usersData.forEach(row => {
          userMappings[row[DBFields.UserId]] = row[DBFields.UserDisplayName];
        });
        setUserMappings(userMappings);
      }
    },
    [usersData, usersApiStatus]
  );
  //fetch enabledDuids
  useEffect(
    () => {
      const enabledDuidsUrl = GetEnabledDuidBidTypesUrl();
      enabledDuidsSendRequest({ url: enabledDuidsUrl });
    },
    [enabledDuidsSendRequest]
  );

  //update enabled duids data
  useEffect(
    () => {
      if (enabledDuidsApiStatus === ApiStatus.Success && enabledDuidsData) {
        const rows = enabledDuidsData.map(row => ({
          [GridDuidFields.Duid]: row[DBFields.Duid],
          [GridDuidFields.BidType]: row[DBFields.BidType]
        }));
        setRowData(rows);
      }
    },
    [enabledDuidsData, enabledDuidsApiStatus]
  );

  //fetch monitor
  const fetchMonitor = useCallback(
    () => {
      const url = GetMonitorTablesUrl();
      monitorSendRequest({ url: url });
    },
    [monitorSendRequest]
  );

  //start monitor polling
  useInterval(
    () => {
      fetchMonitor();
    },
    monitorInterval,
    { immediate: true }
  );

  //on update monitor data
  useEffect(
    () => {
      if (monitorApiStatus === ApiStatus.Success && monitorData) {
        // parse data
        let monitors = TransformToMonitorDict(monitorData);

        setDraftsUpdateId(monitors[MonitorTables.Drafts]);
        setAlgoOffersUpdateId(monitors[MonitorTables.Drafts]); //algo offers are stored in drafts table
        setAckOffersUpdateId(monitors[MonitorTables.AckOffers]);
        setSubmittedStagesUpdateId(monitors[MonitorTables.BidStatus]);
        setAutoStatusUpdateId(monitors[MonitorTables.AutoStatus]);
        setTemplatesUpdateId(monitors[MonitorTables.Template]);
        setBidSyncUpdateId(monitors[MonitorTables.EventLog]);
      }
    },
    [monitorData, monitorApiStatus]
  );

  //fetch config stages
  useEffect(
    () => {
      if (showLastSubmission) {
        const url = GetConfigSubmissionStagesUrl();
        configSubmissionStagesSendRequest({ url: url });
      }
    },
    [configSubmissionStagesSendRequest, showLastSubmission]
  );

  //on update config stages data
  useEffect(
    () => {
      if (
        configSubmissionStagesApiStatus === ApiStatus.Success &&
        configSubmissionStagesData
      ) {
        //change to dict
        let dict = {};
        configSubmissionStagesData.forEach(row => {
          dict[row[DBFields.StageId]] = row[DBFields.Description];
        });
        setStagesOptions(dict);
      }
    },
    [configSubmissionStagesApiStatus, configSubmissionStagesData]
  );

  //fetch auto status
  useEffect(
    () => {
      if (showAutoStatus && autoStatusUpdateId) {
        const url = GetAutoStatusUrl();
        autoStatusSendRequest({ url });
      }
    },
    [autoStatusSendRequest, autoStatusUpdateId, showAutoStatus]
  );

  //on update auto status data
  useEffect(
    () => {
      if (
        autoStatusApiStatus === ApiStatus.Success &&
        autoStatusData &&
        gridApi
      ) {
        let dict = {};
        autoStatusData.forEach(row => {
          const duid = row[DBFields.Duid];
          const bidType = row[DBFields.BidType];
          const key = GetDuidBidTypeKey(duid, bidType);

          dict[key] = {
            [GridDuidFields.AutoStatus]: row[DBFields.AutoStatus],
            [GridDuidFields.AutoStatusLastModifiedAt]:
              row[DBFields.LastModifiedAt],
            [GridDuidFields.AutoStatusLastModifiedBy]:
              row[DBFields.LastModifiedBy],
            [GridDuidFields.AutoStatusLastModifiedNotes]:
              row[DBFields.LastModifiedNotes]
          };
        });

        updateGridData({
          gridFields: [
            GridDuidFields.AutoStatus,
            GridDuidFields.AutoStatusLastModifiedAt,
            GridDuidFields.AutoStatusLastModifiedBy,
            GridDuidFields.AutoStatusLastModifiedNotes
          ],
          dataDict: dict
        });
      }
    },
    [autoStatusApiStatus, autoStatusData, updateGridData, gridApi]
  );

  //fetch drafts
  useEffect(
    () => {
      if (showDraftOffers && draftsUpdateId) {
        const url = GetDraftsUrl({
          bidSettlementDate: bidSettlementDate,
          user: loggedInUserId
        });

        draftsSendRequest({ url: url });
      }
    },
    [
      loggedInUserId,
      bidSettlementDate,
      draftsSendRequest,
      draftsUpdateId,
      showDraftOffers
    ]
  );

  //on update drafts data
  useEffect(
    () => {
      if (draftsApiStatus === ApiStatus.Success && draftsData && gridApi) {
        let dict = {};
        draftsData.forEach(row => {
          const duid = row[DBFields.Duid];
          const bidType = row[DBFields.BidType];
          const key = GetDuidBidTypeKey(duid, bidType);
          const draftEditLink = InternalLinks.DraftOffer({
            duid: duid,
            bidType: bidType,
            bidCategory: CalcBidCategoryFromBidType({
              bidType: bidType
            }),
            bidSettlementDate: bidSettlementDate
          });
          dict[key] = {
            [GridDuidFields.DraftLastModifiedAt]: row[DBFields.LastModifiedAt],
            [GridDuidFields.DraftLinkEdit]: draftEditLink
          };
        });

        updateGridData({
          gridFields: [
            GridDuidFields.DraftLastModifiedAt,
            GridDuidFields.DraftLinkEdit
          ],
          dataDict: dict
        });
        setActionBarConfig(prevState => ({
          ...prevState,
          actions: {
            ...prevState.actions,
            [ActionTypeIds.SubmitDrafts]: { enabled: draftsData.length > 0 }
          }
        }));
      }
    },
    [draftsData, draftsApiStatus, gridApi, updateGridData, bidSettlementDate]
  );
  //fetch templates
  useEffect(
    () => {
      if (showTemplateOffers && templatesUpdateId) {
        const templatesUrl = GetTemplatesInaSetUrl({
          templateSetId: templateSetId
        });

        templatesSendRequest({ url: templatesUrl });
      }
    },
    [templatesSendRequest, templateSetId, templatesUpdateId, showTemplateOffers]
  );

  //update templates data
  useEffect(
    () => {
      if (
        templatesApiStatus === ApiStatus.Success &&
        templatesData &&
        gridApi
      ) {
        let dict = {};
        templatesData.forEach(row => {
          const duid = row[DBFields.Duid];
          const bidType = row[DBFields.BidType];
          const key = GetDuidBidTypeKey(duid, bidType);
          const templateEditLink = InternalLinks.TemplateOffer({
            duid: duid,
            bidType: bidType,
            bidCategory: CalcBidCategoryFromBidType({
              bidType: bidType
            }),
            templateSetId: templateSetId
          });

          dict[key] = {
            [GridDuidFields.TemplateLastModifiedAt]:
              row[DBFields.LastModifiedAt],
            [GridDuidFields.TemplateLastModifiedBy]:
              row[DBFields.LastModifiedBy],
            [GridDuidFields.TemplateEditLink]: templateEditLink
          };
        });

        updateGridData({
          gridFields: [
            GridDuidFields.TemplateLastModifiedAt,
            GridDuidFields.TemplateLastModifiedBy,
            GridDuidFields.TemplateEditLink
          ],
          dataDict: dict
        });
        setActionBarConfig(prevState => ({
          ...prevState,
          actions: {
            ...prevState.actions,
            [ActionTypeIds.SubmitTemplates]: {
              enabled: templatesData.length > 0
            }
          }
        }));
      }
    },
    [templatesApiStatus, templatesData, updateGridData, gridApi, templateSetId]
  );

  //fetch algo offer
  useEffect(
    () => {
      if (showAlgoOffers && algoOffersUpdateId) {
        const url = GetAlgoOffersUrl({
          bidSettlementDate: bidSettlementDate
        });

        algoOffersSendRequest({ url: url });
      }
    },
    [
      bidSettlementDate,
      algoOffersSendRequest,
      algoOffersUpdateId,
      showAlgoOffers
    ]
  );

  //on update algo offer data
  useEffect(
    () => {
      if (
        algoOffersApiStatus === ApiStatus.Success &&
        algoOffersData &&
        gridApi
      ) {
        let dict = {};
        algoOffersData.forEach(row => {
          const duid = row[DBFields.Duid];
          const bidType = row[DBFields.BidType];
          const key = GetDuidBidTypeKey(duid, bidType);
          const link = InternalLinks.AlgoOffer({
            duid: duid,
            bidType: bidType,
            bidCategory: CalcBidCategoryFromBidType({
              bidType: bidType
            }),
            bidSettlementDate: bidSettlementDate
          });
          dict[key] = {
            [GridDuidFields.LatestAlgoDraftLastModifiedAt]:
              row[DBFields.LastModifiedAt],
            [GridDuidFields.AlgoDraftViewLink]: link,
            [GridDuidFields.LatestAlgoDraftSolveId]: row[DBFields.SolveId],
            [GridDuidFields.SolveHistoryViewLink]: InternalLinks.Solve({
              solveId: row[DBFields.SolveId],
              bidSettlementDate: row[DBFields.BidSettlementDate]
            })
          };
        });

        updateGridData({
          gridFields: [
            GridDuidFields.LatestAlgoDraftLastModifiedAt,
            GridDuidFields.AlgoDraftViewLink,
            GridDuidFields.LatestAlgoDraftSolveId,
            GridDuidFields.SolveHistoryViewLink
          ],
          dataDict: dict
        });
      }
    },
    [
      algoOffersData,
      algoOffersApiStatus,
      gridApi,
      updateGridData,
      bidSettlementDate
    ]
  );

  //fetch ack offer
  useEffect(
    () => {
      if (showAckOffers && ackOffersUpdateId) {
        ackOffersSendRequest({
          url: GetAckOffersMetadataLatestWithCheckUrl({
            bidSettlementDate: bidSettlementDate
          })
        });
      }
    },
    [bidSettlementDate, ackOffersSendRequest, ackOffersUpdateId, showAckOffers]
  );

  //on update ack offer data
  useEffect(
    () => {
      if (
        ackOffersApiStatus === ApiStatus.Success &&
        ackOffersData &&
        gridApi
      ) {
        let dict = {};

        ackOffersData.forEach(row => {
          const duid = row[DBFields.Duid];
          const bidType = row[DBFields.BidType];
          const key = GetDuidBidTypeKey(duid, bidType);
          const link = InternalLinks.AckOffer({
            duid: duid,
            bidType: bidType,
            bidCategory: CalcBidCategoryFromBidType({
              bidType: row[DBFields.BidType]
            }),
            bidSettlementDate: bidSettlementDate
          });
          dict[key] = {
            [GridDuidFields.AckOfferAemoOfferTimeStamp]:
              row[DBFields.AemoOfferTimeStamp],
            [GridDuidFields.AckOfferAemoAuthorisedBy]:
              row[DBFields.AemoAuthorisedBy],
            [GridDuidFields.AckOfferAemoReferenceId]:
              row[DBFields.AemoReferenceId],
            [GridDuidFields.AckOfferAemoStatus]: row[DBFields.AemoStatus],
            [GridDuidFields.AckOfferAemoMethod]: row[DBFields.AemoMethod],
            [GridDuidFields.AckOfferAemoEntryType]: row[DBFields.AemoEntryType],
            [GridDuidFields.AckOfferAemoRebidReason]:
              row[DBFields.AemoRebidReason],
            [GridDuidFields.AckOfferAemoTransactionId]:
              row[DBFields.AemoTransactionId],
            [GridDuidFields.AckOfferLoadedBy]: row[DBFields.LoadedBy],
            [GridDuidFields.AckOfferLoadedAt]: row[DBFields.LoadedAt],
            [GridDuidFields.AckOfferViewLink]: link,
            [GridDuidFields.AckHistorySetLink]: InternalLinks.AckSet({
              aemoTransactionId: row[DBFields.AemoTransactionId]
            }),
            [GridDuidFields.BidCheckReference]: row[DBFields.ReferenceBidCheck]
          };
        });
        updateGridData({
          gridFields: [
            GridDuidFields.AckOfferAemoOfferTimeStamp,
            GridDuidFields.AckOfferAemoAuthorisedBy,
            GridDuidFields.AckOfferAemoReferenceId,
            GridDuidFields.AckOfferAemoStatus,
            GridDuidFields.AckOfferAemoMethod,
            GridDuidFields.AckOfferAemoEntryType,
            GridDuidFields.AckOfferAemoRebidReason,
            GridDuidFields.AckOfferAemoTransactionId,
            GridDuidFields.AckOfferLoadedBy,
            GridDuidFields.AckOfferLoadedAt,
            GridDuidFields.AckOfferViewLink,
            GridDuidFields.AckHistorySetLink,
            GridDuidFields.BidCheckReference
          ],
          dataDict: dict
        });
      }
    },
    [
      ackOffersData,
      ackOffersApiStatus,
      gridApi,
      updateGridData,
      bidSettlementDate
    ]
  );

  //fetch submitted stages
  useEffect(
    () => {
      if (showLastSubmission && submittedStagesUpdateId) {
        const url = GetLatestStageBySubmissionByUserUrl({
          bidSettlementDate: bidSettlementDate,
          user: loggedInUserId
        });
        submittedStagesSendRequest({ url: url });
      }
    },
    [
      bidSettlementDate,
      loggedInUserId,
      submittedStagesSendRequest,
      submittedStagesUpdateId,
      showLastSubmission
    ]
  );

  //on update submitted stages data
  useEffect(
    () => {
      if (
        stagesOptions &&
        submittedStagesApiStatus === ApiStatus.Success &&
        submittedStagesData &&
        gridApi
      ) {
        let dict = {};
        submittedStagesData.forEach(row => {
          const duid = row[DBFields.Duid];
          const bidType = row[DBFields.BidType];
          const key = GetDuidBidTypeKey(duid, bidType);
          const link = InternalLinks.SubmittedSet({
            submittedSetId: row[DBFields.SubmittedBidSetId]
          });
          dict[key] = {
            [GridDuidFields.LastSubmissionSubmissionId]:
              row[DBFields.SubmittedBidSetId],
            [GridDuidFields.LastSubmissionSubmittedAt]:
              row[DBFields.SubmittedAt],
            [GridDuidFields.LastSubmissionStageId]: row[DBFields.LatestStageId]
              ? stagesOptions[row[DBFields.LatestStageId]]
              : "",
            [GridDuidFields.LastSubmissionStageIdStatusCombo]:
              row[DBFields.LatestStageId] + row[DBFields.LatestStatus],
            [GridDuidFields.LastSubmissionStageStatus]:
              row[DBFields.LatestStatus],
            [GridDuidFields.LastSubmissionLink]: link
          };
        });

        updateGridData({
          gridFields: [
            GridDuidFields.LastSubmissionSubmissionId,
            GridDuidFields.LastSubmissionSubmittedAt,
            GridDuidFields.LastSubmissionStageId,
            GridDuidFields.LastSubmissionStageIdStatusCombo,
            GridDuidFields.LastSubmissionStageStatus,
            GridDuidFields.LastSubmissionLink
          ],
          dataDict: dict
        });
      }
    },
    [
      submittedStagesData,
      submittedStagesApiStatus,
      gridApi,
      updateGridData,
      stagesOptions
    ]
  );

  //fetch submitted stages algo
  useEffect(
    () => {
      if (showAlgoOffers && submittedStagesUpdateId) {
        const url = GetLatestStageBySubmissionAlgoUrl({
          bidSettlementDate: bidSettlementDate
        });
        submittedStagesAlgoSendRequest({ url: url });
      }
    },
    [
      bidSettlementDate,
      submittedStagesAlgoSendRequest,
      submittedStagesUpdateId,
      showAlgoOffers
    ]
  );

  //on update submitted stages algo data
  useEffect(
    () => {
      if (
        stagesOptions &&
        submittedStagesAlgoApiStatus === ApiStatus.Success &&
        submittedStagesAlgoData &&
        gridApi
      ) {
        let dict = {};
        submittedStagesAlgoData.forEach(row => {
          const duid = row[DBFields.Duid];
          const bidType = row[DBFields.BidType];
          const key = GetDuidBidTypeKey(duid, bidType);
          const link = InternalLinks.SubmittedSet({
            submittedSetId: row[DBFields.SubmittedBidSetId]
          });
          dict[key] = {
            [GridDuidFields.LastSubmissionSubmissionIdAlgo]:
              row[DBFields.SubmittedBidSetId],
            [GridDuidFields.LastSubmissionSubmittedAtAlgo]:
              row[DBFields.SubmittedAt],
            [GridDuidFields.LastSubmissionStageIdAlgo]: row[
              DBFields.LatestStageId
            ]
              ? stagesOptions[row[DBFields.LatestStageId]]
              : "",
            [GridDuidFields.LastSubmissionStageIdStatusComboAlgo]:
              row[DBFields.LatestStageId] + row[DBFields.LatestStatus],
            [GridDuidFields.LastSubmissionStageStatusAlgo]:
              row[DBFields.LatestStatus],
            [GridDuidFields.LastSubmissionLinkAlgo]: link
          };
        });

        updateGridData({
          gridFields: [
            GridDuidFields.LastSubmissionSubmissionIdAlgo,
            GridDuidFields.LastSubmissionSubmittedAtAlgo,
            GridDuidFields.LastSubmissionStageIdAlgo,
            GridDuidFields.LastSubmissionStageIdStatusComboAlgo,
            GridDuidFields.LastSubmissionStageStatusAlgo,
            GridDuidFields.LastSubmissionLinkAlgo
          ],
          dataDict: dict
        });
      }
    },
    [
      submittedStagesAlgoData,
      submittedStagesAlgoApiStatus,
      gridApi,
      updateGridData,
      stagesOptions
    ]
  );

  return (
    <ApiStatusWrapper
      statuses={
        showLastSubmission
          ? [enabledDuidsApiStatus, configSubmissionStagesApiStatus]
          : [enabledDuidsApiStatus]
      }
    >
      <Space size={"large"}>
        {showBidSettlementDate && (
          <Space>
            <label>Settlement date: </label>
            <DatePickerSettlementDate
              onChange={onChangeBidSettlementDate}
              value={bidSettlementDate}
            />
          </Space>
        )}
        {
          <Space>
            <span>Bids last synced</span>
            <b>
              <DisplayTimeAgo datetime={lastSyncInfo.lastSyncedRunAt} />
            </b>
            |<span>Latest AEMO Offer Timestamp: </span>
            <b>{lastSyncInfo.lastSyncedTillRunDt}</b>
          </Space>
        }
      </Space>

      <Grid
        gridHeight={gridHeight}
        statusBar={{
          statusPanels: [
            {
              statusPanel: "agSelectedRowCountComponent",
              align: "left"
            },
            {
              statusPanel: "agTotalRowCountComponent",
              align: "left"
            }
            // {
            //   statusPanel: "agFilteredRowCountComponent",
            //   align: "left"
            // }
          ]
        }}
        defaultColDef={{
          resizable: false,
          suppressMenu: true,
          suppressMovableColumns: true,
          editable: false
        }}
        columnDefs={cols}
        autoGroupColumnDef={{
          headerName: "Target",
          field: GridDuidFields.BidType,
          width: 190,
          suppressSizeToFit: true,
          cellRendererParams: {
            checkbox: allowSelection
          },
          pinned: "left",
          headerCheckboxSelection: true
        }}
        // getRowClass={params => {
        //   return highlightRow &&
        //     params &&
        //     params.data &&
        //     highlightRow.duid === params.data.duid &&
        //     highlightRow.bidType === params.data.bid_type
        //     ? "highlighted-row"
        //     : "";
        // }}
        rowData={rowData}
        rowSelection={"multiple"}
        groupSelectsChildren={true}
        groupSelectsFiltered={true}
        groupDefaultExpanded={-1}
        enableRangeSelection={false}
        enableRangeHandle={false}
        enableCharts={false}
        suppressCellSelection={true}
        suppressContextMenu={true}
        suppressRowClickSelection={true}
        rowMultiSelectWithClick={true}
        enableCellChangeFlash={true}
        onGridReady={onGridReady}
        onSelectionChanged={onSelectionChanged}
        getRowNodeId={getRowNodeId}
        frameworkComponents={{
          GridCellLink: GridCellLink,
          GridCellTimeAgo: GridCellTimeAgo,
          GridCellTimeAgoLink: GridCellTimeAgoLink,
          GridCellSubmissionStatus: GridCellSubmissionStatus,
          GridCellBidCheck: GridCellBidCheck
        }}
      />

      {actionBarConfig &&
        allowSelection && (
          <Actions
            targets={selectedRows}
            targetLabel={showTemplateOffers ? templateSetId : bidSettlementDate}
            actionConfig={actionBarConfig}
            bidSettlementDate={bidSettlementDate}
          />
        )}
    </ApiStatusWrapper>
  );
};

GridDuid.propTypes = {
  showAckOffers: bool.isRequired,
  showDraftOffers: bool.isRequired,
  showLastSubmission: bool.isRequired,
  showAlgoOffers: bool.isRequired,
  showAutoStatus: bool.isRequired,
  allowSelection: bool.isRequired,
  gridHeightOffset: number,
  monitorInterval: number,
  actionConfig: object,
  showTemplateOffers: bool.isRequired,
  templateSetId: string,
  openColsByDefault: bool
};

GridDuid.defaultProps = {
  showAckOffers: false,
  showDraftOffers: false,
  showLastSubmission: false,
  showAlgoOffers: false,
  showAutoStatus: false,
  allowSelection: false,
  showTemplateOffers: false,
  gridHeightOffset: 270,
  monitorInterval: 5000,
  openColsByDefault: false
};
