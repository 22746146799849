import React from "react";
import { object, string } from "prop-types";
import { Result } from "antd";

export const DisplayError = ({ style, title, subTitle }) => (
  <Result style={style} status={"warning"} title={title} subTitle={subTitle} />
);

DisplayError.propTypes = {
  style: object,
  title: string.isRequired,
  subTitle: string
};

DisplayError.defaultProps = {
  title: "Error",
  subTitle: "Please try again"
};
