import React, { useState, useEffect, useCallback } from "react";
import { AppContainer, TitleHeader, TitleSection } from "../components";
import { Typography, InputNumber, Alert } from "antd";
import { ApplyBidAllocationToPeriodFcas } from "../utils";
import "./bidAllocator.css";
const { Text } = Typography;

const BANDS_IDX = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
export const BidAllocator = ({ match, history }) => {
  const [logger, setLogger] = useState([]);

  const [outputBidPeriod, setOutputBidPeriod] = useState({});
  const [bidPrices, setBidPrices] = useState({
    priceBand1: 3.74,
    priceBand2: 5.9,
    priceBand3: 7.7,
    priceBand4: 8.99,
    priceBand5: 10.8,
    priceBand6: 15.0,
    priceBand7: 18.1,
    priceBand8: 20.8,
    priceBand9: 26.0,
    priceBand10: 15000.0
  });
  const [forecastRRP, setForecastRRP] = useState(8);
  const [breakEvenRRPOV, setBreakEvenRRPOV] = useState(0);
  const [breakEvenRRPNOV, setBreakEvenRRPNOV] = useState(0);
  const [traderPrice1, setTraderPrice1] = useState(null);
  const [traderPrice2, setTraderPrice2] = useState(8.5);
  const [traderPrice3, setTraderPrice3] = useState(22);
  const [limitVolume, setLimitVolume] = useState(30);
  const [optimalVolume, setOptimalVolume] = useState(30);
  const [maxCapacity, setMaxCapacity] = useState(75);
  const [nonOptimalVolume, setNonOptimalVolume] = useState(null);
  const [nonDiscretionaryVolume, setNonDiscretionaryVolume] = useState(null);
  const [discretionaryVolume, setDiscretionaryVolume] = useState(null);
  const [maxAvail, setMaxAvail] = useState(75);

  //derive inputs
  useEffect(
    () => {
      const discretionaryVolume = Math.min(limitVolume, maxAvail);
      const nonDiscretionaryVolume = maxAvail - discretionaryVolume;
      const nonOptimalVolume = discretionaryVolume - optimalVolume;
      setNonOptimalVolume(nonOptimalVolume);
      setDiscretionaryVolume(discretionaryVolume);
      setNonDiscretionaryVolume(nonDiscretionaryVolume);
    },
    [
      limitVolume,
      optimalVolume,
      maxAvail,
      setNonOptimalVolume,
      setNonDiscretionaryVolume,
      setDiscretionaryVolume
    ]
  );

  useEffect(
    () => {
      const { logs, bidPeriod } = ApplyBidAllocationToPeriodFcas({
        bidPrices,
        forecastRRP,
        breakEvenRRPOV,
        breakEvenRRPNOV,
        traderPrice1,
        traderPrice2,
        traderPrice3,
        limitVolume,
        optimalVolume,
        maxCapacity,
        discretionaryVolume,
        nonDiscretionaryVolume,
        nonOptimalVolume
      });
      setOutputBidPeriod(bidPeriod);
      setLogger(logs);
    },
    [
      bidPrices,
      forecastRRP,
      breakEvenRRPOV,
      breakEvenRRPNOV,
      traderPrice1,
      traderPrice2,
      traderPrice3,
      limitVolume,
      optimalVolume,
      maxCapacity,
      discretionaryVolume,
      nonDiscretionaryVolume,
      nonOptimalVolume,
      setLogger,
      setOutputBidPeriod
    ]
  );

  const onChangeOptimalVolume = useCallback(
    value => {
      setOptimalVolume(value);
    },
    [setOptimalVolume]
  );

  const onChangeBreakEvenRRPOV = useCallback(
    value => {
      setBreakEvenRRPOV(value);
    },
    [setBreakEvenRRPOV]
  );

  const onChangeBreakEvenRRPNOV = useCallback(
    value => {
      setBreakEvenRRPNOV(value);
    },
    [setBreakEvenRRPNOV]
  );

  const onChangeForecastRRP = useCallback(
    value => {
      setForecastRRP(value);
    },
    [setForecastRRP]
  );

  const onChangeLimitVolume = useCallback(
    value => {
      setLimitVolume(value);
    },
    [setLimitVolume]
  );

  const onChangeTraderPrice1 = useCallback(
    value => {
      setTraderPrice1(value);
    },
    [setTraderPrice1]
  );

  const onChangeTraderPrice2 = useCallback(
    value => {
      setTraderPrice2(value);
    },
    [setTraderPrice2]
  );

  const onChangeTraderPrice3 = useCallback(
    value => {
      setTraderPrice3(value);
    },
    [setTraderPrice3]
  );
  const onChangeMaxCapacity = useCallback(
    value => {
      setMaxCapacity(value);
    },
    [setMaxCapacity]
  );

  const onChangeMaxAvail = useCallback(
    value => {
      setMaxAvail(value);
    },
    [setMaxAvail]
  );

  const onChangePriceBandEach = useCallback(
    (idx, value) => {
      setBidPrices(prevState => ({
        ...prevState,
        [idx]: value
      }));
    },
    [setBidPrices]
  );

  const onChangePriceBand = idx => value => {
    onChangePriceBandEach(idx, value);
  };

  return (
    <AppContainer history={history} match={match}>
      <TitleSection title={"FCAS Bid Allocation"} />
      <div className={"bid-allocator"}>
        <div>
          <Alert
            message={"Allocation Rules"}
            description={
              <div>
                {"OV in PB(n,n+x) where PBn > BERRP &  PBn+x < max(FRRP, TP1)"}
                <br />
                {"NOV in PB(n,n+x) where PBn > max(BERRP, FRRP) & PBn+x < TP1"}
                <br />
                {"NDV in PB(n,n+x) where PBn > Max(TP2,BERRP) & PBn+x < TP3"}
              </div>
            }
            type={"info"}
            showIcon={true}
          />
          <br />
          <TitleHeader title={"Inputs"} />
          <table className={"input"}>
            <thead>
              <tr>
                <th>Max Avail</th>

                <th>
                  Limit Volume<br />(LV)
                </th>
                <th>
                  Trader Price 1<br />(TP1)
                </th>
                <th>
                  Trader Price 2<br />(TP2)
                </th>
                <th>
                  Trader Price 3<br />(TP3)
                </th>
                <th>
                  Max Capacity<br />(CAP)
                </th>
                <th>
                  Forecast RRP<br />(FRRP)
                </th>
                <th>
                  Break Even RRP OV<br />(BERRP OV)
                </th>
                <th>
                  Break Even RRP NOV<br />(BERRP NOV)
                </th>
                <th>
                  Optimal Volume<br />(OV)
                </th>
                <th>
                  Non Optimal Volume<br />(NOV)
                </th>
                <th>
                  Discretionary Volume<br />(DV)
                </th>
                <th>
                  Non Discretionary Volume<br />(NDV)
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <InputNumber value={maxAvail} onChange={onChangeMaxAvail} />
                </td>

                <td>
                  <InputNumber
                    value={limitVolume}
                    onChange={onChangeLimitVolume}
                  />
                </td>
                <td>
                  <InputNumber
                    value={traderPrice1}
                    onChange={onChangeTraderPrice1}
                  />
                </td>
                <td>
                  <InputNumber
                    value={traderPrice2}
                    onChange={onChangeTraderPrice2}
                  />
                </td>
                <td>
                  <InputNumber
                    value={traderPrice3}
                    onChange={onChangeTraderPrice3}
                  />
                </td>
                <td>
                  <InputNumber
                    value={maxCapacity}
                    onChange={onChangeMaxCapacity}
                  />
                </td>
                <td>
                  <InputNumber
                    value={forecastRRP}
                    onChange={onChangeForecastRRP}
                  />
                </td>
                <td>
                  <InputNumber
                    value={breakEvenRRPOV}
                    onChange={onChangeBreakEvenRRPOV}
                  />
                </td>
                <td>
                  <InputNumber
                    value={breakEvenRRPNOV}
                    onChange={onChangeBreakEvenRRPNOV}
                  />
                </td>
                <td>
                  <InputNumber
                    value={optimalVolume}
                    onChange={onChangeOptimalVolume}
                  />
                </td>
                <td>
                  <InputNumber value={nonOptimalVolume} disabled={true} />
                </td>
                <td>
                  <InputNumber value={discretionaryVolume} disabled={true} />
                </td>
                <td>
                  <InputNumber value={nonDiscretionaryVolume} disabled={true} />
                </td>
              </tr>
            </tbody>
          </table>
          <br />
          <table className={"input"}>
            <thead>
              <tr>
                {BANDS_IDX.map(idx => (
                  <th key={`band${idx}`}>Price Band {idx}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                {BANDS_IDX.map(idx => (
                  <td key={`priceband${idx}`}>
                    <InputNumber
                      value={bidPrices[`priceBand${idx}`]}
                      onChange={onChangePriceBand(`priceBand${idx}`)}
                    />
                  </td>
                ))}
              </tr>
            </tbody>
          </table>
          <br />
          <TitleHeader title={"Outputs"} />
          <table className={"output"}>
            <thead>
              <tr>
                {BANDS_IDX.map(idx => (
                  <th key={`band${idx}`}>
                    Band {idx}
                    <br />
                    ${bidPrices[`priceBand${idx}`]}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                {BANDS_IDX.map(idx => (
                  <td key={`outputbandmw${idx}`}>
                    {outputBidPeriod[`band${idx}`]}
                  </td>
                ))}
              </tr>
            </tbody>
          </table>
        </div>
        <div>
          <br />
          <TitleHeader title={"Logs"} />
          <table className={"logs"}>
            {logger.map((row, i) => (
              <tr key={`log-${i}`}>
                <td className={"idx"}>{i + 1}</td>
                <td className={"msg"}>
                  <Text type={row.type}>{row.message}</Text>
                </td>
                <td className={"code"}>
                  {row.code && <Text code={true}>{row.code}</Text>}
                </td>
              </tr>
            ))}
          </table>
        </div>
      </div>
    </AppContainer>
  );
};
