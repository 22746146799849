import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { OfferChart } from "../../offer_chart";
import { CalcBidCategoryFromBidType, InternalLinks } from "../../../utils";
import { ApiMethodTypes, useApi } from "../../../hooks";
import { ApiStatus, DBFields, VofferApi, ParametersApi } from "../../../utils";
import {
  CalcIfReverseBands,
  CalcPeriodKey
} from "../../offer_table/utils/calc";
import { ApiStatusWrapper } from "../../ApiStatusWrapper";

import { NavLink } from "react-router-dom";
import moment from "moment";
import { useInterval } from "ahooks";
const { GetLatestAckBidUrl } = VofferApi;

const UPDATE_TIMER_INTERVAL = 5000; //10 secs

export const AckOfferChartDashboard = ({
  duid,
  bidType,
  bidSettlementDate,
  containerSize,
  ackOfferUpdateId
}) => {
  //region NEM time
  const [isCurrentDay, setIsCurrentDay] = useState(
    bidSettlementDate ===
      moment()
        .utc()
        .add(10, "hours")
        .format("YYYY-MM-DD")
  );

  useEffect(
    () => {
      setIsCurrentDay(
        bidSettlementDate ===
          moment()
            .utc()
            .add(10, "hours")
            .format("YYYY-MM-DD")
      );
    },
    [bidSettlementDate]
  );

  const getCurrentNemPeriod = () => {
    const utcnow = moment().utc();
    // Your moment at midnight
    const utcMidnight = utcnow.clone().startOf("day");
    // Difference in minutes
    const diffMinutes = utcnow.diff(utcMidnight, "minutes") + 1; //add 1 minutes to flip to next period on 5min interval
    const diff5min = diffMinutes / 5;
    //use mod to get periodID. note 10*12 converts to NEM time from utc, 4*12 subtracts 4 hours for start of NEM day
    const periodId = Math.max(
      1,
      Math.ceil((diff5min + 10 * 12 - 4 * 12 + 288) % 288)
    );
    return periodId;
  };

  const [now, setNow] = useState(getCurrentNemPeriod());

  useInterval(() => {
    setNow(getCurrentNemPeriod());
  }, UPDATE_TIMER_INTERVAL);

  //endregion

  const [bidCategory] = useState(CalcBidCategoryFromBidType({ bidType }));

  const [isReverseBands, setIsReverseBands] = useState(false);

  //region Tech params

  //fetch technical params
  //DISPATCH TYPE
  const [dispatchType, setDispatchType] = useState(null);
  const {
    sendRequest: techParamsDispatchTypeSendRequest,
    data: techParamsDispatchTypeData,
    apiStatus: techParamsDispatchTypeApiStatus
  } = useApi({ method: ApiMethodTypes.Get });

  useEffect(
    () => {
      techParamsDispatchTypeSendRequest({
        url: ParametersApi.GetDispatchTypeUrl({
          duid,
          bidType
        })
      });
    },
    [techParamsDispatchTypeSendRequest, duid, bidType]
  );

  //on update technical params data
  useEffect(
    () => {
      if (
        techParamsDispatchTypeApiStatus === ApiStatus.Success &&
        techParamsDispatchTypeData
      ) {
        if (techParamsDispatchTypeData.length > 0)
          setDispatchType(techParamsDispatchTypeData[0]["val"]);
        else {
          console.error("Unable to find tech params");
        }
      }
    },
    [techParamsDispatchTypeData, techParamsDispatchTypeApiStatus]
  );

  useEffect(
    () => {
      if (dispatchType !== null) {
        const isReverse = CalcIfReverseBands(bidType, dispatchType);
        setIsReverseBands(isReverse);
      }
    },
    [bidType, dispatchType]
  );
  //endregion

  //region Offer Data
  const [priceBands, setPriceBands] = useState([]);
  const [periods, setPeriods] = useState([]);
  const [metadata, setMetatdata] = useState({});
  const {
    sendRequest: offersSendRequest,
    data: offersData,
    apiStatus: offersApiStatus
  } = useApi({ method: ApiMethodTypes.Get });

  //fetch offer data
  useEffect(
    () => {
      if (ackOfferUpdateId)
        offersSendRequest({
          url: GetLatestAckBidUrl({
            bidType: bidType,
            bidCategory: bidCategory,
            duid: duid,
            bidSettlementDate: bidSettlementDate
          })
        });
    },
    [
      offersSendRequest,
      duid,
      bidType,
      bidSettlementDate,
      bidCategory,
      ackOfferUpdateId
    ]
  );

  //on update offer data
  useEffect(
    () => {
      if (offersApiStatus === ApiStatus.Success && offersData) {
        const metadata = offersData.metadata;
        const bid = offersData.bid;
        if (bid.prices !== undefined) {
          setPriceBands(bid.prices);
        }

        const periodKey = CalcPeriodKey({
          bidCategory: bidCategory
        });
        setPeriods(bid[periodKey]);

        setMetatdata(metadata);
      }
    },
    [offersData, offersApiStatus, bidCategory]
  );

  //endregion

  return (
    <div>
      <ApiStatusWrapper
        statuses={[techParamsDispatchTypeApiStatus, offersApiStatus]}
      >
        <h4 style={{ textAlign: "center", marginTop: "8px" }}>
          <NavLink
            to={InternalLinks.AckOffer({
              bidCategory: bidCategory,
              bidType: bidType,
              bidSettlementDate: bidSettlementDate,
              duid: duid
            })}
          >
            Active Acknowledged Bid (<span>{duid}</span> {bidType}{" "}
            {bidSettlementDate})
          </NavLink>
          <br />
          {metadata && (
            <span>
              Authorised by: {metadata[DBFields.AemoAuthorisedBy]}
              {metadata[DBFields.AemoOfferTimeStamp] && (
                <span>
                  {" "}
                  | AEMO Offer Timestamp:{" "}
                  {metadata[DBFields.AemoOfferTimeStamp]
                    .replace("Z", "")
                    .replace("T", " ")
                    .slice(0, 19)}
                </span>
              )}
            </span>
          )}
        </h4>
        <OfferChart
          nemPeriodId={isCurrentDay ? now : null}
          width={containerSize.width}
          height={containerSize.height}
          priceBands={priceBands}
          bidCategory={bidCategory}
          periods={periods}
          isReverseBands={isReverseBands}
        />
      </ApiStatusWrapper>
    </div>
  );
};

AckOfferChartDashboard.propTypes = {
  containerSize: PropTypes.shape({
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired
  }).isRequired,
  duid: PropTypes.string.isRequired,
  bidType: PropTypes.string.isRequired,
  bidSettlementDate: PropTypes.string.isRequired
};
