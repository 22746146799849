import React, { useEffect, useState } from "react";
import { ApiStatusWrapper, AppContainer, OffersMenu } from "../components";

import { Offer } from "../components";
import { ApiMethodTypes, useApi } from "../hooks";
import { ApiStatus, DBFields, InternalLinks, VofferApi } from "../utils";
import { CalcBidCategoryFromBidType } from "../utils";
import { Layout } from "antd";

const {
  DeleteTemplateBidUrl,
  GetTemplateBidUrl,
  GetTemplatesInaSetUrl,
  PutTemplateBidUrl
} = VofferApi;

export const TemplateOffer = ({ history, match }) => {
  const { bidType, bidCategory, duid, templateSetId } = match.params;

  const getTemplateBidUrl = GetTemplateBidUrl({
    bidType: bidType,
    bidCategory: bidCategory,
    duid: duid,
    templateSetId: templateSetId
  });

  const putTemplateBidUrl = PutTemplateBidUrl({
    bidType: bidType,
    bidCategory: bidCategory,
    duid: duid,
    templateSetId: templateSetId
  });

  const deleteTemplateBidUrl = DeleteTemplateBidUrl({
    bidType: bidType,
    bidCategory: bidCategory,
    duid: duid,
    templateSetId: templateSetId
  });
  //region Get offers
  const [offers, setOffers] = useState({});
  const {
    sendRequest: templatesSendRequest,
    data: templatesData,
    apiStatus: templatesApiStatus
  } = useApi({ method: ApiMethodTypes.Get });

  //fetch template offer
  useEffect(
    () => {
      templatesSendRequest({
        url: GetTemplatesInaSetUrl({
          templateSetId: templateSetId
        })
      });
    },
    [templatesSendRequest, templateSetId]
  );

  //on update templates data
  useEffect(
    () => {
      if (templatesApiStatus === ApiStatus.Success && templatesData) {
        let dict = {};

        templatesData.forEach(row => {
          const duid = row[DBFields.Duid];
          const bidType = row[DBFields.BidType];
          const templateEditLink = InternalLinks.TemplateOffer({
            duid: duid,
            bidType: bidType,
            bidCategory: CalcBidCategoryFromBidType({
              bidType: bidType
            }),
            templateSetId: templateSetId
          });
          if (!dict[duid]) dict[duid] = {};
          if (!dict[duid][bidType]) dict[duid][bidType] = {};

          dict[duid][bidType] = {
            link: templateEditLink,
            metadata: row
          };
        });
        setOffers(dict);
      }
    },
    [templatesApiStatus, templatesData, templateSetId]
  );

  //endregion
  return (
    <AppContainer history={history} match={match}>
      <ApiStatusWrapper statuses={[templatesApiStatus]}>
        <Layout>
          <Layout.Sider width={180} collapsed={false}>
            <OffersMenu availableOffers={offers} selected={{ duid, bidType }} />
          </Layout.Sider>
          <Layout style={{ overflow: "hidden" }}>
            <Layout.Content style={{ background: "#fff", padding: "8px" }}>
              <Offer
                duid={duid}
                // bidSettlementDate={null}
                bidType={bidType}
                bidCategory={bidCategory}
                offerLabel={templateSetId}
                allowEdit={true}
                allowDelete={true}
                getOfferUrl={getTemplateBidUrl}
                putOfferUrl={putTemplateBidUrl}
                deleteOfferUrl={deleteTemplateBidUrl}
              />
            </Layout.Content>
          </Layout>
        </Layout>
      </ApiStatusWrapper>
    </AppContainer>
  );
};
