import { LogType } from "./LogType";

export const SpreadVolumeIntoBands = ({ bidPeriod, volume, bandIndexes }) => {
  let logs = [];
  logs.push({
    type: LogType.DEBUG,
    message: `Spreading volume in to bands`,
    code: JSON.stringify({
      volume,
      bandIndexes,
      bidPeriod
    })
  });
  if (bandIndexes.length === 0) {
    logs.push({
      type: LogType.WARNING,
      message: "No band indexes passed to spread volume, so ignoring the spread"
    });

    return bidPeriod;
  }

  const numberOfBands = bandIndexes.length;
  const bandVolumeEach = Math.floor(volume / numberOfBands);
  let remainingVolume = volume % numberOfBands;

  for (let i = 0; i < bandIndexes.length; i++) {
    const band = bandIndexes[i];
    let volumeToAssign = bandVolumeEach;
    if (remainingVolume > 0) {
      //assign extra 1MW if there is remainder of volume
      volumeToAssign = volumeToAssign + 1;
      remainingVolume = remainingVolume - 1;
    }
    const currentVolume = bidPeriod[band];
    //add to existing volume
    bidPeriod[band] = currentVolume + volumeToAssign;
  }
  logs.push({
    type: LogType.DEBUG,
    message: `Bid period after volume spread`,
    code: JSON.stringify({ bidPeriod })
  });
  return { logs, bidPeriod };
};
