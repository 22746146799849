import React from "react";
import { func, string } from "prop-types";
import { ButtonPrimary } from "../generic/buttons";
import { ApiStatusIcon } from "../ApiStatusIcon";

export const ButtonSave = ({ text, apiStatus, onClick, disabled }) => {
  return (
    <>
      <ButtonPrimary
        style={{ marginRight: "8px" }}
        onClick={onClick}
        disabled={disabled}
      >
        {text}
      </ButtonPrimary>
      <ApiStatusIcon apiStatus={apiStatus} />
    </>
  );
};

ButtonSave.propTypes = {
  text: string.isRequired,
  apiStatus: string,
  onClick: func.isRequired
};

ButtonSave.defaultProps = {
  text: "Save"
};
