export const Colors = {
  band1: "#5899DA",
  band2: "#E8743B",
  band3: "#19A979",
  band4: "#ED4A7B",
  band5: "#945ECF",
  band6: "#13A4B4",
  band7: "#525DF4",
  band8: "#BF399E",
  band9: "#EE6868",
  band10: "#6C8893",
  fixedLoad: "#555",
  maxAvail: "green",
  pasaAvail: "blue",
  rampUpRate: "yellow",
  rampDownRate: "orange",
  enablementMin: "red",
  enablementMax: "purple",
  lowBreakPoint: "yellow",
  highBreakPoint: "orange"
};
