import { TechParams } from "./TechParams";

export const TechParamLabels = {
  [TechParams.MaxCap]: "Max Capacity",
  [TechParams.MaxBidPrice]: "Max Bid Price",
  [TechParams.MinBidPrice]: "Min Bid Price",
  [TechParams.MLF]: "MLF",
  [TechParams.MaxRocUp]: "Max ROC Up",
  [TechParams.MaxRocDown]: "Max ROC Down",
  [TechParams.MaxUpperAngle]: "Max Upper Angle",
  [TechParams.MaxLowerAngle]: "Max Lower Angle",
  [TechParams.MaxEnablementMax]: "Max EnablementMax",
  [TechParams.MinEnablementMin]: "Min EnablementMin",
  [TechParams.DispatchType]: "Dispatch Type",
  [TechParams.StartType]: "Start Type",
  [TechParams.IsDailyEnergyConstraint]: "Is Daily Energy Constraint?"
};
