import React from "react";
import { object, string, func, any, arrayOf, shape } from "prop-types";
import { Radio } from "antd";

export const Switch = ({
  style,
  selectedValue,
  onChangeValue,
  items,
  buttonStyle,
  size
}) => {
  const onChange = e => {
    onChangeValue(e.target.value);
  };

  return (
    <div style={style}>
      <Radio.Group
        onChange={onChange}
        defaultValue={selectedValue}
        buttonStyle={buttonStyle}
        size={size}
      >
        {items.map(item => (
          <Radio.Button key={item.value} value={item.value}>
            {item.content}
          </Radio.Button>
        ))}
      </Radio.Group>
    </div>
  );
};

Switch.propTypes = {
  style: object,
  selectedValue: string.isRequired,
  onChangeValue: func.isRequired,
  items: arrayOf(
    shape({
      value: string.isRequired,
      content: any.isRequired
    }).isRequired
  ).isRequired,
  buttonStyle: string,
  size: string
};
