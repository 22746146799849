import React from "react";
import { string } from "prop-types";
import { Spinner } from "./Spinner";

export const PageSpinner = ({ text }) => (
  <div
    style={{
      position: "absolute",
      top: "45%",
      width: "100%",
      textAlign: "center"
    }}
  >
    <Spinner />
    <h4>{text}</h4>
  </div>
);

PageSpinner.propTypes = {
  text: string
};
