import React from "react";
import { object } from "prop-types";
import { ButtonPrimary } from "../generic";
import { InternalLinks } from "../../utils/constants";

export const ButtonLogin = ({ style }) => (
  <ButtonPrimary href={InternalLinks.Login} size={"large"} style={style}>
    Login
  </ButtonPrimary>
);

ButtonLogin.propTypes = {
  style: object
};
