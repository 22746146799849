import React from "react";
import { Result } from "antd";

import { ButtonBackHome } from "../components";

export const Forbidden = () => (
  <Result
    status={"403"}
    title={"403"}
    subTitle={"Sorry, you don't have permissions to perform this action"}
    extra={<ButtonBackHome />}
  />
);
