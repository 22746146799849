import React, { useState, useCallback, useEffect, useContext } from "react";
import { string, arrayOf, shape, func } from "prop-types";
import { InputSelect } from "../components";
import moment from "moment";
import { DatePicker, Space, Tag } from "antd";
import { CalcTimePeriodFromString } from "../utils/calc";
import { ThemeContext } from "../contexts";

export const DateRangeTimePeriodPicker = ({
  fromDateTime,
  timePeriodOptions,
  selectedTimePeriod,
  onChange,
  timePeriodProps
}) => {
  const [timePeriod, setTimePeriod] = useState(selectedTimePeriod);

  const [fromDate, setFromDate] = useState(fromDateTime);

  const onChangeTimePeriod = useCallback(value => {
    setTimePeriod(value);
  }, []);

  //on changing time period options, set the first option value selected as default option if saved time period does not exists in new options
  useEffect(
    () => {
      let isMatch = false;
      if (timePeriod && timePeriodOptions) {
        for (let i = 0; i < timePeriodOptions.length; i++) {
          if (timePeriodOptions[i].value === timePeriod) {
            isMatch = true;
            break;
          }
        }
      }

      if (!isMatch) {
        const defaultTimePeriod = timePeriodOptions
          ? timePeriodOptions[0].value
          : null;
        setTimePeriod(defaultTimePeriod);
      }
    },
    [timePeriodOptions, timePeriod]
  );

  const onChangeFromDate = useCallback(value => {
    setFromDate(value);
  }, []);

  const getMomentDateTime = useCallback((rawDt, rawDtInputFormat) => {
    const m_dt = moment(rawDt, rawDtInputFormat);
    //TODO add checks for invalid date etc, check for passed input format
    return m_dt;
  }, []);

  const getFormattedDateTime = useCallback(m_dt => {
    if (!m_dt) return m_dt;
    return m_dt.format("YYYY-MM-DD HH:mm:00");
  }, []);

  const getDateTimes = useCallback(
    (rawStartDateTime, rawStartDateTimeFormat, timePeriod, isAdd) => {
      //create a copy of moment date, otherwise will modify existing object
      let m_startDate = getMomentDateTime(
        rawStartDateTime,
        rawStartDateTimeFormat
      );
      let formattedStartDate = getFormattedDateTime(m_startDate);
      let formattedEndDate = "";
      const tp = CalcTimePeriodFromString(timePeriod);
      if (tp) {
        //modify start date
        if (isAdd === true) {
          m_startDate = m_startDate.add(tp.amount, tp.period);
        } else if (isAdd === false) {
          m_startDate = m_startDate.subtract(tp.amount, tp.period);
        }

        //format dates
        formattedStartDate = getFormattedDateTime(m_startDate);
        formattedEndDate = getFormattedDateTime(
          m_startDate.add(tp.amount, tp.period)
        );
      } else {
        console.error("TimePeriod not found", timePeriod);
      }

      return {
        startDateTime: formattedStartDate,
        endDateTime: formattedEndDate
      };
    },
    [getMomentDateTime, getFormattedDateTime]
  );

  //update to date
  useEffect(
    () => {
      const { startDateTime, endDateTime } = getDateTimes(
        fromDate,
        "YYYY-MM-DD HH:mm:00",
        timePeriod,
        null
      );
      onChange(startDateTime, endDateTime, timePeriod);
    },
    [fromDate, timePeriod, getDateTimes, onChange]
  );
  const onClickToday = useCallback(
    () => {
      const today = moment()
        .set("h", 4)
        .set("m", 0)
        .set("s", 0); //4AM today
      setFromDate(today);
    },
    [setFromDate]
  );
  const theme = useContext(ThemeContext);

  return (
    <Space size={"middle"}>
      <Space size={"small"}>
        From Date:
        <DatePicker
          showTime={{
            format: "HH:mm"
          }}
          format="YYYY-MM-DD HH:mm"
          minuteStep={5}
          onChange={onChangeFromDate}
          value={moment(fromDate, "YYYY-MM-DD HH:mm:00")}
          showNow={false}
          renderExtraFooter={() => (
            <Tag
              onClick={onClickToday}
              style={{ cursor: "pointer" }}
              color={theme.linkColor}
            >
              Today 4AM
            </Tag>
          )}
        />
      </Space>
      <Space size={"small"}>
        Time period:
        {timePeriodOptions && (
          <InputSelect
            value={timePeriod}
            options={timePeriodOptions}
            onChange={onChangeTimePeriod}
            {...timePeriodProps}
          />
        )}
      </Space>
    </Space>
  );
};

DateRangeTimePeriodPicker.propTypes = {
  fromDateTime: string.isRequired,
  selectedTimePeriod: string.isRequired,
  timePeriodOptions: arrayOf(
    shape({
      text: string.isRequired,
      value: string.isRequired
    })
  ).isRequired,
  onChange: func.isRequired
};
