//calc band 1 or band 10 automatically based on max mw and other bands, depends on if bands are reversed

import { ParseBandMW } from "../parsers";

export const CalcAutoBand = (
  maxBid,
  band1or10,
  band2,
  band3,
  band4,
  band5,
  band6,
  band7,
  band8,
  band9
) =>
  ParseBandMW(maxBid) -
  (ParseBandMW(band1or10) +
    ParseBandMW(band2) +
    ParseBandMW(band3) +
    ParseBandMW(band4) +
    ParseBandMW(band5) +
    ParseBandMW(band6) +
    ParseBandMW(band7) +
    ParseBandMW(band8) +
    ParseBandMW(band9));
