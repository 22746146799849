import React, { useState, useEffect, useContext, useCallback } from "react";
import PropTypes from "prop-types";
import moment from "moment";

import { Chart, ChartHelper } from "../generic";
import { ApiMethodTypes, useApi } from "../../hooks";
import { ParametersApi, ApiStatus, DBFields } from "../../utils";
import { ThemeContext } from "../../contexts";

const UPDATE_TIMER_INTERVAL = 5000; //10 secs
export const BidParamValueChart = ({
  duid,
  bidType,
  parameterId,
  chartWidth,
  chartHeight,
  timeStep,
  startTime,
  stopTime,
  refreshId,
  title,
  units
}) => {
  const theme = useContext(ThemeContext);

  const [series, setSeries] = useState([]);

  const [now, setNow] = useState(
    moment()
      .utc()
      .add(10, "hours")
  );
  const updateNow = useCallback(
    () => {
      setNow(
        moment()
          .utc()
          .add(10, "hours")
      ); //update candy cane now nem time
    },
    [setNow]
  );
  //start update timer
  useEffect(
    () => {
      const timer = setInterval(updateNow, UPDATE_TIMER_INTERVAL);
      return () => clearInterval(timer);
    },
    [updateNow]
  );

  const {
    sendRequest: bidParamValuesSendRequest,
    data: bidParamValuesData,
    apiStatus: cbidParamValuesApiStatus
  } = useApi({ method: ApiMethodTypes.Get });

  //fetch
  useEffect(
    () => {
      if (duid && bidType && parameterId && startTime && stopTime && timeStep)
        bidParamValuesSendRequest({
          url: ParametersApi.GetBidParamValuesComputedUrl({
            duid,
            bidType,
            parameterId,
            startTime,
            stopTime,
            timeStep
          })
        });
    },
    [
      bidParamValuesSendRequest,
      duid,
      bidType,
      parameterId,
      startTime,
      stopTime,
      timeStep,
      refreshId //force data refresh
    ]
  );

  //received response
  useEffect(
    () => {
      if (
        cbidParamValuesApiStatus === ApiStatus.Success &&
        bidParamValuesData
      ) {
        let avgValueSeriesData = [];
        let minValueSeriesData = [];
        let maxValueSeriesData = [];
        bidParamValuesData.forEach(row => {
          const dt = ChartHelper.formatDateTime(row[DBFields.SettlementDate]);
          avgValueSeriesData.push([dt, row[DBFields.AvgVal]]);

          minValueSeriesData.push([dt, row[DBFields.MinVal]]);
          maxValueSeriesData.push([dt, row[DBFields.MaxVal]]);
        });
        setSeries([
          {
            name: "Max Value",
            data: maxValueSeriesData,
            step: "right",
            color: "#888",
            lineWidth: 2,
            yAxis: 0
          },

          {
            name: "Min Value",
            data: minValueSeriesData,
            step: "right",
            color: "#333",
            lineWidth: 2,
            yAxis: 0
          },
          {
            name: "Avg Value",
            data: avgValueSeriesData,
            step: "right",
            color: theme.appMasterColor,
            lineWidth: 2,
            type: "area",
            yAxis: 0
          }
        ]);
      }
    },
    [bidParamValuesData, cbidParamValuesApiStatus, theme.appMasterColor]
  );

  return (
    <Chart
      chartWidth={chartWidth}
      chartHeight={chartHeight}
      title={title}
      subTitle={`${startTime} - ${stopTime}`}
      series={series}
      yAxis={[
        {
          title: { text: units ? units : "Value" }
        }
      ]}
      xAxis={[
        {
          type: "datetime",
          plotLines: [
            {
              value: ChartHelper.formatDateTime(
                now.format("YYYY-MM-DD HH:mm:ss")
              ),
              dashStyle: "dash",
              width: 2,
              color: "#000",
              label: { text: now.format("HH:mm") }
            }
          ]
        }
      ]}
    />
  );
};

BidParamValueChart.propTypes = {
  duid: PropTypes.string.isRequired,
  bidType: PropTypes.string.isRequired,
  parameterId: PropTypes.string.isRequired,
  chartWidth: PropTypes.number,
  chartHeight: PropTypes.number,
  timeStep: PropTypes.string.isRequired,
  startTime: PropTypes.string.isRequired,
  stopTime: PropTypes.string.isRequired,
  refreshId: PropTypes.string //used to force refresh of the chart
};

BidParamValueChart.defaultProps = {
  chartWidth: 1600,
  chartHeight: 900
};
