export const GridFields = {
  UserId: "UserId",
  UserDisplayName: "UserDisplayName",
  UserType: "UserType",
  UserEditLink: "UserEditLink",
  ExternalUserId: "ExternalUserId",
  RoleId: "RoleId",
  RoleName: "RoleName",
  Description: "Description",
  RoleEditLink: "RoleEditLink",
  LastModifiedAt: "LastModifiedAt",
  Inactive: "inactive",
  ParameterId: "ParameterId",
  ParameterName: "ParameterName",
  ParameterType: "ParameterType",
  ParameterLink: "ParameterLink",
  SettlementDate: "SettlementDate",
  Val: "Val",
  Priority: "Priority",
  LastModifiedBy: "LastModifiedBy",
  LastModifiedNotes: "LastModifiedNotes",
  SourceId: "SourceId",
  FromDateTime: "FromDateTime",
  ToDateTime: "ToDateTime",
  OutageLink: "OutageLink",
  OutageId: "OutageId",
  RebidReason: "RebidReason",
  DI: "DI",
  Duid: "Duid",
  BidType: "BidType",
  BidParamLink: "BidParamLink",
  GeneralParamLink: "GeneralParamLink",
  OutageParametersLink: "OutageParametersLink",
  OutageParameterLink: "OutageParameterLink",
  ParamValuesSparkline: "ParamValuesSparkline",
  Service: "service",
  RRP: "rrp",
  Revenue: "Revenue",
  FuelValue: "FuelValue",
  Amount: "Amount",
  EnergyRevenueImpact: "EnergyRevenueImpact",
  SingleValue: "SingleValue",
  SingleToDateTime: "SingleToDateTime",
  SingleLastModifiedBy: "SingleLastModifiedBy",
  SingleLastModifiedAt: "SingleLastModifiedAt",
  SubmittedBidSetViewLink: "SubmittedBidSetViewLink",
  LatestSubmittedAt: "latestSubmittedAt",
  LatestSubmittedStageId: "latestSubmittedStageId",
  LatestSubmittedStageIdStatusCombo: "latestSubmittedStageIdStatusCombo",
  LatestSubmittedStageStatus: "latestSubmittedStageStatus",
  LatestSubmittedStageStartedAt: "latestSubmittedStageStartedAt",
  LatestSubmittedStageFinishedAt: "latestSubmittedStageFinishedAt",
  AemoAckStatus: "aemoAckStatus",
  TemplateModifiedAtUriCombo: "templateModifiedAtUriCombo",
  TemplateOfferEditLink: "templateOfferEditLink",
  TemplateSetIdEditLink: "templateSetIdEditLink",
  SubmittedBidSetId: "submittedBidSetId",
  SubmittedBidOfferViewLink: "submittedBidOfferViewLink",
  SubmittedBy: "submittedBy",
  SubmittedAt: "submittedAt",
  RebidCategory: "rebidCategory",
  EventTime: "eventTime",
  AwareTime: "awareTime",
  DecisionTime: "decisionTime",
  EntryType: "EntryType",
  BidSettlementDate: "bidSettlementDate",
  LatestStageId: "latestStageId",
  LatestStatus: "latestStatus",
  TemplateSetName: "templateSetName",
  Owner: "owner",
  TemplateSetId: "templateSetId",
  FileURI: "fileURI",
  AemoTransactionId: "AemoTransactionId",
  AemoReferenceId: "AemoReferenceId",
  AemoOfferTimeStamp: "AemoOfferTimeStamp",
  AemoMethod: "AemoMethod",
  AemoAuthorisedBy: "AemoAuthorisedBy",
  AemoStatus: "AemoStatus",
  LoadedBy: "LoadedBy",
  LoadedAt: "LoadedAt",
  IsLoaded: "IsLoaded",
  AckSetLink: "AckSetLink",
  SolveId: "SolveId",
  SolveLink: "SolveLink",

  BidSource: "BidSource",
  PeriodId: "PeriodId",
  PeriodTime: "PeriodTime",
  PriceBand1: "PriceBand1",
  PriceBand2: "PriceBand2",
  PriceBand3: "PriceBand3",
  PriceBand4: "PriceBand4",
  PriceBand5: "PriceBand5",
  PriceBand6: "PriceBand6",
  PriceBand7: "PriceBand7",
  PriceBand8: "PriceBand8",
  PriceBand9: "PriceBand9",
  PriceBand10: "PriceBand10",
  Band1: "band1",
  Band2: "band2",
  Band3: "band3",
  Band4: "band4",
  Band5: "band5",
  Band6: "band6",
  Band7: "band7",
  Band8: "band8",
  Band9: "band9",
  Band10: "band10",
  FixedLoad: "FixedLoad",
  PasaAvail: "PasaAvail",
  RampUpRate: "RampUpRate",
  RampDownRate: "RampDownRate",
  MaxAvail: "MaxAvail",
  HighBreakPoint: "HighBreakPoint",
  LowBreakPoint: "LowBreakPoint",
  EnablementMax: "EnablementMax",
  EnablementMin: "EnablementMin",
  MaxCap: "maxcap",
  LimitVolume: "lv",
  TraderPrice1: "tp1",
  TraderPrice2: "tp2",
  TraderPrice3: "tp3",
  Utilisation: "util",
  MLF: "mlf",
  DeltaLimitVolume: "delta_lv",
  SRMC: "srmc",
  ForecastRRP: "frrp",
  BreakEvenRRP: "berrp",
  OptimalVolume: "ov",
  CurrentVolume: "cv",
  MinDV: "min_dv",
  MaxDV: "max_dv",
  P5RunDateTime: "p5_run_datetime",
  PdRunDateTime: "pd_run_datetime",
  AckSetOfferLink: "AckSetOfferLink",
  AlgoId: "AlgoId",
  RefBidAemoId: "RefBidAemoId",
  CurrentBidAemoId: "CurrentBidAemoId",
  Comments: "Comments",
  IsSentForSubmission: "IsSentForSubmission",
  SkippedReason: "SkippedReason",
  WasOptimised: "WasOptimised",
  HasChanged: "HasChanged",
  BandsMWChanged: "BandsMWChanged",
  ModifiedBidSource: "ModifiedBidSource",
  TotalBids: "TotalBids",
  SentForSubmissionBids: "SentForSubmissionBids",
  AutoStatus: "AutoStatus"
};
