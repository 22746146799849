import React from "react";
import { object, string } from "prop-types";
import TimeAgo from "react-timeago";

export const DisplayTimeAgo = ({ style, datetime }) => {
  return (
    <span style={style}>
      <TimeAgo date={datetime} />
    </span>
  );
};

DisplayTimeAgo.propTypes = {
  style: object,
  datetime: string.isRequired
};
