import React, { useCallback, useEffect, useState } from "react";
import {
  ApiStatusWrapper,
  AppContainer,
  DatePickerSettlementDate,
  Grid,
  GridCellLink,
  GridCellStyleAutoStatus,
  GridCellTime,
  GridCellTimeAgo,
  GridCellTypes
} from "../components";
import { Space } from "antd";
import moment from "moment";
import { ApiMethodTypes, useApi } from "../hooks";
import {
  ApiStatus,
  DBFields,
  GridFields,
  VofferApi,
  AuthApi,
  GridConfigDefault,
  TransformToMonitorDict,
  MonitorTables
} from "../utils";
import { useInterval } from "ahooks";
const GRID_HEIGHT_OFFSET = 225;
const MONITOR_INTERVAL = 5000;

export const AutoStatusHistory = ({ history, match }) => {
  //region Sett Date
  const [selectedDate, setSelectedDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  const onChangeSelectedDate = useCallback(
    date => {
      setSelectedDate(date);
    },
    [setSelectedDate]
  );
  //endregion

  //region User Map
  const {
    sendRequest: usersSendRequest,
    data: usersData,
    apiStatus: usersApiStatus
  } = useApi({ method: ApiMethodTypes.Get });
  const [userMappings, setUserMappings] = useState({});

  //fetch users
  useEffect(
    () => {
      usersSendRequest({ url: AuthApi.GetUsersUrl() });
    },
    [usersSendRequest]
  );
  //update users data
  useEffect(
    () => {
      if (usersApiStatus === ApiStatus.Success && usersData) {
        let userMappings = {};

        usersData.forEach(row => {
          userMappings[row[DBFields.UserId]] = row[DBFields.UserDisplayName];
        });
        setUserMappings(userMappings);
      }
    },
    [usersData, usersApiStatus]
  );

  //endregion

  //region Monitor
  const [autoStatusUpdateId, setAutoStatuspdateId] = useState();

  const {
    sendRequest: monitorSendRequest,
    data: monitorData,
    apiStatus: monitorApiStatus
  } = useApi({ method: ApiMethodTypes.Get });

  //fetch monitor
  const fetchMonitor = useCallback(
    () => {
      const url = VofferApi.GetMonitorTablesUrl();
      monitorSendRequest({ url: url });
    },
    [monitorSendRequest]
  );

  useInterval(
    () => {
      fetchMonitor();
    },
    MONITOR_INTERVAL,
    { immediate: true }
  );

  //update monitor data
  useEffect(
    () => {
      if (monitorApiStatus === ApiStatus.Success && monitorData) {
        // parse data
        let monitors = TransformToMonitorDict(monitorData);

        setAutoStatuspdateId(monitors[MonitorTables.AutoStatus]);
      }
    },
    [monitorData, monitorApiStatus]
  );

  //endregion

  //region Auto Status History
  const {
    sendRequest: autoStatusHistorySendRequest,
    data: autoStatusHistoryData,
    apiStatus: autoStatusHistoryApiStatus
  } = useApi({ method: ApiMethodTypes.Get });

  //fetch autoStatusHistory
  useEffect(
    () => {
      if (
        autoStatusUpdateId &&
        selectedDate &&
        selectedDate !== "Invalid date"
      ) {
        autoStatusHistorySendRequest({
          url: VofferApi.GetAutoStatusHistoryUrl({ switchedAt: selectedDate })
        });
      }
    },
    [autoStatusHistorySendRequest, autoStatusUpdateId, selectedDate]
  );

  useEffect(
    () => {
      if (
        autoStatusHistoryApiStatus === ApiStatus.Success &&
        autoStatusHistoryData
      ) {
        const newRows = autoStatusHistoryData.map(row => ({
          [GridFields.Duid]: row[DBFields.Duid],
          [GridFields.BidType]: row[DBFields.BidType],
          [GridFields.AutoStatus]: row[DBFields.AutoStatus],
          [GridFields.LastModifiedAt]: row[DBFields.LastModifiedAt],
          [GridFields.LastModifiedBy]: row[DBFields.LastModifiedBy],
          [GridFields.LastModifiedNotes]: row[DBFields.LastModifiedNotes]
        }));
        setRowData(newRows);
      }
    },
    [autoStatusHistoryApiStatus, autoStatusHistoryData]
  );

  //endregion

  //region Grid
  const [gridHeight, setGridHeight] = useState(600);
  const [rowData, setRowData] = useState([]);
  const [columnDefs, setColumnDefs] = useState([]);
  useEffect(
    () => {
      const cols = [
        {
          headerName: "Grouped",
          field: GridFields.LastModifiedAt,
          rowGroupIndex: 0,
          hide: true,
          valueFormatter: params => {
            return params && params.value
              ? moment(params.value).format("HH:mm:ss")
              : null;
          }
        },
        {
          headerName: "DUID",
          field: GridFields.Duid,
          floatingFilter: true,
          filter: "agSetColumnFilter",
          filterParams: { excelMode: "windows" },
          width: 100
        },
        {
          headerName: "Bid Type",
          field: GridFields.BidType,
          floatingFilter: true,
          filter: "agSetColumnFilter",
          filterParams: { excelMode: "windows" },
          width: 120
        },
        {
          headerName: "Mode",
          field: GridFields.AutoStatus,
          width: 75,
          floatingFilter: true,
          filter: "agSetColumnFilter",
          filterParams: { excelMode: "windows" },
          cellStyle: GridCellStyleAutoStatus
        },
        {
          headerName: "Switched At",
          field: GridFields.LastModifiedAt,

          // rowGroupIndex: 0,
          cellRenderer: GridCellTypes.GridCellTimeAgo,
          width: 120
        },
        {
          headerName: "Switched By",
          field: GridFields.LastModifiedBy,
          floatingFilter: true,
          filter: "agSetColumnFilter",
          filterParams: { excelMode: "windows" },
          refData: userMappings,
          width: 120
        },
        {
          headerName: "Notes",
          field: GridFields.LastModifiedNotes,
          width: 500
        }
      ];
      setColumnDefs(cols);
    },
    [setColumnDefs, userMappings]
  );

  const onGridReady = useCallback(params => {
    // setGridApi(params.api);
    // params.api.sizeColumnsToFit();
  }, []);

  //once on mount
  useEffect(() => {
    setGridHeight(window.innerHeight - GRID_HEIGHT_OFFSET);
  }, []);

  const getRowNodeId = useCallback(data => {
    return data[GridFields.AemoTransactionId];
  }, []);
  //endregion

  return (
    <AppContainer history={history} match={match}>
      <Space>
        <label>Switched date:</label>
        <DatePickerSettlementDate
          onChange={onChangeSelectedDate}
          value={selectedDate}
        />
      </Space>

      <ApiStatusWrapper
        style={{ position: "absolute", top: "49%", left: "49%", zIndex: 10 }}
        statuses={[autoStatusHistoryApiStatus]}
      />
      <ApiStatusWrapper statuses={[usersApiStatus]}>
        <Grid
          autoGroupColumnDef={{
            headerName: "Switched At",
            width: 160
          }}
          gridHeight={gridHeight}
          defaultColDef={GridConfigDefault}
          columnDefs={columnDefs}
          rowData={rowData}
          suppressCellSelection={true}
          suppressContextMenu={true}
          suppressRowClickSelection={true}
          enableCellChangeFlash={true}
          onGridReady={onGridReady}
          getRowNodeId={getRowNodeId}
          groupDefaultExpanded={-1}
          frameworkComponents={{
            GridCellTimeAgo: GridCellTimeAgo,
            GridCellTime: GridCellTime,
            GridCellLink: GridCellLink
          }}
        />
      </ApiStatusWrapper>
    </AppContainer>
  );
};
