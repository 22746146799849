import React, { useCallback, useState, useEffect } from "react";
import { arrayOf, object, shape, string, any, number } from "prop-types";

import "./actionChanges.css";

import { GridDuidFields } from "../../grid/grid_duid/GridDuidFields";
import { GetDuidBidTypeKey, GridConfigDefault } from "../../../utils";
import { Grid, GridCellTimeAgo, GridCellValidation } from "../../grid";

export const ActionChanges = ({ style, changes, cols, gridHeight }) => {
  const [columnDefs, setColumnDefs] = useState([]);
  const [rowData] = useState(changes);
  const [gridApi, setGridApi] = useState();

  const getRowNodeId = useCallback(data => {
    let duid = data[GridDuidFields.Duid];
    let bidType = data[GridDuidFields.BidType];

    return GetDuidBidTypeKey(duid, bidType);
  }, []);

  const onGridReady = useCallback(params => {
    setGridApi(params.api);
    // params.api.sizeColumnsToFit();

    // this.gridColumnApi = params.columnApi;
  }, []);

  const updateGridData = useCallback(
    ({ gridFields, dataDict }) => {
      let newRows = [];

      //update grid
      if (gridApi) {
        gridApi.forEachNode(node => {
          if (node && node.group === false && node.data) {
            const nodeId = node.id;

            const rowData = dataDict[nodeId];

            let data = node.data; //copy data
            gridFields.forEach(gf => {
              let value = ""; //default set fields to null, to empty string as ag recommends
              if (rowData) {
                value = rowData[gf];
              }
              data[gf] = value;
            });
            newRows.push(data); //only need to send data changes, not whole row node
          }
        });
      }
      if (gridApi) gridApi.applyTransaction({ update: newRows });
    },
    [gridApi]
  );

  useEffect(
    () => {
      let colDefs = [];
      cols.forEach(c => {
        colDefs.push({
          ...c
        });
      });

      setColumnDefs(colDefs);
    },
    [cols]
  );

  useEffect(
    () => {
      if (changes) {
        let dict = {};
        changes.forEach(row => {
          const rowKey = GetDuidBidTypeKey(
            row[GridDuidFields.Duid],
            row[GridDuidFields.BidType]
          );
          dict[rowKey] = row;
        });
        updateGridData({ gridFields: cols.map(c => c.field), dataDict: dict });
      }
    },
    [changes, updateGridData, cols]
  );

  return (
    <div style={style}>
      <Grid
        statusBar={{
          statusPanels: [
            {
              statusPanel: "agTotalRowCountComponent",
              align: "left"
            }
          ]
        }}
        autoGroupColumnDef={{
          headerName: "Target",
          field: GridDuidFields.BidType,
          width: 200,
          suppressSizeToFit: true
        }}
        gridHeight={gridHeight}
        //domLayout={"autoHeight"}
        groupDefaultExpanded={-1}
        defaultColDef={GridConfigDefault}
        columnDefs={columnDefs}
        rowData={rowData}
        enableRangeSelection={false}
        enableRangeHandle={false}
        enableCharts={false}
        suppressCellSelection={true}
        suppressContextMenu={true}
        suppressRowClickSelection={true}
        rowMultiSelectWithClick={true}
        getRowNodeId={getRowNodeId}
        onGridReady={onGridReady}
        frameworkComponents={{
          GridCellTimeAgo: GridCellTimeAgo,
          GridCellValidation: GridCellValidation
        }}
      />
    </div>
  );
};

ActionChanges.propTypes = {
  style: object,
  changes: arrayOf(
    shape({
      duid: string.isRequired,
      bid_type: string.isRequired,
      change: any,
      status: string.isRequired
    })
  ),
  cols: arrayOf(
    shape({
      field: string.isRequired,
      label: string.isRequired,
      format: string
    })
  ).isRequired,
  gridHeight: number
};

ActionChanges.defaultProps = {
  gridHeight: 500
};
