import React from "react";
import { object, string, oneOf } from "prop-types";
import { Alert } from "antd";
import { ValidationMessageTypeValues } from "../offer_table/utils/config";

export const ValidationMessage = ({
  style,
  fieldLabel,
  messageType,
  message
}) => {
  let msg = "";
  if (fieldLabel) msg += `${fieldLabel}: `;
  if (message) msg += message;
  return (
    <Alert
      style={{
        marginBottom: "4px",
        background: "transparent",
        border: 0,
        paddingBottom: 0,
        ...style
      }}
      type={messageType.toLowerCase()}
      showIcon={true}
      //description={message}
      message={msg}
    />
  );
};

ValidationMessage.propTypes = {
  style: object,
  messageType: oneOf(ValidationMessageTypeValues).isRequired,
  message: string.isRequired,
  fieldLabel: string
};
