import React from "react";
import { string, bool, func, number, array } from "prop-types";
import { FormItemInputNumber } from "../index";

export const InputControlTtime = ({
  value,
  label,
  validations,
  disabled,
  onChange
}) => (
  <FormItemInputNumber
    value={value}
    label={label}
    suffix={"mins"}
    validations={validations}
    required={true}
    disabled={disabled}
    inputStyle={{ width: "50%" }}
    onChange={onChange}
  />
);

InputControlTtime.propTypes = {
  value: number.isRequired,
  label: string.isRequired,
  validations: array,
  disabled: bool,
  onChange: func
};
