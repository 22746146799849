import React from "react";
import { object } from "prop-types";
import { PageSpinner } from "../components/generic/spinners";

import queryString from "query-string";

export const Callback = ({ history, auth }) => {
  const parsed = queryString.parse(history.location.search);

  auth.callback(parsed.access_token);
  return <PageSpinner text={"Processing Login..."} />;
};

Callback.propTypes = {
  auth: object
};
