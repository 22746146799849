import React from "react";

const Colors = {
  White: "#fff",
  DarkGray: "#444",
  BrandDarkBlue: "#143660",
  BrandBlueGray: "#c5d2de",
  BrandStoneBlue: "#7396bf",
  BrandTeal: "#13a89e",
  BrandGreen: "#8dc63f",
  AppLight: "#efded9",
  AppMaster: "#e16c46",
  AppDark: "#ad541e",
  Error: "#f5222d",
  Success: "#52c41a",
  Warning: "#faad14"
};

export const ThemeIds = {
  Light: "light",
  Dark: "dark"
};

export const themes = {
  [ThemeIds.Light]: {
    // appColor: "#e16c46",
    // appColorLight: "#efded9",
    // appColorDark: "#ad541e",
    // linkColor: "#13a89e",
    // titleColor: "#143660",
    // backgroundColor: "#7396bf",
    // backgroundColorDark: "#143660",
    // backgroundColorLight: "#c5d2de",
    // contrastColor: "#8dc63f",
    appMasterColor: Colors.AppMaster,
    appBackgroundColor: Colors.White,
    drawerBackgroundColor: Colors.White,
    drawerHeaderBackgroundColor: Colors.AppLight,
    drawerHeaderTitleColor: Colors.BrandDarkBlue,
    breadCrumbBackgroundColor: Colors.AppLight,
    containerBackgroundColor: Colors.White,
    titleColor: Colors.AppDark,
    borderColorSubtle: "#ddd",
    siderBackgroundColor: Colors.BrandDarkBlue,
    secondSiderBackgroundColor: Colors.AppLight,
    errorColor: Colors.Error,
    warningColor: Colors.Warning,
    successColor: Colors.Success,
    algoBackgroundColor: Colors.AppLight,
    manualBackgroundColor: Colors.BrandBlueGray,
    negativeValueBackgroundColor: "#ffb1b1"
  },
  [ThemeIds.Dark]: {
    appMasterColor: Colors.AppMaster,
    appBackgroundColor: Colors.DarkGray,
    drawerBackgroundColor: Colors.DarkGray,
    drawerHeaderBackgroundColor: Colors.BrandDarkBlue,
    drawerHeaderTitleColor: Colors.AppLight,
    breadCrumbBackgroundColor: Colors.AppLight,
    containerBackgroundColor: Colors.White,
    titleColor: Colors.White,
    borderColorSubtle: "#aaa",
    siderBackgroundColor: Colors.BrandDarkBlue,
    secondSiderBackgroundColor: Colors.BrandStoneBlue,

    errorColor: Colors.Error,
    warningColor: Colors.Warning,
    successColor: Colors.Success,
    algoBackgroundColor: Colors.AppLight
  }
};

export const getThemeById = themeId => themes[themeId];

export const ThemeContext = React.createContext(getThemeById(ThemeIds.Light));

export const ThemeProvider = ThemeContext.Provider;
export const ThemeConsumer = ThemeContext.Consumer;
