import React, { useCallback, useEffect, useState } from "react";
import {
  ApiStatusWrapper,
  AppContainer,
  Grid,
  GridCellLink,
  GridCellTime,
  GridCellTimeAgo,
  GridCellTypes
} from "../components";
import {
  ApiStatus,
  AuthApi,
  CalcBidCategoryFromBidType,
  DBFields,
  GridConfigDefault,
  GridFields,
  InternalLinks,
  MonitorTables,
  TransformToMonitorDict,
  VofferApi
} from "../utils";
import { ApiMethodTypes, useApi } from "../hooks";
import { useInterval } from "ahooks";
const GRID_HEIGHT_OFFSET = 200;
const MONITOR_INTERVAL = 5000;

export const AckSet = ({ history, match }) => {
  const { aemoTransactionId } = match.params;

  //region User Map
  const {
    sendRequest: usersSendRequest,
    data: usersData,
    apiStatus: usersApiStatus
  } = useApi({ method: ApiMethodTypes.Get });
  const [userMappings, setUserMappings] = useState({});

  //fetch users
  useEffect(
    () => {
      usersSendRequest({ url: AuthApi.GetUsersUrl() });
    },
    [usersSendRequest]
  );
  //update users data
  useEffect(
    () => {
      if (usersApiStatus === ApiStatus.Success && usersData) {
        let userMappings = {};

        usersData.forEach(row => {
          userMappings[row[DBFields.UserId]] = row[DBFields.UserDisplayName];
        });
        setUserMappings(userMappings);
      }
    },
    [usersData, usersApiStatus]
  );

  //endregion

  //region Monitor
  const [ackSetUpdateId, setAckSetUpdateId] = useState();

  const {
    sendRequest: monitorSendRequest,
    data: monitorData,
    apiStatus: monitorApiStatus
  } = useApi({ method: ApiMethodTypes.Get });

  //fetch monitor
  const fetchMonitor = useCallback(
    () => {
      const url = VofferApi.GetMonitorTablesUrl();
      monitorSendRequest({ url: url });
    },
    [monitorSendRequest]
  );

  useInterval(
    () => {
      fetchMonitor();
    },
    MONITOR_INTERVAL,
    { immediate: true }
  );

  //update monitor data
  useEffect(
    () => {
      if (monitorApiStatus === ApiStatus.Success && monitorData) {
        // parse data
        let monitors = TransformToMonitorDict(monitorData);

        setAckSetUpdateId(monitors[MonitorTables.AckOffers]);
      }
    },
    [monitorData, monitorApiStatus]
  );

  //endregion

  //region Ack set
  const {
    sendRequest: ackSetSendRequest,
    data: ackSetData,
    apiStatus: ackSetApiStatus
  } = useApi({ method: ApiMethodTypes.Get });

  //fetch ack set
  useEffect(
    () => {
      if (ackSetUpdateId)
        ackSetSendRequest({
          url: VofferApi.GetAckSetById({ aemoTransactionId })
        });
    },
    [ackSetSendRequest, ackSetUpdateId, aemoTransactionId]
  );

  useEffect(
    () => {
      if (ackSetApiStatus === ApiStatus.Success && ackSetData) {
        const newRows = ackSetData.map(row => ({
          [GridFields.BidSettlementDate]: row[DBFields.BidSettlementDate],
          [GridFields.Duid]: row[DBFields.Duid],
          [GridFields.BidType]: row[DBFields.BidType],
          [GridFields.EntryType]: row[DBFields.AemoEntryType],
          [GridFields.RebidCategory]: row[DBFields.AemoRebidCategory],
          [GridFields.RebidReason]: row[DBFields.AemoRebidReason],
          [GridFields.EventTime]: row[DBFields.AemoRebidEventTime],
          [GridFields.AwareTime]: row[DBFields.AemoRebidAwareTime],
          [GridFields.DecisionTime]: row[DBFields.AemoRebidDecisionTime],
          [GridFields.IsLoaded]: row[DBFields.IsLoaded],
          [GridFields.LoadedBy]: row[DBFields.LoadedBy],
          [GridFields.LoadedAt]: row[DBFields.LoadedAt],
          [GridFields.AckSetOfferLink]: InternalLinks.AckSetOffer({
            bidSettlementDate: row[DBFields.BidSettlementDate],
            bidCategory: CalcBidCategoryFromBidType({
              bidType: row[DBFields.BidType]
            }),
            bidType: row[DBFields.BidType],
            duid: row[DBFields.Duid],
            aemoTransactionId: aemoTransactionId
          })
        }));
        setRowData(newRows);
      }
    },
    [ackSetApiStatus, ackSetData, aemoTransactionId]
  );

  //endregion

  //region Grid
  const [gridHeight, setGridHeight] = useState(600);
  const [rowData, setRowData] = useState([]);
  const [columnDefs, setColumnDefs] = useState([]);

  const cellRenderParamsOffer = useCallback(params => {
    let value = null;
    let link = null;
    if (params.data) {
      value = params.data[GridFields.BidType];
      link = params.data[GridFields.AckSetOfferLink];
    }

    return {
      value: value,
      link: link
    };
  }, []);

  useEffect(
    () => {
      const cols = [
        {
          headerName: "Bid Settlement Date",
          field: GridFields.BidSettlementDate,
          width: 120,
          rowGroupIndex: 0,
          hide: true
        },
        {
          headerName: "DUID",
          field: GridFields.Duid,
          width: 120,
          rowGroupIndex: 1,
          hide: true
        },
        {
          headerName: "Bid Type",
          field: GridFields.BidType,
          width: 120,
          cellRenderer: GridCellTypes.GridCellLink,
          cellRendererParams: params => cellRenderParamsOffer(params)
        },
        {
          headerName: "Entry Type",
          field: GridFields.EntryType,
          width: 120
        },
        {
          headerName: "Category",
          field: GridFields.RebidCategory,
          width: 120
        },
        {
          headerName: "Rebid Reason",
          field: GridFields.RebidReason,
          width: 250
        },
        {
          headerName: "Event Time",
          field: GridFields.EventTime,
          width: 120
        },
        {
          headerName: "Aware Time",
          field: GridFields.AwareTime,
          width: 120
        },
        {
          headerName: "Decision Time",
          field: GridFields.DecisionTime,
          width: 120
        },
        {
          headerName: "Is Loaded",
          field: GridFields.IsLoaded,
          width: 120
        },
        {
          headerName: "Loaded By",
          field: GridFields.LoadedBy,
          refData: userMappings,
          width: 120
        },
        {
          headerName: "Loaded At",
          field: GridFields.LoadedAt,
          cellRenderer: GridCellTypes.GridCellTimeAgo,
          width: 120
        }
      ];
      setColumnDefs(cols);
    },
    [setColumnDefs, cellRenderParamsOffer, userMappings]
  );

  const onGridReady = useCallback(params => {
    // setGridApi(params.api);
    // params.api.sizeColumnsToFit();
  }, []);

  //once on mount
  useEffect(() => {
    setGridHeight(window.innerHeight - GRID_HEIGHT_OFFSET);
  }, []);

  const getRowNodeId = useCallback(data => {
    return `${data[GridFields.Duid]}_${data[GridFields.BidType]}_${
      data[GridFields.BidSettlementDate]
    }`;
  }, []);
  //endregion

  return (
    <AppContainer history={history} match={match}>
      <ApiStatusWrapper
        style={{ position: "absolute", top: "49%", left: "49%", zIndex: 10 }}
        statuses={[ackSetApiStatus]}
      />
      <ApiStatusWrapper statuses={[usersApiStatus]}>
        <Grid
          gridHeight={gridHeight}
          defaultColDef={GridConfigDefault}
          columnDefs={columnDefs}
          groupDefaultExpanded={-1}
          rowData={rowData}
          suppressCellSelection={true}
          suppressContextMenu={false}
          suppressRowClickSelection={false}
          enableCellChangeFlash={true}
          onGridReady={onGridReady}
          getRowNodeId={getRowNodeId}
          frameworkComponents={{
            GridCellTimeAgo: GridCellTimeAgo,
            GridCellTime: GridCellTime,
            GridCellLink: GridCellLink
          }}
        />
      </ApiStatusWrapper>
    </AppContainer>
  );
};
