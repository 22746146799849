//returns price bands in order
import { NumberOfBands } from "../config";
import { CalcPriceBandIndex } from "./CalcPriceBandIndex";

export const CalcPriceBandOrder = isReverse => {
  let bands = [];
  for (let i = 0; i < NumberOfBands; i++) {
    let bandId = CalcPriceBandIndex(i, isReverse);
    bands.push(`priceBand${bandId}`);
  }
  return bands;
};
