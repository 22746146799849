import React, { useEffect, useState } from "react";
import { string, shape, object } from "prop-types";
import { Menu } from "antd";
import { ApiStatus, DBFields } from "../../utils";
import { ApiMethodTypes, useApi } from "../../hooks";
import { NavLink } from "react-router-dom";
import { VofferApi } from "../../utils";
const { SubMenu } = Menu;
export const OffersMenu = ({ selected, availableOffers }) => {
  //region data
  const [menuItems, setMenuItems] = useState({});
  const {
    sendRequest: enabledDuidsSendRequest,
    data: enabledDuidsData,
    apiStatus: enabledDuidsApiStatus
  } = useApi({ method: ApiMethodTypes.Get });

  //fetch enabledDuids
  useEffect(
    () => {
      enabledDuidsSendRequest({ url: VofferApi.GetEnabledDuidBidTypesUrl() });
    },
    [enabledDuidsSendRequest]
  );

  //update enabled duids data
  useEffect(
    () => {
      if (enabledDuidsApiStatus === ApiStatus.Success && enabledDuidsData) {
        let dict = {};

        enabledDuidsData.forEach(row => {
          const duid = row[DBFields.Duid];
          if (!dict[duid]) dict[duid] = [];
          dict[duid].push(row[DBFields.BidType]);
        });
        setMenuItems(dict);
      }
    },
    [enabledDuidsData, enabledDuidsApiStatus]
  );
  //endregion

  const renderMenuItem = ({ duid, bidType }) => {
    //find matching in availableOffers
    let match;
    if (
      availableOffers &&
      availableOffers[duid] &&
      availableOffers[duid][bidType]
    )
      match = availableOffers[duid][bidType];
    return (
      <Menu.Item key={GetMenuKey({ duid, bidType })} disabled={!match}>
        {match ? (
          <NavLink to={match.link}>{bidType}</NavLink>
        ) : (
          <span>{bidType}</span>
        )}
      </Menu.Item>
    );
  };

  return (
    <Menu
      mode="inline"
      defaultSelectedKeys={[
        GetMenuKey({ duid: selected.duid, bidType: selected.bidType })
      ]}
      defaultOpenKeys={[selected.duid]}
      style={{
        height: "100%"
      }}
    >
      {Object.keys(menuItems).map(duid => (
        <SubMenu key={duid} title={duid}>
          {menuItems[duid].map(bidType => renderMenuItem({ duid, bidType }))}
        </SubMenu>
      ))}
    </Menu>
  );
};

const GetMenuKey = ({ duid, bidType }) => `${duid}.${bidType}`;

OffersMenu.propTypes = {
  selected: shape({
    duid: string.isRequired,
    bidType: string.isRequired
  }).isRequired,
  availableOffers: object.isRequired
};
