import { ValidationMessageType } from "../enums";

//constructs a validation message
export const ValidationMessageCompareGreaterThanEqual = ({
  messageType = ValidationMessageType.Error,
  fieldLabel,
  compareFieldLabel,
  fieldValue = null,
  compareFieldValue = null
}) => ({
  messageType: messageType,
  message: `${fieldLabel} ${
    fieldValue !== null ? `(${fieldValue})` : ""
  } should be greater than or equal to ${compareFieldLabel} ${
    compareFieldValue !== null ? `(${compareFieldValue})` : ""
  } `
});
