import {
  ValidationMessagePositive,
  ValidationMessageRequired
} from "../validation_messages";
import { CheckValuePositive, CheckValueRequired } from "../checks";
import { ValidationMessageType } from "../enums";

export const ValidateTtimes = value => {
  let validations = [];

  //Validate: value is required
  const check1 = CheckValueRequired(value);
  if (!check1)
    validations.push(ValidationMessageRequired(ValidationMessageType.Error));

  //Validate:  value is positive number
  const check2 = CheckValuePositive(value);
  if (!check2)
    validations.push(ValidationMessagePositive(ValidationMessageType.Error));

  return validations;
};
