import { GetDuidBidTypeKey } from "./GetDuidBidTypeKey";

export const TransformToDictByDuidBidType = data => {
  let dict = {};
  data.forEach(item => {
    const key = GetDuidBidTypeKey(item.duid, item.bid_type);
    dict[key] = item;
  });

  return dict;
};
