import React from "react";
import { Result } from "antd";

import { ButtonBackHome } from "../components";

export const NotFound = () => (
  <Result
    status={"404"}
    title={"404"}
    subTitle={"Sorry, the page you visited does not exist."}
    extra={<ButtonBackHome />}
  />
);
