import React, { useCallback, useEffect, useState } from "react";
import {
    ApiStatusWrapper,
    AppContainer,
    DatePickerSettlementDate,
    Grid,
    GridCellLink,
    GridCellTime,
    GridCellTimeAgo, GridCellTypes,
} from "../components";
import { Space } from "antd";
import moment from "moment";
import { ApiMethodTypes, useApi } from "../hooks";
import {
    ApiStatus,
    DBFields,
    GridFields,
    VofferApi,
    AuthApi,
    GridConfigDefault,
    TransformToMonitorDict,
    MonitorTables, InternalLinks, CalcBidCategoryFromBidType
} from "../utils";
import { useInterval } from "ahooks";
const GRID_HEIGHT_OFFSET = 225;
const MONITOR_INTERVAL = 5000;

export const DispatchTimesHistory = ({ history, match }) => {
    //region Sett Date
    const [selectedDate, setSelectedDate] = useState(
        moment().format("YYYY-MM-DD")
    );
    const onChangeSelectedDate = useCallback(
        date => {
            setSelectedDate(date);
        },
        [setSelectedDate]
    );
    //endregion

    //region User Map
    const {
        sendRequest: usersSendRequest,
        data: usersData,
        apiStatus: usersApiStatus
    } = useApi({ method: ApiMethodTypes.Get });
    const [userMappings, setUserMappings] = useState({});

    //fetch users
    useEffect(
        () => {
            usersSendRequest({ url: AuthApi.GetUsersUrl() });
        },
        [usersSendRequest]
    );
    //update users data
    useEffect(
        () => {
            if (usersApiStatus === ApiStatus.Success && usersData) {
                let userMappings = {};

                usersData.forEach(row => {
                    userMappings[row[DBFields.UserId]] = row[DBFields.UserDisplayName];
                });
                setUserMappings(userMappings);
            }
        },
        [usersData, usersApiStatus]
    );

    //endregion

    //region Monitor
    const [ackOffersUpdateId, setAckOffersUpdateId] = useState();
    const [ackOffersSetUpdateId, setAckOffersSetUpdateId] = useState();
    const {
        sendRequest: monitorSendRequest,
        data: monitorData,
        apiStatus: monitorApiStatus
    } = useApi({ method: ApiMethodTypes.Get });

    //fetch monitor
    const fetchMonitor = useCallback(
        () => {
            const url = VofferApi.GetMonitorTablesUrl();
            monitorSendRequest({ url: url });
        },
        [monitorSendRequest]
    );

    useInterval(
        () => {
            fetchMonitor();
        },
        MONITOR_INTERVAL,
        { immediate: true }
    );

    //update monitor data
    useEffect(
        () => {
            if (monitorApiStatus === ApiStatus.Success && monitorData) {
                // parse data
                let monitors = TransformToMonitorDict(monitorData);

                setAckOffersUpdateId(monitors[MonitorTables.AckOffers]);
                setAckOffersSetUpdateId(monitors[MonitorTables.AckOffersSet]);
            }
        },
        [monitorData, monitorApiStatus]
    );

    //endregion

    //region ack metadata dispatch  times
    const {
        sendRequest: ackMetadataHistorySendRequest,
        data: ackMetadataHistoryData,
        apiStatus: ackMetadataHistoryApiStatus
    } = useApi({ method: ApiMethodTypes.Get });

    //fetch autoStatusHistory
    useEffect(
        () => {
            if (
                ackOffersUpdateId &&
                ackOffersSetUpdateId &&
                selectedDate &&
                selectedDate !== "Invalid date"
            ) {
                ackMetadataHistorySendRequest({
                    url: VofferApi.GetAckMetadataDispatchTimesUrl({ bidSettlementDate: selectedDate })
                });
            }
        },
        [ackMetadataHistorySendRequest, ackOffersUpdateId, ackOffersSetUpdateId, selectedDate]
    );

    useEffect(
        () => {
            if (
                ackMetadataHistoryApiStatus === ApiStatus.Success &&
                ackMetadataHistoryData
            ) {
                const newRows = ackMetadataHistoryData.map(row => ({
                    [GridFields.DI]: row[DBFields.DI],
                    [GridFields.Duid]: row[DBFields.Duid],
                    [GridFields.BidType]: row[DBFields.BidType],
                    [GridFields.AemoOfferTimeStamp]:  row[DBFields.AemoOfferTimeStamp]
                        ? row[DBFields.AemoOfferTimeStamp]
                            .replace("Z", "")
                            .replace("T", " ")
                        : row[DBFields.AemoOfferTimeStamp],
                    [GridFields.AemoTransactionId]: row[DBFields.AemoTransactionId],
                    [GridFields.AckSetOfferLink]: InternalLinks.AckSetOffer({
                        bidSettlementDate: selectedDate,
                        bidCategory: CalcBidCategoryFromBidType({
                            bidType: row[DBFields.BidType]
                        }),
                        bidType: row[DBFields.BidType],
                        duid: row[DBFields.Duid],
                        aemoTransactionId: row[DBFields.AemoTransactionId]
                    }),
                    [GridFields.SolveId]: row[DBFields.SolveId],
                    [GridFields.SolveLink]:  InternalLinks.Solve({
                        solveId: row[DBFields.SolveId],
                        bidSettlementDate: selectedDate
                    }),
                }));
                setRowData(newRows);
            }
        },
        [ackMetadataHistoryApiStatus, ackMetadataHistoryData, selectedDate]
    );

    //endregion

    //region Grid
    const [gridHeight, setGridHeight] = useState(600);
    const [rowData, setRowData] = useState([]);
    const [columnDefs, setColumnDefs] = useState([]);
    useEffect(
        () => {
            const cols = [
                {
                    headerName: "Grouped",
                    field: GridFields.DI,
                    rowGroupIndex: 0,
                    hide: true,

                    valueFormatter: params => {
                        return params && params.value
                            ? moment(params.value).utc().format("HH:mm:ss")//utc
                            : null;
                    }
                },
                {
                    headerName: "DUID",
                    field: GridFields.Duid,
                    floatingFilter: true,
                    filter: "agSetColumnFilter",
                    filterParams: { excelMode: "windows" },
                    width: 100
                },
                {
                    headerName: "Bid Type",
                    field: GridFields.BidType,
                    floatingFilter: true,
                    filter: "agSetColumnFilter",
                    filterParams: { excelMode: "windows" },
                    width: 120
                },
                {
                    headerName: "AEMO Offer Timestamp",
                    field: GridFields.AemoOfferTimeStamp,
                    width: 175,
                    floatingFilter: true,
                    filter: "agSetColumnFilter",
                    filterParams: { excelMode: "windows" },
                },
                {
                    headerName: "AEMO Transaction Id",
                    field: GridFields.AemoTransactionId,
                    width: 270,
                    floatingFilter: true,
                    filter: "agSetColumnFilter",
                    filterParams: { excelMode: "windows" },
                    cellRenderer: GridCellTypes.GridCellLink,
                    cellRendererParams: params =>
                        params.data
                            ? {
                                link: params.data[GridFields.AckSetOfferLink],
                                openInNewTab: true
                            }
                            : null
                },{
                    headerName: "Solve Id",
                    field: GridFields.SolveId,
                    width: 270,
                    floatingFilter: true,
                    filter: "agSetColumnFilter",
                    filterParams: { excelMode: "windows" },
                    cellRenderer: GridCellTypes.GridCellLink,
                    cellRendererParams: params =>
                        params.data
                            ? {
                                link: params.data[GridFields.SolveLink],
                                openInNewTab: true
                            }
                            : null
                },

            ];
            setColumnDefs(cols);
        },
        [setColumnDefs, userMappings]
    );

    const onGridReady = useCallback(params => {
        // setGridApi(params.api);
        // params.api.sizeColumnsToFit();
    }, []);

    //once on mount
    useEffect(() => {
        setGridHeight(window.innerHeight - GRID_HEIGHT_OFFSET);
    }, []);

    const getRowNodeId = useCallback(data => {
        return data[GridFields.AemoTransactionId];
    }, []);
    //endregion

    return (
        <AppContainer history={history} match={match}>
            <Space>
                <label>Settlement date:</label>
                <DatePickerSettlementDate
                    onChange={onChangeSelectedDate}
                    value={selectedDate}
                />
            </Space>

            <ApiStatusWrapper
                style={{ position: "absolute", top: "49%", left: "49%", zIndex: 10 }}
                statuses={[ackMetadataHistoryApiStatus]}
            />
            <ApiStatusWrapper statuses={[usersApiStatus]}>
                <Grid
                    autoGroupColumnDef={{
                        headerName: "DI",
                        width: 160
                    }}
                    gridHeight={gridHeight}
                    defaultColDef={GridConfigDefault}
                    columnDefs={columnDefs}
                    rowData={rowData}
                    suppressCellSelection={true}
                    suppressContextMenu={true}
                    suppressRowClickSelection={true}
                    enableCellChangeFlash={true}
                    onGridReady={onGridReady}
                    getRowNodeId={getRowNodeId}
                    groupDefaultExpanded={-1}
                    frameworkComponents={{
                        GridCellTimeAgo: GridCellTimeAgo,
                        GridCellTime: GridCellTime,
                        GridCellLink: GridCellLink
                    }}
                />
            </ApiStatusWrapper>
        </AppContainer>
    );
};
