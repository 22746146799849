import React, { useContext } from "react";
import { string } from "prop-types";
import { ApiStatus } from "../utils/constants";
import { ThemeContext } from "../contexts";
import {
  CheckCircleOutlined,
  LoadingOutlined,
  WarningOutlined
} from "@ant-design/icons";

export const ApiStatusIcon = React.memo(({ apiStatus }) => {
  const theme = useContext(ThemeContext);

  switch (apiStatus) {
    case ApiStatus.Success:
      return <CheckCircleOutlined style={{ color: theme.successColor }} />;
    case ApiStatus.Loading:
      return <LoadingOutlined spin style={{ color: theme.appMasterColor }} />;

    case ApiStatus.Failed:
      return <WarningOutlined style={{ color: theme.errorColor }} />;
    default:
      return null;
    //do nothing
  }
});

ApiStatusIcon.propTypes = {
  apiStatus: string
};
