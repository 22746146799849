import React from "react";
import { Result } from "antd";

import { ButtonLogin } from "../components";

export const LoggedOut = () => (
  <Result
    status={"success"}
    title={"Logged Out"}
    subTitle={"You have been logged out from this app."}
    extra={<ButtonLogin />}
  />
);
