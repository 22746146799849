export const CalcTimePeriodFromString = timePeriodString => {
  if (!timePeriodString) return null;
  const arr = timePeriodString.split(" ");
  if (arr.length !== 2) {
    console.error(timePeriodString, " timePeriodString not supported");
    return null;
  }
  const amount = arr[0];
  const period = arr[1];
  if (["ms", "s", "m", "h", "d", "w", "M", "Q", "y", "Y"].indexOf(period) < 0) {
    console.error(period, " period not valid");
    return null;
  }

  return { amount: amount, period: period };
};
