import React from "react";
import { object, any } from "prop-types";
import { Button } from "antd";

///Primary style button
export const ButtonPrimary = props => (
  <Button type={"primary"} {...props}>
    {props.children}
  </Button>
);

ButtonPrimary.propTypes = {
  children: any,
  style: object
};
