import React from "react";
import { string } from "prop-types";

import { DisplayTimeAgo } from "../../generic";

export const GridCellTimeAgo = React.memo(({ value }) => {
  return <span>{value ? <DisplayTimeAgo datetime={value} /> : value}</span>;
});

GridCellTimeAgo.propTypes = {
  value: string
};
