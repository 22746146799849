import React from "react";
import { object, any } from "prop-types";
import { Button } from "antd";

///Secondary style button
export const ButtonSecondary = props => (
  <Button type={"default"} {...props}>
    {props.children}
  </Button>
);

ButtonSecondary.propTypes = {
  style: object,
  children: any
};
