import React, { useCallback, useState } from "react";
import { TemplateSetInfo } from "../components";
import { DBFields, InternalLinks, VofferApi } from "../utils";
import { Redirect } from "react-router-dom";
import { AppContainer } from "../components";
import { ApiMethodTypes } from "../hooks";
import { Col, Row } from "antd";

const POST_URL = VofferApi.PostTemplateSetNew();

export const TemplateSetNew = ({ history, match }) => {
  const [templateSetId, setTemplateSetId] = useState("");

  const onSaveSuccess = useCallback(
    ({ data }) => {
      setTemplateSetId(data[DBFields.TemplateSetId]);
    },
    [setTemplateSetId]
  );

  return (
    <AppContainer history={history} match={match}>
      {templateSetId && (
        <Redirect
          to={InternalLinks.TemplateSet({ templateSetId: templateSetId })}
        />
      )}
      <Row gutter={8}>
        <Col md={8}>
          <TemplateSetInfo
            submitUrl={POST_URL}
            submitUrlMethodType={ApiMethodTypes.Post}
            onSaveSuccess={onSaveSuccess}
          />
        </Col>
      </Row>
    </AppContainer>
  );
};
