import React from "react";
import { AppContainer, BidParams } from "../components";
import { BidParamCategory } from "../utils";

export const StandingParams = ({ history, match }) => {
  return (
    <AppContainer history={history} match={match}>
      <BidParams categoryId={BidParamCategory.Standing} />
    </AppContainer>
  );
};
