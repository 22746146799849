import React, { useContext, useState, useEffect } from "react";
import { Layout } from "antd";
import {
  ApiStatusWrapper,
  AppContainer,
  Offer,
  OffersMenu
} from "../components";

import { AppContext } from "../contexts";
import { ApiMethodTypes, useApi } from "../hooks";
import {
  ApiStatus,
  DBFields,
  InternalLinks,
  VofferApi,
  CalcBidCategoryFromBidType
} from "../utils";

const {
  DeleteDraftBidUrl,
  GetDraftBidUrl,
  GetDraftsUrl,
  PutDraftBidUrl
} = VofferApi;

export const DraftOffer = ({ history, match }) => {
  const { bidType, bidCategory, duid, bidSettlementDate } = match.params;

  const { loggedInUserId } = useContext(AppContext);

  const putDraftBidUrl = PutDraftBidUrl({
    bidType: bidType,
    bidCategory: bidCategory,
    duid: duid,
    bidSettlementDate: bidSettlementDate,
    user: loggedInUserId
  });

  const deleteDraftBidUrl = DeleteDraftBidUrl({
    bidType: bidType,
    bidCategory: bidCategory,
    duid: duid,
    bidSettlementDate: bidSettlementDate,
    user: loggedInUserId
  });

  const getDraftBidUrl = GetDraftBidUrl({
    bidType: bidType,
    bidCategory: bidCategory,
    duid: duid,
    bidSettlementDate: bidSettlementDate,
    user: loggedInUserId
  });

  //region Get offers
  const [offers, setOffers] = useState({});
  const {
    sendRequest: draftsSendRequest,
    data: draftsData,
    apiStatus: draftsApiStatus
  } = useApi({ method: ApiMethodTypes.Get });

  //fetch drafts
  useEffect(
    () => {
      draftsSendRequest({
        url: GetDraftsUrl({
          bidSettlementDate: bidSettlementDate,
          user: loggedInUserId
        })
      });
    },
    [loggedInUserId, bidSettlementDate, draftsSendRequest]
  );

  //on update drafts data
  useEffect(
    () => {
      if (draftsApiStatus === ApiStatus.Success && draftsData) {
        let dict = {};
        draftsData.forEach(row => {
          const duid = row[DBFields.Duid];
          const bidType = row[DBFields.BidType];
          const draftEditLink = InternalLinks.DraftOffer({
            duid: duid,
            bidType: bidType,
            bidCategory: CalcBidCategoryFromBidType({
              bidType: bidType
            }),
            bidSettlementDate: bidSettlementDate
          });
          if (!dict[duid]) dict[duid] = {};
          if (!dict[duid][bidType]) dict[duid][bidType] = {};

          dict[duid][bidType] = {
            link: draftEditLink,
            metadata: row
          };
        });
        setOffers(dict);
      }
    },
    [draftsData, draftsApiStatus, bidSettlementDate]
  );
  //endregion

  return (
    <AppContainer history={history} match={match}>
      <ApiStatusWrapper statuses={[draftsApiStatus]}>
        <Layout>
          <Layout.Sider width={180} collapsed={false}>
            <OffersMenu availableOffers={offers} selected={{ duid, bidType }} />
          </Layout.Sider>
          <Layout style={{ overflow: "hidden" }}>
            <Layout.Content style={{ background: "#fff", padding: "8px" }}>
              <Offer
                duid={duid}
                bidSettlementDate={bidSettlementDate}
                bidType={bidType}
                bidCategory={bidCategory}
                offerLabel={bidSettlementDate}
                allowEdit={true}
                allowDelete={true}
                getOfferUrl={getDraftBidUrl}
                putOfferUrl={putDraftBidUrl}
                deleteOfferUrl={deleteDraftBidUrl}
              />
            </Layout.Content>
          </Layout>
        </Layout>
      </ApiStatusWrapper>
    </AppContainer>
  );
};
