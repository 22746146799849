import { LogType } from "./LogType";

export const FindBandsInPriceRange = ({
  bidPrices,
  minBandPrice,
  maxBandPrice
}) => {
  let logs = [];
  if (
    bidPrices === null ||
    minBandPrice === null ||
    maxBandPrice === null ||
    maxBandPrice < minBandPrice
  )
    logs.push({
      type: LogType.ERROR,
      message: `Invalid values passed to findBandsInPriceRange`,
      code: JSON.stringify({
        minBandPrice,
        maxBandPrice,
        bidPrices
      })
    });

  logs.push({
    type: LogType.DEBUG,
    message: `Finding bands in price range`,
    code: JSON.stringify({
      minBandPrice,
      maxBandPrice,
      bidPrices
    })
  });
  let selectedBandIndexes = [];
  for (let i = 1; i <= 10; i++) {
    const price = bidPrices[`priceBand${i}`];
    if (minBandPrice <= price && price <= maxBandPrice)
      selectedBandIndexes.push(`band${i}`);
  }
  if (selectedBandIndexes.length > 0) {
    logs.push({
      type: LogType.DEBUG,
      message: `Found bands in price range`,
      code: JSON.stringify({
        selectedBandIndexes
      })
    });
  } else
    logs.push({
      type: LogType.WARNING,
      message: `Unable to find bands in price range`
    });
  return { logs, selectedBandIndexes };
};
