import React, { useState, useCallback, useEffect } from "react";
import {
  ApiStatusWrapper,
  AppContainer,
  DatePickerSettlementDate,
  Grid,
  GridCellLink,
  GridCellTime,
  GridCellTimeAgo,
  InputSelect,
  SolveResultChart
} from "../components";
import { ApiMethodTypes, useApi } from "../hooks";
import {
  ApiStatus,
  DBFields,
  GridConfigDefault,
  GridFields,
  VadvisorApi,
  VofferApi
} from "../utils";
import { ColorsCell } from "../components/offer_table/utils/colors";
import { Card, Space } from "antd";
import { BidType } from "../components/offer_table/utils/enums";
import moment from "moment";
const GRID_HEIGHT_OFFSET = 360;

export const SolveResultComposite = ({ history, match }) => {
  const [containerSize, setContainerSize] = useState({
    width: 1000,
    height: 1000
  });

  //region Sett Date
  const [bidSettlementDate, setBidSettlementDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  const onChangeBidSettlementDate = useCallback(
    date => {
      setBidSettlementDate(moment(date).format("YYYY-MM-DD"));
    },
    [setBidSettlementDate]
  );
  //endregion

  //region Solves Result Composite
  const {
    sendRequest: solveResultSendRequest,
    data: solveResultData,
    apiStatus: solveResultApiStatus
  } = useApi({ method: ApiMethodTypes.Get });

  //fetch solve result composite by day
  useEffect(() => {
    solveResultSendRequest({
      url: VadvisorApi.GetSolvesCompositeResult({
        bidSettlementDateStr: bidSettlementDate.replace(/-/g, "")
      })
    });
  }, [solveResultSendRequest, bidSettlementDate]);

  useEffect(() => {
    if (solveResultApiStatus === ApiStatus.Success && solveResultData) {
      const newRows = solveResultData.map(row => ({
        [GridFields.Duid]: row[DBFields.Duid],
        [GridFields.BidType]: row[DBFields.BidType],
        [GridFields.BidSource]: row[DBFields.BidSource],
        [GridFields.PeriodId]: row[DBFields.PeriodId],
        [GridFields.PeriodTime]: row[DBFields.PeriodTime],
        [GridFields.PriceBand1]: row[DBFields.PriceBand1],
        [GridFields.PriceBand2]: row[DBFields.PriceBand2],
        [GridFields.PriceBand3]: row[DBFields.PriceBand3],
        [GridFields.PriceBand4]: row[DBFields.PriceBand4],
        [GridFields.PriceBand5]: row[DBFields.PriceBand5],
        [GridFields.PriceBand6]: row[DBFields.PriceBand6],
        [GridFields.PriceBand7]: row[DBFields.PriceBand7],
        [GridFields.PriceBand8]: row[DBFields.PriceBand8],
        [GridFields.PriceBand9]: row[DBFields.PriceBand9],
        [GridFields.PriceBand10]: row[DBFields.PriceBand10],
        [GridFields.Band1]: row[DBFields.Band1],
        [GridFields.Band2]: row[DBFields.Band2],
        [GridFields.Band3]: row[DBFields.Band3],
        [GridFields.Band4]: row[DBFields.Band4],
        [GridFields.Band5]: row[DBFields.Band5],
        [GridFields.Band6]: row[DBFields.Band6],
        [GridFields.Band7]: row[DBFields.Band7],
        [GridFields.Band8]: row[DBFields.Band8],
        [GridFields.Band9]: row[DBFields.Band9],
        [GridFields.Band10]: row[DBFields.Band10],
        [GridFields.MaxAvail]: row[DBFields.MaxAvail],
        [GridFields.PasaAvail]: row[DBFields.PasaAvail],
        [GridFields.RampDownRate]: row[DBFields.RampDownRate],
        [GridFields.RampUpRate]: row[DBFields.RampUpRate],
        [GridFields.FixedLoad]: row[DBFields.FixedLoad],
        [GridFields.EnablementMax]: row[DBFields.EnablementMax],
        [GridFields.EnablementMin]: row[DBFields.EnablementMin],
        [GridFields.HighBreakPoint]: row[DBFields.HighBreakPoint],
        [GridFields.LowBreakPoint]: row[DBFields.LowBreakPoint],
        // [GridFields.MaxCap]: row[DBFields.MaxCap],
        // [GridFields.LimitVolume]: row[DBFields.LimitVolume],
        // [GridFields.TraderPrice1]: row[DBFields.TraderPrice1],
        // [GridFields.TraderPrice2]: row[DBFields.TraderPrice2],
        // [GridFields.TraderPrice3]: row[DBFields.TraderPrice3],
        // [GridFields.Utilisation]: row[DBFields.Utilisation],
        // [GridFields.MLF]: row[DBFields.MLF],
        // [GridFields.DeltaLimitVolume]: row[DBFields.DeltaLimitVolume],
        // [GridFields.SRMC]: row[DBFields.SRMC],
        [GridFields.ForecastRRP]: row[DBFields.ForecastRRP],
        [GridFields.BreakEvenRRP]: row[DBFields.BreakEvenRRP],
        [GridFields.OptimalVolume]: row[DBFields.OptimalVolume]
        // [GridFields.CurrentVolume]: row[DBFields.CurrentVolume],
        // [GridFields.MinDV]: row[DBFields.MinDV],
        // [GridFields.MaxDV]: row[DBFields.MaxDV],
        // [GridFields.P5RunDateTime]: row[DBFields.P5RunDateTime],
        // [GridFields.PdRunDateTime]: row[DBFields.PdRunDateTime],
        // [GridFields.HasChanged]: row[DBFields.HasChanged],
        // [GridFields.BandsMWChanged]: row[DBFields.BandsMWChanged],
        // [GridFields.ModifiedBidSource]: row[DBFields.ModifiedBidSource],
        // [GridFields.WasOptimised]: row[DBFields.WasOptimised]
      }));
      setRowData(newRows);
    }
  }, [solveResultApiStatus, solveResultData]);

  //endregion
  //region Grid
  const [gridHeight, setGridHeight] = useState(600);
  const [rowData, setRowData] = useState([]);
  const [columnDefs, setColumnDefs] = useState([]);
  useEffect(() => {
    const cols = [
      {
        headerName: "Period",
        pinned: true,
        children: [
          {
            headerName: "Id",
            field: GridFields.PeriodId,
            // filter: true,
            // floatingFilter: true, //TODO order value by number than text
            type: "numericColumn",
            width: 80,
            pinned: true,
            columnGroupShow: "open"
          },
          {
            headerName: "Time",
            field: GridFields.PeriodTime,
            pinned: true,
            valueFormatter: params =>
              params.data[GridFields.PeriodTime]
                ? params.data[GridFields.PeriodTime].slice(0, 16)
                : null,
            //columnGroupShow: "open",
            floatingFilter: true,
            filter: "agSetColumnFilter",
            filterParams: { excelMode: "windows" },
            width: 120
          }
        ]
      },
      {
        headerName: "Source",
        filter: true,
        floatingFilter: true,
        pinned: true,
        children: [
          {
            headerName: "Duid",
            field: GridFields.Duid,
            floatingFilter: true,
            filter: "agSetColumnFilter",
            filterParams: { excelMode: "windows" },
            width: 100,
            pinned: true
          },
          {
            headerName: "Bid Type",
            field: GridFields.BidType,
            floatingFilter: true,
            filter: "agSetColumnFilter",
            filterParams: { excelMode: "windows" },
            width: 110,
            pinned: true
          }
          // {
          //   headerName: "Source",
          //   headerTooltip: "Bid Source",
          //   field: GridFields.BidSource,
          //   floatingFilter: true,
          //   filter: "agSetColumnFilter",
          //   columnGroupShow: "open",
          //   filterParams: { excelMode: "windows" },
          //   width: 90,
          //   pinned: true
          // }
        ]
      },
      {
        headerName: "Solver Input",
        children: [
          // {
          //   headerName: "P5 RunDT",
          //   headerTooltip: "P5min Run DateTime",
          //   field: GridFields.P5RunDateTime,
          //   width: 180,
          //   columnGroupShow: "open",
          //   floatingFilter: true,
          //   filter: "agSetColumnFilter",
          //   filterParams: { excelMode: "windows" }
          // },
          // {
          //   headerName: "PD RunDT",
          //   headerTooltip: "Predispatch Run DateTime",
          //   field: GridFields.PdRunDateTime,
          //   width: 180,
          //   columnGroupShow: "open",
          //   floatingFilter: true,
          //   filter: "agSetColumnFilter",
          //   filterParams: { excelMode: "windows" }
          // },

          {
            headerName: "FRRP",
            headerTooltip: "Forecast RRP",
            field: GridFields.ForecastRRP,
            width: 100,
            floatingFilter: true,
            filter: "agSetColumnFilter",
            filterParams: { excelMode: "windows" }
          }
          // {
          //   headerName: "SRMC",
          //   headerTooltip: "Short Run Marginal Cost",
          //   field: GridFields.SRMC,
          //   width: 80,
          //   columnGroupShow: "open",
          //   floatingFilter: true,
          //   filter: "agSetColumnFilter",
          //   filterParams: { excelMode: "windows" }
          // },
          // {
          //   headerName: "Util",
          //   headerTooltip: "Utilisation",
          //   field: GridFields.Utilisation,
          //   width: 80,
          //   columnGroupShow: "open",
          //   floatingFilter: true,
          //   filter: "agSetColumnFilter",
          //   filterParams: { excelMode: "windows" }
          // },
          //
          // {
          //   headerName: "Min DV",
          //   headerTooltip: "Minimum Discretionary Volume",
          //   field: GridFields.MinDV,
          //   width: 80,
          //   floatingFilter: true,
          //   filter: "agSetColumnFilter",
          //   filterParams: { excelMode: "windows" }
          // },
          // {
          //   headerName: "CV",
          //   headerTooltip: "Current Volume",
          //   field: GridFields.CurrentVolume,
          //   width: 80,
          //   floatingFilter: true,
          //   filter: "agSetColumnFilter",
          //   filterParams: { excelMode: "windows" }
          // },
          // {
          //   headerName: "Max DV",
          //   headerTooltip: "Maximum Discretionary Volume",
          //   field: GridFields.MaxDV,
          //   width: 80,
          //   floatingFilter: true,
          //   filter: "agSetColumnFilter",
          //   filterParams: { excelMode: "windows" }
          // },
          // {
          //   headerName: "dLV",
          //   headerTooltip: "Delta Limit Volume",
          //   field: GridFields.DeltaLimitVolume,
          //   width: 80,
          //   columnGroupShow: "open",
          //   floatingFilter: true,
          //   filter: "agSetColumnFilter",
          //   filterParams: { excelMode: "windows" }
          // },
          // {
          //   headerName: "LV",
          //   headerTooltip: "Limit Volume",
          //   field: GridFields.LimitVolume,
          //   width: 80,
          //   columnGroupShow: "open",
          //   floatingFilter: true,
          //   filter: "agSetColumnFilter",
          //   filterParams: { excelMode: "windows" }
          // },
          // {
          //   headerName: "TP1",
          //   headerTooltip: "Trader Price 1",
          //   field: GridFields.TraderPrice1,
          //   width: 80,
          //   columnGroupShow: "open",
          //   floatingFilter: true,
          //   filter: "agSetColumnFilter",
          //   filterParams: { excelMode: "windows" }
          // },
          // {
          //   headerName: "TP2",
          //   headerTooltip: "Trader Price 2",
          //   field: GridFields.TraderPrice2,
          //   width: 80,
          //   columnGroupShow: "open",
          //   floatingFilter: true,
          //   filter: "agSetColumnFilter",
          //   filterParams: { excelMode: "windows" }
          // },
          // {
          //   headerName: "TP3",
          //   headerTooltip: "Trader Price 3",
          //   field: GridFields.TraderPrice3,
          //   width: 80,
          //   columnGroupShow: "open",
          //   floatingFilter: true,
          //   filter: "agSetColumnFilter",
          //   filterParams: { excelMode: "windows" }
          // },
          // {
          //   headerName: "Cap",
          //   headerTooltip: "Max Capacity",
          //   field: GridFields.MaxCap,
          //   width: 80,
          //   columnGroupShow: "open",
          //   floatingFilter: true,
          //   filter: "agSetColumnFilter",
          //   filterParams: { excelMode: "windows" }
          // },
          // {
          //   headerName: "MLF",
          //   headerTooltip: "Marginal Loss Factor",
          //   field: GridFields.MLF,
          //   width: 80,
          //   columnGroupShow: "open",
          //   floatingFilter: true,
          //   filter: "agSetColumnFilter",
          //   filterParams: { excelMode: "windows" }
          // }
        ]
      },
      {
        headerName: "Solver Output",
        children: [
          {
            headerName: "OV",
            headerTooltip: "Optimal Volume",
            field: GridFields.OptimalVolume,
            width: 80,
            floatingFilter: true,
            filter: "agSetColumnFilter",
            filterParams: { excelMode: "windows" }
          },
          {
            headerName: "BERRP",
            headerTooltip: "Break Even RRP",
            field: GridFields.BreakEvenRRP,
            width: 80,
            floatingFilter: true,
            filter: "agSetColumnFilter",
            filterParams: { excelMode: "windows" }
          }
          // {
          //   headerName: "Optimised?",
          //   headerTooltip: "Was Period Optimised?",
          //   field: GridFields.WasOptimised,
          //   width: 100,
          //   floatingFilter: true,
          //   filter: "agSetColumnFilter",
          //   filterParams: { excelMode: "windows" }
          // },
          // {
          //   headerName: "Changed?",
          //   headerTooltip: "Has Period Changed?",
          //   field: GridFields.HasChanged,
          //   width: 100,
          //   columnGroupShow: "open",
          //   floatingFilter: true,
          //   filter: "agSetColumnFilter",
          //   filterParams: { excelMode: "windows" }
          // },
          // {
          //   headerName: "MW Change",
          //   headerTooltip: "Bands MW Changed",
          //   field: GridFields.BandsMWChanged,
          //   width: 100,
          //   floatingFilter: true,
          //   filter: "agSetColumnFilter",
          //   filterParams: { excelMode: "windows" }
          // },
          // {
          //   headerName: "Source for Modified Bid",
          //   headerTooltip: "Source for Modified Bid",
          //   field: GridFields.ModifiedBidSource,
          //   width: 100,
          //   columnGroupShow: "open",
          //   floatingFilter: true,
          //   filter: "agSetColumnFilter",
          //   filterParams: { excelMode: "windows" }
          // }
        ]
      },
      {
        headerName: "Physical",
        children: [
          {
            headerName: "MaxAvail",
            headerTooltip: "Max Avail",

            field: GridFields.MaxAvail,
            width: 100,
            floatingFilter: true,
            filter: "agSetColumnFilter",
            filterParams: { excelMode: "windows" }
          },
          {
            headerName: "PasaAvail",
            headerTooltip: "Pasa Avail",
            field: GridFields.PasaAvail,
            width: 100,
            floatingFilter: true,
            filter: "agSetColumnFilter",
            filterParams: { excelMode: "windows" },
            columnGroupShow: "open"
          },
          {
            headerName: "RampUp",
            headerTooltip: "Ramp Up Rate",
            field: GridFields.RampUpRate,
            width: 100,
            floatingFilter: true,
            filter: "agSetColumnFilter",
            filterParams: { excelMode: "windows" },
            columnGroupShow: "open"
          },
          {
            headerName: "RampDown",
            headerTooltip: "Ramp Down Rate",
            field: GridFields.RampDownRate,
            width: 100,
            floatingFilter: true,
            filter: "agSetColumnFilter",
            filterParams: { excelMode: "windows" },
            columnGroupShow: "open"
          },
          {
            headerName: "EnabMin",
            headerTooltip: "Enablement Min",
            field: GridFields.EnablementMin,
            width: 100,
            floatingFilter: true,
            filter: "agSetColumnFilter",
            filterParams: { excelMode: "windows" },
            columnGroupShow: "open"
          },
          {
            headerName: "LowBP",
            headerTooltip: "Low Break Point",
            field: GridFields.LowBreakPoint,
            width: 100,
            floatingFilter: true,
            filter: "agSetColumnFilter",
            filterParams: { excelMode: "windows" },
            columnGroupShow: "open"
          },
          {
            headerName: "HighBP",
            headerTooltip: "High Break Point",
            field: GridFields.HighBreakPoint,
            width: 100,
            floatingFilter: true,
            filter: "agSetColumnFilter",
            filterParams: { excelMode: "windows" },
            columnGroupShow: "open"
          },
          {
            headerName: "EnabMax",
            headerTooltip: "Enablement Max",
            field: GridFields.EnablementMax,
            width: 100,
            floatingFilter: true,
            filter: "agSetColumnFilter",
            filterParams: { excelMode: "windows" },
            columnGroupShow: "open"
          },
          {
            headerName: "FixedLoad",
            headerTooltip: "Fixed Load",
            field: GridFields.FixedLoad,
            width: 100,
            floatingFilter: true,
            filter: "agSetColumnFilter",
            filterParams: { excelMode: "windows" },
            columnGroupShow: "open"
          }
        ]
      },
      {
        headerName: "Bid Financial",
        children: [
          {
            headerName: "Band 1",
            field: GridFields.Band1,
            width: 100,
            floatingFilter: true,
            filter: "agSetColumnFilter",
            filterParams: { excelMode: "windows" }
          },
          {
            headerName: "Band 2",
            field: GridFields.Band2,
            width: 100,
            floatingFilter: true,
            filter: "agSetColumnFilter",
            filterParams: { excelMode: "windows" }
          },
          {
            headerName: "Band 3",
            field: GridFields.Band3,
            width: 100,
            floatingFilter: true,
            filter: "agSetColumnFilter",
            filterParams: { excelMode: "windows" }
          },
          {
            headerName: "Band 4",
            field: GridFields.Band4,
            width: 100,
            floatingFilter: true,
            filter: "agSetColumnFilter",
            filterParams: { excelMode: "windows" }
          },
          {
            headerName: "Band 5",
            field: GridFields.Band5,
            width: 100,
            floatingFilter: true,
            filter: "agSetColumnFilter",
            filterParams: { excelMode: "windows" }
          },
          {
            headerName: "Band 6",
            field: GridFields.Band6,
            width: 100,
            floatingFilter: true,
            filter: "agSetColumnFilter",
            filterParams: { excelMode: "windows" }
          },
          {
            headerName: "Band 7",
            field: GridFields.Band7,
            width: 100,
            floatingFilter: true,
            filter: "agSetColumnFilter",
            filterParams: { excelMode: "windows" }
          },
          {
            headerName: "Band 8",
            field: GridFields.Band8,
            width: 100,
            floatingFilter: true,
            filter: "agSetColumnFilter",
            filterParams: { excelMode: "windows" }
          },
          {
            headerName: "Band 9",
            field: GridFields.Band9,
            width: 100,
            floatingFilter: true,
            filter: "agSetColumnFilter",
            filterParams: { excelMode: "windows" }
          },
          {
            headerName: "Band 10",
            field: GridFields.Band10,
            width: 100,
            floatingFilter: true,
            filter: "agSetColumnFilter",
            filterParams: { excelMode: "windows" }
          },
          {
            headerName: "PB1",
            headerTooltip: "Price Band 1",
            field: GridFields.PriceBand1,
            width: 100,
            floatingFilter: true,
            filter: "agSetColumnFilter",
            filterParams: { excelMode: "windows" },
            columnGroupShow: "open"
          },
          {
            headerName: "PB2",
            headerTooltip: "Price Band 2",
            field: GridFields.PriceBand2,
            width: 100,
            floatingFilter: true,
            filter: "agSetColumnFilter",
            filterParams: { excelMode: "windows" },
            columnGroupShow: "open"
          },
          {
            headerName: "PB3",
            headerTooltip: "Price Band 3",
            field: GridFields.PriceBand3,
            width: 100,
            floatingFilter: true,
            filter: "agSetColumnFilter",
            filterParams: { excelMode: "windows" },
            columnGroupShow: "open"
          },
          {
            headerName: "PB4",
            headerTooltip: "Price Band 4",
            field: GridFields.PriceBand4,
            width: 100,
            floatingFilter: true,
            filter: "agSetColumnFilter",
            filterParams: { excelMode: "windows" },
            columnGroupShow: "open"
          },
          {
            headerName: "PB5",
            headerTooltip: "Price Band 5",
            field: GridFields.PriceBand5,
            width: 100,
            floatingFilter: true,
            filter: "agSetColumnFilter",
            filterParams: { excelMode: "windows" },
            columnGroupShow: "open"
          },
          {
            headerName: "PB6",
            headerTooltip: "Price Band 6",
            field: GridFields.PriceBand6,
            width: 100,
            floatingFilter: true,
            filter: "agSetColumnFilter",
            filterParams: { excelMode: "windows" },
            columnGroupShow: "open"
          },
          {
            headerName: "PB7",
            headerTooltip: "Price Band 7",
            field: GridFields.PriceBand7,
            width: 100,
            floatingFilter: true,
            filter: "agSetColumnFilter",
            filterParams: { excelMode: "windows" },
            columnGroupShow: "open"
          },
          {
            headerName: "PB8",
            headerTooltip: "Price Band 8",
            field: GridFields.PriceBand8,
            width: 100,
            floatingFilter: true,
            filter: "agSetColumnFilter",
            filterParams: { excelMode: "windows" },
            columnGroupShow: "open"
          },
          {
            headerName: "PB9",
            headerTooltip: "Price Band 9",
            field: GridFields.PriceBand9,
            width: 100,
            floatingFilter: true,
            filter: "agSetColumnFilter",
            filterParams: { excelMode: "windows" },
            columnGroupShow: "open"
          },
          {
            headerName: "PB10",
            headerTooltip: "Price Band 10",
            field: GridFields.PriceBand10,
            width: 100,
            floatingFilter: true,
            filter: "agSetColumnFilter",
            filterParams: { excelMode: "windows" },
            columnGroupShow: "open"
          }
        ]
      }
    ];
    setColumnDefs(cols);
  }, [setColumnDefs]);

  const onGridReady = useCallback(params => {
    // setGridApi(params.api);
    // params.api.sizeColumnsToFit();
  }, []);

  //once on mount
  useEffect(() => {
    setGridHeight((window.innerHeight * 2) / 3 - GRID_HEIGHT_OFFSET);

    setContainerSize({
      width: window.innerWidth - 100,
      height: window.innerHeight
    });
  }, [setContainerSize]);

  const getRowNodeId = useCallback(data => {
    return `${data[GridFields.Duid]}_${data[GridFields.BidType]}_${
      data[GridFields.BidSource]
    }_${data[GridFields.PeriodId]}`;
  }, []);
  //endregion
  //region enabled duid bid types
  const [duids, setDuids] = useState([]);
  const [bidTypes, setBidTypes] = useState([]);
  // const [enabledDuidBidTypes, setEnabledDuidBidTypes] = useState({});
  const {
    sendRequest: enabledDuidsSendRequest,
    data: enabledDuidsData,
    apiStatus: enabledDuidsApiStatus
  } = useApi({ method: ApiMethodTypes.Get });

  //fetch enabledDuids
  useEffect(() => {
    const enabledDuidsUrl = VofferApi.GetEnabledDuidBidTypesUrl();
    enabledDuidsSendRequest({ url: enabledDuidsUrl });
  }, [enabledDuidsSendRequest]);

  //update enabled duids data
  useEffect(() => {
    if (enabledDuidsApiStatus === ApiStatus.Success && enabledDuidsData) {
      let duids = new Set();
      let bidTypes = new Set();
      let enabled = {};
      enabledDuidsData.forEach(row => {
        const duid = row[DBFields.Duid];
        const bidType = row[DBFields.BidType];
        duids.add(duid);
        bidTypes.add(bidType);
        if (!enabled[duid]) enabled[duid] = [];
        enabled[duid].push(bidType);
      });
      let bidTypeOptions = [];
      bidTypes.forEach(item => {
        bidTypeOptions.push({ text: item, value: item });
      });
      setBidTypes(bidTypeOptions);

      // setDuids([...duids]); //using spread operator to convert Set to array
      let duidOptions = [];
      duids.forEach(item => {
        duidOptions.push({ text: item, value: item });
      });
      setDuids(duidOptions);
      if (duidOptions.length > 0) setDuid([duidOptions[0].value]); //set default
      // setEnabledDuidBidTypes(enabled);
    }
  }, [enabledDuidsData, enabledDuidsApiStatus]);
  //endregion

  //region bid type
  const [bidType, setBidType] = useState([BidType.Energy]);
  const onChangeBidType = useCallback(
    values => {
      setBidType(values);
    },
    [setBidType]
  );

  //endregion

  //region duid
  const [duid, setDuid] = useState([]);
  const onChangeDuid = useCallback(
    values => {
      setDuid(values);
    },
    [setDuid]
  );

  //endregion

  return (
    <AppContainer history={history} match={match}>
      <Space>
        <label>Settlement Date:</label>
        <DatePickerSettlementDate
          onChange={onChangeBidSettlementDate}
          value={bidSettlementDate}
        />
      </Space>
      <br />
      <br />
      <ApiStatusWrapper statuses={[solveResultApiStatus]}>
        <Card
          title={
            <Space size={"large"}>
              {/*<TitleHeader title={"Optimal volume"} />*/}
              <Space>
                <label>DUID: </label>
                <InputSelect
                  mode={"multiple"}
                  style={{ minWidth: "200px" }}
                  onChange={onChangeDuid}
                  value={duid}
                  options={duids}
                />
              </Space>
              <Space>
                <label>Bid Type: </label>
                <InputSelect
                  mode={"multiple"}
                  style={{ minWidth: "200px" }}
                  onChange={onChangeBidType}
                  value={bidType}
                  options={bidTypes}
                />
              </Space>
            </Space>
          }
        >
          {duid &&
            duid.map(d =>
              bidType.map(b => (
                <SolveResultChart
                  key={`chart-${d}-${b}`}
                  chartHeight={containerSize.height / 3}
                  chartWidth={containerSize.width}
                  data={rowData.filter(
                    row =>
                      row[GridFields.Duid] === d &&
                      row[GridFields.BidType] === b
                  )}
                  duid={d}
                  bidType={b}
                />
              ))
            )}
        </Card>
        <Grid
          gridHeight={gridHeight}
          defaultColDef={{
            ...GridConfigDefault,
            cellStyle: params => ({
              backgroundColor:
                params.data[GridFields.BidSource] === "modified"
                  ? ColorsCell.AlgoBackground
                  : null
            })
          }}
          defaultExportParams={{
            allColumns: true
          }}
          columnDefs={columnDefs}
          rowData={rowData}
          suppressCellSelection={true}
          suppressContextMenu={false}
          suppressRowClickSelection={false}
          enableCellChangeFlash={true}
          onGridReady={onGridReady}
          getRowNodeId={getRowNodeId}
          frameworkComponents={{
            GridCellTimeAgo: GridCellTimeAgo,
            GridCellTime: GridCellTime,
            GridCellLink: GridCellLink
          }}
          sideBar={{
            toolPanels: [
              {
                id: "columns",
                labelDefault: "Columns",
                labelKey: "columns",
                iconKey: "columns",
                toolPanel: "agColumnsToolPanel",
                toolPanelParams: {
                  suppressRowGroups: true,
                  suppressValues: true,
                  suppressPivotMode: true
                }
              }
            ]
          }}
        />
      </ApiStatusWrapper>
    </AppContainer>
  );
};
