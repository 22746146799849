import {
  ValidationMessageCompareGreaterThanEqual,
  ValidationMessageRequired
} from "../validation_messages";
import { CheckValueGreaterThanEqual, CheckValueRequired } from "../checks";
import { FieldLabels, GridFields, ValidationMessageType } from "../enums";
import { CalcLowBreakPoint } from "../calc";

// Low BreakPoint
// >= EnableMin
// >= minAllowedLowBP  (as per calc)
export const ValidateLowBreakPoint = ({
  value,
  enablementMinValue,
  maxLowerAngle,
  maxAvailValue
}) => {
  let validations = [];

  //Validate: value is required
  const check1 = CheckValueRequired(value);
  if (!check1)
    validations.push(
      ValidationMessageRequired(
        check1 ? ValidationMessageType.Success : ValidationMessageType.Error
      )
    );

  //Validate: value is greater than equal to min allowed low bp
  const minLowerBreakPoint = CalcLowBreakPoint({
    lowerAngle: maxLowerAngle,
    currentFcasAvail: maxAvailValue,
    minEnablement: enablementMinValue
  });

  const check2 = CheckValueGreaterThanEqual({
    value,
    compareValue: minLowerBreakPoint
  });
  if (!check2)
    validations.push(
      ValidationMessageCompareGreaterThanEqual({
        messageType: ValidationMessageType.Error,
        fieldLabel: FieldLabels[GridFields.LowBreakPoint],
        fieldValue: value,
        compareFieldLabel: `Min Allowed ${
          FieldLabels[GridFields.LowBreakPoint]
        }`,
        compareFieldValue: minLowerBreakPoint
      })
    );

  //Validate: value is greater than or equal to enablement min
  const check3 = CheckValueGreaterThanEqual({
    value,
    compareValue: enablementMinValue
  });
  if (!check3)
    validations.push(
      ValidationMessageCompareGreaterThanEqual({
        messageType: ValidationMessageType.Error,
        fieldLabel: FieldLabels[GridFields.LowBreakPoint],
        fieldValue: value,
        compareFieldLabel: FieldLabels[GridFields.EnablementMin],
        compareFieldValue: enablementMinValue
      })
    );

  return validations;
};
