import { useEffect } from "react";
export const usePreventWindowUnload = ({ preventDefault }) => {
  useEffect(
    () => {
      if (!preventDefault) return;
      const handleBeforeUnload = event => {
        event.preventDefault();
        event.returnValue = ""; //required for chrome
      };

      window.addEventListener("beforeunload", handleBeforeUnload);
      return () =>
        window.removeEventListener("beforeunload", handleBeforeUnload);
    },
    [preventDefault]
  );
};

//note: also need to use UnsavedChangesPrompt component for navigating away from page
