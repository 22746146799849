import React from "react";
import { array, bool, func, number } from "prop-types";

// import { TitleForm } from "../../titles";
import { OtherDailyParamsLabels } from "../utils/enums";
import { FormItemInputNumber } from "./FormItemInputNumber";

export const FormOtherPhysical = ({
  canEdit,
  dailyEnergyConstraint,
  dailyEnergyConstraintValidations,
  onChangeDailyEnergyConstraint
}) => (
  <>
    {/*<TitleForm title={"Other"} />*/}
    <FormItemInputNumber
      label={OtherDailyParamsLabels["dailyEnergyConstraint"]}
      value={dailyEnergyConstraint}
      validations={dailyEnergyConstraintValidations}
      onChange={onChangeDailyEnergyConstraint}
      required={true}
      inputStyle={{ width: "50%" }}
      disabled={!canEdit}
    />
  </>
);

FormOtherPhysical.propTypes = {
  canEdit: bool.isRequired,
  dailyEnergyConstraint: number.isRequired,
  dailyEnergyConstraintValidations: array,
  onChangeDailyEnergyConstraint: func.isRequired
};
