import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  ApiStatusWrapper,
  ButtonSecondary,
  Grid,
  GridCellTimeAgoLink,
  GridCellTypes
} from "../components";
import { AppContext } from "../contexts";

import {
  ApiStatus,
  DBFields,
  VofferApi,
  AuthApi,
  GridConfigDefault,
  GridFields,
  InternalLinks,
  TemplateActionTypes
} from "../utils";
import { ApiMethodTypes, useApi } from "../hooks";
import { AppContainer } from "../components";
import { NavLink } from "react-router-dom";
import { PlusCircleOutlined } from "@ant-design/icons";

const GRID_HEIGHT_OFFSET = 230;

const AutoGroupColumnDef = {
  headerName: "Owner",
  field: GridFields.Owner
};

export const TemplateSets = ({ history, match }) => {
  const [gridHeight, setGridHeight] = useState(600);
  const [rowData, setRowData] = useState([]);
  // const [gridApi, setGridApi] = useState();
  const [userMappings, setUserMappings] = useState({});

  const { loggedInUserId } = useContext(AppContext);
  const {
    sendRequest: usersSendRequest,
    data: usersData,
    apiStatus: usersApiStatus
  } = useApi({ method: ApiMethodTypes.Get });
  const {
    sendRequest: templateSetsSendRequest,
    data: templateSetsData,
    apiStatus: templateSetsApiStatus
  } = useApi({ method: ApiMethodTypes.Get });

  const columnDefs = [
    {
      headerName: "Template Set Name",
      field: GridFields.TemplateSetName
    },
    {
      headerName: "Description",
      field: GridFields.Description
    },
    {
      headerName: "Last modified at",
      field: GridFields.TemplateModifiedAtUriCombo,
      cellRenderer: GridCellTypes.GridCellTimeAgoLink,
      cellRendererParams: params =>
        params.data
          ? {
              time: params.data[GridFields.LastModifiedAt],
              link: params.data[GridFields.TemplateSetIdEditLink]
            }
          : null
    },
    {
      headerName: "Last modified by",
      field: GridFields.LastModifiedBy,
      refData: userMappings
    },
    {
      headerName: "Owner",
      field: GridFields.Owner,
      rowGroupIndex: 0,
      hide: true
    }
  ];

  const onGridReady = useCallback(params => {
    // setGridApi(params.api);
    params.api.sizeColumnsToFit();
  }, []);

  const getRowNodeId = useCallback(data => {
    return data[GridFields.TemplateSetId];
  }, []);

  const resizeGrid = useCallback(() => {
    setGridHeight(window.innerHeight - GRID_HEIGHT_OFFSET);
  }, []);

  //fetch users
  useEffect(
    () => {
      usersSendRequest({ url: AuthApi.GetUsersUrl() });
    },
    [usersSendRequest]
  );
  //update users data
  useEffect(
    () => {
      if (usersApiStatus === ApiStatus.Success && usersData) {
        let userMappings = {};

        usersData.forEach(row => {
          userMappings[row[DBFields.UserId]] = row[DBFields.UserDisplayName];
        });
        setUserMappings(userMappings);
      }
    },
    [usersData, usersApiStatus]
  );

  const fetchTemplateSets = useCallback(
    () => {
      const templateSetsUrl = VofferApi.GetTemplateSetsUrl({
        user: loggedInUserId
      });
      templateSetsSendRequest({ url: templateSetsUrl });
    },
    [templateSetsSendRequest, loggedInUserId]
  );

  //once on mount
  useEffect(
    () => {
      resizeGrid();
      fetchTemplateSets();
    },
    [resizeGrid, fetchTemplateSets]
  );

  //on template data
  useEffect(
    () => {
      if (templateSetsApiStatus === ApiStatus.Success) {
        let newRows = templateSetsData.map(row => ({
          [GridFields.TemplateSetId]: row[DBFields.TemplateSetId],
          [GridFields.TemplateSetName]: row[DBFields.TemplateSetName],
          [GridFields.LastModifiedBy]: row[DBFields.LastModifiedBy],
          [GridFields.LastModifiedAt]: row[DBFields.LastModifiedAt],
          [GridFields.Description]: row[DBFields.Description],
          [GridFields.Owner]: row[DBFields.IsTeam] ? "Team" : "Personal",
          [GridFields.TemplateModifiedAtUriCombo]: `${
            row[DBFields.LastModifiedAt]
          }-${row[DBFields.TemplateSetId]}`,
          [GridFields.TemplateSetIdEditLink]: InternalLinks.TemplateSet({
            templateSetId: row[DBFields.TemplateSetId],
            templateAction: TemplateActionTypes.Edit
          })
        }));

        setRowData(newRows);
      }
    },
    [templateSetsApiStatus, templateSetsData]
  );

  return (
    <AppContainer history={history} match={match}>
      <ButtonSecondary icon={<PlusCircleOutlined />}>
        <NavLink to={InternalLinks.TemplateSetNew}>
          &nbsp;Add new template set
        </NavLink>
      </ButtonSecondary>
      <ApiStatusWrapper statuses={[templateSetsApiStatus]}>
        <Grid
          gridHeight={gridHeight}
          defaultColDef={GridConfigDefault}
          autoGroupColumnDef={AutoGroupColumnDef}
          columnDefs={columnDefs}
          rowData={rowData}
          groupDefaultExpanded={-1}
          suppressCellSelection={true}
          suppressContextMenu={true}
          suppressRowClickSelection={true}
          enableCellChangeFlash={true}
          onGridReady={onGridReady}
          getRowNodeId={getRowNodeId}
          frameworkComponents={{
            GridCellTimeAgoLink: GridCellTimeAgoLink
          }}
        />
      </ApiStatusWrapper>
    </AppContainer>
  );
};

TemplateSets.propTypes = {};
