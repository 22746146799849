import { GridCellTypes } from "../../grid_cells";
import { GridDuidFields } from "../GridDuidFields";

export const GridColDefAckOffer = ({
  userMappings,
  openByDefault = false
}) => ({
  headerName: "Active Acknowledged Bids",
  headerTooltip: "For each unit and service, it is the Bid with the latest AEMO Offer Timestamp as of the time that pdBidr last Synced to AEMO.",
  openByDefault: openByDefault,
  marryChildren: true,
  children: [
    {
      headerName: "AEMO Offer Timestamp",
      headerTooltip: "The AEMO Offer Timestamp for the latest known bid (for the unit and service) as of the time that pdBidr last Synced to AEMO.",
      field: GridDuidFields.AckOfferAemoOfferTimeStamp,
      width: 170,
      cellRenderer: GridCellTypes.GridCellLink,
      cellRendererParams: params =>
        params.data
          ? {
              value: params.data[GridDuidFields.AckOfferAemoOfferTimeStamp]
                ? params.data[GridDuidFields.AckOfferAemoOfferTimeStamp]
                    .replace("Z", "")
                    .replace("T", " ")
                : "",
              link: params.data[GridDuidFields.AckOfferViewLink]
            }
          : null
    },

    {
      headerName: "Authorised By",
      headerTooltip: "The person, or system, that submitted the Active Acknowledged Bid.  Note that 'pdBidr Algo' is a bid formulated by pdBidr and 'pdBidr Sync' is a resubmitted manual bid submitted by pdBidr to correct a bid sequence error.",
      field: GridDuidFields.AckOfferAemoAuthorisedBy,
      width: 120
      // columnGroupShow: "open"
    },
    {
      headerName: "Algo Check",
      headerTooltip: "After an Algo Bid is Acknowledged by AEMO pdBidr synchronises with AEMO and verifies that the Algo Bid consumed the last manual bid preceding the Algo Bid.  This field will always be blank unless the Authorised By is pdBidr.",
      field: GridDuidFields.BidCheckReference,
      width: 90,
      cellRenderer: GridCellTypes.GridCellBidCheck
    },
    {
      headerName: "AEMO Transaction Id",
      headerTooltip: "The identifier created by AEMO and returned as part of the bid acknowledgement.", 
      field: GridDuidFields.AckOfferAemoTransactionId,
      width: 250,
      columnGroupShow: "open",
      cellRenderer: GridCellTypes.GridCellLink,
      cellRendererParams: params =>
        params.data
          ? {
              openInNewTab: true,
              value: params.data[GridDuidFields.AckOfferAemoTransactionId],
              link: params.data[GridDuidFields.AckHistorySetLink]
            }
          : null
    },
    {
      headerName: "AEMO Reference Id",
      headerTooltip: "The identifier created by the bid submission system (i.e. pdBidr or another third party tool) and submitted to AEMO as part of the bid.", 
      field: GridDuidFields.AckOfferAemoReferenceId,
      width: 300,
      columnGroupShow: "open"
    }
    // {
    //   headerName: "Loaded At",
    //   headerTooltip: "A time count stating how long ago the Active Acknowledged Bid was uploaded to AEMO.", 
    //   field: GridDuidFields.AckOfferLoadedAt,
    //   cellRenderer: GridCellTypes.GridCellTimeAgo,
    //   width: 120,
    //   columnGroupShow: "open"
    // },
    // {
    //   headerName: "Rebid Reason",
    //   field: GridDuidFields.AckOfferAemoRebidReason,
    //   width: 200,
    //   columnGroupShow: "open"
    // },
    // {
    //   headerName: "Method",
    //   field: GridDuidFields.AckOfferAemoMethod,
    //   width: 100,
    //   columnGroupShow: "open"
    // },
    // {
    //   headerName: "Entry Type",
    //   field: GridDuidFields.AckOfferAemoEntryType,
    //   width: 100,
    //   columnGroupShow: "open"
    // },
    // {
    //   headerName: "Loaded By",
    //   field: GridDuidFields.AckOfferLoadedBy,
    //   width: 150,
    //   columnGroupShow: "open",
    //   refData: userMappings
    // }
  ]
});
