import { ValidationMessageRequired } from "../validation_messages";
import { CheckValueRequired } from "../checks";
import { ValidationMessageType } from "../enums";

export const ValidatePriceBandEach = value => {
  let validations = [];

  //Validate: value is required
  const check1 = CheckValueRequired(value);
  if (!check1)
    validations.push(ValidationMessageRequired(ValidationMessageType.Error));

  return validations;
};
