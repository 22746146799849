import React from "react";
import { string, bool } from "prop-types";

import { InternalLink } from "../../generic";

export const GridCellLink = ({ value, link, openInNewTab }) => {
  return (
    <span>
      {value ? (
        link ? (
          <InternalLink url={link} openInNewTab={openInNewTab}>
            {value}
          </InternalLink>
        ) : (
          { value }
        )
      ) : (
        value
      )}
    </span>
  );
};

GridCellLink.propTypes = {
  value: string,
  link: string,
  openInNewTab: bool
};
