import React, { useState, useCallback, useEffect } from "react";
import { Space } from "antd";
import {
  ApiStatusWrapper,
  AppContainer,
  DatePickerSettlementDate,
  Grid,
  GridCellLink,
  GridCellTime,
  GridCellTimeAgo,
  GridCellTypes
} from "../components";
import {
  ApiStatus,
  AuthApi,
  DBFields,
  GridConfigDefault,
  GridFields,
  InternalLinks,
  MonitorTables,
  TransformToMonitorDict,
  VadvisorApi,
  VofferApi
} from "../utils";
import moment from "moment";
import { ApiMethodTypes, useApi } from "../hooks";
import { useInterval } from "ahooks";
const GRID_HEIGHT_OFFSET = 225;
const MONITOR_INTERVAL = 5000;

export const SolveList = ({ history, match }) => {
  //region Monitor
  const [solverBidSetUpdateId, setSolverBidSetUpdateId] = useState();

  const {
    sendRequest: monitorSendRequest,
    data: monitorData,
    apiStatus: monitorApiStatus
  } = useApi({ method: ApiMethodTypes.Get });
  //fetch monitor
  const fetchMonitor = useCallback(
    () => {
      const url = VofferApi.GetMonitorTablesUrl();
      monitorSendRequest({ url: url });
    },
    [monitorSendRequest]
  );

  useInterval(
    () => {
      fetchMonitor();
    },
    MONITOR_INTERVAL,
    { immediate: true }
  );

  //update monitor data
  useEffect(
    () => {
      if (monitorApiStatus === ApiStatus.Success && monitorData) {
        // parse data
        let monitors = TransformToMonitorDict(monitorData);

        setSolverBidSetUpdateId(monitors[MonitorTables.SolverBidSet]);
      }
    },
    [monitorData, monitorApiStatus]
  );

  //endregion

  //region Sett Date
  const [bidSettlementDate, setBidSettlementDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  const onChangeBidSettlementDate = useCallback(
    date => {
      setBidSettlementDate(moment(date).format("YYYY-MM-DD"));
    },
    [setBidSettlementDate]
  );
  //endregion

  //region Solves List
  const {
    sendRequest: solvesSendRequest,
    data: solvesData,
    apiStatus: solvesApiStatus
  } = useApi({ method: ApiMethodTypes.Get });

  //fetch solves
  useEffect(
    () => {
      if (solverBidSetUpdateId) {
        if (bidSettlementDate && bidSettlementDate !== "Invalid date") {
          solvesSendRequest({
            url: VadvisorApi.GetSolveBidSets({
              bidSettlementDate: bidSettlementDate
            })
          });
        } else {
          //do something?
        }
      }
    },
    [solvesSendRequest, bidSettlementDate, solverBidSetUpdateId]
  );

  useEffect(
    () => {
      if (solvesApiStatus === ApiStatus.Success && solvesData) {
        const newRows = solvesData.map(row => ({
          [GridFields.SolveId]: row[DBFields.SolveId],
          [GridFields.SolveLink]: InternalLinks.Solve({
            solveId: row[DBFields.SolveId],
            bidSettlementDate
          }),
          [GridFields.PdRunDateTime]: row["pd_run_date_time"],
          [GridFields.P5RunDateTime]: row["p5min_run_date_time"],
          [GridFields.AlgoId]: row[DBFields.AlgoId],
          [GridFields.LastModifiedAt]: row[DBFields.LastModifiedAt],
          [GridFields.LastModifiedBy]: row[DBFields.LastModifiedBy],
          [GridFields.SubmittedBidSetId]: row[DBFields.SubmittedBidSetId],
          [GridFields.SubmittedBidSetViewLink]: InternalLinks.SubmittedSet({
            submittedSetId: row[DBFields.SubmittedBidSetId]
          }),
          [GridFields.TotalBids]: row[DBFields.TotalBids],
          [GridFields.SentForSubmissionBids]:
            row[DBFields.SentForSubmissionBids]
        }));
        setRowData(newRows);
      }
    },
    [solvesApiStatus, solvesData, bidSettlementDate]
  );

  //endregion

  //region UserMappings
  const {
    sendRequest: usersSendRequest,
    data: usersData,
    apiStatus: usersApiStatus
  } = useApi({ method: ApiMethodTypes.Get });
  const [userMappings, setUserMappings] = useState({});

  //fetch users
  useEffect(
    () => {
      usersSendRequest({ url: AuthApi.GetUsersUrl() });
    },
    [usersSendRequest]
  );
  //update users data
  useEffect(
    () => {
      if (usersApiStatus === ApiStatus.Success && usersData) {
        let userMappings = {};

        usersData.forEach(row => {
          userMappings[row[DBFields.UserId]] = row[DBFields.UserDisplayName];
        });
        setUserMappings(userMappings);
      }
    },
    [usersData, usersApiStatus]
  );

  //endregion

  // region AlgoMappings
  const {
    sendRequest: algosSendRequest,
    data: algosData,
    apiStatus: algosApiStatus
  } = useApi({ method: ApiMethodTypes.Get });
  const [algoMappings, setAlgoMappings] = useState({});

  //fetch algos
  useEffect(
    () => {
      algosSendRequest({ url: VadvisorApi.ConfigAlgos() });
    },
    [algosSendRequest]
  );
  //update algos data
  useEffect(
    () => {
      if (algosApiStatus === ApiStatus.Success && algosData) {
        let algoMappings = {};

        algosData.forEach(row => {
          algoMappings[row[DBFields.AlgoId]] = row[DBFields.AlgoName];
        });
        setAlgoMappings(algoMappings);
      }
    },
    [algosData, algosApiStatus]
  );

  //endregion

  //region Grid
  const [gridHeight, setGridHeight] = useState(600);
  const [rowData, setRowData] = useState([]);
  const [columnDefs, setColumnDefs] = useState([]);
  useEffect(
    () => {
      const cols = [
        {
          headerName: "Solve Id",
          field: GridFields.SolveId,
          width: 300,
          cellRenderer: GridCellTypes.GridCellLink,
          cellRendererParams: params =>
            params.data
              ? {
                  link: params.data[GridFields.SolveLink]
                }
              : null,
          floatingFilter: true,
          filter: "agSetColumnFilter",
          filterParams: { excelMode: "windows" }
        },
        {
          headerName: "Pd RunDateTime",
          field: GridFields.PdRunDateTime,
          floatingFilter: true,
          filter: "agSetColumnFilter",
          filterParams: { excelMode: "windows" },
          rowGroupIndex: 0
        },
        {
          headerName: "P5min RunDateTime",
          field: GridFields.P5RunDateTime,
          floatingFilter: true,
          filter: "agSetColumnFilter",
          filterParams: { excelMode: "windows" }
        },
        {
          headerName: "Bids (Sent/Total)",
          field: GridFields.TotalBids,
          width: 150,
          valueGetter: params =>
            params.data
              ? `${params.data[GridFields.SentForSubmissionBids]}/${
                  params.data[GridFields.TotalBids]
                }`
              : null,
          floatingFilter: true,
          filter: "agSetColumnFilter",
          filterParams: { excelMode: "windows" }
        },

        {
          headerName: "Algo",
          field: GridFields.AlgoId,
          refData: algoMappings,
          floatingFilter: true,
          filter: "agSetColumnFilter",
          filterParams: { excelMode: "windows" }
        },
        {
          headerName: "Last Modified",
          children: [
            {
              headerName: "At",
              field: GridFields.LastModifiedAt,
              cellRenderer: GridCellTypes.GridCellTimeAgo,
              width: 150
            },
            {
              headerName: "By",
              field: GridFields.LastModifiedBy,
              width: 120,
              refData: userMappings,
              columnGroupShow: "open"
            }
          ]
        },
        {
          headerName: "Submission Id",
          field: GridFields.SubmittedBidSetId,
          width: 300,
          cellRenderer: GridCellTypes.GridCellLink,
          cellRendererParams: params =>
            params.data
              ? {
                  link: params.data[GridFields.SubmittedBidSetViewLink],
                  openInNewTab: true
                }
              : null,
          floatingFilter: true,
          filter: "agSetColumnFilter",
          filterParams: { excelMode: "windows" }
        }
      ];
      setColumnDefs(cols);
    },
    [algoMappings, userMappings, setColumnDefs]
  );

  const onGridReady = useCallback(params => {
    // setGridApi(params.api);
    // params.api.sizeColumnsToFit();
  }, []);

  //once on mount
  useEffect(() => {
    setGridHeight(window.innerHeight - GRID_HEIGHT_OFFSET);
  }, []);

  const getRowNodeId = useCallback(data => {
    return data[GridFields.SolveId];
  }, []);
  //endregion

  return (
    <AppContainer history={history} match={match}>
      <Space>
        <label>Settlement Date:</label>
        <DatePickerSettlementDate
          onChange={onChangeBidSettlementDate}
          value={bidSettlementDate}
        />
      </Space>
      <ApiStatusWrapper
        style={{ position: "absolute", top: "49%", left: "49%", zIndex: 10 }}
        statuses={[solvesApiStatus]}
      />
      <ApiStatusWrapper statuses={[algosApiStatus, usersApiStatus]}>
        <Grid
          gridHeight={gridHeight}
          defaultColDef={GridConfigDefault}
          columnDefs={columnDefs}
          rowData={rowData}
          groupDefaultExpanded={-1}
          suppressCellSelection={true}
          suppressContextMenu={false}
          suppressRowClickSelection={false}
          enableCellChangeFlash={true}
          onGridReady={onGridReady}
          getRowNodeId={getRowNodeId}
          frameworkComponents={{
            GridCellTimeAgo: GridCellTimeAgo,
            GridCellTime: GridCellTime,
            GridCellLink: GridCellLink
          }}
        />
      </ApiStatusWrapper>
    </AppContainer>
  );
};
