import {
  ValidationMessageCompareGreaterThanEqual,
  ValidationMessageInRange,
  ValidationMessageRequired
} from "../validation_messages";
import {
  CheckValueGreaterThanEqual,
  CheckValueInRange,
  CheckValueRequired
} from "../checks";
import { FieldLabels, ValidationMessageType, GridFields } from "../enums";

export const ValidatePasaAvail = ({ value, maxCap, maxAvailValue }) => {
  let validations = [];

  //Validate: value is required
  const check1 = CheckValueRequired(value);
  if (!check1)
    validations.push(ValidationMessageRequired(ValidationMessageType.Error));

  //Validate: value is in range
  const check2 = CheckValueInRange({ value, max: maxCap, min: 0 });
  if (!check2)
    validations.push(
      ValidationMessageInRange({
        messageType: ValidationMessageType.Error,
        min: 0,
        max: maxCap
      })
    );

  //Validate: value is greater than or equal to max avail
  const check3 = CheckValueGreaterThanEqual({
    value,
    compareValue: maxAvailValue
  });
  if (!check3)
    validations.push(
      ValidationMessageCompareGreaterThanEqual({
        messageType: ValidationMessageType.Error,
        fieldLabel: FieldLabels[GridFields.PasaAvail],
        fieldValue: value,
        compareFieldLabel: FieldLabels[GridFields.MaxAvail],
        compareFieldValue: maxAvailValue
      })
    );

  return validations;
};
