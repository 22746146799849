import React, { useCallback, useEffect, useState } from "react";
import { object } from "prop-types";
import { Steps } from "antd";
import {
  ClockCircleOutlined,
  CheckCircleOutlined,
  ExclamationCircleOutlined
} from "@ant-design/icons";

import { ApiStatusWrapper, ButtonLink, DisplayTime } from "../components";

import {
  ApiStatus,
  DBFields,
  VofferApi,
  InternalLinks,
  SubmissionStatus
} from "../utils";
import { useApi, ApiMethodTypes } from "../hooks";

const STEPS_STATUS = {
  Wait: "wait",
  // Process: "process",
  Finish: "finish",
  Error: "error"
};
const { GetConfigSubmissionStagesUrl } = VofferApi;
export const SubmissionSteps = ({ stages }) => {
  const [stagesOptions, setStagesOptions] = useState([]);
  const {
    sendRequest: configSubmissionStagesSendRequest,
    data: configSubmissionStagesData,
    apiStatus: configSubmissionStagesApiStatus
  } = useApi({ method: ApiMethodTypes.Get });

  const getStageStatus = useCallback(
    stageId => {
      let status = STEPS_STATUS.Wait;
      if (stages[stageId] && stages[stageId].status) {
        let submissionStatus = stages[stageId].status;
        if (submissionStatus === SubmissionStatus.PASS) {
          status = STEPS_STATUS.Finish;
        } else if (submissionStatus === SubmissionStatus.FAIL) {
          status = STEPS_STATUS.Error;
        }
      }
      return status;
    },
    [stages]
  );

  const getStageIcon = useCallback(
    stageId => {
      const status = getStageStatus(stageId);
      switch (status) {
        // case STEPS_STATUS.Process:
        //   return <LoadingOutlined />;
        case STEPS_STATUS.Error:
          return <ExclamationCircleOutlined />;
        case STEPS_STATUS.Finish:
          return <CheckCircleOutlined />;
        case STEPS_STATUS.Wait:
          return <ClockCircleOutlined />;
        default:
          console.error(`Not implemented status=${stageId} in getStageIcon`);
          return null;
      }
    },
    [getStageStatus]
  );

  //fetch config stages
  useEffect(
    () => {
      const url = GetConfigSubmissionStagesUrl();
      configSubmissionStagesSendRequest({ url: url });
    },
    [configSubmissionStagesSendRequest]
  );

  //on update config stages data
  useEffect(
    () => {
      if (
        configSubmissionStagesApiStatus === ApiStatus.Success &&
        configSubmissionStagesData
      ) {
        setStagesOptions(configSubmissionStagesData);
      }
    },
    [configSubmissionStagesApiStatus, configSubmissionStagesData]
  );

  return (
    <ApiStatusWrapper statuses={[configSubmissionStagesApiStatus]}>
      <Steps progressDot={false} direction={"vertical"}>
        {stagesOptions.map(opt => (
          <Steps.Step
            key={opt[DBFields.StageId]}
            title={opt[DBFields.Description]}
            status={getStageStatus(opt[DBFields.StageId])}
            icon={getStageIcon(opt[DBFields.StageId])}
            subTitle={
              <div style={{ fontSize: "0.8rem" }}>
                {stages[opt[DBFields.StageId]] && (
                  <div>
                    {stages[opt[DBFields.StageId]][DBFields.FinishedAt] && (
                      <span>
                        <b>Finished: </b>
                        <DisplayTime
                          datetime={
                            stages[opt[DBFields.StageId]][DBFields.FinishedAt]
                          }
                        />
                        {stages[opt[DBFields.StageId]][DBFields.LogFileUri] && (
                          <ButtonLink
                            href={InternalLinks.JsonViewer({
                              fileUri: encodeURIComponent(
                                stages[opt[DBFields.StageId]][
                                  DBFields.LogFileUri
                                ]
                              )
                            })}
                          >
                            View Logs
                          </ButtonLink>
                        )}
                      </span>
                    )}
                  </div>
                )}
              </div>
            }
          />
        ))}
      </Steps>
    </ApiStatusWrapper>
  );
};

SubmissionSteps.propTypes = {
  stages: object
};
