import { useEffect, useState } from "react";

export const useStateWithSessionStorage = key => {
  const [value, setValue] = useState(sessionStorage.getItem(key) || "");

  useEffect(
    () => {
      sessionStorage.setItem(key, value);
    },
    [value, key]
  );
  return [value, setValue];
};
