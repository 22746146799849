import {
  ValidationMessageCompareGreaterThanEqual,
  ValidationMessageCompareLessThanEqual,
  ValidationMessageRequired
} from "../validation_messages";
import {
  CheckValueGreaterThanEqual,
  CheckValueLessThanEqual,
  CheckValueRequired
} from "../checks";
import { FieldLabels, GridFields, ValidationMessageType } from "../enums";
import { CalcHighBreakPoint } from "../calc";

// High BreakPoint
// >= Low Breakpoint
// <= maxAllowedHighBp (as per calc)
export const ValidateHighBreakPoint = ({
  value,
  lowBreakPointValue,
  enablementMaxValue,
  maxUpperAngle,
  maxAvailValue
}) => {
  let validations = [];

  //Validate: value is required
  const check1 = CheckValueRequired(value);
  if (!check1)
    validations.push(ValidationMessageRequired(ValidationMessageType.Error));

  //Validate: value is less than calc allowed highbp
  const maxHighBreakPoint = CalcHighBreakPoint({
    upperAngle: maxUpperAngle,
    currentFcasAvail: maxAvailValue,
    maxEnablement: enablementMaxValue
  });

  const check2 = CheckValueLessThanEqual({
    value,
    compareValue: maxHighBreakPoint
  });
  if (!check2)
    validations.push(
      ValidationMessageCompareLessThanEqual({
        messageType: ValidationMessageType.Error,
        fieldLabel: FieldLabels[GridFields.HighBreakPoint],
        fieldValue: value,
        compareFieldLabel: `Max Allowed ${
          FieldLabels[GridFields.HighBreakPoint]
        }`,
        compareFieldValue: maxHighBreakPoint
      })
    );

  //Validate: value is greater than or equal to low break point
  const check3 = CheckValueGreaterThanEqual({
    value,
    compareValue: lowBreakPointValue
  });
  if (!check3)
    validations.push(
      ValidationMessageCompareGreaterThanEqual({
        messageType: ValidationMessageType.Error,
        fieldLabel: FieldLabels[GridFields.HighBreakPoint],
        fieldValue: value,
        compareFieldLabel: FieldLabels[GridFields.LowBreakPoint],
        compareFieldValue: lowBreakPointValue
      })
    );

  return validations;
};
