import React from "react";
import { object } from "prop-types";
import { PageSpinner } from "../components/generic/spinners";

export const LogOut = ({ auth }) => {
  auth.logout();
  return <PageSpinner text={"Logging out..."} />;
};

LogOut.propTypes = {
  auth: object
};
