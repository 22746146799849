import React from "react";
import { GridDuid, AppContainer } from "../components";
import { ActionConfigOffers } from "../components/actions/action_configs";

export const Offers = ({ history, match }) => (
  <AppContainer history={history} match={match}>
    <GridDuid
      showAutoStatus={true}
      showDraftOffers={true}
      allowSelection={true}
      actionConfig={ActionConfigOffers}
      showLastSubmission={true}
      showAckOffers={true}
      showAlgoOffers={true}
    />
  </AppContainer>
);

Offers.propTypes = {};
