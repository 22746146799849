import { Domain } from "../../config";
import { ApiAlgoUser } from "./ApiConfig";

const BASE_URL = `https://api.voffer.${Domain}`;

export const VofferApi = {
  //DRAFT
  GetDraftsUrl: ({ bidSettlementDate, user }) =>
    `${BASE_URL}/v1/bidset/draft?user=${user}&bid_settlement_date=${bidSettlementDate}`,
  PostDraftLoadFromFilesUrl: ({ bidSettlementDate, user }) =>
    `${BASE_URL}/v1/bidset/load_from_files/draft/${bidSettlementDate}/${user}`,
  GetDraftBidUrl: ({ bidCategory, bidType, duid, bidSettlementDate, user }) =>
    `${BASE_URL}/v1/bid/draft/${bidCategory}/${bidType}/${duid}/${bidSettlementDate}/${user}`,
  PutDraftBidUrl: ({ bidCategory, bidType, duid, bidSettlementDate, user }) =>
    `${BASE_URL}/v1/bid/draft/${bidCategory}/${bidType}/${duid}/${bidSettlementDate}/${user}`,
  DeleteDraftBidUrl: ({
    bidCategory,
    bidType,
    duid,
    bidSettlementDate,
    user
  }) =>
    `${BASE_URL}/v1/bid/draft/${bidCategory}/${bidType}/${duid}/${bidSettlementDate}/${user}`,
  //CHECK
  GetCheckBidSetUrl: ({ bidSettlementDate }) =>
    `${BASE_URL}/v1/bidset/check?bid_settlement_date=${bidSettlementDate}`,
  //ALGO
  GetAlgoOffersUrl: ({ bidSettlementDate }) =>
    VofferApi.GetDraftsUrl({
      bidSettlementDate: bidSettlementDate,
      user: ApiAlgoUser
    }),
  GetAlgoBidUrl: ({ bidCategory, bidType, duid, bidSettlementDate }) =>
    VofferApi.GetDraftBidUrl({
      bidType: bidType,
      bidCategory: bidCategory,
      bidSettlementDate: bidSettlementDate,
      duid: duid,
      user: ApiAlgoUser
    }),
  //TEMPLATE
  GetTemplatesInaSetUrl: ({ templateSetId }) =>
    `${BASE_URL}/v1/bidset/sets/template/${templateSetId}`,
  PostTemplateLoadFromFilesUrl: ({ templateSetId }) =>
    `${BASE_URL}/v1/bidset/load_from_files/sets/template/${templateSetId}`,
  GetTemplateBidUrl: ({ bidCategory, bidType, duid, templateSetId }) =>
    `${BASE_URL}/v1/bid/template/${bidCategory}/${bidType}/${duid}/${templateSetId}`,
  PutTemplateBidUrl: ({ bidCategory, bidType, duid, templateSetId }) =>
    `${BASE_URL}/v1/bid/template/${bidCategory}/${bidType}/${duid}/${templateSetId}`,
  DeleteTemplateBidUrl: ({ bidCategory, bidType, duid, templateSetId }) =>
    `${BASE_URL}/v1/bid/template/${bidCategory}/${bidType}/${duid}/${templateSetId}`,
  //SUBMITTED SET
  PostSubmitLoadFromFiles: ({ bidSettlementDate }) =>
    `${BASE_URL}/v1/bidset/load_from_files/sets/submitted/${bidSettlementDate}`,
  GetBidsInASubmittedSetUrl: ({ submittedSetId }) =>
    `${BASE_URL}/v1/bidset/sets/submitted/${submittedSetId}`,
  GetSubmittedSetInfoUrl: ({ submittedSetId }) =>
    `${BASE_URL}/v1/set/submitted/${submittedSetId}`,
  GetSubmittedOfferUrl: ({ submittedSetId, bidType, bidCategory, duid }) =>
    `${BASE_URL}/v1/bid/submitted/${bidCategory}/${bidType}/${duid}/${submittedSetId}`,
  GetStatusesInASubmittedSetUrl: ({ submittedSetId }) =>
    `${BASE_URL}/v1/sets/submitted/${submittedSetId}/statuses`,
  //ACK
  GetAckMetadataDispatchTimesUrl: ({ bidSettlementDate }) =>
    `${BASE_URL}/v1/bidset/ack/metadata/dispatch_times?bid_settlement_date=${bidSettlementDate}`,
  GetAckOffersMetadataLatestWithCheckUrl: ({ bidSettlementDate }) =>
    `${BASE_URL}/v1/bidset/ack/metadata/latest_with_check?bid_settlement_date=${bidSettlementDate}`,
  GetAckOffersMetadataLatestUrl: ({ bidSettlementDate }) =>
    `${BASE_URL}/v1/bidset/ack/metadata/latest_by_date_range?from_bid_settlement_date=${bidSettlementDate}&to_bid_settlement_date=${bidSettlementDate}`,
  GetLatestStageBySubmissionByUserUrl: ({ user, bidSettlementDate }) =>
    `${BASE_URL}/v1/bidset/stage_status/latest/sets/submitted/${user}?bid_settlement_date=${bidSettlementDate}`,
  GetLatestStageBySubmissionAlgoUrl: ({ bidSettlementDate }) =>
    `${BASE_URL}/v1/bidset/stage_status/latest/sets/submitted/${ApiAlgoUser}?bid_settlement_date=${bidSettlementDate}`,
  GetLatestAckBidUrl: ({ bidCategory, bidType, duid, bidSettlementDate }) =>
    `${BASE_URL}/v2/bid/ack/${bidCategory}/${bidType}/${duid}/${bidSettlementDate}?days_offset=1`,
  GetAckBidUrl: ({
    bidCategory,
    bidType,
    duid,
    bidSettlementDate,
    aemoTransactionId
  }) =>
    `${BASE_URL}/v1/bid/ehub_ack/${bidCategory}/${bidType}/${duid}/${bidSettlementDate}/${aemoTransactionId}`,
  //CONFIG
  GetAlgoSettlementDatesUrl: () =>
    `${BASE_URL}/v1/config/bid_settlement_dates/draft?user=${ApiAlgoUser}`,
  GetDraftsSettlementDatesUrl: ({ user }) =>
    `${BASE_URL}/v1/config/bid_settlement_dates/draft?user=${user}`,
  GetAckSettlementDatesUrl: () =>
    `${BASE_URL}/v1/config/bid_settlement_dates/ack`,
  GetConfigRebidCategoriesUrl: () => `${BASE_URL}/v1/config/rebid_categories`,
  GetConfigSubmissionStagesUrl: () => `${BASE_URL}/v1/config/submission_stages`,
  GetConfigAutoStatusesUrl: () => `${BASE_URL}/v1/config/auto_statuses`,
  //MONITOR
  GetMonitorTablesUrl: () => `${BASE_URL}/v1/monitor/tables`,
  //SETS
  GetTemplateSetsUrl: ({ user }) => `${BASE_URL}/v1/sets/template/${user}`,
  PostTemplateSetNew: () => `${BASE_URL}/v1/sets/template`,
  GetTemplateSetInfoUrl: ({ templateSetId }) =>
    `${BASE_URL}/v1/set/template/${templateSetId}`,
  PutTemplateSetInfoUrl: ({ templateSetId }) =>
    `${BASE_URL}/v1/set/template/${templateSetId}`,
  DeleteTemplateSetInfoUrl: ({ templateSetId }) =>
    `${BASE_URL}/v1/set/template/${templateSetId}`,
  GetSubmittedSetsByBidSettlementDate: ({ bidSettlementDate }) =>
    `${BASE_URL}/v1/sets/submitted?bid_settlement_date=${bidSettlementDate}`,
  GetSubmittedSetsBySubmissionDate: ({ submissionDate }) =>
    `${BASE_URL}/v1/sets/submitted?submission_date=${submissionDate}`,
  GetAckSetsByAckDate: ({ ackDate }) =>
    `${BASE_URL}/v1/sets/ehub_ack?ack_date=${ackDate}`,
  GetAckSetsBySettDate: ({ bidSettlementDate }) =>
    `${BASE_URL}/v1/sets/ehub_ack?bid_settlement_date=${bidSettlementDate}`,
  GetAckSetById: ({ aemoTransactionId }) =>
    `${BASE_URL}/v1/set/ehub_ack/${aemoTransactionId}`,
  //SETTINGS
  GetEnabledDuidBidTypesUrl: () =>
    `${BASE_URL}/v1/settings/duid_bid_types?enabled_only=true`,
  GetAutoStatusHistoryUrl: ({ switchedAt }) =>
    `${BASE_URL}/v1/settings/auto_status_history?switched_at=${switchedAt}`,
  GetAutoStatusUrl: () => `${BASE_URL}/v1/settings/auto_status`,
  PostAutoStatusUrl: () => `${BASE_URL}/v1/settings/auto_status`,
  GetIsRebidPeriodUrl: ({ bidSettlementDate }) =>
    `${BASE_URL}/v1/settings/is_rebid_period/${bidSettlementDate}`,
  //UTILS
  GetReadJsonFile: ({ encodedFileUri }) =>
    `${BASE_URL}/v1/utils/readjsonfile?file_uri=${encodedFileUri}`,
  GetEventLogLatestByIdUrl: ({ eventId }) =>
    `${BASE_URL}/v1/event_log/latest/${eventId}`
};
