import { GridCellTypes } from "../../grid_cells";
import { GridDuidFields } from "../GridDuidFields";

export const GridColDefLastSubmission = {
  headerName: "Your Last Submission",
  headerTooltip: "Same content as Algo Last Submission except the information refers to Bids you submitted from Your Drafts.  It will not provide Bids submitted by others from pdBidr nor your bids from third party software.",
  children: [
    {
      headerName: "Submission Status",
      headerTooltip: "Shows the pass/fail of all 5 stages in submitting a bid.  Normally this field will have a green tick and state 5/5 send to AEMO which means the bid was received and acknowledged by AEMO.", 
      field: GridDuidFields.LastSubmissionStageIdStatusCombo,
      cellRenderer: GridCellTypes.GridCellSubmissionStatus,
      width: 130,
      cellRendererParams: params =>
        params.data
          ? {
              stage: params.data[GridDuidFields.LastSubmissionStageId],
              status: params.data[GridDuidFields.LastSubmissionStageStatus]
              // link: params.data[GridDuidFields.LastSubmissionLink]
            }
          : null
    },
    {
      headerName: "Submitted At",
      headerTooltip: "How long ago an Algo Bid was last submitted to AEMO.", 
      field: GridDuidFields.LastSubmissionSubmittedAt,
      cellRenderer: GridCellTypes.GridCellTimeAgo,
      //columnGroupShow: "open",
      width: 110
    },
    {
      headerName: "Submission Id",
      headerTooltip: "An identifier assigned by pdBidr to every Algo Bid that is Submitted to AEMO.  Click the link to open a new tab to show the Submission Info and Submission Progress",
      field: GridDuidFields.LastSubmissionSubmissionId,
      cellRenderer: GridCellTypes.GridCellLink,
      cellRendererParams: params =>
        params.data
          ? {
              link: params.data[GridDuidFields.LastSubmissionLink],
              openInNewTab: true
            }
          : null,
      columnGroupShow: "open",
      width: 250
    }
  ]
};
