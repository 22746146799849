import { Prompt } from "react-router";
import React from "react";
import { bool } from "prop-types";

export const UnsavedChangesPrompt = ({ hasUnsavedChanges }) => {
  return (
    <Prompt
      when={hasUnsavedChanges}
      message="You have unsaved changes, are you sure you want to leave?"
    />
  );
};

UnsavedChangesPrompt.propTypes = {
  hasUnsavedChanges: bool.isRequired
};

UnsavedChangesPrompt.defaultProps = {
  hasUnsavedChanges: false
};
//note: also need to use usePreventWindowUnload hook for closing tabs
