import React from "react";
import { array, number, shape, func, bool } from "prop-types";
import { Input, Col, Row } from "antd";

import { LabelSpan } from "../utils/config";
import { CalcAtNodePrice, CalcPriceBandOrder } from "../utils/calc";
import { InfoTooltip, SectionTooltip } from "../../generic/";
import { FormItemInputNumber } from "./FormItemInputNumber";
import { FormItem } from "./FormItem";
import { InputNumber } from "./inputs";

import { PriceBandLabels } from "../utils/enums";

export const FormPriceBands = ({
  canEdit,
  onChangePriceBand,
  mlf,
  minPriceBand,
  maxPriceBand,
  priceBands,
  validationsPriceBands,
  ifReverseBands,
  showNodePrices
}) => {
  const priceBandsOrder = CalcPriceBandOrder(ifReverseBands);
  return (
    <>
      {/*<TitleForm title={"Price Bands"} />*/}

      {showNodePrices && (
        <FormItemInputNumber
          label={"MLF"}
          value={mlf}
          disabled={true}
          inputStyle={{ width: "50%" }}
        />
      )}
      <FormItemInputNumber
        label={"Min Price Band"}
        value={minPriceBand}
        disabled={true}
        inputStyle={{ width: "50%" }}
        prefix={"$"}
      />
      <FormItemInputNumber
        label={"Max Price Band"}
        value={maxPriceBand}
        disabled={true}
        inputStyle={{ width: "50%" }}
        prefix={"$"}
      />
      {ifReverseBands && (
        <SectionTooltip
          message={"Note that price bands are in reverse order"}
        />
      )}
      {showNodePrices && (
        <Row>
          <Col span={LabelSpan}>&nbsp;</Col>
          <Col span={24 - LabelSpan}>
            <Row>
              <Col span={12}>
                <b>Sent out</b>
                <InfoTooltip
                  text={"Price as sent out to AEMO in bid file"}
                  style={{ marginLeft: "4px" }}
                />
              </Col>
              <Col span={12}>
                <b>At the node</b>
                <InfoTooltip
                  text={
                    "Price at the node, calculated by dividing the sent out by the MLF"
                  }
                  style={{ marginLeft: "4px" }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      )}
      {priceBandsOrder.map(pb => (
        <div key={pb}>
          <FormItem
            label={PriceBandLabels[pb]}
            validations={validationsPriceBands && validationsPriceBands[pb]}
            required={true}
          >
            <Input.Group compact={true}>
              <InputNumber
                style={{ width: "50%" }}
                value={priceBands[pb]}
                prefix={"$"}
                onChange={onChangePriceBand(pb)}
                disabled={!canEdit}
                min={minPriceBand}
                max={maxPriceBand}
              />
              {showNodePrices && (
                <InputNumber
                  style={{ width: "50%" }}
                  prefix={"$"}
                  disabled={true}
                  value={
                    priceBands &&
                    priceBands[pb] !== null &&
                    CalcAtNodePrice(priceBands[pb], mlf)
                  }
                />
              )}
            </Input.Group>
          </FormItem>
        </div>
      ))}
    </>
  );
};

FormPriceBands.propTypes = {
  showNodePrices: bool.isRequired,
  ifReverseBands: bool.isRequired,
  canEdit: bool.isRequired,
  priceBands: shape({
    priceBand1: number.isRequired,
    priceBand2: number.isRequired,
    priceBand3: number.isRequired,
    priceBand4: number.isRequired,
    priceBand5: number.isRequired,
    priceBand6: number.isRequired,
    priceBand7: number.isRequired,
    priceBand8: number.isRequired,
    priceBand9: number.isRequired,
    priceBand10: number.isRequired
  }).isRequired,
  validationsPriceBands: shape({
    priceBand1: array,
    priceBand2: array,
    priceBand3: array,
    priceBand4: array,
    priceBand5: array,
    priceBand6: array,
    priceBand7: array,
    priceBand8: array,
    priceBand9: array,
    priceBand10: array
  }),
  onChangePriceBand: func.isRequired,
  mlf: number.isRequired,
  minPriceBand: number.isRequired,
  maxPriceBand: number.isRequired
};
