import { GridCellTypes } from "../../grid_cells";
import { GridDuidFields } from "../GridDuidFields";
import { GridCellStyleAutoStatus } from "../../grid_cell_styles";

export const GridColDefAutoStatus = ({
  userMappings,
  openByDefault = false
}) => ({
  headerName: "Algo Auto Submit",
  headerTooltip: "Effectively the ON/OFF switch for automatically submitting Algo Bids (subject to validation checks and a change in the bid).",
  openByDefault: openByDefault,
  marryChildren: true,
  children: [
    {
      headerName: "Mode",
      headerTooltip: "ON means that pdBidr will submit Algo Bids as they are formulated subject to validation checks and a change in the bid.",
      field: GridDuidFields.AutoStatus,
      width: 60,
      cellStyle: GridCellStyleAutoStatus
    },
    {
      headerName: "Switched At",
      headerTooltip: "How long ago the Submit Mode was last changed.",
      field: GridDuidFields.AutoStatusLastModifiedAt,
      cellRenderer: GridCellTypes.GridCellTimeAgo,
      // columnGroupShow: "open",
      width: 120
    },
    {
      headerName: "Switched By",
      headerTooltip: "Who changed the submit mode last.  This occurred at the time of 'Switched At'.",
      field: GridDuidFields.AutoStatusLastModifiedBy,
      columnGroupShow: "open",
      width: 200,
      refData: userMappings
    },
    {
      headerName: "Notes",
      headerTooltip: "Notes made by the person who last switch submit mode.  This field is not compulsory and may be null.", 
      field: GridDuidFields.AutoStatusLastModifiedNotes,
      columnGroupShow: "open",
      width: 300
    }
  ]
});
