import React from "react";
import { array, bool, object, string } from "prop-types";
import { Chart } from "../generic";

import { Colors } from "./colors";
import { CalcPriceBandIndex } from "../offer_table/utils/calc";
import { FormatPriceBand } from "../offer_table/utils/formatters";
import { CalcTimeFromPeriodId } from "../offer_table/utils/calc";
import { FieldLabels, GridFields } from "../offer_table/utils/enums";
import { BidCategory } from "../../utils";

const GetPhysicalSeriesTypes = bidCategory => {
  let seriesTypes = [];
  switch (bidCategory) {
    case BidCategory.ENERGY:
      seriesTypes = [
        GridFields.FixedLoad,
        GridFields.MaxAvail,
        GridFields.PasaAvail,
        GridFields.RampRateUp,
        GridFields.RampRateDown
      ];
      break;
    case BidCategory.FCAS:
      seriesTypes = [
        GridFields.MaxAvail,
        GridFields.EnablementMax,
        GridFields.EnablementMin,
        GridFields.HighBreakPoint,
        GridFields.LowBreakPoint
      ];
      break;
    default:
      console.error(
        `Not implemented bidCategory=${bidCategory} in GetPhysicalSeriesTypes`
      );
      break;
  }
  return seriesTypes;
};

export const OfferChart = ({
  nemPeriodId,
  title,
  priceBands,
  periods,
  width,
  height,
  isReverseBands,
  bidCategory
}) => {
  let series = [];
  const physicalSeriesTypes = GetPhysicalSeriesTypes(bidCategory);
  let seriesDataByType = {};
  let seriesDataByBand = {};
  periods.forEach(row => {
    for (let i = 1; i <= 10; i++) {
      if (!seriesDataByBand[`band${i}`]) seriesDataByBand[`band${i}`] = [];
      seriesDataByBand[`band${i}`].push([row.periodId, row[`band${i}`]]);
    }
    physicalSeriesTypes.forEach(st => {
      if (!seriesDataByType[st]) seriesDataByType[st] = [];

      seriesDataByType[st].push([row.periodId, row[st]]);
    });
  });

  Object.keys(priceBands).forEach((pb, i) => {
    const pbIdx = CalcPriceBandIndex(i, isReverseBands);
    //    let data = [[0, 0]].concat(seriesDataByBand[`band${pbIdx}`]); //hack: to make sure that first row change is visible in area stack without line width, added a period 0, with 0 value
    let data = seriesDataByBand[`band${pbIdx}`]; //adding the above hack solution causes chart to break with can't find x of undefined error
    series.push({
      name: FormatPriceBand(priceBands[`priceBand${pbIdx}`]),
      type: "area",
      step: "right",
      data: data, //seriesDataByBand[`band${pbIdx}`],
      color: Colors[`band${pbIdx}`],
      yAxis: 0
    });
  });

  physicalSeriesTypes.forEach(st => {
    series.push({
      name: FieldLabels[st],
      type: "line",
      step: "right",
      data: seriesDataByType[st],
      color: Colors[st],
      yAxis:
        [
          GridFields.RampRateUp,
          GridFields.RampRateDown,
          GridFields.EnablementMin,
          GridFields.EnablementMax,
          GridFields.HighBreakPoint,
          GridFields.LowBreakPoint
        ].indexOf(st) >= 0
          ? 1
          : 0 //put roc up/down, trapezium on separate axis
    });
  });

  return (
    <Chart
      title={title}
      chartHeight={height}
      chartWidth={width}
      xAxis={[
        {
          //  title: { text: "Time" },
          tickInterval: 12,
          labels: {
            formatter: obj => `${CalcTimeFromPeriodId(obj.value)}`
          },
          crosshair: true,
          plotLines: nemPeriodId
            ? [
                {
                  value: nemPeriodId,
                  dashStyle: "dash",
                  width: 2,
                  color: "#000",
                  label: { text: CalcTimeFromPeriodId(nemPeriodId) }
                }
              ]
            : []
        }
      ]}
      yAxis={[
        {
          reversedStacks: false,
          title: {
            text: "MW"
          }
        },
        {
          title: {
            text: `${
              bidCategory === BidCategory.ENERGY ? "ROC" : "Trapezium"
            } (MW)`
          },
          opposite: true
        }
      ]}
      series={series}
      plotOptions={{
        area: {
          stacking: "normal",
          step: "right",
          marker: {
            enabled: false
          },
          lineWidth: 0
        }
      }}
      // tooltip={{
      //   padding: 4,
      //   formatter: function() {
      //     return this.points.reduce(function(s, point) {
      //       return (
      //         s +
      //         "<tr><td><b>" +
      //         point.series.name +
      //         "</b> </td><td> " +
      //         point.y +
      //         "</td></tr>"
      //       );
      //     }, `<b>${CalcTimeFromPeriodId(this.x)} (${this.x})</b><table>`);
      //   },
      //   footerFormat: "</table>",
      //   useHTML: true,
      //   shared: true
      // }}
    />
  );
};

OfferChart.propTypes = {
  title: string,
  priceBands: object.isRequired,
  periods: array.isRequired,
  isReverseBands: bool.isRequired,
  bidCategory: string.isRequired
};
