import { GridDuidFields } from "../GridDuidFields";
import { GridCellTypes } from "../../grid_cells";

export const GridColDefDraftOffers = {
  headerName: "Your Draft Bids",
  headerTooltip: "You must first Load into drafts bids from another source (such as yesterday's draft etc).  Your Draft Bids are unique to you.  Other users will have their own Draft Bids.", 
  children: [
    {
      headerName: "Modified At",
      headerTooltip: "How long ago your Draft Bid was last saved (or loaded).  This is helpful to keep track of which manual bids you have recently edited.", 
      field: GridDuidFields.DraftLastModifiedAt,
      width: 120,
      cellRenderer: GridCellTypes.GridCellTimeAgoLink,
      cellRendererParams: params =>
        params.data
          ? {
              time: params.data[GridDuidFields.DraftLastModifiedAt],
              link: params.data[GridDuidFields.DraftLinkEdit]
            }
          : null
    }
  ]
};
