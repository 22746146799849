//transform in-state price data structure to format for API saving bid energy periods
import { CalcIfReverseBands, CalcAutoBand } from "../calc";
import { ParseBandMW, ParsePhysicalValue } from "../parsers";
import { BidCategory } from "../../../../utils/constants";
import { GridFields } from "../enums";

export const TransformSavePeriods = ({
  rowData,
  maxMW,
  bidType,
  dispatchType,
  bidCategory
}) => {
  const isReverseBands = CalcIfReverseBands(bidType, dispatchType);

  const periods = [];
  rowData.forEach(row => {
    let period = {
      periodId: row.periodId,
      band1: !isReverseBands
        ? ParseBandMW(row.band1)
        : CalcAutoBand(
            maxMW,
            row.band10,
            row.band2,
            row.band3,
            row.band4,
            row.band5,
            row.band6,
            row.band7,
            row.band8,
            row.band9
          ),
      band2: ParseBandMW(row.band2),
      band3: ParseBandMW(row.band3),
      band4: ParseBandMW(row.band4),
      band5: ParseBandMW(row.band5),
      band6: ParseBandMW(row.band6),
      band7: ParseBandMW(row.band7),
      band8: ParseBandMW(row.band8),
      band9: ParseBandMW(row.band9),
      band10: isReverseBands
        ? ParseBandMW(row.band10)
        : CalcAutoBand(
            maxMW,
            row.band1,
            row.band2,
            row.band3,
            row.band4,
            row.band5,
            row.band6,
            row.band7,
            row.band8,
            row.band9
          )
    };

    let physicalCols = [];
    if (bidCategory === BidCategory.ENERGY) {
      physicalCols = [
        GridFields.MaxAvail,
        GridFields.FixedLoad,
        GridFields.RampRateUp,
        GridFields.RampRateDown,
        GridFields.PasaAvail
      ];
    } else if (bidCategory === BidCategory.FCAS) {
      physicalCols = [
        GridFields.MaxAvail,
        GridFields.EnablementMax,
        GridFields.EnablementMin,
        GridFields.LowBreakPoint,
        GridFields.HighBreakPoint
      ];
    }
    physicalCols.forEach(c => {
      period[c] = row[c] ? ParsePhysicalValue(row[c]) : null;
    });

    periods.push(period);
  });
  return periods;
};
