import React, { useCallback } from "react";
import { string, bool, arrayOf, func, shape } from "prop-types";
import { Checkbox } from "antd";

export const InputCheckbox = ({ options, value, onChange }) => {
  const isChecked = useCallback(
    optValue => {
      let match = value.filter(v => v === optValue);
      return match.length > 0;
    },
    [value]
  );

  const onChangeCheckbox = useCallback(
    optValue => e => {
      const checked = e.target.checked;
      let newValues = value;
      if (checked) newValues.push(optValue);
      else newValues.splice(newValues.indexOf(optValue), 1);
      onChange(newValues);
    },
    [value, onChange]
  );
  return (
    <>
      {options.map(opt => (
        <div key={opt.value}>
          <Checkbox
            defaultChecked={isChecked(opt.value)}
            onChange={onChangeCheckbox(opt.value)}
          >
            {opt.text}
            {opt.description && <span> ({opt.description})</span>}
          </Checkbox>
        </div>
      ))}
    </>
  );
};

InputCheckbox.propTypes = {
  options: arrayOf(
    shape({
      text: string.isRequired,
      value: string.isRequired,
      description: string
    })
  ),
  disabled: bool,
  onChange: func
};
