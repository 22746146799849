export const GridFields = {
  PeriodId: "periodId",
  Time: "time",
  Band1: "band1",
  Band2: "band2",
  Band3: "band3",
  Band4: "band4",
  Band5: "band5",
  Band6: "band6",
  Band7: "band7",
  Band8: "band8",
  Band9: "band9",
  Band10: "band10",
  MaxAvail: "maxAvail",
  PasaAvail: "pasaAvail",
  FixedLoad: "fixedLoad",
  RampRateUp: "rampUpRate",
  RampRateDown: "rampDownRate",
  EnablementMin: "enablementMin",
  EnablementMax: "enablementMax",
  LowBreakPoint: "lowBreakPoint",
  HighBreakPoint: "highBreakPoint"
};
