import React from "react";
import { object, string, func, bool, any, number, array } from "prop-types";

import { FormItem } from "./FormItem";
import { InputNumber } from "./inputs";

export const FormItemInputNumber = ({
  label,
  formItemStyle,
  inputStyle,
  required,
  onChange,
  suffix,
  prefix,
  value,
  validations,
  disabled
}) => (
  <FormItem
    label={label}
    required={required}
    style={formItemStyle}
    validations={validations}
  >
    <InputNumber
      style={inputStyle}
      value={value}
      onChange={onChange}
      suffix={suffix}
      prefix={prefix}
      disabled={disabled}
    />
  </FormItem>
);

FormItemInputNumber.propTypes = {
  label: string.isRequired,
  formItemStyle: object,
  inputStyle: object,
  required: bool,
  onChange: func,
  suffix: any,
  prefix: any,
  value: number,
  validations: array,
  disabled: bool
};
