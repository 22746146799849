import React from "react";
import { string } from "prop-types";

import { ValidationMessage } from "../../validations/ValidationMessage";

export const GridCellValidation = React.memo(({ value }) => {
  if (value)
    return (
      <ValidationMessage
        style={{ margin: 0, marginTop: "-4px" }}
        message={value.message}
        messageType={value.type}
      />
    );
  else return null;
});

GridCellValidation.propTypes = {
  message: string,
  type: string
};
