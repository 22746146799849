import React from "react";
import { object, shape, bool, arrayOf, string, func } from "prop-types";
import { Select } from "antd";

export const InputSelect = ({
  style,
  options,
  disabled,
  value,
  onChange,
  mode
}) => (
  <Select
    style={style}
    value={value}
    disabled={disabled}
    onChange={onChange}
    mode={mode}
  >
    {options.map(opt => (
      <Select.Option value={opt.value} key={opt.value}>
        {opt.text}
      </Select.Option>
    ))}
  </Select>
);

InputSelect.propTypes = {
  style: object,
  options: arrayOf(
    shape({
      text: string,
      value: string
    })
  ),
  disabled: bool,
  value: string,
  onChange: func,
  mode: string
};
