import React, { useCallback, useEffect, useState } from "react";
import { shape, string, arrayOf } from "prop-types";
import { TitleSection } from "../titles";
import { Form, FormItem, InputText } from "../generic/form";
import { ApiStatus, DBFields } from "../../utils/constants";
import { ApiMethodTypes, useApi } from "../../hooks";
import { AuthApi } from "../../utils/api";
import { ApiStatusWrapper } from "../ApiStatusWrapper";
import { InputCheckbox } from "./InputCheckbox";

const FIELDS = {
  RoleName: "RoleName",
  Description: "Description",
  Scopes: "Scopes"
};

export const RoleForm = ({
  data,
  submitUrl,
  submitUrlMethodType,
  onSaveSuccess
}) => {
  let roleName = "";
  let description = "";
  let scopes = [];
  if (data) {
    roleName = data.info[DBFields.RoleName];
    description = data.info[DBFields.Description];
    scopes = data.scopes;
  }
  //SUBMIT
  const {
    sendRequest: submitSendRequest,
    apiStatus: submitApiStatus,
    data: submitData
  } = useApi({ method: submitUrlMethodType });

  const handleSubmit = useCallback(
    fieldsValue => {
      const data = {
        info: {
          [DBFields.RoleName]: fieldsValue[FIELDS.RoleName],
          [DBFields.Description]: fieldsValue[FIELDS.Description]
        },
        [DBFields.Scopes]: fieldsValue[FIELDS.Scopes]
      };

      if (!submitUrl) {
        console.error("submitUrl not passed in handleSubmit in RoleForm.jsx");
        return;
      }
      submitSendRequest({ url: submitUrl, data: data });
    },
    [submitSendRequest, submitUrl]
  );

  //on update post submit
  useEffect(
    () => {
      if (
        submitApiStatus === ApiStatus.Success &&
        submitData &&
        onSaveSuccess
      ) {
        onSaveSuccess({ data: submitData });
      }
    },
    [onSaveSuccess, submitApiStatus, submitData]
  );

  //SCOPES OPTIONS
  const [scopesOptions, setScopesOptions] = useState([]);
  const {
    sendRequest: scopesSendRequest,
    apiStatus: scopesApiStatus,
    data: scopesData
  } = useApi({ method: ApiMethodTypes.Get });

  //fetch roles
  useEffect(
    () => {
      scopesSendRequest({ url: AuthApi.GetScopesUrl() });
    },
    [scopesSendRequest]
  );
  //roles data
  useEffect(
    () => {
      if (scopesApiStatus === ApiStatus.Success && scopesData) {
        let options = [];
        scopesData.forEach(s => {
          options.push({
            value: s.scope_id,
            text: s.scope_id,
            description: s.description
          });
        });
        setScopesOptions(options);
      }
    },
    [scopesApiStatus, scopesData, setScopesOptions]
  );

  return (
    <>
      {/*<ApiStatusWrapper statuses={[deleteOfferApiStatus]}>*/}
      {/*<Redirect to={InternalLinks.DeleteSuccess} />*/}
      {/*</ApiStatusWrapper>*/}
      {/*{showDeleteButton && (*/}
      {/*<ButtonDelete onClick={onDeleteSetInfo} style={{ float: "right" }}>*/}
      {/*Delete Template Set*/}
      {/*</ButtonDelete>*/}
      {/*)}*/}
      <TitleSection title={"Role Info"} />
      <ApiStatusWrapper statuses={[scopesApiStatus]}>
        <Form
          onSubmit={handleSubmit}
          apiStatus={submitApiStatus}
          labelSpan={8}
          initialValues={{
            [FIELDS.RoleName]: roleName,
            [FIELDS.Description]: description,
            [FIELDS.Scopes]: scopes
          }}
          // disableEdit={disableEdit}
          submitButtonText={"Save Changes"}
          showCancelButton={false}
        >
          <FormItem
            label={"Role Name"}
            field={FIELDS.RoleName}
            isRequired={true}
            inputControl={<InputText />}
          />
          <FormItem
            label={"Description"}
            field={FIELDS.Description}
            isRequired={false}
            inputControl={<InputText />}
          />
          <FormItem
            label={"Scopes"}
            field={FIELDS.Scopes}
            isRequired={false}
            inputControl={<InputCheckbox options={scopesOptions} />}
            itemProps={{
              extra:
                "Changes to scopes can take up to 2 hours to propagate for each user. Alternatively, ask user(s) to logout and login again."
            }}
          />
        </Form>
      </ApiStatusWrapper>
    </>
  );
};

RoleForm.propTypes = {
  data: shape({
    info: {
      description: string.isRequired,
      role_name: string.isRequired
    },
    scopes: arrayOf(string.isRequired)
  })
};
