import React from "react";
import { object } from "prop-types";
import { ValidationMessage } from "./ValidationMessage";
import { TitleSection } from "../titles";
import { ValidationMessageType } from "../offer_table/utils/enums";

export const ValidationsDisplay = ({ style, section, data }) => {
  // console.log("Validation display", data);
  return (
    <div style={style}>
      <TitleSection title={section} />
      {data && data.length > 0 ? (
        data.map((item, i) => (
          <div key={`validation-${i}`}>
            {item.validations.map((v, j) => (
              <ValidationMessage
                key={`message-${i}-${j}`}
                messageType={v.messageType}
                message={v.message}
                fieldLabel={item.fieldLabel}
              />
            ))}
          </div>
        ))
      ) : (
        <div>
          <ValidationMessage
            messageType={ValidationMessageType.Success}
            message={"No errors or warnings"}
          />
        </div>
      )}
    </div>
  );
};

ValidationsDisplay.propTypes = {
  style: object
};
