import React from "react";
import { object } from "prop-types";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import pdViewIcon from "./app-icon.svg";

const antIcon = <LoadingOutlined spin style={{ fontSize: 42 }} />;

export const Spinner = ({ style }) => (
  <div
    style={{
      textAlign: "center",
      width: "42px",
      height: "42px",
      display: "inline-block",
      background: "white",
      borderRadius: "50%",
      border: "3px solid #ccc",
      padding: "1px",
      ...style
    }}
  >
    <img
      src={pdViewIcon}
      alt={"loading"}
      style={{
        marginTop: "1px",
        width: "32px",
        height: "32px"
      }}
    />
    <Spin
      style={{ position: "relative", top: "-37px", left: "-4px" }}
      indicator={antIcon}
    />
  </div>
);

Spinner.propTypes = {
  style: object
};
