import { CalcTanDeg } from "./CalcTanDeg";

export const CalcHighBreakPoint = ({
  upperAngle,
  maxEnablement,
  currentFcasAvail
}) => {
  if (maxEnablement === 0) return 0; //special case as otherwise due to rounding error, high bp comes to -1
  return Math.floor(
    parseFloat(maxEnablement) -
      parseFloat(currentFcasAvail) * CalcTanDeg({ degree: 90 - upperAngle })
  );
};
