import { ValidationMessageType } from "../enums";

//constructs a validation message
export const ValidationMessageRequired = (
  messageType = ValidationMessageType.Error,
  fieldName = "Value"
) => ({
  messageType: messageType,
  message: `${fieldName} should be entered`
});
