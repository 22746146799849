import React, { useCallback, useEffect, useState } from "react";
import {
  ApiStatusWrapper,
  AppContainer,
  DatePickerSettlementDate,
  Grid,
  GridCellLink,
  GridCellTime,
  GridCellTimeAgo,
  GridCellTypes,
  Switch
} from "../components";
import { Space } from "antd";
import moment from "moment";
import { ApiMethodTypes, useApi } from "../hooks";
import {
  ApiStatus,
  DBFields,
  GridFields,
  VofferApi,
  AuthApi,
  GridConfigDefault,
  TransformToMonitorDict,
  MonitorTables,
  InternalLinks
} from "../utils";
import { useInterval } from "ahooks";
const GRID_HEIGHT_OFFSET = 225;
const MONITOR_INTERVAL = 5000;

const GROUP_BY = {
  SettlementDate: "SettlementDate",
  AckDate: "AckDate"
};

const groupByOptions = [
  {
    value: GROUP_BY.AckDate,
    content: "Acknowledged Date"
  },
  {
    value: GROUP_BY.SettlementDate,
    content: "Settlement Date"
  }
];

export const AckSets = ({ history, match }) => {
  //region Sett Date
  const [selectedDate, setSelectedDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  const onChangeSelectedDate = useCallback(
    date => {
      setSelectedDate(date);
    },
    [setSelectedDate]
  );
  //endregion
  //region Group By
  const [selectedGroupBy, setSelectedGroupBy] = useState(GROUP_BY.AckDate);
  const onChangeGroupBy = useCallback(
    value => {
      setSelectedGroupBy(value);
    },
    [setSelectedGroupBy]
  );

  //endregion
  //region User Map
  const {
    sendRequest: usersSendRequest,
    data: usersData,
    apiStatus: usersApiStatus
  } = useApi({ method: ApiMethodTypes.Get });
  const [userMappings, setUserMappings] = useState({});

  //fetch users
  useEffect(
    () => {
      usersSendRequest({ url: AuthApi.GetUsersUrl() });
    },
    [usersSendRequest]
  );
  //update users data
  useEffect(
    () => {
      if (usersApiStatus === ApiStatus.Success && usersData) {
        let userMappings = {};

        usersData.forEach(row => {
          userMappings[row[DBFields.UserId]] = row[DBFields.UserDisplayName];
        });
        setUserMappings(userMappings);
      }
    },
    [usersData, usersApiStatus]
  );

  //endregion

  //region Monitor
  const [ackSetsUpdateId, setAckSetsUpdateId] = useState();

  const {
    sendRequest: monitorSendRequest,
    data: monitorData,
    apiStatus: monitorApiStatus
  } = useApi({ method: ApiMethodTypes.Get });

  //fetch monitor
  const fetchMonitor = useCallback(
    () => {
      const url = VofferApi.GetMonitorTablesUrl();
      monitorSendRequest({ url: url });
    },
    [monitorSendRequest]
  );

  useInterval(
    () => {
      fetchMonitor();
    },
    MONITOR_INTERVAL,
    { immediate: true }
  );

  //update monitor data
  useEffect(
    () => {
      if (monitorApiStatus === ApiStatus.Success && monitorData) {
        // parse data
        let monitors = TransformToMonitorDict(monitorData);

        setAckSetsUpdateId(monitors[MonitorTables.AckOffersSet]);
      }
    },
    [monitorData, monitorApiStatus]
  );

  //endregion

  //region Ack sets
  const {
    sendRequest: ackSetsSendRequest,
    data: ackSetsData,
    apiStatus: ackSetsApiStatus
  } = useApi({ method: ApiMethodTypes.Get });

  //fetch ack sets
  useEffect(
    () => {
      if (ackSetsUpdateId && selectedDate && selectedDate !== "Invalid date") {
        if (selectedGroupBy === GROUP_BY.AckDate)
          ackSetsSendRequest({
            url: VofferApi.GetAckSetsByAckDate({ ackDate: selectedDate })
          });
        else
          ackSetsSendRequest({
            url: VofferApi.GetAckSetsBySettDate({
              bidSettlementDate: selectedDate
            })
          });
      }
    },
    [ackSetsSendRequest, ackSetsUpdateId, selectedDate, selectedGroupBy]
  );

  useEffect(
    () => {
      if (ackSetsApiStatus === ApiStatus.Success && ackSetsData) {
        const newRows = ackSetsData.map(row => ({
          [GridFields.BidSettlementDate]: row[DBFields.BidSettlementDate],
          [GridFields.AemoOfferTimeStamp]: row[DBFields.AemoOfferTimeStamp]
            ? row[DBFields.AemoOfferTimeStamp]
                .replace("Z", "")
                .replace("T", " ")
            : row[DBFields.AemoOfferTimeStamp],
          [GridFields.AemoAuthorisedBy]: row[DBFields.AemoAuthorisedBy],
          [GridFields.AemoMethod]: row[DBFields.AemoMethod],
          [GridFields.AemoStatus]: row[DBFields.AemoStatus],
          [GridFields.AemoTransactionId]: row[DBFields.AemoTransactionId],
          [GridFields.AemoReferenceId]: row[DBFields.AemoReferenceId],
          [GridFields.LoadedBy]: row[DBFields.LoadedBy],
          [GridFields.LoadedAt]: row[DBFields.LoadedAt],
          [GridFields.AckSetLink]: InternalLinks.AckSet({
            aemoTransactionId: row[DBFields.AemoTransactionId]
          }),
          [GridFields.Comments]: row[DBFields.Comments]
        }));
        setRowData(newRows);
      }
    },
    [ackSetsApiStatus, ackSetsData]
  );

  //endregion

  //region Grid
  const [gridHeight, setGridHeight] = useState(600);
  const [rowData, setRowData] = useState([]);
  const [columnDefs, setColumnDefs] = useState([]);
  useEffect(
    () => {
      const cols = [
        {
          headerName: "Settlement Date",
          field: GridFields.BidSettlementDate,
          rowGroupIndex: 0,
          hide: true
        },
        {
          headerName: "AEMO Transaction Id",
          field: GridFields.AemoTransactionId,
          cellRenderer: GridCellTypes.GridCellLink,
          cellRendererParams: params =>
            params.data
              ? {
                  link: params.data[GridFields.AckSetLink]
                }
              : null,
          width: "250px",
          floatingFilter: true,
          filter: "agSetColumnFilter",
          filterParams: { excelMode: "windows" }
        },
        {
          headerName: "AEMO Offer Timestamp",
          field: GridFields.AemoOfferTimeStamp,
          cellRenderer: GridCellTypes.GridCellTime,
          floatingFilter: true,
          filter: "agSetColumnFilter",
          filterParams: { excelMode: "windows" },
          width: "170px"
        },
        {
          headerName: "Authorised By",
          field: GridFields.AemoAuthorisedBy,
          floatingFilter: true,
          filter: "agSetColumnFilter",
          filterParams: { excelMode: "windows" }
        },
        {
          headerName: "Method",
          field: GridFields.AemoMethod,
          width: 100,
          floatingFilter: true,
          filter: "agSetColumnFilter",
          filterParams: { excelMode: "windows" }
        },
        {
          headerName: "Status",
          field: GridFields.AemoStatus,
          width: 100,
          floatingFilter: true,
          filter: "agSetColumnFilter",
          filterParams: { excelMode: "windows" }
        },
        {
          headerName: "Loaded By",
          field: GridFields.LoadedBy,
          refData: userMappings,
          width: 120
        },
        {
          headerName: "Loaded At",
          field: GridFields.LoadedAt,
          cellRenderer: GridCellTypes.GridCellTimeAgo,
          width: 120
        },
        {
          headerName: "AEMO Reference Id",
          field: GridFields.AemoReferenceId,
          width: "300px",
          floatingFilter: true,
          filter: "agSetColumnFilter",
          filterParams: { excelMode: "windows" }
        },
        {
          headerName: "Comments",
          field: GridFields.Comments,
          floatingFilter: true,
          filter: "agSetColumnFilter",
          filterParams: { excelMode: "windows" }
        }
      ];
      setColumnDefs(cols);
    },
    [setColumnDefs, userMappings]
  );

  const onGridReady = useCallback(params => {
    // setGridApi(params.api);
    // params.api.sizeColumnsToFit();
  }, []);

  //once on mount
  useEffect(() => {
    setGridHeight(window.innerHeight - GRID_HEIGHT_OFFSET);
  }, []);

  const getRowNodeId = useCallback(data => {
    return data[GridFields.AemoTransactionId];
  }, []);
  //endregion

  return (
    <AppContainer history={history} match={match}>
      <Space size={"large"}>
        <Space>
          <label>Group by:</label>
          <Switch
            selectedValue={selectedGroupBy}
            onChangeValue={onChangeGroupBy}
            items={groupByOptions}
          />
        </Space>
        <Space>
          <label>Ack date:</label>
          <DatePickerSettlementDate
            onChange={onChangeSelectedDate}
            value={selectedDate}
          />
        </Space>
      </Space>
      <ApiStatusWrapper
        style={{ position: "absolute", top: "49%", left: "49%", zIndex: 10 }}
        statuses={[ackSetsApiStatus]}
      />
      <ApiStatusWrapper statuses={[usersApiStatus]}>
        <Grid
          autoGroupColumnDef={{
            headerName: "Settlement Date",
            width: 160
          }}
          gridHeight={gridHeight}
          defaultColDef={GridConfigDefault}
          columnDefs={columnDefs}
          rowData={rowData}
          suppressCellSelection={true}
          suppressContextMenu={true}
          suppressRowClickSelection={true}
          enableCellChangeFlash={true}
          onGridReady={onGridReady}
          getRowNodeId={getRowNodeId}
          groupDefaultExpanded={-1}
          frameworkComponents={{
            GridCellTimeAgo: GridCellTimeAgo,
            GridCellTime: GridCellTime,
            GridCellLink: GridCellLink
          }}
        />
      </ApiStatusWrapper>
    </AppContainer>
  );
};
