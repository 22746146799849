import React from "react";
import { Route, Redirect } from "react-router-dom";
import { AppProvider } from "../contexts";

export const AuthenticatedRoute = ({ component: Component, auth, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      auth.isAuthenticated() ? (
        <AppProvider
          value={{
            loggedInUserId: auth.getUserId(),
            loggedInUserEmail: auth.getUserEmail()
          }}
        >
          <Component {...props} />
        </AppProvider>
      ) : (
        <div
          style={{
            backgroundColor: "white",
            position: "fixed",
            width: "100%",
            height: "100%",
            top: 0,
            left: 0,
            zIndex: 1000
          }}
        >
          <Redirect
            to={{
              pathname: "/auth/login",
              state: { from: props.location }
            }}
          />
        </div>
      )
    }
  />
);
