import { ValidationMessageType } from "../enums";

//constructs a validation message
export const ValidationMessageCompareLessThan = (
  messageType = ValidationMessageType.Error,
  fieldName,
  compareFieldName
) => ({
  messageType: messageType,
  message: `${fieldName} should be less than ${compareFieldName}`
});
