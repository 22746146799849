import React from "react";
import { string, number } from "prop-types";

export const IconSummary = ({ size, color }) => (
  <svg
    height={size}
    width={size}
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    x="0px"
    y="0px"
    viewBox="0 0 52 52"
  >
    <g>
      <g>
        <path d="M50,50H27V2h23V50z M29,48h19V4H29V48z" />
      </g>
      <g>
        <path d="M25,17H2V2h23V17z M4,15h19V4H4V15z" />
      </g>
      <g>
        <path d="M25,33H2V19h23V33z M4,31h19V21H4V31z" />
      </g>
      <g>
        <path d="M25,50H2V35h23V50z M4,48h19V37H4V48z" />
      </g>
    </g>{" "}
  </svg>
);

IconSummary.propTypes = {
  size: number.isRequired,
  color: string.isRequired
};
