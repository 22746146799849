import React, { useCallback, useEffect, useState } from "react";
import { Space } from "antd";
import {
  ApiStatusWrapper,
  AppContainer,
  DatePickerSettlementDate,
  Grid,
  GridCellLink,
  GridCellSubmissionStatus,
  GridCellTime,
  GridCellTimeAgo,
  GridCellTypes,
  Switch
} from "../components";

import {
  ApiStatus,
  DBFields,
  VofferApi,
  AuthApi,
  GridConfigDefault,
  GridFields,
  InternalLinks,
  MonitorTables,
  SubmittedActionTypes,
  TransformToMonitorDict
} from "../utils";
import { ApiMethodTypes, useApi } from "../hooks";
import moment from "moment";
import { useInterval } from "ahooks";

const GRID_HEIGHT_OFFSET = 225;
const MONITOR_INTERVAL = 5000;
const {
  GetConfigRebidCategoriesUrl,
  GetConfigSubmissionStagesUrl,
  GetMonitorTablesUrl,
  GetSubmittedSetsByBidSettlementDate,
  GetSubmittedSetsBySubmissionDate
} = VofferApi;
const { GetUsersUrl } = AuthApi;

const GROUP_BY = {
  SettlementDate: "SettlementDate",
  SubmissionDate: "SubmissionDate"
};

const groupByOptions = [
  {
    value: GROUP_BY.SubmissionDate,
    content: "Submission Date"
  },
  {
    value: GROUP_BY.SettlementDate,
    content: "Settlement Date"
  }
];

export const SubmittedSets = ({ history, match }) => {
  const [columnDefs, setColumnDefs] = useState([]);
  const [gridHeight, setGridHeight] = useState(600);
  const [stagesOptions, setStagesOptions] = useState();
  const [rebidCategoriesOptions, setRebidCategoriesOptions] = useState();
  const [rowData, setRowData] = useState([]);
  // const [gridApi, setGridApi] = useState();
  const [bidStatusUpdateId, setBidStatusUpdateId] = useState();
  const [userMappings, setUserMappings] = useState({});
  const [selectedDate, setSelectedDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  const {
    sendRequest: usersSendRequest,
    data: usersData,
    apiStatus: usersApiStatus
  } = useApi({ method: ApiMethodTypes.Get });

  const {
    sendRequest: monitorSendRequest,
    data: monitorData,
    apiStatus: monitorApiStatus
  } = useApi({ method: ApiMethodTypes.Get });

  const {
    sendRequest: submittedSetsSendRequest,
    data: submittedSetsData,
    apiStatus: submittedSetsApiStatus
  } = useApi({ method: ApiMethodTypes.Get });

  const {
    sendRequest: configStatusSendRequest,
    data: configStatusData,
    apiStatus: configStatusApiStatus
  } = useApi({ method: ApiMethodTypes.Get });

  const {
    sendRequest: configRebidCategorySendRequest,
    data: configRebidCategoryData,
    apiStatus: configRebidCategoryApiStatus
  } = useApi({ method: ApiMethodTypes.Get });

  //region Group By
  const [selectedGroupBy, setSelectedGroupBy] = useState(
    GROUP_BY.SubmissionDate
  );
  const onChangeGroupBy = useCallback(
    value => {
      setSelectedGroupBy(value);
    },
    [setSelectedGroupBy]
  );

  //endregion

  const cellRenderParamsStageStatus = useCallback(
    params => {
      let stage = null;
      let status = null;

      if (params && params.data && stagesOptions) {
        stage = stagesOptions[params.data[GridFields.LatestStageId]];
        status = params.data[GridFields.LatestStatus];
        //link = params.data[GridFields.SubmittedBidSetViewLink];
      }
      return {
        stage: stage,
        status: status
        //link: link
      };
    },
    [stagesOptions]
  );

  useEffect(
    () => {
      const cols = [
        {
          headerName: "Settlement Date",
          rowGroupIndex: 0, // selectedGroupBy === GROUP_BY.SubmissionDate ? 0 : null,
          field: GridFields.BidSettlementDate,
          hide: true
        },
        {
          headerName: "Submission Id",
          field: GridFields.SubmittedBidSetId,
          width: 280,
          cellRenderer: GridCellTypes.GridCellLink,
          cellRendererParams: params =>
            params.data
              ? {
                  link: params.data[GridFields.SubmittedBidSetViewLink]
                }
              : null,
          floatingFilter: true,
          filter: "agSetColumnFilter",
          filterParams: { excelMode: "windows" }
        },
        {
          headerName: "Latest Submission Status",
          field: GridFields.LatestSubmittedStageIdStatusCombo,
          cellRenderer: GridCellTypes.GridCellSubmissionStatus,
          cellRendererParams: params => cellRenderParamsStageStatus(params),
          width: 200
          // filter: true,
          // floatingFilter: true
        },
        {
          headerName: "Submitted By",
          field: GridFields.SubmittedBy,
          refData: userMappings,
          width: 180,
          floatingFilter: true,
          filter: "agSetColumnFilter",
          filterParams: { excelMode: "windows" }
        },
        {
          headerName: "Submitted At",
          field: GridFields.SubmittedAt,
          cellRenderer: GridCellTypes.GridCellTimeAgo,
          width: 120
        },
        {
          headerName: "Rebid Category",
          field: GridFields.RebidCategory,
          width: 120,
          floatingFilter: true,
          filter: "agSetColumnFilter",
          filterParams: { excelMode: "windows" }
        },
        // {
        //   headerName: "Rebid Reason",
        //   field: GridFields.RebidReason,
        //   width: 200
        // },

        {
          headerName: "Event Time",
          field: GridFields.EventTime,
          cellRenderer: GridCellTypes.GridCellTime,
          width: 130
        },
        {
          headerName: "Aware Time",
          field: GridFields.AwareTime,
          cellRenderer: GridCellTypes.GridCellTime,
          width: 130
        },
        {
          headerName: "Decision Time",
          field: GridFields.DecisionTime,
          cellRenderer: GridCellTypes.GridCellTime,
          width: 130
        },
        {
          headerName: "AEMO Offer Timestamp",
          field: GridFields.AemoOfferTimeStamp,
          width: 170
        },
        {
          headerName: "AEMO Transaction Id",
          field: GridFields.AemoTransactionId,
          width: 300,
          cellRenderer: GridCellTypes.GridCellLink,
          floatingFilter: true,
          filter: "agSetColumnFilter",
          filterParams: { excelMode: "windows" },
          cellRendererParams: params =>
            params.data
              ? {
                  link: params.data[GridFields.AckSetLink],
                  openInNewTab: true
                }
              : null
        },
        {
          headerName: "Solve Id",
          field: GridFields.SolveId,
          width: 300,
          cellRenderer: GridCellTypes.GridCellLink,
          floatingFilter: true,
          filter: "agSetColumnFilter",
          filterParams: { excelMode: "windows" },
          cellRendererParams: params =>
            params.data
              ? {
                  link: params.data[GridFields.SolveLink],
                  openInNewTab: true
                }
              : null
        }
      ];
      setColumnDefs(cols);
    },
    [setColumnDefs, cellRenderParamsStageStatus, userMappings]
  );

  const onGridReady = useCallback(params => {
    // setGridApi(params.api);
    // params.api.sizeColumnsToFit();
  }, []);

  //once on mount
  useEffect(() => {
    setGridHeight(window.innerHeight - GRID_HEIGHT_OFFSET);
  }, []);

  //fetch users
  useEffect(
    () => {
      usersSendRequest({ url: GetUsersUrl() });
    },
    [usersSendRequest]
  );
  //update users data
  useEffect(
    () => {
      if (usersApiStatus === ApiStatus.Success && usersData) {
        let userMappings = {};

        usersData.forEach(row => {
          userMappings[row[DBFields.UserId]] = row[DBFields.UserDisplayName];
        });
        setUserMappings(userMappings);
      }
    },
    [usersData, usersApiStatus]
  );
  //fetch monitor
  const fetchMonitor = useCallback(
    () => {
      const url = GetMonitorTablesUrl();
      monitorSendRequest({ url: url });
    },
    [monitorSendRequest]
  );

  useInterval(
    () => {
      fetchMonitor();
    },
    MONITOR_INTERVAL,
    { immediate: true }
  );

  const getRowNodeId = useCallback(data => {
    return data[GridFields.TemplateSetId];
  }, []);

  //update monitor data
  useEffect(
    () => {
      if (monitorApiStatus === ApiStatus.Success && monitorData) {
        // parse data
        let monitors = TransformToMonitorDict(monitorData);

        setBidStatusUpdateId(monitors[MonitorTables.BidStatus]);
      }
    },
    [monitorData, monitorApiStatus]
  );

  //fetch config stages
  useEffect(
    () => {
      const url = GetConfigSubmissionStagesUrl();
      configStatusSendRequest({ url: url });
    },
    [configStatusSendRequest]
  );

  //update config status
  useEffect(
    () => {
      if (configStatusApiStatus === ApiStatus.Success && configStatusData) {
        //change to dict
        let dict = {};
        configStatusData.forEach(row => {
          dict[row[DBFields.StageId]] = row[DBFields.Description];
        });
        setStagesOptions(dict);
      }
    },
    [configStatusApiStatus, configStatusData]
  );

  //fetch config rebid categories
  useEffect(
    () => {
      const url = GetConfigRebidCategoriesUrl();
      configRebidCategorySendRequest({ url: url });
    },
    [configRebidCategorySendRequest]
  );

  //update config rebid categories
  useEffect(
    () => {
      if (
        configRebidCategoryApiStatus === ApiStatus.Success &&
        configRebidCategoryData
      ) {
        //change to dict
        let dict = {};
        configRebidCategoryData.forEach(row => {
          dict[row["rebid_category_id"]] = row["rebid_category_description"];
        });
        setRebidCategoriesOptions(dict);
      }
    },
    [configRebidCategoryApiStatus, configRebidCategoryData]
  );

  //fetch submitted sets
  useEffect(
    () => {
      if (
        bidStatusUpdateId &&
        selectedDate &&
        selectedDate !== "Invalid date"
      ) {
        if (selectedGroupBy === GROUP_BY.SubmissionDate)
          submittedSetsSendRequest({
            url: GetSubmittedSetsBySubmissionDate({
              submissionDate: selectedDate
            })
          });
        else
          submittedSetsSendRequest({
            url: GetSubmittedSetsByBidSettlementDate({
              bidSettlementDate: selectedDate
            })
          });
      }
    },
    [selectedGroupBy, submittedSetsSendRequest, bidStatusUpdateId, selectedDate] //currently refetching if anything in bid status table changed, can optimise it if needed in future
  );

  //update submitted sets
  const getRebidCategoryDesc = useCallback(
    categoryId => {
      if (categoryId) {
        if (rebidCategoriesOptions[categoryId])
          return rebidCategoriesOptions[categoryId];
        else
          console.error(
            `No description found for rebid category=${categoryId}`,
            rebidCategoriesOptions
          );
      }
      return categoryId;
    },
    [rebidCategoriesOptions]
  );

  useEffect(
    () => {
      if (
        submittedSetsApiStatus === ApiStatus.Success &&
        submittedSetsData &&
        rebidCategoriesOptions
      ) {
        const newRows = submittedSetsData.map(row => ({
          [GridFields.SubmittedBy]: row[DBFields.SubmittedBy],
          [GridFields.SubmittedAt]: row[DBFields.SubmittedAt],
          [GridFields.SubmittedBidSetId]: row[DBFields.SubmittedBidSetId],
          [GridFields.RebidReason]: row[DBFields.RebidReason],
          [GridFields.RebidCategory]: getRebidCategoryDesc(
            row[DBFields.RebidCategory]
          ),
          [GridFields.EventTime]: row[DBFields.EventTime],
          [GridFields.AwareTime]: row[DBFields.AwareTime],
          [GridFields.DecisionTime]: row[DBFields.DecisionTime],
          [GridFields.BidSettlementDate]: row[DBFields.BidSettlementDate],
          [GridFields.LatestStageId]: row[DBFields.LatestStageId],
          [GridFields.LatestStatus]: row[DBFields.LatestStatus],
          [GridFields.SubmittedBidSetViewLink]: InternalLinks.SubmittedSet({
            submittedSetId: row[DBFields.SubmittedBidSetId],
            submittedAction: SubmittedActionTypes.View
          }),
          [GridFields.LatestSubmittedStageIdStatusCombo]: `${
            row[DBFields.LatestStageId]
          }-${row[DBFields.Status]}-${row[DBFields.SubmittedBidSetId]}`, //creating this so as to force the update on cell renderer
          [GridFields.SolveId]: row[DBFields.SolveId],
          [GridFields.SolveLink]: InternalLinks.Solve({
            solveId: row[DBFields.SolveId],
            bidSettlementDate: row[DBFields.BidSettlementDate]
          }),
          [GridFields.AemoTransactionId]: row[DBFields.AemoTransactionId],
          [GridFields.AckSetLink]: InternalLinks.AckSet({
            aemoTransactionId: row[DBFields.AemoTransactionId]
          }),
          [GridFields.AemoOfferTimeStamp]: row[DBFields.AemoOfferTimeStamp]
            ? row[DBFields.AemoOfferTimeStamp]
                .replace("Z", "")
                .replace("T", " ")
            : row[DBFields.AemoOfferTimeStamp]
        }));
        setRowData(newRows);
      }
    },
    [
      submittedSetsApiStatus,
      submittedSetsData,
      rebidCategoriesOptions,
      getRebidCategoryDesc
    ]
  );
  const onChangeBidSettlementDate = useCallback(
    date => {
      setSelectedDate(date);
    },
    [setSelectedDate]
  );

  return (
    <AppContainer history={history} match={match}>
      <Space size={"large"}>
        <Space>
          <label>Group by:</label>
          <Switch
            selectedValue={selectedGroupBy}
            onChangeValue={onChangeGroupBy}
            items={groupByOptions}
          />
        </Space>
        <Space>
          <label>Date:</label>
          <DatePickerSettlementDate
            onChange={onChangeBidSettlementDate}
            value={selectedDate}
          />
        </Space>
      </Space>
      <ApiStatusWrapper
        style={{ position: "absolute", top: "49%", left: "49%", zIndex: 10 }}
        statuses={[submittedSetsApiStatus]}
      />
      <ApiStatusWrapper
        statuses={[configRebidCategoryApiStatus, configStatusApiStatus]}
      >
        <Grid
          autoGroupColumnDef={{
            headerName: "Settlement Date",
            width: 160
          }}
          gridHeight={gridHeight}
          defaultColDef={GridConfigDefault}
          groupDefaultExpanded={-1}
          columnDefs={columnDefs}
          rowData={rowData}
          suppressCellSelection={true}
          suppressContextMenu={true}
          suppressRowClickSelection={true}
          enableCellChangeFlash={true}
          onGridReady={onGridReady}
          getRowNodeId={getRowNodeId}
          frameworkComponents={{
            GridCellTimeAgo: GridCellTimeAgo,
            GridCellSubmissionStatus: GridCellSubmissionStatus,
            GridCellTime: GridCellTime,
            GridCellLink: GridCellLink
          }}
        />
      </ApiStatusWrapper>
    </AppContainer>
  );
};

SubmittedSets.propTypes = {};
