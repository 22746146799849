import React from "react";
import { object } from "prop-types";

import { DatePicker as DatePickerAntd } from "antd";

export const DatePicker = props => <DatePickerAntd {...props} />;

DatePicker.propTypes = {
  style: object
};
