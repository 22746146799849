import React, { useState, useCallback } from "react";
import { Layout } from "antd";
import { any, object } from "prop-types";

import { AppSider, BreadCrumbRouter } from "../components";
import { getThemeById, ThemeIds, ThemeProvider } from "../contexts";

export const AppContainer = ({
  history,
  match,
  children,
  style,
  contentPadding = "16px"
}) => {
  const [themeId, setThemeId] = useState(ThemeIds.Light);
  const [theme, setTheme] = useState(getThemeById(themeId));

  const onChangeTheme = useCallback(
    themeId => {
      setThemeId(themeId);
      setTheme(getThemeById(themeId));
    },
    [setTheme]
  );

  const pagePath = history.location.pathname;

  return (
    <ThemeProvider value={theme}>
      <Layout style={{ minHeight: "100vh" }}>
        <AppSider
          location={pagePath}
          selectedThemeId={themeId}
          onChangeTheme={onChangeTheme}
        />
        <Layout.Content
          style={{
            // backgroundColor: theme.appBackgroundColor,
            ...style
          }}
        >
          <BreadCrumbRouter history={history} match={match} />
          <div style={{ padding: contentPadding }}>{children}</div>
        </Layout.Content>
      </Layout>
    </ThemeProvider>
  );
};

AppContainer.propTypes = {
  children: any.isRequired,
  history: object.isRequired,
  match: object.isRequired
};
