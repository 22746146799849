import React from "react";
import { object, number, bool } from "prop-types";
import { Input } from "antd";

export const InputTextArea = props => (
  <Input.TextArea type={"text"} {...props} />
);

InputTextArea.propTypes = {
  style: object,
  rows: number.isRequired,
  disabled: bool
};
