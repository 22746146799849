import { history } from "../history";
import { AuthConfig } from "./auth-config";
import jwt from "jsonwebtoken";
import { PdClientTypes } from "./PdClientTypes";

const appUrl = window.location.origin; //base url only
const forbiddenUrl = `${appUrl}/auth/forbidden`;
export class Auth {
  constructor() {
    //remember last path
    const pathName = window.location.pathname;
    const pathExcludeList = [
      "/auth/login",
      "/auth/login/cognito",
      "/auth/login/azure",
      "/auth/callback",
      "/auth/logout",
      "/auth/loggedout",
      "/auth/forbidden",
      "/auth/error"
    ];
    if (pathExcludeList.indexOf(pathName) < 0) {
      localStorage.setItem("last_path", pathName);
    }
  }

  login() {
    const loginUrl = `${AuthConfig.BaseUrl}/v1/auth/user?pd_client_id=${
      AuthConfig.PdClientId
    }&pd_client_type=${this.getPdClientType()}&pd_redirect_uri=${appUrl}/auth/callback`;

    window.location = loginUrl;
  }
  forbidden() {
    window.location = forbiddenUrl;
  }

  logout() {
    const userId = this.getUserId();
    const logoutUrl = `${AuthConfig.BaseUrl}/v1/auth/user_logout?pd_client_id=${
      AuthConfig.PdClientId
    }&pd_client_type=${this.getPdClientType()}&user_id=${userId}&pd_logout_uri=${appUrl}/auth/loggedout`;
    // Clear access token and ID token from local storage

    localStorage.removeItem("pd_access_token");
    localStorage.removeItem("pd_expires_at");
    localStorage.removeItem("pd_user_id");
    localStorage.removeItem("pd_user_email");

    //redirect to sso logout
    window.location = logoutUrl;
  }

  callback(access_token) {
    if (access_token) {
      //decode token
      const decoded = jwt.decode(access_token);

      localStorage.setItem("pd_access_token", access_token);
      localStorage.setItem("pd_expires_at", decoded.exp * 1000);
      localStorage.setItem("pd_user_id", decoded.sub);
      localStorage.setItem("pd_user_email", decoded.email);

      // navigate to the last_path route
      const pathName = localStorage.getItem("last_path");
      if (pathName) history.replace(pathName);
      else history.replace("/");
    }
  }

  isAuthenticated() {
    // Check whether the current time is past the
    // access token's expiry time
    const expiresAt = JSON.parse(localStorage.getItem("pd_expires_at"));

    return new Date().getTime() < expiresAt;
  }

  getUserId() {
    return localStorage.getItem("pd_user_id"); //todo temp
  }

  getUserEmail() {
    return localStorage.getItem("pd_user_email");
  }

  setPdClientType(pdClientType) {
    if (Object.values(PdClientTypes).indexOf(pdClientType) >= 0)
      localStorage.setItem("pd_client_type", pdClientType);
    else alert(`Invalid pdClientType=${pdClientType}`);
  }

  getPdClientType() {
    return localStorage.getItem("pd_client_type") ?? AuthConfig.PdClientType;
  }
}
