import { ValidationMessageType } from "../enums";

//constructs a validation message
export const ValidationMessageInRange = ({
  min,
  max,
  messageType = ValidationMessageType.Error,
  fieldName = "Value"
}) => ({
  messageType: messageType,
  message: `${fieldName} should be in range ${min}-${max}`
});
