import { LogType } from "./LogType";

export const FindFirstBandGreaterThanPrice = ({
  bidPrices,
  priceThreshold
}) => {
  let logs = [];
  logs.push({
    type: LogType.DEBUG,
    message: `Finding first band greater than price threshold`,
    code: JSON.stringify({ priceThreshold, bidPrices })
  });

  let selectedBand = null;
  let i = 1;
  while (i <= 10) {
    const price = bidPrices[`priceBand${i}`];
    if (price >= priceThreshold) {
      selectedBand = `band${i}`;
      break;
    }
    i++;
  }
  if (selectedBand !== null)
    logs.push({
      type: LogType.DEBUG,
      message: `Found first band  greater than price threshold`,
      code: JSON.stringify({ selectedBand })
    });
  else
    logs.push({
      type: LogType.WARNING,
      message: `Unable to find band greater than price threshold`
    });
  return { selectedBand, logs };
};
