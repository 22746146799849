import React, { useContext } from "react";
import { object, string } from "prop-types";

import { ThemeContext } from "../../contexts";

export const TitleHeader = React.memo(({ style, title }) => {
  const theme = useContext(ThemeContext);

  return (
    <h4
      style={{
        color: theme.titleColor,
        margin: 0,
        fontWeight: "bold",
        ...style
      }}
    >
      {title}
    </h4>
  );
});

TitleHeader.propTypes = {
  style: object,
  title: string.isRequired
};
