import React, { useEffect } from "react";
import { ApiStatusWrapper, AppContainer, UserForm } from "../components";
import { ApiMethodTypes, useApi } from "../hooks";
import { AuthApi } from "../utils/api";
import { Row, Col } from "antd";

export const User = ({ history, match }) => {
  const { userId } = match.params;

  const {
    sendRequest: userInfoSendRequest,
    data: userInfoData,
    apiStatus: userInfoApiStatus
  } = useApi({ method: ApiMethodTypes.Get });

  //fetch user info data
  useEffect(
    () => {
      userInfoSendRequest({ url: AuthApi.GetUserByIdUrl({ userId: userId }) });
    },
    [userId, userInfoSendRequest]
  );
  const submitUrl = AuthApi.PutUserByIdUrl({ userId });

  return (
    <AppContainer match={match} history={history}>
      <Row gutter={16}>
        <Col xl={12} sm={24}>
          <ApiStatusWrapper statuses={[userInfoApiStatus]}>
            <UserForm
              data={userInfoData}
              submitUrl={submitUrl}
              submitUrlMethodType={ApiMethodTypes.Put}
            />
          </ApiStatusWrapper>
        </Col>
      </Row>
    </AppContainer>
  );
};
