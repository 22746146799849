import React, { useEffect, useState } from "react";
import {
  ApiStatusWrapper,
  AppContainer,
  Offer,
  OffersMenu
} from "../components";

import { Layout } from "antd";
import { ApiMethodTypes, useApi } from "../hooks";
import {
  ApiStatus,
  DBFields,
  InternalLinks,
  VofferApi,
  CalcBidCategoryFromBidType
} from "../utils";

const { GetBidsInASubmittedSetUrl, GetSubmittedOfferUrl } = VofferApi;

export const SubmittedOffer = ({ history, match }) => {
  const {
    bidParamType,
    bidType,
    bidCategory,
    duid,
    submittedSetId
  } = match.params;

  const getSubmittedBidUrl = GetSubmittedOfferUrl({
    bidParamType: bidParamType,
    bidType: bidType,
    bidCategory: bidCategory,
    duid: duid,
    submittedSetId: submittedSetId
  });
  //region Get offers
  const [offers, setOffers] = useState({});

  const {
    sendRequest: bidsSendRequest,
    data: bidsData,
    apiStatus: bidsApiStatus
  } = useApi({ method: ApiMethodTypes.Get });

  //fetch submitted offers
  useEffect(
    () => {
      bidsSendRequest({
        url: GetBidsInASubmittedSetUrl({ submittedSetId: submittedSetId })
      });
    },
    [bidsSendRequest, submittedSetId]
  );

  //on update submitted offers
  useEffect(
    () => {
      if (bidsApiStatus === ApiStatus.Success && bidsData) {
        let dict = {};

        bidsData.forEach(row => {
          const duid = row[DBFields.Duid];
          const bidType = row[DBFields.BidType];
          const link = InternalLinks.SubmittedOffer({
            duid: row[DBFields.Duid],
            bidType: row[DBFields.BidType],
            bidCategory: CalcBidCategoryFromBidType({
              bidType: row[DBFields.BidType]
            }),
            submittedSetId: submittedSetId
          });
          if (!dict[duid]) dict[duid] = {};
          if (!dict[duid][bidType]) dict[duid][bidType] = {};

          dict[duid][bidType] = {
            link: link,
            metadata: row
          };
        });
        setOffers(dict);
      }
    },
    [bidsApiStatus, bidsData, submittedSetId]
  );

  //endregion
  return (
    <AppContainer history={history} match={match}>
      <ApiStatusWrapper statuses={[bidsApiStatus]}>
        <Layout>
          <Layout.Sider width={180} collapsed={false}>
            <OffersMenu availableOffers={offers} selected={{ duid, bidType }} />
          </Layout.Sider>
          <Layout style={{ overflow: "hidden" }}>
            <Layout.Content style={{ background: "#fff", padding: "8px" }}>
              <Offer
                duid={duid}
                //bidSettlementDate={bidSet} //todo get submitted offer date
                bidParamType={bidParamType}
                bidType={bidType}
                bidCategory={bidCategory}
                offerLabel={submittedSetId}
                getOfferUrl={getSubmittedBidUrl}
              />
            </Layout.Content>
          </Layout>
        </Layout>
      </ApiStatusWrapper>
    </AppContainer>
  );
};
