import React, { useCallback, useContext, useEffect, useState } from "react";
import { string } from "prop-types";
import { Row, Col } from "antd";
import {
  ApiStatusWrapper,
  AppContainer,
  DisplayLabelValueList,
  DisplayTime,
  DisplayTimeAgo,
  Grid,
  GridCellLink,
  InternalLink,
  SubmissionSteps,
  TitleSection
} from "../components";
import {
  ApiStatus,
  DBFields,
  GridConfigDefault,
  GridFields,
  MonitorTables,
  TransformToMonitorDict,
  VofferApi,
  AuthApi
} from "../utils";

import { ApiMethodTypes, useApi } from "../hooks";
import { CalcBidCategoryFromBidType } from "../utils";
import { InternalLinks } from "../utils";
import { GridCellTypes } from "../components";
import { GridDuidFields } from "../components/grid/grid_duid/GridDuidFields";
import { ThemeContext } from "../contexts";
import { useInterval } from "ahooks";
const {
  GetSubmittedSetInfoUrl,
  GetBidsInASubmittedSetUrl,
  GetConfigRebidCategoriesUrl,
  GetMonitorTablesUrl,
  GetStatusesInASubmittedSetUrl
} = VofferApi;
const { GetUsersUrl } = AuthApi;
// const GRID_HEIGHT_OFFSET = 200;
const MONITOR_INTERVAL = 5000;

export const SubmittedSet = ({ history, match }) => {
  const theme = useContext(ThemeContext);
  const { submittedSetId } = match.params;
  // const [gridHeight, setGridHeight] = useState(600);
  // const [gridApi, setGridApi] = useState();
  const [rowData, setRowData] = useState([]);
  const [bidStatusUpdateId, setBidStatusUpdateId] = useState();
  const [bidStages, setBidStages] = useState([]);
  const [userMappings, setUserMappings] = useState({});
  const [rebidCategoriesMappings, setRebidCategoriesMappings] = useState({});

  const {
    sendRequest: monitorSendRequest,
    data: monitorData,
    apiStatus: monitorApiStatus
  } = useApi({ method: ApiMethodTypes.Get });

  const {
    sendRequest: bidStatusSendRequest,
    data: bidStatusData,
    apiStatus: bidStatusApiStatus
  } = useApi({ method: ApiMethodTypes.Get });

  const {
    sendRequest: bidsSendRequest,
    data: bidsData,
    apiStatus: bidsApiStatus
  } = useApi({ method: ApiMethodTypes.Get });

  const {
    sendRequest: bidSetInfoSendRequest,
    data: bidSetInfoData,
    apiStatus: bidSetInfoApiStatus
  } = useApi({ method: ApiMethodTypes.Get });

  const {
    sendRequest: usersSendRequest,
    data: usersData,
    apiStatus: usersApiStatus
  } = useApi({ method: ApiMethodTypes.Get });

  const {
    sendRequest: configRebidCategorySendRequest,
    data: configRebidCategoryData,
    apiStatus: configRebidCategoryApiStatus
  } = useApi({ method: ApiMethodTypes.Get });

  const [bidSetInfoRows, setBidSetInfoRows] = useState([]);

  const columnDefs = [
    {
      headerName: "DUID",
      field: GridFields.Duid,
      rowGroupIndex: 0,
      hide: true
    },
    // {
    //   headerName: "Offer Type",
    //   field: GridFields.BidType,
    //   cellRenderer: GridCellTypes.GridCellLink,
    //   cellRendererParams: params => cellRenderParamsOffer(params)
    // },
    {
      headerName: "Bid Category",
      field: GridFields.BidParamType,
      cellRenderer: GridCellTypes.GridCellLink,
      cellRendererParams: params => cellRenderParamsOffer(params)
    }
  ];

  //once on mount
  // useEffect(() => {
  //   setGridHeight(window.innerHeight - GRID_HEIGHT_OFFSET);
  // }, []);

  //fetch monitor
  const fetchMonitor = useCallback(
    () => {
      const url = GetMonitorTablesUrl();
      monitorSendRequest({ url: url });
    },
    [monitorSendRequest]
  );

  useInterval(
    () => {
      fetchMonitor();
    },
    MONITOR_INTERVAL,
    { immediate: true }
  );

  //update monitor data
  useEffect(
    () => {
      if (monitorApiStatus === ApiStatus.Success && monitorData) {
        // parse data
        let monitors = TransformToMonitorDict(monitorData);

        setBidStatusUpdateId(monitors[MonitorTables.BidStatus]);
      }
    },
    [monitorData, monitorApiStatus]
  );
  //fetch users
  useEffect(
    () => {
      usersSendRequest({ url: GetUsersUrl() });
    },
    [usersSendRequest]
  );
  //update users data
  useEffect(
    () => {
      if (usersApiStatus === ApiStatus.Success && usersData) {
        let userMappings = {};

        usersData.forEach(row => {
          userMappings[row[DBFields.UserId]] = row[DBFields.UserDisplayName];
        });
        setUserMappings(userMappings);
      }
    },
    [usersData, usersApiStatus]
  );

  //fetch config rebid categories
  useEffect(
    () => {
      configRebidCategorySendRequest({ url: GetConfigRebidCategoriesUrl() });
    },
    [configRebidCategorySendRequest]
  );

  //update config rebid categories
  useEffect(
    () => {
      if (
        configRebidCategoryApiStatus === ApiStatus.Success &&
        configRebidCategoryData
      ) {
        //change to dict
        let dict = {};
        configRebidCategoryData.forEach(row => {
          dict[row[DBFields.RebidCategoryId]] =
            row[DBFields.RebidCategoryDescription];
        });
        setRebidCategoriesMappings(dict);
      }
    },
    [configRebidCategoryApiStatus, configRebidCategoryData]
  );

  const onGridReady = useCallback(params => {
    // setGridApi(params.api);
    //params.api.sizeColumnsToFit();
  }, []);

  const cellRenderParamsOffer = useCallback(params => {
    let value = null;
    let link = null;
    if (params.data) {
      value = params.data[GridFields.BidType];
      link = params.data[GridFields.SubmittedBidOfferViewLink];
    }

    return {
      value: value,
      link: link
    };
  }, []);

  //fetch bid set info
  useEffect(
    () => {
      bidSetInfoSendRequest({
        url: GetSubmittedSetInfoUrl({ submittedSetId: submittedSetId })
      });
    },
    [bidSetInfoSendRequest, submittedSetId]
  );
  //on update bid set info data
  useEffect(
    () => {
      if (
        bidSetInfoApiStatus === ApiStatus.Success &&
        bidSetInfoData &&
        bidSetInfoData.length > 0
      ) {
        const info = bidSetInfoData[0];
        let rows = [];
        rows.push(
          {
            label: "Bid Settlement Date",
            value: info[DBFields.BidSettlementDate]
          },
          {
            label: "Submitted By",
            value: userMappings[info[DBFields.SubmittedBy]]
          },
          {
            label: "Submitted At",
            value: <DisplayTimeAgo datetime={info[DBFields.SubmittedAt]} />
          },
          {
            label: "Rebid Reason",
            value: info[DBFields.RebidReason]
          },
          {
            label: "Rebid Category",
            value: rebidCategoriesMappings[info[DBFields.RebidCategory]]
          },
          {
            label: "Event Time",
            value: <DisplayTime datetime={info[DBFields.EventTime]} />
          },
          {
            label: "Aware Time",
            value: <DisplayTime datetime={info[DBFields.AwareTime]} />
          },
          {
            label: "Decision Time",
            value: <DisplayTime datetime={info[DBFields.DecisionTime]} />
          },
          {
            label: "AEMO Offer TimeStamp",
            value: info[DBFields.AemoOfferTimeStamp]
              ? info[DBFields.AemoOfferTimeStamp]
                  .replace("Z", "")
                  .replace("T", " ")
              : null
          },

          {
            label: "AEMO Transaction Id",
            value: (
              <span>
                {info[DBFields.AemoTransactionId] && (
                  <InternalLink
                    url={InternalLinks.AckSet({
                      aemoTransactionId: info[DBFields.AemoTransactionId]
                    })}
                    openInNewTab={true}
                  >
                    {info[DBFields.AemoTransactionId]}
                  </InternalLink>
                )}
              </span>
            )
          },
          {
            label: "Solve Id",
            value: (
              <span>
                {info[DBFields.SolveId] && (
                  <InternalLink
                    url={InternalLinks.Solve({
                      solveId: info[DBFields.SolveId],
                      bidSettlementDate: info[DBFields.BidSettlementDate]
                    })}
                    openInNewTab={true}
                  >
                    {info[DBFields.SolveId]}
                  </InternalLink>
                )}
              </span>
            )
          },
          {
            label: "Comments",
            value: info[DBFields.Comments]
          }
        );

        setBidSetInfoRows(rows);
      }
    },
    [bidSetInfoApiStatus, bidSetInfoData, userMappings, rebidCategoriesMappings]
  );

  //fetch bids
  useEffect(
    () => {
      const url = GetBidsInASubmittedSetUrl({ submittedSetId: submittedSetId });
      bidsSendRequest({ url: url });
    },
    [bidsSendRequest, submittedSetId]
  );

  //on update bids data
  useEffect(
    () => {
      if (bidsApiStatus === ApiStatus.Success && bidsData) {
        const newRows = bidsData.map(row => ({
          [GridFields.BidType]: row[DBFields.BidType],
          [GridFields.Duid]: row[DBFields.Duid],
          //[GridFields.FileURI]: row[DBFields.FileURI],
          [GridFields.SubmittedBidOfferViewLink]: InternalLinks.SubmittedOffer({
            duid: row[DBFields.Duid],
            bidType: row[DBFields.BidType],
            bidCategory: CalcBidCategoryFromBidType({
              bidType: row[DBFields.BidType]
            }),
            submittedSetId: submittedSetId
          })
        }));
        setRowData(newRows);
      }
    },
    [bidsApiStatus, bidsData, submittedSetId]
  );

  //fetch bid status

  useEffect(
    () => {
      if (bidStatusUpdateId) {
        const url = GetStatusesInASubmittedSetUrl({
          submittedSetId: submittedSetId
        });
        bidStatusSendRequest({ url: url });
      }
    },
    [bidStatusSendRequest, submittedSetId, bidStatusUpdateId]
  );

  //on update bids data
  useEffect(
    () => {
      if (bidStatusApiStatus === ApiStatus.Success && bidStatusData) {
        let dict = {};
        bidStatusData.forEach(s => {
          dict[s.stage_id] = s;
        });

        setBidStages(dict);
      }
    },
    [bidStatusApiStatus, bidStatusData]
  );
  return (
    <AppContainer history={history} match={match}>
      <Row gutter={16}>
        <Col md={8}>
          <TitleSection title={"Submission Info"} />
          <ApiStatusWrapper statuses={[bidSetInfoApiStatus]}>
            <div
              style={{
                backgroundColor: theme.containerBackgroundColor,
                border: `1px solid ${theme.borderColorSubtle}`,
                padding: "8px"
              }}
            >
              <DisplayLabelValueList rows={bidSetInfoRows} />
            </div>
          </ApiStatusWrapper>
          <ApiStatusWrapper statuses={[bidStatusApiStatus]}>
            <TitleSection title={"Submission Progress"} />
            <div
              style={{
                backgroundColor: theme.containerBackgroundColor,
                border: `1px solid ${theme.borderColorSubtle}`,
                padding: "8px"
              }}
            >
              <SubmissionSteps stages={bidStages} />
            </div>
          </ApiStatusWrapper>
        </Col>
        <Col md={16}>
          <ApiStatusWrapper statuses={[bidsApiStatus]}>
            <TitleSection title={"Submitted Bids in the set"} />
            <Grid
              statusBar={{
                statusPanels: [
                  {
                    statusPanel: "agTotalRowCountComponent",
                    align: "left"
                  }
                ]
              }}
              autoGroupColumnDef={{
                headerName: "Target",
                field: GridDuidFields.BidType,
                width: 200,
                suppressSizeToFit: true
              }}
              // gridHeight={gridHeight / 2}
              domLayout={"autoHeight"}
              groupDefaultExpanded={-1}
              defaultColDef={GridConfigDefault}
              columnDefs={columnDefs}
              rowData={rowData}
              enableRangeSelection={false}
              enableRangeHandle={false}
              enableCharts={false}
              suppressCellSelection={true}
              suppressContextMenu={true}
              suppressRowClickSelection={true}
              rowMultiSelectWithClick={true}
              onGridReady={onGridReady}
              frameworkComponents={{
                GridCellLink: GridCellLink
              }}
            />
          </ApiStatusWrapper>
        </Col>
      </Row>
    </AppContainer>
  );
};

SubmittedSet.propTypes = {
  action: string.isRequired,
  submittedSetId: string.isRequired
};
