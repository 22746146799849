import React from "react";
import { object, shape, bool, arrayOf, string, func } from "prop-types";
import { Radio } from "antd";

export const InputRadioButtons = ({
  style,
  options,
  disabled,
  value,
  onChange
}) => {
  const onChangeValue = e => {
    onChange(e.target.value);
  };

  return (
    <Radio.Group
      style={style}
      value={value}
      disabled={disabled}
      onChange={onChangeValue}
    >
      {options.map(opt => (
        <Radio.Button value={opt.value} key={opt.value}>
          {opt.text}
        </Radio.Button>
      ))}
    </Radio.Group>
  );
};

InputRadioButtons.propTypes = {
  style: object,
  options: arrayOf(
    shape({
      text: string,
      value: string
    })
  ),
  disabled: bool,
  value: string,
  onChange: func.isRequired
};
