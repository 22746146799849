export const GridDuidFields = {
  Duid: "duid", //note same as db
  BidType: "bid_type", //not same as db

  DefaultLastModifiedAt: "DefaultLastModifiedAt",
  DefaultLastModifiedBy: "DefaultLastModifiedBy",
  DefaultLinkEdit: "DefaultLinkEdit",
  DefaultLinkNew: "DefaultLinkNew",

  AutoStatus: "AutoStatus",
  AutoStatusLastModifiedAt: "AutoStatusLastModifiedAt",
  AutoStatusLastModifiedBy: "AutoStatusLastModifiedBy",
  AutoStatusLastModifiedNotes: "AutoStatusLastModifiedNotes",

  AckOfferViewLink: "AckOfferViewLink",
  AckHistorySetLink: "AckHistorySetLink",
  LatestAcknowledgedOfferAckAt: "LatestAcknowledgedOfferAckAt",
  LatestAcknowledgedOfferUser: "LatestAcknowledgedOfferUser",
  AckOfferAemoOfferTimeStamp: "AckOfferAemoOfferTimeStamp",
  AckOfferAemoAuthorisedBy: "AckOfferAemoAuthorisedBy",
  AckOfferLoadedBy: "AckOfferLoadedBy",
  AckOfferLoadedAt: "AckOfferLoadedAt",
  AckOfferAemoReferenceId: "AckOfferAemoReferenceId",
  AckOfferAemoTransactionId: "AckOfferAemoTransactionId",
  AckOfferAemoStatus: "AckOfferAemoStatus",
  AckOfferAemoMethod: "AckOfferAemoMethod",
  AckOfferAemoEntryType: "AckOfferAemoEntryType",
  AckOfferAemoRebidReason: "AckOfferAemoRebidReason",

  BidCheckReference: "BidCheckReference",
  BidCheckCurrent: "BidCheckCurrent",

  AlgoDraftViewLink: "AlgoDraftViewLink",
  LatestAlgoDraftLastModifiedAt: "LatestAlgoDraftLastModifiedAt",
  LatestAlgoDraftSolveId: "LatestAlgoDraftSolveId",
  SolveHistoryViewLink: "SolveHistoryViewLink",

  DraftLastModifiedAt: "DraftLastModifiedAt",
  DraftLinkEdit: "DraftLinkEdit",

  LastSubmissionSubmittedAt: "LastSubmissionSubmittedAt",
  LastSubmissionStageId: "LastSubmissionStageId",
  LastSubmissionStageIdStatusCombo: "LastSubmissionStageIdStatusCombo",
  LastSubmissionStageStatus: "LastSubmissionStageStatus",
  LastSubmissionLink: "LastSubmissionLink",
  LastSubmissionSubmissionId: "LastSubmissionSubmissionId",

  LastSubmissionSubmittedAtAlgo: "LastSubmissionSubmittedAtAlgo",
  LastSubmissionSubmissionIdAlgo: "LastSubmissionSubmissionIdAlgo",
  LastSubmissionStageIdAlgo: "LastSubmissionStageIdAlgo",
  LastSubmissionStageIdStatusComboAlgo: "LastSubmissionStageIdStatusComboAlgo",
  LastSubmissionStageStatusAlgo: "LastSubmissionStageStatusAlgo",
  LastSubmissionLinkAlgo: "LastSubmissionLinkAlgo",

  TemplateLastModifiedAt: "TemplateLastModifiedAt",
  TemplateLastModifiedBy: "TemplateLastModifiedBy",
  TemplateEditLink: "TemplateEditLink"
};
