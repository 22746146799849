import React from "react";
import { Result } from "antd";
import queryString from "query-string";

export const AuthError = ({ history, match }) => {
  const parsed = queryString.parse(history.location.search);
  const { message, errorId } = parsed;
  return (
    <div>
      <Result
        status={500}
        title={message ? message : "Something went wrong"}
        subTitle={`Try again, if error persists, please report to software developer. Error Id=${errorId}`}
      />
    </div>
  );
};
