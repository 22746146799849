import React from "react";
import { number, func, array, bool } from "prop-types";

import { InputControlTtime } from "./inputs/InputControlTtime";
import { FormItemInputNumber } from "./index";
import { FastStartProfileLabels } from "../utils/enums/index";
// import { TitleForm } from "../../titles";

export const FormFastStartProfile = ({
  canEdit,
  t1,
  t1Validations,
  t2,
  t2Validations,
  t3,
  t3Validations,
  t4,
  t4Validations,
  totalT,
  totalTValidations,
  minimumLoad,
  minimumLoadValidations,
  onChangeT1,
  onChangeT2,
  onChangeT3,
  onChangeT4,
  onChangeMinimumLoad
}) => {
  return (
    <>
      {/*<TitleForm title={"Fast Start Profile"} />*/}

      <FormItemInputNumber
        label={FastStartProfileLabels["minimumLoad"]}
        value={minimumLoad}
        validations={minimumLoadValidations}
        onChange={onChangeMinimumLoad}
        suffix={"MW"}
        required={true}
        inputStyle={{ width: "50%" }}
        disabled={!canEdit}
      />
      <InputControlTtime
        label={FastStartProfileLabels["t1"]}
        value={t1}
        validations={t1Validations}
        onChange={onChangeT1}
        disabled={!canEdit}
      />
      <InputControlTtime
        label={FastStartProfileLabels["t2"]}
        value={t2}
        validations={t2Validations}
        onChange={onChangeT2}
        disabled={!canEdit}
      />
      <InputControlTtime
        label={FastStartProfileLabels["t3"]}
        value={t3}
        validations={t3Validations}
        onChange={onChangeT3}
        disabled={!canEdit}
      />
      <InputControlTtime
        label={FastStartProfileLabels["t4"]}
        value={t4}
        validations={t4Validations}
        onChange={onChangeT4}
        disabled={!canEdit}
      />
      <InputControlTtime
        label={FastStartProfileLabels["totalT"]}
        value={totalT}
        validations={totalTValidations}
        disabled={true}
      />
    </>
  );
};

FormFastStartProfile.propTypes = {
  canEdit: bool.isRequired,
  t1: number.isRequired,
  t1Validations: array,
  t2: number.isRequired,
  t2Validations: array,
  t3: number.isRequired,
  t3Validations: array,
  t4: number.isRequired,
  t4Validations: array,
  totalT: number.isRequired,
  totalTValidations: array,
  minimumLoad: number.isRequired,
  minimumLoadValidations: array,
  onChangeT1: func.isRequired,
  onChangeT2: func.isRequired,
  onChangeT3: func.isRequired,
  onChangeT4: func.isRequired,
  onChangeMinimumLoad: func.isRequired
};
