import React, { useEffect } from "react";
import { ApiStatusWrapper, AppContainer, RoleForm } from "../components";
import { ApiMethodTypes, useApi } from "../hooks";
import { AuthApi } from "../utils/api";
import { Row, Col } from "antd";

export const Role = ({ history, match }) => {
  const { roleId } = match.params;

  const {
    sendRequest: roleInfoSendRequest,
    data: roleInfoData,
    apiStatus: roleInfoApiStatus
  } = useApi({ method: ApiMethodTypes.Get });

  //fetch user info data
  useEffect(
    () => {
      roleInfoSendRequest({ url: AuthApi.GetRoleByIdUrl({ roleId: roleId }) });
    },
    [roleId, roleInfoSendRequest]
  );
  const submitUrl = AuthApi.PutRoleByIdUrl({ roleId });

  return (
    <AppContainer match={match} history={history}>
      <Row gutter={16}>
        <Col xl={12} sm={24}>
          <ApiStatusWrapper statuses={[roleInfoApiStatus]}>
            <RoleForm
              data={roleInfoData}
              submitUrl={submitUrl}
              submitUrlMethodType={ApiMethodTypes.Put}
            />
          </ApiStatusWrapper>
        </Col>
      </Row>
    </AppContainer>
  );
};
