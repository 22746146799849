import React, { useCallback, useEffect, useState } from "react";
import { object, func, bool, array } from "prop-types";

import {
  Form,
  FormItem,
  InputRadioButtons,
  InputTextArea
} from "../../generic";
import { ActionDrawer } from "./index";
import { ActionTypeIds } from "../ActionTypeIds";
import { getActionTextFromId } from "../GetActionTextFromId";
import { ApiStatus, VofferApi } from "../../../utils";
import {
  ApiStatusWrapper,
  GridCellStyleAutoStatus,
  GridCellTypes
} from "../../index";
import { ActionTargetCheck } from "../ActionTargetCheck";
import { ActionChanges } from "../action_changes";
import { ChangeStatusTypes } from "../action_changes";
import { ApiMethodTypes, useApi } from "../../../hooks";
import { GridDuidFields } from "../../grid/grid_duid/GridDuidFields";
import { ValidationMessageType } from "../../offer_table/utils/enums";
import { ValidationMessage } from "../../validations/ValidationMessage";

const { PostAutoStatusUrl, GetConfigAutoStatusesUrl } = VofferApi;

export const FIELDS = {
  AutoStatusChange: "auto_status_change",
  LastModifiedNotesChange: "last_modified_notes_change"
};

export const ActionDrawerSwitchAutoBidding = React.memo(
  ({ drawerVisible, onDrawerClose, targets }) => {
    const [autoStatusOptions, setAutoStatusOptions] = useState([]);
    const [autoStatus, setAutoStatus] = useState();
    const [changes, setChanges] = useState([]);

    const {
      sendRequest: configAutoStatusSendRequest,
      data: configAutoStatusData,
      apiStatus: configAutoStatusApiStatus
    } = useApi({ method: ApiMethodTypes.Get });

    const {
      sendRequest: postAutoSubmitSendRequest,
      // data: postAutoSubmitData,
      apiStatus: postAutoSubmitApiStatus,
      clear: postAutoSubmitClear
    } = useApi({ method: ApiMethodTypes.Post });

    const changesCols = [
      {
        field: GridDuidFields.Duid,
        headerName: "Duid",
        width: 80,
        rowGroupIndex: 0,
        hide: true
      },
      {
        field: GridDuidFields.BidType,
        headerName: "Bid Type",
        width: 100,
        hide: true
      },
      {
        field: GridDuidFields.AutoStatus,
        headerName: "Current Submit Mode",
        width: 100,
        cellStyle: GridCellStyleAutoStatus
      },
      {
        field: "change",
        headerName: "Change Mode to",
        width: 100,
        cellStyle: GridCellStyleAutoStatus
      },
      {
        field: "validation",
        headerName: "Validation",
        cellRenderer: GridCellTypes.GridCellValidation
      },
      {
        field: "status",
        headerName: "Result",
        width: 100
      }
    ];

    const closeDrawer = useCallback(
      () => {
        postAutoSubmitClear();
        onDrawerClose();
      },
      [onDrawerClose, postAutoSubmitClear]
    );

    const handleCancel = useCallback(
      () => {
        closeDrawer();
      },
      [closeDrawer]
    );

    //fetch config auto statuses
    useEffect(
      () => {
        const url = GetConfigAutoStatusesUrl();
        configAutoStatusSendRequest({ url: url });
      },
      [configAutoStatusSendRequest]
    );

    //on update config auto statuses
    useEffect(
      () => {
        if (
          configAutoStatusApiStatus === ApiStatus.Success &&
          configAutoStatusData
        ) {
          const options = configAutoStatusData.map(item => ({
            value: item.auto_status,
            text: item.auto_status
          }));
          setAutoStatusOptions(options);
        }
      },
      [configAutoStatusData, configAutoStatusApiStatus, setAutoStatusOptions]
    );

    const handleSubmit = useCallback(
      fieldsValue => {
        const url = PostAutoStatusUrl();
        let data = [];
        changes.forEach(t => {
          if (t.status === ChangeStatusTypes.Update) {
            data.push({
              duid: t[GridDuidFields.Duid],
              bid_type: t[GridDuidFields.BidType],
              auto_status: fieldsValue[FIELDS.AutoStatusChange],
              last_modified_notes: fieldsValue[FIELDS.LastModifiedNotesChange]
                ? fieldsValue[FIELDS.LastModifiedNotesChange]
                : "" //always must pass, even blank to override previous one
            });
          }
        });

        postAutoSubmitSendRequest({ url: url, data: data });
      },
      [postAutoSubmitSendRequest, changes]
    );

    //on update post
    useEffect(
      () => {
        if (postAutoSubmitApiStatus === ApiStatus.Success) {
          closeDrawer();
        }
      },
      [postAutoSubmitApiStatus, closeDrawer]
    );

    useEffect(
      () => {
        let changes = [];
        targets.forEach(t => {
          if (autoStatus) {
            t.change = autoStatus;
            t.status = ChangeStatusTypes.NoChange;
            if (autoStatus === t[GridDuidFields.AutoStatus]) {
              t.validation = {
                type: ValidationMessageType.Warning,
                message: "No Change"
              };
              t.status = ChangeStatusTypes.NoChange;
            } else {
              t.validation = {
                type: ValidationMessageType.Success,
                message: "Pass"
              };
              t.status = ChangeStatusTypes.Update;
            }

            changes.push(t);
          }
        });

        setChanges(changes);
      },
      [autoStatus, setChanges, targets]
    );

    const onChangeAutoStatus = useCallback(
      value => {
        setAutoStatus(value);
      },
      [setAutoStatus]
    );

    const noChanges =
      !changes ||
      changes.length < 1 ||
      changes.filter(c => c.status === ChangeStatusTypes.Update).length < 1;

    const disableEdit =
      postAutoSubmitApiStatus === ApiStatus.Loading ||
      postAutoSubmitApiStatus === ApiStatus.Success ||
      noChanges;
    return (
      <ActionDrawer
        title={`${getActionTextFromId(ActionTypeIds.SwitchAutoBidding)}`}
        visible={drawerVisible}
        onClose={closeDrawer}
      >
        <ActionTargetCheck targetsCount={targets.length}>
          <ApiStatusWrapper statuses={[configAutoStatusApiStatus]}>
            <Form
              submitButtonText={"Switch"}
              onSubmit={handleSubmit}
              onCancel={handleCancel}
              apiStatus={postAutoSubmitApiStatus}
              labelSpan={8}
              disableEdit={disableEdit}
              wrapperFooter={
                noChanges ? (
                  <ValidationMessage
                    message={"No submit mode change to perform"}
                    messageType={ValidationMessageType.Warning}
                  />
                ) : null
              }
            >
              <FormItem
                label={"Switch Algo Auto Submit Mode To"}
                field={FIELDS.AutoStatusChange}
                isRequired={true}
                inputControl={
                  <InputRadioButtons
                    options={autoStatusOptions}
                    onChange={onChangeAutoStatus}
                  />
                }
              />
              <FormItem
                label={"Notes"}
                field={FIELDS.LastModifiedNotesChange}
                isRequired={false}
                inputControl={<InputTextArea rows={4} maxLength={200} />}
              />
              {changes &&
                changes.length > 0 && (
                  <ActionChanges cols={changesCols} changes={changes} />
                )}
            </Form>
          </ApiStatusWrapper>
        </ActionTargetCheck>
      </ActionDrawer>
    );
  }
);

ActionDrawerSwitchAutoBidding.propTypes = {
  style: object,
  drawerVisible: bool.isRequired,
  onDrawerClose: func,
  targets: array
};
