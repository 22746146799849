//transform in-state price bands data structure to format for validation display

import { PriceBandLabels } from "../enums";

export const TransformValidationPriceBands = priceBands => {
  let data = [];
  Object.keys(priceBands).forEach(field => {
    const validations = priceBands[field];
    if (validations && validations.length > 0) {
      data.push({
        fieldLabel: PriceBandLabels[field],
        validations: validations
      });
    }
  });
  return data;
};
