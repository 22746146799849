import React from "react";
import { NavLink } from "react-router-dom";
import { LinkOutlined } from "@ant-design/icons";
import { string, bool, object } from "prop-types";

export const InternalLink = ({ url, openInNewTab, children }) => (
  <NavLink to={url} target={openInNewTab && "_blank"}>
    <span>{openInNewTab && <LinkOutlined />} </span>
    {children}
  </NavLink>
);

InternalLink.propTypes = {
  url: string.isRequired,
  openInNewTab: bool,
  children: object
};

InternalLink.defaultProps = {
  openInNewTab: false
};
