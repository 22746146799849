import React, { useCallback, useEffect, useState } from "react";
import {
  ApiStatusWrapper,
  AppContainer,
  ButtonSecondary,
  Grid,
  GridCellTypes,
  GridCellTimeAgoLink
} from "../components";
import { ApiMethodTypes, useApi } from "../hooks";
import {
  GridFields,
  ApiStatus,
  DBFields,
  InternalLinks,
  GridConfigDefault,
  AuthApi
} from "../utils";
import { NavLink } from "react-router-dom";
import { PlusCircleOutlined } from "@ant-design/icons";
const GRID_HEIGHT_OFFSET = 225;

export const Users = ({ history, match }) => {
  const [gridHeight, setGridHeight] = useState(600);
  const [rowData, setRowData] = useState([]);
  const [columnDefs, setColumnDefs] = useState([]);

  // const [userMappings, setUserMappings] = useState({});
  const [roleMappings, setRoleMappings] = useState({});
  // const [gridApi, setGridApi] = useState();
  // const { loggedInUserId } = useContext(AppContext);
  const {
    sendRequest: usersSendRequest,
    data: usersData,
    apiStatus: usersApiStatus
  } = useApi({ method: ApiMethodTypes.Get });

  const {
    sendRequest: rolesSendRequest,
    data: rolesData,
    apiStatus: rolesApiStatus
  } = useApi({ method: ApiMethodTypes.Get });

  useEffect(
    () => {
      const cols = [
        {
          headerName: "User Display Name",
          field: GridFields.UserDisplayName
        },
        {
          headerName: "User Id",
          field: GridFields.UserId
        },
        {
          headerName: "External User Id",
          field: GridFields.ExternalUserId
        },
        {
          headerName: "Role Id",
          field: GridFields.RoleId,
          refData: roleMappings
          // rowGroupIndex: 2
        },
        {
          headerName: "User Type",
          field: GridFields.UserType,
          rowGroupIndex: 1

          // hide: true
        },
        {
          headerName: "Inactive",
          field: GridFields.Inactive,
          rowGroupIndex: 0
        },
        {
          headerName: "Last modified at",
          field: GridFields.LastModifiedAt,
          cellRenderer: GridCellTypes.GridCellTimeAgoLink,
          cellRendererParams: params =>
            params.data
              ? {
                  time: params.data[GridFields.LastModifiedAt],
                  link: params.data[GridFields.UserEditLink]
                }
              : null
        }
      ];
      setColumnDefs(cols);
    },
    [setColumnDefs, roleMappings]
  );

  const onGridReady = useCallback(params => {
    // setGridApi(params.api);
    params.api.sizeColumnsToFit();
  }, []);

  const getRowNodeId = useCallback(data => {
    return data[GridFields.UserId];
  }, []);

  const resizeGrid = useCallback(() => {
    setGridHeight(window.innerHeight - GRID_HEIGHT_OFFSET);
  }, []);

  const fetchUsers = useCallback(
    () => {
      const getUsersUrl = AuthApi.GetUsersUrl();
      usersSendRequest({ url: getUsersUrl });
    },
    [usersSendRequest]
  );

  const fetchRoles = useCallback(
    () => {
      rolesSendRequest({ url: AuthApi.GetRolesUrl() });
    },
    [rolesSendRequest]
  );

  //once on mount
  useEffect(
    () => {
      resizeGrid();
      fetchUsers();
      fetchRoles();
    },
    [resizeGrid, fetchUsers, fetchRoles]
  );

  //on user data
  useEffect(
    () => {
      if (usersApiStatus === ApiStatus.Success) {
        let newRows = [];
        // let userMappings = {};
        usersData.forEach(row => {
          newRows.push({
            [GridFields.UserId]: row[DBFields.UserId],
            [GridFields.UserType]: row[DBFields.UserType],
            [GridFields.UserDisplayName]: row[DBFields.UserDisplayName],
            [GridFields.UserEditLink]: InternalLinks.User({
              userId: row[DBFields.UserId]
            }),
            [GridFields.LastModifiedAt]: row[DBFields.LastModifiedAt],
            [GridFields.ExternalUserId]: row[DBFields.ExternalUserId],
            [GridFields.RoleId]: row[DBFields.RoleId],
            [GridFields.Inactive]: !row[DBFields.Inactive]
              ? "Active"
              : "Inactive"
          });
          // userMappings[row[DBFields.UserId]] = row[DBFields.UserDisplayName];
        });
        // setUserMappings(userMappings);
        setRowData(newRows);
      }
    },
    [usersApiStatus, usersData]
  );

  //on role data
  useEffect(
    () => {
      if (rolesApiStatus === ApiStatus.Success) {
        let mappings = {};
        rolesData.forEach(row => {
          mappings[row[DBFields.RoleId]] = row[DBFields.RoleName];
        });

        setRoleMappings(mappings);
      }
    },
    [rolesApiStatus, rolesData]
  );
  return (
    <AppContainer match={match} history={history}>
      <ButtonSecondary icon={<PlusCircleOutlined />}>
        <NavLink to={InternalLinks.UserNew}>&nbsp;Add new User</NavLink>
      </ButtonSecondary>

      <ApiStatusWrapper statuses={[usersApiStatus, rolesApiStatus]}>
        <Grid
          gridHeight={gridHeight}
          defaultColDef={GridConfigDefault}
          autoGroupColumnDef={{
            headerName: "Group by"
            // field: GridFields.UserType,
            //width: 200
          }}
          columnDefs={columnDefs}
          rowData={rowData}
          groupDefaultExpanded={-1}
          suppressCellSelection={true}
          suppressContextMenu={true}
          suppressRowClickSelection={true}
          enableCellChangeFlash={true}
          onGridReady={onGridReady}
          getRowNodeId={getRowNodeId}
          frameworkComponents={{
            GridCellTimeAgoLink: GridCellTimeAgoLink
          }}
        />
      </ApiStatusWrapper>
    </AppContainer>
  );
};
