import {
  ValidationMessageCompareGreaterThanEqual,
  ValidationMessageInRange,
  ValidationMessageRequired
} from "../validation_messages";
import {
  CheckValueGreaterThanEqual,
  CheckValueInRange,
  CheckValueRequired
} from "../checks";
import { FieldLabels, GridFields, ValidationMessageType } from "../enums";

// Enablement Max
// >= High Breakpoint
// Range (0 - maxEnableMax)
export const ValidateEnablementMax = ({
  value,
  highBreakPointValue,
  minEnablementMin,
  maxEnablementMax
}) => {
  let validations = [];

  //Validate: value is required
  const check1 = CheckValueRequired(value);
  if (!check1)
    validations.push(ValidationMessageRequired(ValidationMessageType.Error));

  //Validate: value is in range
  const check2 = CheckValueInRange({
    value,
    min: minEnablementMin,
    max: maxEnablementMax
  });
  if (!check2)
    validations.push(
      ValidationMessageInRange({
        messageType: ValidationMessageType.Error,
        min: minEnablementMin,
        max: maxEnablementMax
      })
    );

  //Validate: value is greater than or equal to high breakpoint
  const check3 = CheckValueGreaterThanEqual({
    value,
    compareValue: highBreakPointValue
  });
  if (!check3)
    validations.push(
      ValidationMessageCompareGreaterThanEqual({
        messageType: ValidationMessageType.Error,
        fieldLabel: FieldLabels[GridFields.EnablementMax],
        fieldValue: value,
        compareFieldLabel: FieldLabels[GridFields.HighBreakPoint],
        compareFieldValue: highBreakPointValue
      })
    );

  return validations;
};
