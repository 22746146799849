import React, { useCallback, useEffect, useState } from "react";
import { shape, string, func } from "prop-types";
import { Checkbox } from "antd";
import { TitleSection } from "../titles";
import { Form, FormItem, InputText, InputSelect } from "../generic/form";
import { ApiMethodTypes, useApi } from "../../hooks";
import { AuthApi } from "../../utils/api";
import { ApiStatus } from "../../utils/constants";
import { ApiStatusWrapper } from "../ApiStatusWrapper";
import { DBFields } from "../../utils/constants/DBFields";

const FIELDS = {
  UserDisplayName: "UserDisplayName",
  RoleId: "RoleId",
  ExternalUserId: "ExternalUserId",
  UserType: "UserType",
  Inactive: "Inactive"
};

export const UserForm = ({
  data,
  submitUrl,
  submitUrlMethodType,
  onSaveSuccess
}) => {
  let userDisplayName = "";
  let roleId = "";
  let externalUserId = "";
  let userType = "";
  let inactive = false;
  if (data) {
    userDisplayName = data[DBFields.UserDisplayName];
    roleId = data[DBFields.RoleId];
    externalUserId = data[DBFields.ExternalUserId];
    userType = data[DBFields.UserType];
    inactive = data[DBFields.Inactive];
  }

  //SUBMIT
  const {
    sendRequest: submitSendRequest,
    apiStatus: submitApiStatus,
    data: submitData
  } = useApi({ method: submitUrlMethodType });

  const handleSubmit = useCallback(
    fieldsValue => {
      const data = {
        [DBFields.UserDisplayName]: fieldsValue[FIELDS.UserDisplayName],
        [DBFields.RoleId]: fieldsValue[FIELDS.RoleId],
        [DBFields.ExternalUserId]: fieldsValue[FIELDS.ExternalUserId],
        [DBFields.UserType]: fieldsValue[FIELDS.UserType],
        [DBFields.Inactive]: fieldsValue[FIELDS.Inactive]
      };

      if (!submitUrl) {
        console.error("submitUrl not passed in handleSubmit in UserForm.jsx");
        return;
      }
      submitSendRequest({ url: submitUrl, data: data });
    },
    [submitSendRequest, submitUrl]
  );

  //on update post submit
  useEffect(
    () => {
      if (
        submitApiStatus === ApiStatus.Success &&
        submitData &&
        onSaveSuccess
      ) {
        onSaveSuccess({ data: submitData });
      }
    },
    [onSaveSuccess, submitApiStatus, submitData]
  );

  //ROLES OPTIONS
  const [rolesOptions, setRolesOptions] = useState([]);

  const {
    sendRequest: rolesSendRequest,
    apiStatus: rolesApiStatus,
    data: rolesData
  } = useApi({ method: ApiMethodTypes.Get });

  //fetch roles
  useEffect(
    () => {
      rolesSendRequest({ url: AuthApi.GetRolesUrl() });
    },
    [rolesSendRequest]
  );
  //roles data
  useEffect(
    () => {
      if (rolesApiStatus === ApiStatus.Success && rolesData) {
        let rolesOptions = [];
        rolesData.forEach(r => {
          rolesOptions.push({
            value: r.role_id,
            text: r.role_name
          });
        });
        setRolesOptions(rolesOptions);
      }
    },
    [rolesApiStatus, rolesData, setRolesOptions]
  );

  //USER TYPES OPTIONS
  const [userTypesOptions, setUserTypesOptions] = useState([]);

  const {
    sendRequest: userTypesSendRequest,
    apiStatus: userTypesApiStatus,
    data: userTypesData
  } = useApi({ method: ApiMethodTypes.Get });

  //fetch user types
  useEffect(
    () => {
      userTypesSendRequest({ url: AuthApi.GetUserTypesUrl() });
    },
    [userTypesSendRequest]
  );

  //userTypes data
  useEffect(
    () => {
      if (userTypesApiStatus === ApiStatus.Success && userTypesData) {
        let options = [];
        userTypesData.forEach(row => {
          options.push({
            value: row.user_type,
            text: row.user_type
          });
        });
        setUserTypesOptions(options);
      }
    },
    [userTypesApiStatus, userTypesData, setUserTypesOptions]
  );

  return (
    <>
      {/*<ApiStatusWrapper statuses={[deleteOfferApiStatus]}>*/}
      {/*<Redirect to={InternalLinks.DeleteSuccess} />*/}
      {/*</ApiStatusWrapper>*/}
      {/*{showDeleteButton && (*/}
      {/*<ButtonDelete onClick={onDeleteSetInfo} style={{ float: "right" }}>*/}
      {/*Delete Template Set*/}
      {/*</ButtonDelete>*/}
      {/*)}*/}
      <TitleSection title={"User Info"} />
      <ApiStatusWrapper statuses={[rolesApiStatus, userTypesApiStatus]}>
        <Form
          onSubmit={handleSubmit}
          apiStatus={submitApiStatus}
          labelSpan={12}
          initialValues={{
            [FIELDS.UserDisplayName]: userDisplayName,
            [FIELDS.RoleId]: roleId,
            [FIELDS.ExternalUserId]: externalUserId,
            [FIELDS.UserType]: userType,
            [FIELDS.Inactive]: inactive
          }}
          // disableEdit={disableEdit}
          submitButtonText={"Save Changes"}
          showCancelButton={false}
        >
          <FormItem
            label={"User Type"}
            field={FIELDS.UserType}
            isRequired={true}
            inputControl={<InputSelect options={userTypesOptions} />}
          />
          <FormItem
            label={"User Display Name"}
            field={FIELDS.UserDisplayName}
            isRequired={true}
            inputControl={<InputText />}
          />
          <FormItem
            label={"Role"}
            field={FIELDS.RoleId}
            isRequired={true}
            inputControl={<InputSelect options={rolesOptions} />}
            itemProps={{
              extra:
                "Changes to role can take up to 2 hours to propagate for user. Alternatively, ask user to logout and login again."
            }}
          />
          <FormItem
            label={"External User Id"}
            field={FIELDS.ExternalUserId}
            isRequired={true}
            itemProps={{ extra: "Set to user email for manual users" }}
            inputControl={<InputText />}
          />
          <FormItem
            label={"Inactive"}
            field={FIELDS.Inactive}
            isRequired={false}
            itemProps={{ valuePropName: "checked" }}
            inputControl={<Checkbox />}
          />
        </Form>
      </ApiStatusWrapper>
    </>
  );
};

UserForm.propTypes = {
  data: shape({
    // user_id: string.isRequired,
    external_user_id: string.isRequired,
    role_id: string.isRequired,
    user_display_name: string.isRequired,
    user_type: string.isRequired,
    last_modified_at: string.isRequired
  }),
  submitUrl: string.isRequired,
  submitUrlMethodType: string.isRequired,
  onSaveSuccess: func.isRequired
};
