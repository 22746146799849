import React from "react";
import { string, number } from "prop-types";

import "./iconBid.css";

export const IconBid = ({ size, color }) => (
  <svg
    height={size}
    width={size}
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    x="0px"
    y="0px"
    viewBox="-434 236 90 90"
    // style={{ enableBackground: "new -434 236 90 90" }}
  >
    <g>
      <g>
        <polygon
          className="iconBid"
          points="-353.1,314.7 -424.9,314.7 -424.9,258.2 -424.9,247.3 -353.1,247.3   "
        />
        <path
          className="iconBid"
          d="M-424.9,258.8c5.3,0,10.5,0,15.6,0c24,0,45.2,0,56.2,0"
        />
        <polyline
          className="iconBid"
          points="-409.3,258.8 -409.3,267.9 -409.3,276 -409.3,284.1 -409.3,292.2 -409.3,300.3 -409.3,308.5     -409.3,314.7   "
        />
        <polyline
          className="iconBid"
          points="-409.3,267.9 -398,267.9 -387.1,267.9 -376.2,267.9 -365.3,267.9 -353.1,267.9   "
        />
        <polyline
          className="iconBid"
          points="-409.3,276 -398,276 -387.1,276 -376.2,276 -365.3,276 -353.1,276   "
        />
        <polyline
          className="iconBid"
          points="-409.3,284.1 -398,284.1 -387.1,284.1 -376.2,284.1 -365.3,284.1 -353.1,284.1   "
        />
        <polyline
          className="iconBid"
          points="-409.3,292.2 -398,292.2 -387.1,292.2 -376.2,292.2 -365.3,292.2 -353.1,292.2   "
        />
        <polyline
          className="iconBid"
          points="-409.3,300.3 -398,300.3 -387.1,300.3 -376.2,300.3 -365.3,300.3 -353.1,300.3   "
        />
        <polyline
          className="iconBid"
          points="-409.3,307.4 -398,307.4 -387.1,307.4 -376.2,307.4 -365.3,307.4 -353.1,307.4   "
        />
        <path
          className="iconBid"
          d="M-398,267.9c0,2.4,0,5.1,0,8.1c0,2.6,0,5.3,0,8.1c0,2.7,0,5.4,0,8.1s0,5.5,0,8.1c0,2.8,0,5.6,0,8.1    c0,2.3,0,4.4,0,6.2"
        />
        <path
          className="iconBid"
          d="M-387.1,267.9c0,2.4,0,5.1,0,8.1c0,2.6,0,5.3,0,8.1c0,2.7,0,5.4,0,8.1s0,5.5,0,8.1c0,2.8,0,5.6,0,8.1    c0,2.3,0,4.4,0,6.2"
        />
        <path
          className="iconBid"
          d="M-376.2,267.9c0,2.4,0,5.1,0,8.1c0,2.6,0,5.3,0,8.1c0,2.7,0,5.4,0,8.1s0,5.5,0,8.1c0,2.8,0,5.6,0,8.1    c0,2.3,0,4.4,0,6.2"
        />
        <path
          className="iconBid"
          d="M-365.3,267.9c0,2.4,0,5.1,0,8.1c0,2.6,0,5.3,0,8.1c0,2.7,0,5.4,0,8.1s0,5.5,0,8.1c0,2.8,0,5.6,0,8.1    c0,2.3,0,4.4,0,6.2"
        />
      </g>
    </g>
  </svg>
);

IconBid.propTypes = {
  size: number.isRequired,
  color: string.isRequired
};
