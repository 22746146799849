export const DBFields = {
  UserId: "user_id",
  UserType: "user_type",
  UserDisplayName: "user_display_name",
  ExternalUserId: "external_user_id",
  LastModifiedAt: "last_modified_at",
  RoleId: "role_id",
  RoleName: "role_name",
  Description: "description",
  Scopes: "scopes",
  Inactive: "inactive",
  ParameterName: "parameter_name",
  ParameterId: "parameter_id",
  ParameterType: "parameter_type",
  LastModifiedBy: "last_modified_by",
  LastModifiedNotes: "last_modified_notes",
  SourceId: "source_id",
  SourceName: "source_name",
  SourceShortName: "source_short_name",
  SettlementDate: "settlement_date",
  Val: "val",
  AvgVal: "avg_val",
  MaxVal: "max_val",
  MinVal: "min_val",
  FromDateTime: "from_date_time",
  ToDateTime: "to_date_time",
  MinFromDateTime: "min_from_date_time",
  MaxToDateTime: "max_to_date_time",
  Priority: "priority",
  DefaultPriority: "default_priority",
  OutageId: "outage_id",
  RebidReason: "rebid_reason",
  DI: "di",
  Duid: "duid",
  BidType: "bid_type",
  Revenue: "revenue",
  Service: "service",
  GenLiability: "gen_liability",
  GenLiabilityDollarsPerMW: "gen_liability_dollars_per_mw",
  RRP: "rrp",
  TWRRP: "twrrp",
  VWRRP: "vwrrp",
  Volume: "volume",
  Amount: "amount",
  EnergyRevenueImpact: "energy_revenue_impact",
  FuelValue: "fuel_value",
  Earnings: "earnings",
  AlgoId: "algo_id",
  AlgoName: "algo_name",
  IsActive: "is_active",
  AutoStatus: "auto_status",
  StageId: "stage_id",
  Status: "status",
  StartedAt: "started_at",
  FinishedAt: "finished_at",
  LogFileUri: "log_file_uri",
  SubmittedBy: "submitted_by",
  SubmittedAt: "submitted_at",
  SubmittedBidSetId: "submitted_bid_set_id",
  RebidCategory: "rebid_category",
  EventTime: "event_time",
  AwareTime: "aware_time",
  DecisionTime: "decision_time",
  BidSettlementDate: "bid_settlement_date",
  LatestStageId: "latest_stage_id",
  LatestStatus: "latest_status",
  // AemoAckStatus: "aemo_ack_status",
  AemoTransactionId: "aemo_transaction_id",
  AemoOfferTimeStamp: "aemo_offer_time_stamp",
  AemoAuthorisedBy: "aemo_authorised_by",
  LoadedBy: "loaded_by",
  LoadedAt: "loaded_at",
  AemoReferenceId: "aemo_reference_id",
  AemoStatus: "aemo_status",
  AemoMethod: "aemo_method",
  AemoEntryType: "aemo_entry_type",
  AemoRebidReason: "aemo_rebid_reason",
  AemoRebidCategory: "aemo_rebid_category",
  AemoRebidEventTime: "aemo_rebid_event_time",
  AemoRebidAwareTime: "aemo_rebid_aware_time",
  AemoRebidDecisionTime: "aemo_rebid_decision_time",
  TemplateSetName: "template_set_name",
  TemplateSetId: "template_set_id",
  IsTeam: "is_team",
  FileURI: "file_uri",
  IsRebidPeriod: "is_rebid_period",
  RebidCategoryDescription: "rebid_category_description",
  RebidCategoryId: "rebid_category_id",
  IsLoaded: "is_loaded",
  SolveId: "solve_id",
  PeriodId: "period_id",
  PeriodTime: "periodtime",
  BidSource: "bid_source",
  PriceBand1: "price_band1",
  PriceBand2: "price_band2",
  PriceBand3: "price_band3",
  PriceBand4: "price_band4",
  PriceBand5: "price_band5",
  PriceBand6: "price_band6",
  PriceBand7: "price_band7",
  PriceBand8: "price_band8",
  PriceBand9: "price_band9",
  PriceBand10: "price_band10",
  Band1: "band1",
  Band2: "band2",
  Band3: "band3",
  Band4: "band4",
  Band5: "band5",
  Band6: "band6",
  Band7: "band7",
  Band8: "band8",
  Band9: "band9",
  Band10: "band10",
  FixedLoad: "fixed_load",
  PasaAvail: "pasa_avail",
  RampUpRate: "ramp_up_rate",
  RampDownRate: "ramp_down_rate",
  MaxAvail: "max_avail",
  HighBreakPoint: "high_break_point",
  LowBreakPoint: "low_break_point",
  EnablementMax: "enablement_max",
  EnablementMin: "enablement_min",
  MaxCap: "maxcap",
  LimitVolume: "lv",
  TraderPrice1: "tp1",
  TraderPrice2: "tp2",
  TraderPrice3: "tp3",
  Utilisation: "util",
  MLF: "mlf",
  DeltaLimitVolume: "delta_lv",
  SRMC: "srmc",
  ForecastRRP: "frrp",
  BreakEvenRRP: "berrp",
  OptimalVolume: "ov",
  CurrentVolume: "cv",
  MinDV: "min_dv",
  MaxDV: "max_dv",
  P5RunDateTime: "p5_run_datetime",
  PdRunDateTime: "pd_run_datetime",
  Comments: "comments",
  WasOptimised: "was_optimised",
  HasChanged: "has_changed",
  BandsMWChanged: "bands_mw_change",
  ModifiedBidSource: "modified_bid_source",
  TotalBids: "total_bids",
  SentForSubmissionBids: "sent_for_submission_bids",
  Units: "units",
  AbsoluteMin: "absolute_min",
  AbsoluteMax: "absolute_max",
  ReferenceBidCheck: "reference_bid_check",
  CurrentBidCheck: "current_bid_check",
  ExpectedReferenceBidAemoTransactionId:
    "expected_reference_bid_aemo_transaction_id",
  ExpectedCurrentBidAemoTransactionId:
    "expected_current_bid_aemo_transaction_id",
  AlgoAemoTransactionId: "algo_aemo_transaction_id",
  AlgoReferenceAemoTransactionId: "algo_reference_aemo_transaction_id",
  AlgoCurrentAemoTranscationId: "algo_current_aemo_transaction_id",
  AlgoSolveId: "algo_solve_id",
  TriggerAemoTransactionId: "trigger_aemo_transaction_id"
};
