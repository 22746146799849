import React from "react";
import { object } from "prop-types";
import appIcon from "./app-icon.svg";

export const AppLogo = ({ style }) => (
  <img
    src={appIcon}
    alt={"appIcon"}
    style={{
      width: "40px",
      height: "40px",
      margin: "auto",
      display: "block",
      ...style
    }}
  />
);

AppLogo.propTypes = {
  style: object
};
