import React, { useEffect, useState } from "react";
import { string } from "prop-types";
import { Col, Row } from "antd";
import {
  ApiStatusWrapper,
  AppContainer,
  GridDuid,
  TemplateSetInfo,
  TitleSection
} from "../components";
import { ApiStatus, DBFields, VofferApi } from "../utils";
import { ApiMethodTypes, useApi } from "../hooks";
import { ActionConfigTemplateSet } from "../components/actions/action_configs";

export const TemplateSet = ({ history, match }) => {
  const { templateSetId } = match.params;

  const [templateSetName, setTemplateSetName] = useState("");
  const [templateSetDesc, setTemplateSetDesc] = useState("");
  const [templateIsTeam, setTemplateIsTeam] = useState();

  const {
    sendRequest: templateSetInfoSendRequest,
    data: templateSetInfoData,
    apiStatus: templateSetInfoApiStatus
  } = useApi({ method: ApiMethodTypes.Get });

  //fetch template set info
  useEffect(
    () => {
      const templateSetInfoUrl = VofferApi.GetTemplateSetInfoUrl({
        templateSetId
      });
      templateSetInfoSendRequest({ url: templateSetInfoUrl });
    },
    [templateSetInfoSendRequest, templateSetId]
  );

  //update template set info data
  useEffect(
    () => {
      if (
        templateSetInfoApiStatus === ApiStatus.Success &&
        templateSetInfoData &&
        templateSetInfoData.length > 0 //expecting one array result
      ) {
        setTemplateSetName(templateSetInfoData[0][DBFields.TemplateSetName]);
        setTemplateSetDesc(templateSetInfoData[0][DBFields.Description]);
        setTemplateIsTeam(templateSetInfoData[0][DBFields.IsTeam]);
      }
    },
    [templateSetInfoApiStatus, templateSetInfoData]
  );

  const submitUrl = VofferApi.PutTemplateSetInfoUrl({
    templateSetId: templateSetId
  });

  const deleteUrl = VofferApi.DeleteTemplateSetInfoUrl({
    templateSetId: templateSetId
  });
  return (
    <AppContainer history={history} match={match}>
      <Row gutter={8}>
        <Col md={8}>
          <ApiStatusWrapper statuses={[templateSetInfoApiStatus]}>
            {templateSetName && (
              <TemplateSetInfo
                submitUrl={submitUrl}
                submitUrlMethodType={ApiMethodTypes.Put}
                showDeleteButton={true}
                deleteUrl={deleteUrl}
                templateSetName={templateSetName}
                templateIsTeam={templateIsTeam}
                templateSetDesc={templateSetDesc}
              />
            )}
          </ApiStatusWrapper>
        </Col>
        <Col md={16}>
          <TitleSection
            title={"Templates in this Set"}
            style={{ marginTop: 0 }}
          />
          <GridDuid
            gridHeightOffset={300}
            showAutoStatus={true}
            showTemplateOffers={true}
            templateSetId={templateSetId}
            allowSelection={true}
            actionConfig={ActionConfigTemplateSet}
          />
        </Col>
      </Row>
    </AppContainer>
  );
};

TemplateSet.propTypes = {
  templateSetId: string.isRequired
};
