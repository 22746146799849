import React, { useEffect, useState } from "react";
import {
  ApiStatusWrapper,
  AppContainer,
  Offer,
  OffersMenu
} from "../components";

import { Layout } from "antd";
import { ApiStatus, DBFields, InternalLinks, VofferApi } from "../utils";
import { CalcBidCategoryFromBidType } from "../utils";
import { ApiMethodTypes, useApi } from "../hooks";

const { GetAlgoBidUrl, GetAlgoOffersUrl } = VofferApi;

export const AlgoOffer = ({ history, match }) => {
  const { bidType, bidCategory, duid, bidSettlementDate } = match.params;
  const getAlgoBidUrl = GetAlgoBidUrl({
    bidType: bidType,
    bidCategory: bidCategory,
    duid: duid,
    bidSettlementDate: bidSettlementDate
  });

  //region algo offers
  const [offers, setOffers] = useState({});
  const {
    sendRequest: algoOffersSendRequest,
    data: algoOffersData,
    apiStatus: algoOffersApiStatus
  } = useApi({ method: ApiMethodTypes.Get });
  //fetch algo offer
  useEffect(
    () => {
      algoOffersSendRequest({
        url: GetAlgoOffersUrl({
          bidSettlementDate: bidSettlementDate
        })
      });
    },
    [bidSettlementDate, algoOffersSendRequest]
  );

  //on update algo offer data

  useEffect(
    () => {
      if (algoOffersApiStatus === ApiStatus.Success && algoOffersData) {
        let dict = {};
        algoOffersData.forEach(row => {
          const duid = row[DBFields.Duid];
          const bidType = row[DBFields.BidType];
          const link = InternalLinks.AlgoOffer({
            duid: duid,
            bidType: bidType,
            bidCategory: CalcBidCategoryFromBidType({
              bidType: bidType
            }),
            bidSettlementDate: bidSettlementDate
          });
          if (!dict[duid]) dict[duid] = {};
          if (!dict[duid][bidType]) dict[duid][bidType] = {};

          dict[duid][bidType] = {
            link: link,
            metadata: row
          };
        });
        setOffers(dict);
      }
    },
    [algoOffersData, algoOffersApiStatus, bidSettlementDate]
  );
  //endregion
  return (
    <AppContainer history={history} match={match}>
      <ApiStatusWrapper statuses={[algoOffersApiStatus]}>
        <Layout>
          <Layout.Sider width={180} collapsed={false}>
            <OffersMenu availableOffers={offers} selected={{ duid, bidType }} />
          </Layout.Sider>
          <Layout style={{ overflow: "hidden" }}>
            <Layout.Content style={{ background: "#fff", padding: "8px" }}>
              <Offer
                duid={duid}
                bidSettlementDate={bidSettlementDate}
                bidType={bidType}
                bidCategory={bidCategory}
                offerLabel={bidSettlementDate}
                getOfferUrl={getAlgoBidUrl}
              />
            </Layout.Content>
          </Layout>
        </Layout>
      </ApiStatusWrapper>
    </AppContainer>
  );
};
