import moment from "moment";
import { IntervalInMins } from "../config";

export const CalcTimeFromPeriodId = periodId => {
  if (periodId !== null) {
    const startTime = moment()
      .hour(4)
      .minute(0);
    const periodTime = startTime.add(periodId * IntervalInMins, "m");

    return periodTime.format("HH:mm");
  }

  return null;
};
