export const PriceBandLabels = {
  priceBand1: "Price Band 1",
  priceBand2: "Price Band 2",
  priceBand3: "Price Band 3",
  priceBand4: "Price Band 4",
  priceBand5: "Price Band 5",
  priceBand6: "Price Band 6",
  priceBand7: "Price Band 7",
  priceBand8: "Price Band 8",
  priceBand9: "Price Band 9",
  priceBand10: "Price Band 10"
};
