import React, { useCallback, useEffect, useState } from "react";
import { Space, Row, Col, Card } from "antd";
import { AppContainer } from "../components";
import { ApiMethodTypes, useApi, useStateWithSessionStorage } from "../hooks";

import { ApiStatus, DBFields, MonitorTables, VofferApi } from "../utils";
import {
  AckOfferChartDashboard,
  ApiStatusWrapper,
  DatePickerSettlementDate,
  DisplayAutoStatus,
  DividerSection,
  InputSelect,
  TitleHeader
} from "../components";
import moment from "moment";
import { BidType } from "../components/offer_table/utils/enums";
import { TransformToMonitorDict } from "../utils";
import { useInterval } from "ahooks";
const MONITOR_INTERVAL = 5000;
const {
  GetAutoStatusUrl,
  GetEnabledDuidBidTypesUrl,
  GetMonitorTablesUrl
} = VofferApi;

export const Home = ({ history, match }) => {
  //region enabled duid bid types
  // const [duids, setDuids] = useState([]);
  const [bidTypes, setBidTypes] = useState([]);
  const [enabledDuidBidTypes, setEnabledDuidBidTypes] = useState({});
  const {
    sendRequest: enabledDuidsSendRequest,
    data: enabledDuidsData,
    apiStatus: enabledDuidsApiStatus
  } = useApi({ method: ApiMethodTypes.Get });

  //fetch enabledDuids
  useEffect(
    () => {
      const enabledDuidsUrl = GetEnabledDuidBidTypesUrl();
      enabledDuidsSendRequest({ url: enabledDuidsUrl });
    },
    [enabledDuidsSendRequest]
  );

  //update enabled duids data
  useEffect(
    () => {
      if (enabledDuidsApiStatus === ApiStatus.Success && enabledDuidsData) {
        // let duids = new Set();
        let bidTypes = new Set();
        let enabled = {};
        enabledDuidsData.forEach(row => {
          const duid = row[DBFields.Duid];
          const bidType = row[DBFields.BidType];
          // duids.add(duid);
          bidTypes.add(bidType);
          if (!enabled[duid]) enabled[duid] = [];
          enabled[duid].push(bidType);
        });
        let bidTypeOptions = [];
        bidTypes.forEach(item => {
          bidTypeOptions.push({ text: item, value: item });
        });
        // setDuids([...duids]); //using spread operator to convert Set to array
        setBidTypes(bidTypeOptions);
        setEnabledDuidBidTypes(enabled);
      }
    },
    [enabledDuidsData, enabledDuidsApiStatus]
  );
  //endregion

  //region bid type
  const [bidType, setBidType] = useState(BidType.Energy);
  const onChangeBidType = useCallback(
    bidType => {
      setBidType(bidType);
    },
    [setBidType]
  );

  //endregion

  //region bid settlement date
  const [bidSettlementDate, setBidSettlementDate] = useStateWithSessionStorage(
    "GridDuid.bidSettlementDate"
  );
  //on load
  useEffect(
    () => {
      if (bidSettlementDate === "") {
        setBidSettlementDate(moment().format("YYYY-MM-DD"));
      }
    },
    [bidSettlementDate, setBidSettlementDate]
  );
  const onChangeBidSettlementDate = useCallback(
    date => {
      setBidSettlementDate(date);
    },
    [setBidSettlementDate]
  );
  //endregion

  //region Chart
  const [containerSize, setContainerSize] = useState({
    width: 1000,
    height: 1000
  });
  useEffect(
    () => {
      setContainerSize({
        width: window.innerWidth - 320,
        height: window.innerHeight - 160
      });
    },
    [setContainerSize]
  );
  //endregion

  //region Auto Status
  const {
    sendRequest: autoStatusSendRequest,
    data: autoStatusData,
    apiStatus: autoStatusApiStatus
  } = useApi({ method: ApiMethodTypes.Get });
  const [autoStatusUpdateId, setAutoStatusUpdateId] = useState(null);
  const [autoStatuses, setAutoStatuses] = useState({});
  //fetch auto status
  useEffect(
    () => {
      if (autoStatusUpdateId) {
        const url = GetAutoStatusUrl();
        autoStatusSendRequest({ url });
      }
    },
    [autoStatusSendRequest, autoStatusUpdateId]
  );
  const GetAutoStatusKey = ({ duid, bidType }) => `${duid}_${bidType}`;
  //on update auto status data
  useEffect(
    () => {
      if (autoStatusApiStatus === ApiStatus.Success && autoStatusData) {
        let dict = {};
        autoStatusData.forEach(row => {
          const duid = row[DBFields.Duid];
          const bidType = row[DBFields.BidType];
          const key = GetAutoStatusKey({ duid, bidType });

          dict[key] = row[DBFields.AutoStatus];
        });
        setAutoStatuses(dict);
      }
    },
    [autoStatusApiStatus, autoStatusData, setAutoStatuses]
  );

  //endregion

  //region monitor
  const [ackOfferUpdateId, setAckOfferUpdateId] = useState(null);

  const {
    sendRequest: monitorSendRequest,
    data: monitorData,
    apiStatus: monitorApiStatus
  } = useApi({ method: ApiMethodTypes.Get });

  //fetch monitor
  const fetchMonitor = useCallback(
    () => {
      monitorSendRequest({ url: GetMonitorTablesUrl() });
    },
    [monitorSendRequest]
  );

  //start monitor polling
  useInterval(
    () => {
      fetchMonitor();
    },
    MONITOR_INTERVAL,
    { immediate: true }
  );

  //on update monitor data
  useEffect(
    () => {
      if (monitorApiStatus === ApiStatus.Success && monitorData) {
        // parse data
        let monitors = TransformToMonitorDict(monitorData);
        setAutoStatusUpdateId(monitors[MonitorTables.AutoStatus]);
        setAckOfferUpdateId(monitors[MonitorTables.AckOffers]);
      }
    },
    [monitorData, monitorApiStatus]
  );
  //endregion
  return (
    <AppContainer
      history={history}
      match={match}
      //  style={{ backgroundColor: "#eee" }}
    >
      <Row gutter={[8, 8]}>
        <Col xxl={4} xl={6} lg={6} md={8} sm={24} xs={24}>
          <Card
            title={
              <TitleHeader
                title={"SUBMIT MODE"}
                style={{ marginBottom: "8px" }}
              />
            }
          >
            <ApiStatusWrapper
              statuses={[enabledDuidsApiStatus, autoStatusApiStatus]}
            >
              {autoStatuses &&
                Object.keys(autoStatuses).length > 0 &&
                Object.keys(enabledDuidBidTypes).map(duid => (
                  <div key={`${duid}_auto_status`}>
                    <DividerSection orientation={"left"}>{duid}</DividerSection>
                    {enabledDuidBidTypes[duid].map(bidType => (
                      <Row
                        key={`${duid}_${bidType}_auto_status`}
                        gutter={[4, 4]}
                      >
                        <Col xl={16} lg={14} md={14} sm={12} xs={24}>
                          <div style={{ padding: "6px 8px" }}>{bidType}</div>
                        </Col>
                        <Col xl={8} lg={10} md={10} sm={12} xs={24}>
                          <DisplayAutoStatus
                            status={
                              autoStatuses[
                                GetAutoStatusKey({
                                  duid,
                                  bidType
                                })
                              ]
                            }
                          />
                        </Col>
                      </Row>
                    ))}
                  </div>
                ))}
            </ApiStatusWrapper>
          </Card>
        </Col>
        <Col xxl={20} xl={18} lg={18} md={16} sm={24} xs={24}>
          <ApiStatusWrapper statuses={[enabledDuidsApiStatus]}>
            <Card
              title={
                <div>
                  <Space size={"large"}>
                    <TitleHeader title={"ACTIVE BIDS"} />

                    <Space>
                      <label>Settlement date:</label>
                      <DatePickerSettlementDate
                        onChange={onChangeBidSettlementDate}
                        value={bidSettlementDate}
                      />
                    </Space>

                    <Space>
                      <label>Bid Type: </label>
                      <InputSelect
                        style={{ width: "130px" }}
                        onChange={onChangeBidType}
                        value={bidType}
                        options={bidTypes}
                      />
                    </Space>
                  </Space>
                </div>
              }
            >
              <div>
                {Object.keys(enabledDuidBidTypes).map(duid => (
                  <div key={duid}>
                    {enabledDuidBidTypes[duid]
                      .filter(b => b === bidType)
                      .map(bidType => (
                        <AckOfferChartDashboard
                          key={`${duid}_${bidType}`}
                          duid={duid}
                          bidType={bidType}
                          bidSettlementDate={bidSettlementDate}
                          containerSize={{
                            // width: containerSize.width - 500, set to auto
                            height: containerSize.height / 2 - 128
                          }}
                          ackOfferUpdateId={ackOfferUpdateId}
                        />
                      ))}
                  </div>
                ))}
              </div>
            </Card>
          </ApiStatusWrapper>
        </Col>
      </Row>
    </AppContainer>
  );
};

Home.propTypes = {};
