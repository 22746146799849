import moment from "moment";

import { Domain } from "../../config";
const BASE_URL = `https://api.parameters.${Domain}`;

const BID_STRING_PARAMETER = {
  DispatchType: "distype",
  StartType: "starttype",
  IsDailyEnergyConstraint: "isenercons"
};

const BID_PARAMETER = {
  MLF: "mlf",
  MaxBidAvail: "maxcap",
  MaxBidPrice: "maxbidpric",
  MinBidPrice: "minbidpric",
  MaxRocUp: "maxrocup",
  MaxRocDown: "maxrocdown",
  MaxLowerAngle: "maxlowang",
  MaxUpperAngle: "maxupang",
  MinEnablementLevel: "minenalev",
  MaxEnablementLevel: "maxenalev"
};

export const ParametersApi = {
  GetDispatchTypeUrl: ({ duid, bidType }) =>
    GetBidStringParamValuesRecordUrl({
      duid,
      bidType,
      parameterId: BID_STRING_PARAMETER.DispatchType
    }),
  GetStartTypeUrl: ({ duid, bidType }) =>
    GetBidStringParamValuesRecordUrl({
      duid,
      bidType,
      parameterId: BID_STRING_PARAMETER.StartType
    }),
  GetIsDailyEnergyConstraintUrl: ({ duid, bidType }) =>
    GetBidStringParamValuesRecordUrl({
      duid,
      bidType,
      parameterId: BID_STRING_PARAMETER.IsDailyEnergyConstraint
    }),
  GetMlfUrl: ({ duid, bidType, bidSettlementDate }) =>
    GetBidParamValuesComputedAtStartOfDayUrl({
      duid,
      bidType,
      bidSettlementDate,
      parameterId: BID_PARAMETER.MLF
    }),
  GetMaxBidAvailUrl: ({ duid, bidType, bidSettlementDate }) =>
    GetBidParamValuesComputedAtStartOfDayUrl({
      duid,
      bidType,
      bidSettlementDate,
      parameterId: BID_PARAMETER.MaxBidAvail
    }),
  GetMaxBidPriceUrl: ({ duid, bidType, bidSettlementDate }) =>
    GetBidParamValuesComputedAtStartOfDayUrl({
      duid,
      bidType,
      bidSettlementDate,
      parameterId: BID_PARAMETER.MaxBidPrice
    }),
  GetMinBidPriceUrl: ({ duid, bidType, bidSettlementDate }) =>
    GetBidParamValuesComputedAtStartOfDayUrl({
      duid,
      bidType,
      bidSettlementDate,
      parameterId: BID_PARAMETER.MinBidPrice
    }),
  GetMaxRocUpUrl: ({ duid, bidType, bidSettlementDate }) =>
    GetBidParamValuesComputedAtStartOfDayUrl({
      duid,
      bidType,
      bidSettlementDate,
      parameterId: BID_PARAMETER.MaxRocUp
    }),
  GetMaxRocDownUrl: ({ duid, bidType, bidSettlementDate }) =>
    GetBidParamValuesComputedAtStartOfDayUrl({
      duid,
      bidType,
      bidSettlementDate,
      parameterId: BID_PARAMETER.MaxRocDown
    }),
  GetMaxUpperAngleUrl: ({ duid, bidType, bidSettlementDate }) =>
    GetBidParamValuesComputedAtStartOfDayUrl({
      duid,
      bidType,
      bidSettlementDate,
      parameterId: BID_PARAMETER.MaxUpperAngle
    }),
  GetMaxLowerAngleUrl: ({ duid, bidType, bidSettlementDate }) =>
    GetBidParamValuesComputedAtStartOfDayUrl({
      duid,
      bidType,
      bidSettlementDate,
      parameterId: BID_PARAMETER.MaxLowerAngle
    }),
  GetMaxEnablementLevelUrl: ({ duid, bidType, bidSettlementDate }) =>
    GetBidParamValuesComputedAtStartOfDayUrl({
      duid,
      bidType,
      bidSettlementDate,
      parameterId: BID_PARAMETER.MaxEnablementLevel
    }),
  GetMinEnablementLevelUrl: ({ duid, bidType, bidSettlementDate }) =>
    GetBidParamValuesComputedAtStartOfDayUrl({
      duid,
      bidType,
      bidSettlementDate,
      parameterId: BID_PARAMETER.MinEnablementLevel
    }),
  GetConfigParams: ({ categoryId }) =>
    `${BASE_URL}/v1/bid_config/parameters?${
      categoryId ? `category_id=${categoryId}` : ""
    }`,
  GetConfigBidParamById: ({ bidParameterId }) =>
    `${BASE_URL}/v1/bid_config/parameters/${bidParameterId}`,
  GetConfigStringParams: () => `${BASE_URL}/v1/bid_string_config/parameters`,
  GetSettingsParamsDuidBidTypesUrl: ({ categoryId }) =>
    `${BASE_URL}/v1/bid_settings/parameters_duid_bid_types?${
      categoryId ? `category_id=${categoryId}` : ""
    }`,
  GetSettingsStringParamsDuidBidTypesUrl: () =>
    `${BASE_URL}/v1/bid_string_settings/parameters_duid_bid_types`,
  // GetSettingsDuidsUrl: () => `${BASE_URL}/v1/bid_settings/duids`,
  GetBidParamValueBySettingsSingleUrl: ({ settlementDate, categoryId }) =>
    `${BASE_URL}/v1/bid_param_value_by_settings/single?settlement_date=${settlementDate}&${
      categoryId ? `category_id=${categoryId}` : ""
    }`,
  GetBidParamValueInfoDatesBySettingsUrl: ({ categoryId }) =>
    `${BASE_URL}/v1/bid_param_value_info_by_settings/dates?${
      categoryId ? `category_id=${categoryId}` : ""
    }`,
  GetBidParamValueInfoLastModifiedBySettingsUrl: ({ categoryId }) =>
    `${BASE_URL}/v1/bid_param_value_info_by_settings/last_modified?${
      categoryId ? `category_id=${categoryId}` : ""
    }`,
  GetBidStringParamValueInfoBySettingsUrl: () =>
    `${BASE_URL}/v1/bid_string_param_value_info_by_settings`,
  GetBidParamValueInfoDatesUrl: ({ duid, bidType, parameterId }) =>
    `${BASE_URL}/v1/bid_param_value_info/dates?duid=${duid}&bid_type=${bidType}&parameter_id=${parameterId}`,
  GetBidParamValuesRecordsUrl: ({ duid, bidType, parameterId }) =>
    `${BASE_URL}/v1/bid_param_value_records?duid=${duid}&bid_type=${bidType}&parameter_id=${parameterId}`,
  GetBidStringParamValuesRecordUrl: ({ duid, bidType, parameterId }) =>
    `${BASE_URL}/v1/bid_string_param_value_record?duid=${duid}&bid_type=${bidType}&parameter_id=${parameterId}`,
  PostBidParamValueRecordUrl: () => `${BASE_URL}/v1/bid_param_value_record`,
  PutBidStringParamValueRecordUrl: () =>
    `${BASE_URL}/v1/bid_string_param_value_record`,
  GetBidParamValuesComputedUrl: ({
    duid,
    bidType,
    parameterId,
    startTime,
    stopTime,
    timeStep
  }) =>
    `${BASE_URL}/v1/bid_param_values_computed?duid=${duid}&bid_type=${bidType}&parameter_id=${parameterId}&start_time=${startTime}&stop_time=${stopTime}&time_step=${timeStep}`,
  GetGeneralConfigParams: () => `${BASE_URL}/v1/general_config/parameters`,
  GetGeneralParamValueInfoDatesByParamsUrl: () =>
    `${BASE_URL}/v1/general_param_value_info_by_params/dates`,
  GetGeneralParamValueInfoLastModifiedByParamsUrl: () =>
    `${BASE_URL}/v1/general_param_value_info_by_params/last_modified`,
  GetGeneralParamValueInfoDatesUrl: ({ parameterId }) =>
    `${BASE_URL}/v1/general_param_value_info/dates?parameter_id=${parameterId}`,
  GetGeneralParamValuesRecordsUrl: ({ parameterId }) =>
    `${BASE_URL}/v1/general_param_value_records?parameter_id=${parameterId}`,
  PostGeneralParamValueRecordUrl: () =>
    `${BASE_URL}/v1/general_param_value_record`,
  GetGeneralParamValuesComputedUrl: ({
    parameterId,
    startTime,
    stopTime,
    timeStep
  }) =>
    `${BASE_URL}/v1/general_param_values_computed?parameter_id=${parameterId}&start_time=${startTime}&stop_time=${stopTime}&time_step=${timeStep}`
};

const GetBidStringParamValuesRecordUrl = ({ duid, bidType, parameterId }) =>
  `${BASE_URL}/v1/bid_string_param_value_record?duid=${duid}&bid_type=${bidType}&parameter_id=${parameterId}`;

const GetBidParamValuesComputedAtStartOfDayUrl = ({
  duid,
  bidType,
  bidSettlementDate,
  parameterId
}) => {
  const date = bidSettlementDate
    ? moment(bidSettlementDate, "YYYY-MM-DD")
    : moment().format("YYYY-MM-DD"); //if no bid settlement date, set to current day
  const startTime = moment(date, "YYYY-MM-DD").format("YYYY-MM-DD 04:00:00");
  const stopTime = moment(date, "YYYY-MM-DD").format("YYYY-MM-DD 04:05:00");

  return GetBidParamValuesComputedUrl({
    duid,
    bidType,
    parameterId,
    startTime,
    stopTime,
    timeStep: 5
  });
};

const GetBidParamValuesComputedUrl = ({
  duid,
  bidType,
  parameterId,
  startTime,
  stopTime,
  timeStep
}) =>
  `${BASE_URL}/v1/bid_param_values_computed?duid=${duid}&bid_type=${bidType}&parameter_id=${parameterId}&start_time=${startTime}&stop_time=${stopTime}&time_step=${timeStep}`;
