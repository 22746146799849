import React from "react";
import { object, any } from "prop-types";
import { Button } from "antd";

export const ButtonLink = props => (
  <Button type={"link"} size={"small"} {...props}>
    {props.children}
  </Button>
);

ButtonLink.propTypes = {
  style: object,
  children: any
};
