import React from "react";
import { object } from "prop-types";
import { ButtonPrimary } from "../generic";
import { InternalLinks } from "../../utils";

export const ButtonBackOffers = ({ style }) => (
  <ButtonPrimary href={InternalLinks.Offers} size={"large"} style={style}>
    Go to Bids
  </ButtonPrimary>
);

ButtonBackOffers.propTypes = {
  style: object
};
