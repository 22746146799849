export const GridCellTypes = {
  GridCellTimeAgoLink: "GridCellTimeAgoLink",
  GridCellTimeAgo: "GridCellTimeAgo",
  GridCellTime: "GridCellTime",
  GridCellLink: "GridCellLink",
  GridCellSubmissionStatus: "GridCellSubmissionStatus",
  GridCellTooltipValidations: "GridCellTooltipValidations",
  GridCellValidation: "GridCellValidation",
  GridCellBidCheck: "GridCellBidCheck"
};
