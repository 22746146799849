import React, { useEffect, useState, useCallback, useContext } from "react";
import commaNumber from "comma-number";
import moment from "moment";
import {
  ApiStatusWrapper,
  AppContainer,
  Grid,
  DatePickerSettlementDate,
  ButtonPrimary,
  InputSelect
} from "../components";
import { ApiMethodTypes, useApi } from "../hooks";
import {
  ApiStatus,
  DBFields,
  GridConfigDefault,
  GridFields,
  VadvisorApi
} from "../utils";
import { ThemeContext } from "../contexts";
import { Form, Space } from "antd";

const GRID_HEIGHT_OFFSET = 230;

const SourceId = {
  ManualActual: "manual_actual",
  AlgoWhatIf: "algo_whatif"
};
// const sources = [
//   { name: "Algo What If", key: SourceId.AlgoWhatIf },
//   { name: "Manual Actual", key: SourceId.ManualActual }
// ];

const variables = [
  { name: "Earnings", key: DBFields.Earnings, aggFunc: "sum" },
  {
    name: "VWRRP",
    key: DBFields.VWRRP,
    headerTooltip: "Volume Weighted RRP",
    decimalPoint: 2
  },
  {
    name: "TWRRP",
    key: DBFields.TWRRP,
    decimalPoint: 2,
    columnGroupShow: "open",
    headerTooltip: "Time Weighted RRP"
  },
  {
    name: "Fuel Value",
    key: DBFields.FuelValue,
    columnGroupShow: "open",
    aggFunc: "sum"
  },
  {
    name: "Service Revenue",
    key: DBFields.Revenue,
    columnGroupShow: "open",
    aggFunc: "sum"
  },
  {
    name: "Energy Revenue Impact",
    key: DBFields.EnergyRevenueImpact,
    columnGroupShow: "open",
    aggFunc: "sum"
  },
  {
    name: "Volume",
    key: DBFields.Volume,
    //columnGroupShow: "open",
    aggFunc: "sum"
  }
];

export const ReportUnitComparisonSummary = ({ history, match }) => {
  const theme = useContext(ThemeContext);
  const [fromDateTime, setFromDateTime] = useState();
  const [toDateTime, setToDateTime] = useState();

  const [duids, setDuids] = useState([]);
  const [duid1, setDuid1] = useState();
  const [duid2, setDuid2] = useState();
  const [source] = useState(SourceId.ManualActual);
  const [selectedAlgoId] = useState("pd_sutil");

  const [settDate, setSettDate] = useState(
    moment()
      //.add(-3, "d") //temp set to load 3 days ago
      .format("YYYY-MM-DD")
  );

  //region DUIDs

  const {
    sendRequest: settingsDuidBidTypesSendRequest,
    data: settingsDuidBidTypesData,
    apiStatus: settingsDuidBidTypesApiStatus
  } = useApi({ method: ApiMethodTypes.Get });

  //fetch data
  useEffect(() => {
    if (selectedAlgoId)
      settingsDuidBidTypesSendRequest({
        url: VadvisorApi.SettingsDuidBidTypesByAlgoId({
          algoId: selectedAlgoId
        })
      });
  }, [settingsDuidBidTypesSendRequest, selectedAlgoId]);

  //on getting data
  useEffect(() => {
    if (settingsDuidBidTypesApiStatus === ApiStatus.Success) {
      let dataSet = new Set();
      settingsDuidBidTypesData.forEach(row => {
        dataSet.add(row[DBFields.Duid]);
      });

      let data = [];
      dataSet.forEach(duid => {
        data.push({
          value: duid,
          text: duid
        });
      });
      setDuids(data);
      if (data.length > 1) {
        setDuid1(data[1].value);
        setDuid2(data[0].value);
      }
    }
  }, [settingsDuidBidTypesApiStatus, settingsDuidBidTypesData]);

  const onChangeDuid1 = useCallback(value => {
    setDuid1(value);
  }, []);

  const onChangeDuid2 = useCallback(value => {
    setDuid2(value);
  }, []);

  //endregion

  //region Dates
  const onChangeSettDate = useCallback(date => {
    setSettDate(date);
  }, []);

  //on sett date change
  useEffect(() => {
    const fromDateTime = moment(settDate).format("YYYY-MM-DD 00:00:00");
    const toDateTime = moment(settDate)
      .add(1, "d")
      .format("YYYY-MM-DD 00:00:00");

    setFromDateTime(fromDateTime);
    setToDateTime(toDateTime);
  }, [settDate]);

  //endregion

  // //region config algos
  //
  // const {
  //   sendRequest: configAlgoSendRequest,
  //   data: configAlgoData,
  //   apiStatus: configAlgoApiStatus
  // } = useApi({ method: ApiMethodTypes.Get });
  //
  // //fetch data
  // useEffect(
  //   () => {
  //     configAlgoSendRequest({
  //       url: VadvisorApi.ConfigAlgos()
  //     });
  //   },
  //   [configAlgoSendRequest]
  // );
  //
  // //on getting data
  // useEffect(
  //   () => {
  //     if (configAlgoApiStatus === ApiStatus.Success) {
  //       let data = [];
  //       configAlgoData.forEach(row => {
  //         if (row[DBFields.IsActive])
  //           data.push({
  //             value: row[DBFields.AlgoId],
  //             text: row[DBFields.AlgoName]
  //           });
  //       });
  //       setAlgos(data);
  //       if (data.length > 0) setSelectedAlgoId(data[0].value); //select first one
  //     }
  //   },
  //   [configAlgoApiStatus, configAlgoData]
  // );
  //
  // const onChangeAlgoId = useCallback(value => {
  //   setSelectedAlgoId(value);
  // }, []);
  //
  // //endregion

  const {
    sendRequest: reportSendRequest,
    data: reportData,
    apiStatus: reportApiStatus
  } = useApi({ method: ApiMethodTypes.Get });

  const fetchReport = useCallback(() => {
    if (
      fromDateTime &&
      toDateTime &&
      fromDateTime !== "Invalid date" &&
      toDateTime !== "Invalid date"
    )
      reportSendRequest({
        url: VadvisorApi.ReportActualManualPerformanceDay({
          fromDateTime: fromDateTime,
          toDateTime: toDateTime
        })
      });
  }, [reportSendRequest, fromDateTime, toDateTime]);

  //fetch data
  useEffect(() => {
    fetchReport();
  }, [fetchReport]);

  const getVariableKey = ({ source, variableName, duid }) =>
    `${source}_${variableName}_${duid}`;

  //on getting data
  useEffect(() => {
    if (reportApiStatus === ApiStatus.Success) {
      let newRows = [];

      //convert array to dict
      let dataDict = {};

      reportData.forEach(row => {
        const duid = row[DBFields.Duid];
        const service = row[DBFields.Service];

        if (!dataDict[service]) dataDict[service] = {};

        const source = row[DBFields.SourceName];

        [
          DBFields.TWRRP,
          DBFields.VWRRP,
          DBFields.Volume,
          DBFields.Revenue,
          DBFields.FuelValue,
          DBFields.Earnings,
          DBFields.EnergyRevenueImpact
        ].forEach(variableName => {
          const vKey = getVariableKey({ source, variableName, duid });

          dataDict[service][vKey] = row[variableName];
        });
      });
      Object.keys(dataDict).forEach(service => {
        let row = {};
        [duid1, duid2].forEach(duid => {
          row[GridFields.Duid] = duid;
          row[GridFields.BidType] = service;
          variables.forEach(variable => {
            const vKey = getVariableKey({
              source: source,
              variableName: variable.key,
              duid: duid
            });

            row[vKey] = dataDict[service][vKey];
          });
        });
        newRows.push(row);
      });
      setRowData(newRows);
    }
  }, [reportApiStatus, reportData, source, duid1, duid2]);
  //region Grid
  const [gridHeight, setGridHeight] = useState(600);
  const [rowData, setRowData] = useState([]);
  const onGridReady = useCallback(params => {
    // setGridApi(params.api);
    //params.api.sizeColumnsToFit();
  }, []);

  const getRowNodeId = useCallback(data => {
    return data[GridFields.SettlementDate];
  }, []);

  const resizeGrid = useCallback(() => {
    setGridHeight(window.innerHeight - GRID_HEIGHT_OFFSET);
  }, []);

  //once on mount
  useEffect(() => {
    resizeGrid();
  }, [resizeGrid]);

  const [columnDefs, setColumnDefs] = useState([]);

  useEffect(() => {
    let cols = [
      // {
      //   headerName: "DUID",
      //   field: GridFields.Duid,
      //   width: 100,
      //   rowGroupIndex: 0,
      //   hide: true
      // },
      {
        headerName: "Bid Type",
        field: GridFields.BidType,
        width: 100,
        pinned: "left"
      }
    ];

    //add diff cols

    let diffChildren = [];
    variables.forEach(variable => {
      diffChildren.push({
        headerName: variable.name,
        type: "numericColumn",
        width: 120,
        headerTooltip: variable.headerTooltip
          ? variable.headerTooltip
          : variable.name,

        aggFunc: variable.aggFunc,
        valueFormatter: params =>
          formatNumber({ params, decimalPoint: variable.decimalPoint }),
        cellStyle: params => {
          if (params.value < 0)
            return { backgroundColor: theme.negativeValueBackgroundColor };
          else return null;
        },
        columnGroupShow: variable.columnGroupShow,

        valueGetter: params => {
          if (params && params.data) {
            const duid1Value =
              params.data[
                getVariableKey({
                  source: source,
                  variableName: variable.key,
                  duid: duid1
                })
              ];
            const duid2Value =
              params.data[
                getVariableKey({
                  source: source,
                  variableName: variable.key,
                  duid: duid2
                })
              ];
            let diff;
            if (
             duid1Value !== null &&
             duid1Value !== undefined &&
             duid2Value !== null &&
             duid2Value !== undefined
            )
              diff = duid1Value - duid2Value;
            return diff;
          } else return null;
        }
      });
    });

    cols.push({
      headerName: `Difference (${duid1} - ${duid2})`,
      children: diffChildren
    });

    //add each duids cols
    [duid1, duid2].forEach(duid => {
      let duidChildren = [];

      variables.forEach(variable => {
        const vKey = getVariableKey({
          source: source,
          variableName: variable.key,
          duid: duid
        });
        duidChildren.push({
          headerName: variable.name,
          headerTooltip: variable.headerTooltip
            ? variable.headerTooltip
            : variable.name,
          field: vKey,
          width: 120,
          aggFunc: variable.aggFunc,
          columnGroupShow: variable.columnGroupShow,
          cellStyle: params => {
            if (params.value < 0)
              return {
                backgroundColor: theme.negativeValueBackgroundColor
              };
            else return null;
          },
          type: "numericColumn",
          valueFormatter: params =>
            formatNumber({ params, decimalPoint: variable.decimalPoint })
        });
      });
      cols.push({
        headerName: duid,
        children: duidChildren
      });
    });
    setColumnDefs(cols);
  }, [theme, duid1, duid2, source]);

  const formatNumber = ({ params, decimalPoint = 0 }) => {
    return params.value !== undefined && params.value !== null
      ? commaNumber(parseFloat(params.value).toFixed(decimalPoint))
      : params.value;
  };
  //endregion

  return (
    <AppContainer history={history} match={match}>
      <ApiStatusWrapper
        statuses={[reportApiStatus]}
        style={{ position: "absolute", top: "49%", left: "49%", zIndex: 10 }}
      />
      {/*<ApiStatusWrapper statuses={[configAlgoApiStatus]}>*/}
      <Space size={"large"}>
        {/*<Space size={"small"}>*/}
        {/*  Algo:*/}
        {/*  <InputSelect*/}
        {/*    value={selectedAlgoId}*/}
        {/*    options={algos}*/}
        {/*    style={{ width: "150px" }}*/}
        {/*    onChange={onChangeAlgoId}*/}
        {/*  />*/}
        {/*</Space>*/}

        <Form.Item label="Date">
          <DatePickerSettlementDate
            value={settDate}
            onChange={onChangeSettDate}
          />
        </Form.Item>
        <Form.Item label="DUID 1">
          <InputSelect
            style={{ width: "150px" }}
            value={duid1}
            options={duids}
            onChange={onChangeDuid1}
          />
        </Form.Item>
        <Form.Item label="DUID 2">
          <InputSelect
            style={{ width: "150px" }}
            value={duid2}
            options={duids}
            onChange={onChangeDuid2}
          />
        </Form.Item>
        <Form.Item>
          <ButtonPrimary onClick={fetchReport}>Refresh</ButtonPrimary>
        </Form.Item>
      </Space>
      {/*</ApiStatusWrapper>*/}

      <Grid
        gridHeight={gridHeight}
        defaultColDef={GridConfigDefault}
        columnDefs={columnDefs}
        rowData={rowData}
        // autoGroupColumnDef={{ headerName: "DUID", width: 130, pinned: "left" }}
        defaultExportParams={{
          allColumns: true
        }}
        groupDefaultExpanded={-1}
        groupIncludeFooter={true}
        groupIncludeTotalFooter={true}
        enableRangeSelection={true}
        // suppressCellSelection={true}
        // suppressContextMenu={true}
        // suppressRowClickSelection={true}
        // rowSelection={"multiple"}
        enableCellChangeFlash={true}
        onGridReady={onGridReady}
        getRowNodeId={getRowNodeId}
        // frameworkComponents={{
        //   GridCellLink: GridCellLink,
        //   GridCellTimeAgoLink: GridCellTimeAgoLink
        // }}
        sideBar={{
          toolPanels: [
            {
              id: "columns",
              labelDefault: "Columns",
              labelKey: "columns",
              iconKey: "columns",
              toolPanel: "agColumnsToolPanel",
              toolPanelParams: {
                suppressRowGroups: true,
                suppressValues: true,
                suppressPivotMode: true
              }
            }
          ]
        }}
        statusBar={{
          statusPanels: [
            {
              statusPanel: "agAggregationComponent",
              statusPanelParams: {
                // possible values are: 'count', 'sum', 'min', 'max', 'avg'
                aggFuncs: ["sum"]
              }
              // align: "left"
            },
            {
              statusPanel: "agTotalRowCountComponent",
              align: "left"
            }
            // {
            //   statusPanel: "agFilteredRowCountComponent",
            //   align: "left"
            // }
          ]
        }}
      />
    </AppContainer>
  );
};
