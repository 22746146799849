import React, { useCallback, useEffect, useState } from "react";
import {
  ApiStatusWrapper,
  AppContainer,
  ButtonPrimary,
  Grid,
  GridCellLink,
  GridCellTime,
  GridCellTimeAgo,
  GridCellTypes
} from "../components";
import {
  ApiStatus,
  CalcBidCategoryFromBidType,
  DBFields,
  GridConfigDefault,
  GridFields,
  InternalLinks,
  VadvisorApi
} from "../utils";
import { ApiMethodTypes, useApi } from "../hooks";
import { NavLink } from "react-router-dom";
const GRID_HEIGHT_OFFSET = 225;

export const Solve = ({ history, match }) => {
  const { solveId, bidSettlementDate } = match.params;

  //region User Map
  // const {
  //   sendRequest: usersSendRequest,
  //   data: usersData,
  //   apiStatus: usersApiStatus
  // } = useApi({ method: ApiMethodTypes.Get });
  // const [userMappings, setUserMappings] = useState({});
  //
  // //fetch users
  // useEffect(
  //   () => {
  //     usersSendRequest({ url: AuthApi.GetUsersUrl() });
  //   },
  //   [usersSendRequest]
  // );
  // //update users data
  // useEffect(
  //   () => {
  //     if (usersApiStatus === ApiStatus.Success && usersData) {
  //       let userMappings = {};
  //
  //       usersData.forEach(row => {
  //         userMappings[row[DBFields.UserId]] = row[DBFields.UserDisplayName];
  //       });
  //       setUserMappings(userMappings);
  //     }
  //   },
  //   [usersData, usersApiStatus]
  // );
  //
  // //endregion

  //region Solve
  const {
    sendRequest: solveSendRequest,
    data: solveData,
    apiStatus: solveApiStatus
  } = useApi({ method: ApiMethodTypes.Get });

  //fetch solve
  useEffect(
    () => {
      solveSendRequest({
        url: VadvisorApi.GetSolveBidsBySolveId({ solveId, bidSettlementDate })
      });
    },
    [solveSendRequest, solveId, bidSettlementDate]
  );

  useEffect(
    () => {
      if (solveApiStatus === ApiStatus.Success && solveData) {
        const newRows = solveData.map(row => ({
          [GridFields.Duid]: row[DBFields.Duid],
          [GridFields.BidType]: row[DBFields.BidType],
          [GridFields.RefBidAemoId]: row["reference_bid_aemo_transaction_id"],
          [GridFields.CurrentBidAemoId]: row["current_bid_aemo_transaction_id"],
          [GridFields.IsSentForSubmission]: row["is_sent_for_submission"],
          [GridFields.SkippedReason]: row["skipped_reason"],
          [GridFields.LastModifiedBy]: row[DBFields.LastModifiedBy],
          [GridFields.LastModifiedAt]: row[DBFields.LastModifiedAt]
        }));
        setRowData(newRows);
      }
    },
    [solveApiStatus, solveData]
  );

  //endregion

  //region Grid
  const [gridHeight, setGridHeight] = useState(600);
  const [rowData, setRowData] = useState([]);
  const [columnDefs, setColumnDefs] = useState([]);
  useEffect(
    () => {
      const cols = [
        {
          headerName: "DUID",
          field: GridFields.Duid,
          width: 120,
          rowGroupIndex: 1,
          hide: true
        },
        {
          headerName: "Bid Type",
          field: GridFields.BidType,
          width: 120
        },
        {
          headerName: "Submitted?",
          headerTooltip: "Is Sent For Submission",
          field: GridFields.IsSentForSubmission,
          width: 110,
          refData: { true: "SUBMITTED", false: "SKIPPED" },
          cellStyle: params => {
            if (params.value === true) {
              return { backgroundColor: "rgba(10,166,121,0.4)" };
            } else if (params.value === false) {
              return { backgroundColor: "rgba(255,77,79,0.4)" };
            } else {
              return null;
            }
          }
        },
        {
          headerName: "Skipped Reason",
          field: GridFields.SkippedReason,
          width: 250
        },
        {
          headerName: "Reference Bid AEMO Transaction Id",
          field: GridFields.RefBidAemoId,
          width: 250,
          cellRenderer: GridCellTypes.GridCellLink,
          cellRendererParams: params =>
            params.data
              ? {
                  link: InternalLinks.AckSetOffer({
                    aemoTransactionId: params.value,
                    bidSettlementDate: bidSettlementDate,
                    bidCategory: CalcBidCategoryFromBidType({
                      bidType: params.data[GridFields.BidType]
                    }),
                    duid: params.data[GridFields.Duid],
                    bidType: params.data[GridFields.BidType]
                  }),
                  openInNewTab: true
                }
              : null
        },
        {
          headerName: "Current Bid AEMO Transaction Id ",
          field: GridFields.CurrentBidAemoId,
          width: 260,
          cellRenderer: GridCellTypes.GridCellLink,
          cellRendererParams: params =>
            params.data
              ? {
                  link: InternalLinks.AckSetOffer({
                    aemoTransactionId: params.value,
                    bidSettlementDate: bidSettlementDate,
                    bidCategory: CalcBidCategoryFromBidType({
                      bidType: params.data[GridFields.BidType]
                    }),
                    duid: params.data[GridFields.Duid],
                    bidType: params.data[GridFields.BidType]
                  }),
                  openInNewTab: true
                }
              : null
        },

        // {
        //   headerName: "Last Modified By",
        //   field: GridFields.LastModifiedBy,
        //   refData: userMappings,
        //   width: 150
        // },
        {
          headerName: "Last Modified At",
          field: GridFields.LastModifiedAt,
          cellRenderer: GridCellTypes.GridCellTimeAgo,
          width: 150
        }
      ];
      setColumnDefs(cols);
    },
    [setColumnDefs, bidSettlementDate]
  );

  const onGridReady = useCallback(params => {
    // setGridApi(params.api);
    // params.api.sizeColumnsToFit();
  }, []);

  //once on mount
  useEffect(() => {
    setGridHeight(window.innerHeight - GRID_HEIGHT_OFFSET);
  }, []);

  const getRowNodeId = useCallback(data => {
    return `${data[GridFields.Duid]}_${data[GridFields.BidType]}
    }`;
  }, []);
  //endregion

  return (
    <AppContainer history={history} match={match}>
      <ButtonPrimary>
        <NavLink to={InternalLinks.SolveResult({ solveId, bidSettlementDate })}>
          See Solve Results
        </NavLink>
      </ButtonPrimary>
      <ApiStatusWrapper
        style={{ position: "absolute", top: "49%", left: "49%", zIndex: 10 }}
        statuses={[solveApiStatus]}
      />
      <Grid
        gridHeight={gridHeight}
        defaultColDef={GridConfigDefault}
        columnDefs={columnDefs}
        groupDefaultExpanded={-1}
        rowData={rowData}
        suppressCellSelection={true}
        suppressContextMenu={false}
        suppressRowClickSelection={false}
        enableCellChangeFlash={true}
        onGridReady={onGridReady}
        getRowNodeId={getRowNodeId}
        frameworkComponents={{
          GridCellTimeAgo: GridCellTimeAgo,
          GridCellTime: GridCellTime,
          GridCellLink: GridCellLink
        }}
      />
    </AppContainer>
  );
};
