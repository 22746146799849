import React from "react";
import { object, number } from "prop-types";

import { AgGridReact } from "@ag-grid-community/react";
import { AllModules } from "@ag-grid-enterprise/all-modules";

import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
// import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-material.css";
import "./grid.css";
import {LicenseManager} from '@ag-grid-enterprise/core'
LicenseManager.setLicenseKey('CompanyName=pdView Pty Ltd,LicensedApplication=pdBidr,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=1,AssetReference=AG-018508,ExpiryDate=3_September_2022_[v2]_MTY2MjE1OTYwMDAwMA==a195f3335f3622dbdb90caf54afe37ab');


export const Grid = props => (
  <div
    className="ag-theme-balham grid"
    style={{
      height: props.gridHeight,
      width: "100%",
      margin: "8px 0",
      ...props.containerStyle
    }}
  >
    <AgGridReact
      modules={AllModules}
      {...props}
      rowClassRules={{
        "group-row": params => params.node.group,
        ...props.rowClassRules
      }}
    />
  </div>
);

Grid.propTypes = {
  gridHeight: number.isRequired,
  containerStyle: object
};
