import React from "react";
import { object, string } from "prop-types";
import { Alert } from "antd";

export const SectionTooltip = ({ style, message }) => (
  <Alert style={style} message={message} type="info" showIcon />
);

SectionTooltip.propTypes = {
  style: object,
  message: string.isRequired
};
