import React from "react";
import { object } from "prop-types";
import { ButtonPrimary } from "../generic";
import { InternalLinks } from "../../utils/constants";

export const ButtonBackHome = ({ style }) => (
  <ButtonPrimary href={InternalLinks.Home} size={"large"} style={style}>
    Go to home
  </ButtonPrimary>
);

ButtonBackHome.propTypes = {
  style: object
};
