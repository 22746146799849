import React, { useEffect, useCallback, useState } from "react";
import {
  ApiStatusWrapper,
  Grid,
  GridCellLink,
  GridCellTypes,
  GridCellTimeAgo
} from "../index";
import {
  GridConfigDefault,
  GridFields,
  AuthApi,
  ParametersApi,
  ApiStatus,
  DBFields,
  InternalLinks
} from "../../utils";
import { ApiMethodTypes, useApi } from "../../hooks";
import moment from "moment";

const GRID_HEIGHT_OFFSET = 215;
const UPDATE_TIMER_INTERVAL = 5000; //10 secs
const NEM_DT_FORMAT = "YYYY-MM-DDTHH:mm:00";
export const BidParams = ({ categoryId }) => {
  //region NEM time
  const [now, setNow] = useState(
    moment()
      .utc()
      .add(10, "hours")
      .format(NEM_DT_FORMAT)
  );
  const updateNow = useCallback(
    () => {
      setNow(
        moment()
          .utc()
          .add(10, "hours")
          .format(NEM_DT_FORMAT)
      ); //update now nem time
    },
    [setNow]
  );
  //start update timer
  useEffect(
    () => {
      const timer = setInterval(updateNow, UPDATE_TIMER_INTERVAL);
      return () => clearInterval(timer);
    },
    [updateNow]
  );
  //endregion
  //region UserMappings
  const {
    sendRequest: usersSendRequest,
    data: usersData,
    apiStatus: usersApiStatus
  } = useApi({ method: ApiMethodTypes.Get });
  const [userMappings, setUserMappings] = useState({});

  //fetch users
  useEffect(
    () => {
      usersSendRequest({ url: AuthApi.GetUsersUrl() });
    },
    [usersSendRequest]
  );
  //update users data
  useEffect(
    () => {
      if (usersApiStatus === ApiStatus.Success && usersData) {
        let userMappings = {};

        usersData.forEach(row => {
          userMappings[row[DBFields.UserId]] = row[DBFields.UserDisplayName];
        });
        setUserMappings(userMappings);
      }
    },
    [usersData, usersApiStatus]
  );

  //endregion
  //region param mappings
  const [paramMappings, setParamMappings] = useState({});

  const {
    sendRequest: configParamsSendRequest,
    data: configParamsData,
    apiStatus: configParamsApiStatus
  } = useApi({ method: ApiMethodTypes.Get });

  //fetch
  useEffect(
    () => {
      configParamsSendRequest({
        url: ParametersApi.GetConfigParams({ categoryId })
      });
    },
    [configParamsSendRequest, categoryId]
  );

  //received response
  useEffect(
    () => {
      if (configParamsApiStatus === ApiStatus.Success && configParamsData) {
        let dict = {};
        configParamsData.forEach(row => {
          dict[row[DBFields.ParameterId]] = row[DBFields.ParameterName];
        });
        setParamMappings(dict);
      }
    },
    [configParamsData, configParamsApiStatus]
  );

  //endregion

  //region Grid
  const [gridHeight, setGridHeight] = useState(600);
  const [gridApi, setGridApi] = useState();

  const [columnDefs, setColumnDefs] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [nextWeek] = useState(moment().add(7, "d"));

  //define columns
  useEffect(
    () => {
      if (paramMappings && Object.keys(paramMappings).length > 0) {
        const cols = [
          {
            headerName: "Target",
            floatingFilter: true,
            filter: "agSetColumnFilter",
            filterParams: { excelMode: "windows" },

            children: [
              {
                headerName: "Parameter",
                field: GridFields.ParameterId,
                floatingFilter: true,
                filter: "agSetColumnFilter",
                filterParams: {
                  excelMode: "windows",
                  valueFormatter: params => paramMappings[params.value]
                },
                // refData: paramMappings,
                cellRenderer: GridCellTypes.GridCellLink,
                cellRendererParams: params =>
                  params.data
                    ? {
                        link: params.data[GridFields.BidParamLink],
                        value:
                          paramMappings[params.data[GridFields.ParameterId]] //lookup param name
                      }
                    : null
              },
              {
                headerName: "DUID",
                field: GridFields.Duid,
                rowGroupIndex: 0,
                hide: false,
                floatingFilter: true,
                filter: "agSetColumnFilter",
                filterParams: { excelMode: "windows" },
                columnGroupShow: "open"
              },
              {
                headerName: "Bid Type",
                field: GridFields.BidType,
                rowGroupIndex: 1,
                hide: false,
                floatingFilter: true,
                filter: "agSetColumnFilter",
                filterParams: { excelMode: "windows" },
                columnGroupShow: "open"
              }
            ]
          },
          {
            headerName: "Current NEM time",
            children: [
              {
                headerName: "Value",
                field: GridFields.SingleValue,
                type: "numericColumn"
              },
              {
                headerName: "To Date Time",
                field: GridFields.SingleToDateTime
              },

              {
                headerName: "Last Modified At",
                field: GridFields.SingleLastModifiedAt,
                cellRenderer: GridCellTypes.GridCellTimeAgo
              },
              {
                headerName: "Last Modified By",
                field: GridFields.SingleLastModifiedBy,
                refData: userMappings,
                columnGroupShow: "open"
              }
            ]
          },
          {
            headerName: "All Time",
            children: [
              /*   {
                headerName: "From Date Time",
                field: GridFields.FromDateTime
              },  */
              {
                headerName: "To Date Time",
                field: GridFields.ToDateTime,
                cellStyle: params => {
                  if (params.value) {
                    const mValue = moment(params.value);

                    if (mValue < nextWeek) {
                      return { backgroundColor: "rgba(255,77,79,0.4)" }; //color code red if value finishing in a week
                    } else {
                      return null;
                    }
                  }
                }
              },
              {
                headerName: "Last Modified At",
                field: GridFields.LastModifiedAt,
                cellRenderer: GridCellTypes.GridCellTimeAgo
              },
              {
                headerName: "Last Modified By",
                field: GridFields.LastModifiedBy,
                refData: userMappings,
                columnGroupShow: "open"
              }
            ]
          }
        ];
        setColumnDefs(cols);
      }
    },
    [setColumnDefs, paramMappings, userMappings, nextWeek]
  );

  const onGridReady = useCallback(params => {
    setGridApi(params.api);
    params.api.sizeColumnsToFit();
  }, []);

  const getUniqueKey = useCallback(
    ({ duid, bidType, parameterId }) => `${duid}_${bidType}_${parameterId}`,
    []
  );
  //turning those make the expand/collapse to show rows that should be hidden
  const getRowNodeId = useCallback(
    data => {
      return getUniqueKey({
        duid: data[GridFields.Duid],
        bidType: data[GridFields.BidType],
        parameterId: data[GridFields.ParameterId]
      });
    },
    [getUniqueKey]
  );

  const updateGridData = useCallback(
    ({ gridFields, dataDict }) => {
      let newRows = [];

      //update grid
      if (gridApi) {
        gridApi.forEachNode(node => {
          if (node && node.group === false && node.data) {
            const nodeId = node.id;

            const rowData = dataDict[nodeId];

            let data = node.data; //copy data
            gridFields.forEach(gf => {
              let value = ""; //default set fields to null, to empty string as ag recommends
              if (rowData) {
                value = rowData[gf];
              }
              data[gf] = value;
            });
            newRows.push(data); //only need to send data changes, not whole row node
          }
        });
        gridApi.applyTransaction({ update: newRows });
      }
    },
    [gridApi]
  );

  const resizeGrid = useCallback(() => {
    setGridHeight(window.innerHeight - GRID_HEIGHT_OFFSET);
  }, []);

  //once on mount
  useEffect(
    () => {
      resizeGrid();
    },
    [resizeGrid]
  );

  //get settings data
  const {
    sendRequest: settingsParamsSendRequest,
    data: settingsParamsData,
    apiStatus: settingsParamsApiStatus
  } = useApi({ method: ApiMethodTypes.Get });

  //fetch
  useEffect(
    () => {
      settingsParamsSendRequest({
        url: ParametersApi.GetSettingsParamsDuidBidTypesUrl({ categoryId })
      });
    },
    [settingsParamsSendRequest, categoryId]
  );

  //received response
  useEffect(
    () => {
      if (settingsParamsApiStatus === ApiStatus.Success && settingsParamsData) {
        const rows = settingsParamsData.map(row => {
          const parameterId = row[DBFields.ParameterId];
          const duid = row[DBFields.Duid];
          const bidType = row[DBFields.BidType];
          return {
            [GridFields.ParameterId]: parameterId,
            [GridFields.Duid]: duid,
            [GridFields.BidType]: bidType,
            [GridFields.BidParamLink]: InternalLinks.BidParam({
              categoryId,
              parameterId,
              duid,
              bidType
            })
          };
        });

        setRowData(rows);
      }
    },
    [settingsParamsData, settingsParamsApiStatus, categoryId]
  );

  //get pv info dates
  const {
    sendRequest: pvInfoDatesSendRequest,
    data: pvInfoDatesData,
    apiStatus: pvInfoDatesApiStatus
  } = useApi({ method: ApiMethodTypes.Get });

  //fetch
  useEffect(
    () => {
      pvInfoDatesSendRequest({
        url: ParametersApi.GetBidParamValueInfoDatesBySettingsUrl({
          categoryId
        })
      });
    },
    [pvInfoDatesSendRequest, categoryId]
  );

  //received response
  useEffect(
    () => {
      if (pvInfoDatesApiStatus === ApiStatus.Success && pvInfoDatesData) {
        let gridDict = {};
        pvInfoDatesData.forEach(row => {
          const parameterId = row[DBFields.ParameterId];
          const duid = row[DBFields.Duid];
          const bidType = row[DBFields.BidType];
          const rowId = getUniqueKey({ parameterId, duid, bidType });
          gridDict[rowId] = {
            [GridFields.FromDateTime]: row[DBFields.MinFromDateTime],
            [GridFields.ToDateTime]: row[DBFields.MaxToDateTime]
          };
        });
        updateGridData({
          gridFields: [GridFields.FromDateTime, GridFields.ToDateTime],
          dataDict: gridDict
        });
      }
    },
    [pvInfoDatesData, pvInfoDatesApiStatus, getUniqueKey, updateGridData]
  );

  //get pv info last modified
  const {
    sendRequest: pvInfoLastModifiedSendRequest,
    data: pvInfoLastModifiedData,
    apiStatus: pvInfoLastModifiedApiStatus
  } = useApi({ method: ApiMethodTypes.Get });

  //fetch
  useEffect(
    () => {
      pvInfoLastModifiedSendRequest({
        url: ParametersApi.GetBidParamValueInfoLastModifiedBySettingsUrl({
          categoryId
        })
      });
    },
    [pvInfoLastModifiedSendRequest, categoryId]
  );

  //received response
  useEffect(
    () => {
      if (
        pvInfoLastModifiedApiStatus === ApiStatus.Success &&
        pvInfoLastModifiedData
      ) {
        let gridDict = {};
        pvInfoLastModifiedData.forEach(row => {
          const parameterId = row[DBFields.ParameterId];
          const duid = row[DBFields.Duid];
          const bidType = row[DBFields.BidType];
          const rowId = getUniqueKey({ parameterId, duid, bidType });
          gridDict[rowId] = {
            [GridFields.LastModifiedAt]: row[DBFields.LastModifiedAt],
            [GridFields.LastModifiedBy]: row[DBFields.LastModifiedBy]
          };
        });
        updateGridData({
          gridFields: [GridFields.LastModifiedAt, GridFields.LastModifiedBy],
          dataDict: gridDict
        });
      }
    },
    [
      pvInfoLastModifiedData,
      pvInfoLastModifiedApiStatus,
      getUniqueKey,
      updateGridData
    ]
  );

  //get single value
  const {
    sendRequest: singleValueSendRequest,
    data: singleValueData,
    apiStatus: singleValueApiStatus
  } = useApi({ method: ApiMethodTypes.Get });

  //fetch
  useEffect(
    () => {
      singleValueSendRequest({
        url: ParametersApi.GetBidParamValueBySettingsSingleUrl({
          settlementDate: now + "Z",
          categoryId
        })
      });
    },
    [singleValueSendRequest, now, categoryId]
  );

  //received response
  useEffect(
    () => {
      if (singleValueApiStatus === ApiStatus.Success && singleValueData) {
        let gridDict = {};
        singleValueData.forEach(row => {
          const parameterId = row[DBFields.ParameterId];
          const duid = row[DBFields.Duid];
          const bidType = row[DBFields.BidType];
          const rowId = getUniqueKey({ parameterId, duid, bidType });
          gridDict[rowId] = {
            [GridFields.SingleLastModifiedAt]: row[DBFields.LastModifiedAt],
            [GridFields.SingleLastModifiedBy]: row[DBFields.LastModifiedBy],
            [GridFields.SingleValue]: row[DBFields.Val],
            [GridFields.SingleToDateTime]: row[DBFields.ToDateTime]
          };
        });
        updateGridData({
          gridFields: [
            GridFields.SingleLastModifiedAt,
            GridFields.SingleLastModifiedBy,
            GridFields.SingleValue,
            GridFields.SingleToDateTime
          ],
          dataDict: gridDict
        });
      }
    },
    [singleValueData, singleValueApiStatus, getUniqueKey, updateGridData]
  );

  //endregion

  return (
    <ApiStatusWrapper statuses={[configParamsApiStatus, usersApiStatus]}>
      {configParamsData &&
        userMappings &&
        paramMappings && (
          <div>
            <label>
              <b>NEM time: </b>
              {now.replace("T", " ")}
            </label>
            <Grid
              gridHeight={gridHeight}
              defaultColDef={GridConfigDefault}
              autoGroupColumnDef={{
                headerName: "DUID/Bid Type"

                // field: GridFields.BidType

                //width: 200
              }}
              columnDefs={columnDefs}
              rowData={rowData}
              groupDefaultExpanded={-1}
              suppressCellSelection={true}
              suppressContextMenu={true}
              suppressRowClickSelection={true}
              enableCellChangeFlash={true}
              onGridReady={onGridReady}
              getRowNodeId={getRowNodeId} // turning those make the expand/collapse to show rows that should be hidden
              frameworkComponents={{
                GridCellLink: GridCellLink,
                GridCellTimeAgo: GridCellTimeAgo
              }}
            />
          </div>
        )}
    </ApiStatusWrapper>
  );
};
