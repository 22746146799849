import React, { useCallback, useContext, useEffect, useState } from "react";
import { func, bool, array, string } from "prop-types";
import { ActionDrawer } from "./ActionDrawer";
import { ActionTypeIds } from "../ActionTypeIds";
import { getActionTextFromId } from "../GetActionTextFromId";
import { AppContext } from "../../../contexts";
import { DatePicker } from "antd";
import moment from "moment";

import {
  TargetTypes,
  ApiStatus,
  VofferApi,
  TransformToDictByDuidBidType,
  GetDuidBidTypeKey,
  DBFields
} from "../../../utils";

import { Form, FormItem, InputSelect, InputTextArea } from "../../generic";
import { ActionTargetCheck } from "../ActionTargetCheck";
import { ActionChanges, ChangeStatusTypes } from "../action_changes";
import {
  DatePickerSettlementDate,
  ApiStatusWrapper,
  GridCellTypes
} from "../../../components";
import { ApiMethodTypes, useApi } from "../../../hooks";
import { GridDuidFields } from "../../grid/grid_duid/GridDuidFields";
import { ValidationMessageType } from "../../offer_table/utils/enums";
import { ValidationMessage } from "../../validations/ValidationMessage";
const {
  GetDraftsUrl,
  PostSubmitLoadFromFiles,
  GetIsRebidPeriodUrl,
  GetConfigRebidCategoriesUrl,
  GetTemplatesInaSetUrl
} = VofferApi;
const FORM_FIELD = {
  RebidReason: "rebid_reason",
  RebidCategory: "rebid_category",
  EventTime: "event_time",
  AwareTime: "aware_time",
  DecisionTime: "decision_time"
};

export const ActionDrawerSubmit = React.memo(
  ({
    drawerVisible,
    onDrawerClose,
    targets,
    targetType,
    targetLabel,
    defaultSubmitDate
  }) => {
    const { loggedInUserId } = useContext(AppContext);
    const [changes, setChanges] = useState([]);
    const [isRebidPeriod, setIsRebidPeriod] = useState(true);
    const [rebidCategoriesOptions, setRebidCategoriesOptions] = useState([]);
    const [submitDate, setSubmitDate] = useState(defaultSubmitDate);
    const timeNemNow = moment()
      .utc()
      .add(10, "hours");

    const {
      sendRequest: rebidCheckSendRequest,
      data: rebidChecksData,
      apiStatus: rebidCheckApiStatus
    } = useApi({ method: ApiMethodTypes.Get });

    const {
      sendRequest: rebidCategoriesSendRequest,
      data: rebidCategoriesData,
      apiStatus: rebidCategoriesApiStatus
    } = useApi({ method: ApiMethodTypes.Get });

    const {
      sendRequest: offersSendRequest,
      data: offersData,
      apiStatus: offersApiStatus
    } = useApi({ method: ApiMethodTypes.Get });

    const {
      sendRequest: postSubmitSendRequest,
      apiStatus: postSubmitApiStatus,
      clear: postSubmitClear
    } = useApi({ method: ApiMethodTypes.Post });

    const changesCols = [
      {
        field: GridDuidFields.Duid,
        headerName: "Duid",
        width: 80,
        rowGroupIndex: 0,
        hide: true
      },
      {
        field: GridDuidFields.BidType,
        headerName: "Bid Type",
        width: 100,
        hide: true
      },
      {
        field: "change",
        headerName: "Submit Bid",
        cellRenderer: GridCellTypes.GridCellTimeAgo,
        width: 120
      },
      {
        field: "validation",
        headerName: "Validation",
        cellRenderer: GridCellTypes.GridCellValidation
      },
      {
        field: "status",
        headerName: "Result",
        width: 100
      }
    ];

    //fetch rebid check
    useEffect(
      () => {
        if (submitDate !== "") {
          const url = GetIsRebidPeriodUrl({
            bidSettlementDate: submitDate
          });
          rebidCheckSendRequest({ url: url });
        }
      },
      [rebidCheckSendRequest, submitDate]
    );

    //on update rebid check data
    useEffect(
      () => {
        if (rebidCheckApiStatus === ApiStatus.Success && rebidChecksData) {
          setIsRebidPeriod(rebidChecksData[DBFields.IsRebidPeriod]);
        }
      },
      [rebidCheckApiStatus, rebidChecksData]
    );

    //fetch rebid categories
    useEffect(
      () => {
        const url = GetConfigRebidCategoriesUrl();
        rebidCategoriesSendRequest({ url: url });
      },
      [rebidCategoriesSendRequest]
    );

    //on update rebid categories data
    useEffect(
      () => {
        if (
          rebidCategoriesApiStatus === ApiStatus.Success &&
          rebidCategoriesData
        ) {
          const options = rebidCategoriesData.map(row => ({
            text: row[DBFields.RebidCategoryDescription],
            value: row[DBFields.RebidCategoryId]
          }));
          setRebidCategoriesOptions(options);
        }
      },
      [rebidCategoriesData, rebidCategoriesApiStatus]
    );

    //fetch offers to submit
    useEffect(
      () => {
        let url = null;
        switch (targetType) {
          case TargetTypes.Draft:
            url = GetDraftsUrl({
              bidSettlementDate: targetLabel,
              user: loggedInUserId
            });
            break;
          case TargetTypes.Template:
            url = GetTemplatesInaSetUrl({
              templateSetId: targetLabel
            });
            break;
          default:
            console.error(
              `targetType=${targetType} not implemented for fetchOffers`
            );
            break;
        }

        if (!url) return;

        offersSendRequest({ url: url });
      },
      [targetLabel, targetType, loggedInUserId, offersSendRequest]
    );

    //on update offers data
    useEffect(
      () => {
        if (offersApiStatus === ApiStatus.Success && offersData) {
          const dict = TransformToDictByDuidBidType(offersData);
          let changes = [];
          targets.forEach(t => {
            const offer = dict[GetDuidBidTypeKey(t.duid, t.bid_type)];

            let status = ChangeStatusTypes.NoChange;
            let lastModifiedAt = null;
            let file_uri = null;
            if (offer && offer[DBFields.FileURI]) {
              lastModifiedAt = offer[DBFields.LastModifiedAt];
              file_uri = offer[DBFields.FileURI];
              if (file_uri) {
                t.validation = {
                  type: ValidationMessageType.Success,
                  message: "Pass"
                };
                status = ChangeStatusTypes.Update;
              }
            }
            t.change = lastModifiedAt; //<DisplayTimeAgo datetime={} />;
            t.file_uri = file_uri;
            t.status = status;

            changes.push(t);
          });

          setChanges(changes);
        }
      },
      [offersData, offersApiStatus, targets]
    );

    const closeDrawer = useCallback(
      () => {
        postSubmitClear();
        onDrawerClose();
      },
      [onDrawerClose, postSubmitClear]
    );

    const handleCancel = useCallback(
      () => {
        closeDrawer();
      },
      [closeDrawer]
    );

    const handleSubmit = useCallback(
      fieldsValue => {
        const rebidReason = fieldsValue[FORM_FIELD.RebidReason];
        const rebidCategory = fieldsValue[FORM_FIELD.RebidCategory];
        const eventTime = fieldsValue[FORM_FIELD.EventTime];
        const awareTime = fieldsValue[FORM_FIELD.AwareTime];
        const decisionTime = fieldsValue[FORM_FIELD.DecisionTime];

        const bids = [];
        changes.forEach(t => {
          if (t.status === ChangeStatusTypes.Update) {
            bids.push({
              duid: t.duid,
              bid_type: t.bid_type,
              load_from_file_uri: t.file_uri
            });
          }
        });
        const data = {
          bids: bids,
          rebid_explanation: {
            reason: rebidReason,
            category: rebidCategory,
            eventTime: eventTime,
            awareTime: awareTime,
            decisionTime: decisionTime
          }
        };
        const url = PostSubmitLoadFromFiles({
          bidSettlementDate: submitDate
        });
        postSubmitSendRequest({ url: url, data: data });
      },
      [submitDate, changes, postSubmitSendRequest]
    );

    //on update post
    useEffect(
      () => {
        if (postSubmitApiStatus === ApiStatus.Success) {
          closeDrawer();
        }
      },
      [postSubmitApiStatus, closeDrawer]
    );

    const onChangeSubmitDate = useCallback(
      date => {
        setSubmitDate(date);
      },
      [setSubmitDate]
    );

    const noChanges =
      !changes ||
      changes.length < 1 ||
      changes.filter(c => c.status === ChangeStatusTypes.Update).length < 1;

    const disableEdit =
      postSubmitApiStatus === ApiStatus.Loading ||
      postSubmitApiStatus === ApiStatus.Success ||
      noChanges;

    return (
      <ActionDrawer
        title={`${getActionTextFromId(
          targetType === TargetTypes.Draft
            ? ActionTypeIds.SubmitDrafts
            : ActionTypeIds.SubmitTemplates
        )} `}
        visible={drawerVisible}
        onClose={closeDrawer}
      >
        <ActionTargetCheck targetsCount={targets.length}>
          <Form
            onSubmit={handleSubmit}
            onCancel={handleCancel}
            apiStatus={postSubmitApiStatus}
            labelSpan={8}
            disableEdit={disableEdit}
            initialValues={{
              submit_date: submitDate,
              [FORM_FIELD.EventTime]: timeNemNow,
              [FORM_FIELD.DecisionTime]: timeNemNow,
              [FORM_FIELD.AwareTime]: timeNemNow
            }}
            submitButtonText={"Submit"}
            disableSubmit={disableEdit}
            wrapperFooter={
              noChanges ? (
                <ValidationMessage
                  message={"No draft bid to submit"}
                  messageType={ValidationMessageType.Warning}
                />
              ) : null
            }
          >
            <FormItem
              label={"Submit to date"}
              field={"submit_date"}
              isRequired={true}
              inputControl={
                <DatePickerSettlementDate onChange={onChangeSubmitDate} />
              }
            />
            <ApiStatusWrapper statuses={[rebidCheckApiStatus]}>
              {isRebidPeriod && (
                <div>
                  <ApiStatusWrapper statuses={[rebidCategoriesApiStatus]}>
                    <FormItem
                      label={"Rebid Category"}
                      field={FORM_FIELD.RebidCategory}
                      isRequired={true}
                      inputControl={
                        <InputSelect options={rebidCategoriesOptions} />
                      }
                    />
                  </ApiStatusWrapper>
                  <FormItem
                    label={"Rebid Reason"}
                    field={FORM_FIELD.RebidReason}
                    isRequired={true}
                    inputControl={<InputTextArea rows={3} />}
                  />
                  <FormItem
                    label={"Event time"}
                    field={FORM_FIELD.EventTime}
                    isRequired={true}
                    inputControl={
                      <DatePicker
                        showTime={{ format: "HH:mm:ss" }}
                        format={"YYYY-MM-DD HH:mm:ss"}
                      />
                    }
                  />
                  <FormItem
                    label={"Aware time"}
                    field={FORM_FIELD.AwareTime}
                    isRequired={true}
                    inputControl={
                      <DatePicker
                        showTime={{ format: "HH:mm:ss" }}
                        format={"YYYY-MM-DD HH:mm:ss"}
                      />
                    }
                  />
                  <FormItem
                    label={"Decision time"}
                    field={FORM_FIELD.DecisionTime}
                    isRequired={true}
                    inputControl={
                      <DatePicker
                        showTime={{ format: "HH:mm:ss" }}
                        format={"YYYY-MM-DD HH:mm:ss"}
                      />
                    }
                  />
                </div>
              )}
            </ApiStatusWrapper>

            <ApiStatusWrapper statuses={[offersApiStatus]}>
              {changes &&
                changes.length > 0 && (
                  <ActionChanges
                    changes={changes}
                    cols={changesCols}
                    gridHeight={300}
                  />
                )}
            </ApiStatusWrapper>
          </Form>
        </ActionTargetCheck>
      </ActionDrawer>
    );
  }
);

ActionDrawerSubmit.propTypes = {
  drawerVisible: bool.isRequired,
  onDrawerClose: func,
  targets: array,
  targetType: string.isRequired,
  targetLabel: string.isRequired,
  defaultSubmitDate: string
};
